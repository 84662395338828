import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import SharePopup from '../../../components/org/global/SharePopup.js'
import IssueHeader from "../../../components/org/issue/IssueHeader";
import IssueNotes from "../../../components/org/issue/IssueNotes";
import { db } from "../../../config/firebase";
// import { useLocation } from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";

function IssueNotesPage({ match }) {
	const [allNotesRef, setAllNotesRef] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [issueId, setIssueId] = useState("");
  // const {pathname} = useLocation();
  const [sharePopup, setSharePopup] = useState(false);
	// const [upvotedNotes, setUpvotedNotes] = useState([]);
	// const [upvotedNotesRef, setUpvotedNotesRef] = useState(null);

	// const { currUser, currUserRef } = useAuth();

	const [issueInfo, setIssueInfo] = useState({});
	useEffect(async () => {
		console.log("why here :(");
		if (match && match.params.issue) {
			let issueId = match.params.issue;

			{
				if (issueId.length == 0) {
					setError("Bad link. No issue id given.");
				} else {
					setIssueId(issueId);
					setAllNotesRef(
						db.collection("issues").doc(issueId).collection("notes")
					);
					const issueData = await db
						.collection("issues")
						.doc(issueId)
						.get();
					setIssueInfo(issueData.data());
				}
			}
			// setUpvotedNotesRef(db.collection("civilians").doc(currUser.uid).collection("upvotedNotes"));
			// const snapshot = await db.collection("civilians").doc(currUser.uid).collection("upvotedNotes").get();
			// snapshot.forEach((doc) => {
			//   setUpvotedNotes((upvotedNotes) => [...upvotedNotes, doc.id]);
			// });
			setLoading(false);
		}
	}, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>

      {/* url={`https://grassrootsgov.org${pathname.slice(4)}`} */}

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/issues/${issueId}/notes`}
          type="issue"
          >
        </SharePopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<IssueHeader
					issueId={issueId}
					title={issueInfo.issueTitle}
					legislator={`${issueInfo.legislator.firstName} ${issueInfo.legislator.lastName}`}
					legislatorId={issueInfo.legislator.id}
					region={`${issueInfo.legislator.region}, ${
						issueInfo.legislator.chamber === "Senate"
							? "State-Wide"
							: "District " + issueInfo.legislator.district
					}`}
          toggleSharePopup={toggleSharePopup}
				/>

				<IssueNotes
					notesRef={allNotesRef}
					issueId={match.params.issue}
					issueDistrict={issueInfo.legislator.district}
					issueState={issueInfo.state}
				/>
				{/* <IssueNotes notesRef={allNotesRef} issueId={match.params.issue} userId={currUser.uid}/> */}
			</div>
		</AppLayout>
	);
}

export default IssueNotesPage;
