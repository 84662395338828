import React from "react";
import { Link } from "react-router-dom";
import NoteCard from "../../global/notes/NoteCard1";

const HomeNotesContainer = (props) => {
  if (!props.notes || props.notes.length == 0) {
    return <div className="mt-4 text-lg">You currently have no notes. Find an issue and post a note to get started!</div>;
  }

  return (
    <div className="bg-gray-100 rounded-lg px-14 py-10 mb-5">

      <div className="flex justify-between items-center mb-8">
        <div className="inline-flex space-x-5 items-center">
          {/* <img
            src={props.profilePic}
            className="rounded-full object-cover h-14 w-14"
          />
          <h1 className="whitespace-no-wrap text-3xl font-bold leading-tight text-gray-900">
            {props.name} ({props.region})
          </h1> */}
          <h1 className="whitespace-no-wrap text-3xl font-bold leading-tight text-gray-900">
            All Notes
          </h1>
        </div>
        {/* <div className="inline-flex space-x-8">
          <Link className="whitespace-no-wrap flex items-center text-xl font-semibold leading-tight text-red-500">
            View all
            <svg
              className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </Link>
        </div> */}
      </div>

      <div className="grid grid-cols-4 gap-x-12">
        {props.notes.map((note) => {
          return (
            <NoteCard
              {...note}
              noteId = {note}
            />
          );
        })}
      </div>

    </div>
  );
};

export default HomeNotesContainer;
