import React, { useState, useContext, useEffect } from "react";
import AppLayout from "../../../../layouts/AppLayout";
import { Link, NavLink, useHistory } from "react-router-dom";
// import AddBtn from "../../../components/civ/global/buttons/AddButton";
import IssueCard from "../../../../components/civ/global/issues/IssueCard";
import { StateDBContext } from "../../../../contexts/StateContext";

const StateIssuesTopicListPage = (props) => {
	let history = useHistory();

	const topic = props.location.state;

	const activeIssues = useContext(StateDBContext);
	let [issuesDisplay, showIssues] = useState(
		<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>
	);

	// update component results when data fetched & passed
	useEffect(() => {
		if (activeIssues[3] != null) {
			showIssues(
				activeIssues[3][topic].map((issue, i) => (
					<IssueCard
						key={i}
						issueId={issue.id}
						issueTitle={issue.data().issueTitle}
						supporters={issue.data().supporters}
						created={issue.data().dateCreated}
						copies={issue.data().regionalCopies}
						legislator={
							issue.data().legislator.firstName +
							" " +
							issue.data().legislator.lastName
						}
						region={
							issue.data().legislator.region +
							"-" +
							issue.data().district
						}
					/>
				))
			);
		}
	}, [activeIssues]);

	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<button
					className="flex space-x-2 items-center focus:outline-none"
					onClick={() => {
						history.goBack();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="flex-shrink-0 h-5 w-5 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
					<h1>Back</h1>
				</button>

				<h1 className="mt-8 whitespace-no-wrap text-3xl font-bold leading-tight text-black">
					State Issues: {topic}
				</h1>

				<br />

				<div className="bg-white shadow overflow-hidden sm:rounded-md">
					<div className="divide-y divide-gray-200">
						{issuesDisplay}
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default StateIssuesTopicListPage;
