import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { Transition } from "@headlessui/react";

const BrowseEventNav = (props) => {

  const [optionsOpen, setOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  }

  return (
    <div className="mt-6">
      <h1 className="text-3xl font-bold">
        {props.title}
      </h1>
      <div className="flex justify-between items-center mt-2">
        <h2>
          Hosted by <Link to={`/org/organizations/${props.organizerId}/latest`} className="hover:underline">{props.organizer}</Link>
        </h2>
        <div className="flex space-x-2 items-center">
          <Link
            to={`/org/events/${props.eventId}/reshare`}
            className="btn-primary">
            Reshare Event
          </Link>
          {/* <button
            className="btn-white">
            Share
          </button> */}
          <div class="relative inline-block text-left pl-2">
            <div>
              <button onClick={toggleOptions} type="button" class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="options-menu" aria-expanded="true" aria-haspopup="true">
                <span class="sr-only">Open options</span>
                {/* <!-- Heroicon name: solid/dots-vertical --> */}
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
            </div>
            <Transition
            show={optionsOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
              <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="py-1" role="none">
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Report content</a>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );

};

export default BrowseEventNav;
