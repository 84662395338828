import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import firebase from "firebase";
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png"

const EventCardSmall = (props) => {

  const [orgSrc, setOrgSrc] = useState(defaultProfilePic)

  useEffect(async () => {

    let storageRef = firebase.storage().ref();
    let userPicRef = storageRef.child(`images/organization/profilePics/${props.organizerId}`)
    try {
      let result = await userPicRef.getDownloadURL()
      setOrgSrc(result)
    } catch(error) {
      console.log(error)
    }

  }, [])

  return (
    <Link to={`/org/events/${props.eventId}/details`}>
      <div className="shadow hover:shadow-md rounded-lg px-6 py-6">
        <div className="flex space-x-8 items-center">
          <img className="rounded-lg h-20 w-20 border-2 border-gray-100" src={orgSrc}/>
          <div className="flex-auto">
            <h1 className="text-2xl font-semibold mb-4">{props.eventTitle}</h1>
            <div className="flex justify-between items-center">
              <div className="flex space-x-4 items-center">
                <h1 className="text-lg font-medium">
                  {props.organizerName}
                </h1>
                <h1 className="text-lg text-gray-500">
                  {props.isVirtual === true ? "Virtual Event" : "In-Person Event"}
                </h1>
              </div>
              <div className="flex items-center text-red-500">
                <Link className="text-md font-semibold">
                  View Event
                </Link>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default EventCardSmall;
