import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
// import firebase from "firebase";
import { useParams } from "react-router";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png"
import firebase from 'firebase'
import jd from '../../../assets/jd.png';


const OrganizationHeader = (props) => {

  let history = useHistory();
  let { organization } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizationId, setOrganizationId] = useState("");
  const [organizationInfo, setOrganizationInfo] = useState({});
  const { currUser, currUserRef } = useAuth();
  const [status, setStatus] = useState({});
  const [following, setFollowing] = useState(props.following);
  // const [src, setSrc] = useState(defaultProfilePic)
  // console.log("following:", following)

  const [ organizationData, setOrganizationData] = useState({
    orgName: props.orgName,
    orgId: props.orgId,
    activeIssueCount: props.activeIssueCount,
    upcomingEventCount: props.upcomingEventCount,
    followerCount: props.followerCount,
  });

  // useEffect(async () => {
  //   let storageRef = firebase.storage().ref();
  //   let userPicRef = storageRef.child(`images/organization/profilePics/${props.orgId}`)
  //   userPicRef.getDownloadURL().then((url) => {
  //     setSrc(url)
  //     // setLoading(false);
  //   }).catch((error) => {
  //     console.log(error)
  //     // setLoading(false);
  //   })
  // })

  // useEffect(async () => {
  //   const issuesFollowingRef = db.collection("civilians").doc(currUser.uid).collection("follows");
  //    const snapshot = await issuesFollowingRef.where("issueId", '==', props.issueId).get();
  //    if (!snapshot.empty) {
  //      setFollowing(true);
  //    }
  // }, [])


  async function followOrg() {

    console.log("following upon trigger:", following)
    if (following) {
      db.collection("civilians").doc(currUser.uid).collection("orgsFollowing").doc(props.orgId).delete().then(() => {
        setFollowing(false)
      });
      db.collection("organizations").doc(props.orgId).update({followerCount: firebase.firestore.FieldValue.increment(-1)})
    }
    else {
      db.collection("civilians").doc(currUser.uid).collection("orgsFollowing").doc(props.orgId).set({
        orgName: props.orgName,
        orgId: props.orgId,
        activeIssueCount: props.activeIssueCount,
        upcomingEventCount: props.upcomingEventCount,
        followerCount: (props.followerCount + 1),
      }).then(() => {
        setFollowing(true)
      });
      db.collection("organizations").doc(props.orgId).update({followerCount: firebase.firestore.FieldValue.increment(1)})
    }

  }

  return (
    <>
      <div className="bg-blue-50 flex justify-between items-end px-16 py-8">
        <div className="flex items-end space-x-10">
          <img src={props.profilePicUrl} className="h-24 w-24 rounded-lg"/>
          <h1 className="font-bold text-3xl">{props.orgName}</h1>
        </div>
        <div className="text-right">
          <h1 className='font-medium'>
            {props.followerCount ? props.followerCount : '0'}{' '}
            {(props.followerCount !== 1) ? "followers" : "follower" }
          </h1>          <h1 className="font-medium">{props.hqCity}, {props.hqState}</h1>
          <div className="mb-3"></div>
          {currUser ?
          <button
            onClick={followOrg}
            className={following ? "btn-secondary mr-2 focus:outline-none" : "btn-primary mr-2 focus:outline-none"}>
            {following ? "Following" : "Follow"}
          </button>
          :
          <button
            onClick={props.toggleAuthPopup}
            className="btn-primary mr-2 focus:outline-none"
          >
            Follow
          </button>
          }
          {/* <button className="btn-white">Share</button> */}
        </div>
      </div>

      <div className="flex justify-between items-center px-16">
        <div className="flex space-x-14 py-6">
          <NavLink to={`/organizations/${props.orgId}/latest`} className="font-bold text-2.5xl text-gray-400 hover:underline"  activeClassName="text-gray-900">
            Latest
          </NavLink>
          <NavLink to={`/organizations/${props.orgId}/issues`} className="font-bold text-2.5xl text-gray-400 hover:underline"  activeClassName="text-gray-900">
            Issues
          </NavLink>
          <NavLink to={`/organizations/${props.orgId}/events`} className="font-bold text-2.5xl text-gray-400 hover:underline"  activeClassName="text-gray-900">
            Events
          </NavLink>
          <NavLink to={`/organizations/${props.orgId}/about`} className="font-bold text-2.5xl text-gray-400 hover:underline"  activeClassName="text-gray-900">
            About
          </NavLink>
        </div>

        <div className="flex space-x-4 items-end">
          {/* <div>
            <label for="location" class="text-sm font-medium text-justiceBlue-500">Sort By</label>
            <select id="location" name="location" class="mt-1 w-full text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md">
              <option selected>Date Posted</option>
              <option>Popularity</option>
              <option>Etc</option>
            </select>
          </div> */}
          {/* <span className="rounded-md shadow-sm">
            <button type="button" className="items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:shadow-outline-red-500 focus:border-indigo-red-600 active:bg-red-600">
              + Suggest Issue/Event
            </button>
          </span> */}
        </div>

      </div>

      <hr className="border-solid mb-1"/>

    </>
  )
}

export default OrganizationHeader;
