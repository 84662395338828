import React from "react";
import OrganizationIssuePost from '../global/organizations/OrganizationIssuePost'
import OrganizationEventPost from '../global/organizations/OrganizationEventPost'

const OrganizationLatest = (props) => {

  return (
    <div>
      {(props.orgPosts && props.orgPosts.length > 0 ) ?
      <div>
        {props.orgPosts && (props.orgPosts).map((post) => {
          if (post.contentType === "issue") {
            console.log("POST!")
            return(
              <OrganizationIssuePost
                postId={post.postId}
                orgId={post.orgId}
                orgName={post.orgName}
                datePosted={post.datePosted}
                postBody={post.postBody}
                issueTitle={post.issue.issueTitle}
                legislatorName={post.issue.legislatorName}
                legislatorParty={post.issue.legislatorParty}
                legislatorState={post.issue.legislatorState}
                issueDateCreated={post.issue.issueDateCreated}
                regionalCopyCount={post.issue.regionalCopyCount}
                supporterCount={post.issue.supporterCount}
                issueTopicTags={post.issue.issueTopicTags}
                issueId={post.issue.issueId}
                deletable={props.isUser}
                profilePicUrl={props.profilePicUrl}
              />
            )
          }
          else {
            return(
              <OrganizationEventPost
                postId={post.postId}
                orgId={post.orgId}
                orgName={post.orgName}
                datePosted={post.datePosted}
                postBody={post.postBody}
                eventTitle={post.event.eventTitle}
                eventOrgName={post.event.eventOrgName}
                eventDateTime={post.event.eventDateTime}
                eventIsVirtual={post.event.eventIsVirtual}
                eventCity={post.event.eventCity}
                eventState={post.event.eventState}
                attendingCount={post.event.attendingCount}
                interestedCount={post.event.interestedCount}
                eventTopicTags={post.event.eventTopicTags}
                eventId={post.event.eventId}
                deletable={props.isUser}
                profilePicUrl={props.profilePicUrl}
              />
            )
          }
        })
        }
      </div>
      :
      <div className="text-xl font-medium">This organization does not have any posts yet.</div>
      }
    </div>
  )

}

export default OrganizationLatest;
