import React, { useState, useEffect } from "react";
import StateMembers from "./StateMembers";
// import { db } from "../../config/firebase";
import legislators from "../../../assets/jsonData/legislators.json";
import {allStates} from "../../../assets/states"

const Members = () => {
  const [members, setMembers] = useState({});
  // const [senators, setSenators] = useState([]);
  // const [house, setHouse] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    // const membersRef = db.collection("legislators");
    // const snapshot = await db.collection("legislators").orderBy("region").get();
    // if (!snapshot.empty) {
    if (Object.keys(members).length === 0) {
      let tempData = {};

      Object.keys(legislators).forEach((id) => {
        if (legislators[id]) {
          let member = legislators[id];
          let state = member.region;
          let chamber = member.chamber;
          if (tempData[state]) {
            if (tempData[state][chamber]) {
              tempData[state][chamber].push(member);
            } else {
              tempData[state][chamber] = [member];
            }
          } else {
            tempData[state] = {};
            tempData[state][chamber] = [member];
          }
        }
      });
      // snapshot.forEach((doc) => {
      //   // if ("firstName" in doc.data() && doc.data()["firstName"] !== "") {
      //   let state = doc.data().region;
      //   let chamber = doc.data().chamber;
      //   if (tempData[state]) {
      //     if (tempData[state][chamber]) {
      //       tempData[state][chamber].push(doc.data());
      //     } else {
      //       tempData[state][chamber] = [doc.data()];
      //     }
      //   } else {
      //     tempData[state] = {};
      //     tempData[state][chamber] = [doc.data()];
      //   }
      // }
      // });

      setMembers(tempData);
    }
    // } else {
    //   console.log("empty snapshot");
    // }
    setLoading(false);
  }, []);

  if (loading) {
    return <></>;
  }
  return (
    <>
      <div className=" h-screen overflow-hidden shadow rounded-lg">
        <div className="bg-justiceBlue-600 px-4 py-5 sm:px-6">
          <h1 className=" whitespace-no-wrap text-2xl font-bold leading-tight text-white">
            All Members of Congress
          </h1>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <div className="w-full h-screen overflow-y-scroll">
            {members &&
              allStates.map((state) => {
                if (members[state]) {
                  return (
                    <>
                      <StateMembers
                        state={state}
                        senate={
                          members[state]["Senate"]
                            ? members[state]["Senate"]
                            : null
                        }
                        house={members[state]["House"]}
                      />
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
