import React from 'react';
import { Link } from "react-router-dom";
import LegLayout from "../../../layouts/LegLayout"

function InboxUnresolvedIssuesPage(props) {
    return (
      <LegLayout>
        <h1>hi</h1>
      </LegLayout>
    );
}

export default InboxUnresolvedIssuesPage;
