import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import BillHeader from "../../../components/civ/billsvotes/BillHeader";
// import BillLearnMore from "../../../components/billsvotes/BillLearnMore";
import { db } from "../../../config/firebase";

function BillsLearnMorePage({ match }) {
	const [billInfo, setBillInfo] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [billId, setBillId] = useState("");
	useEffect(async () => {
		if (match && match.params.bill) {
			let billId = match.params.bill;

			if (billId.length == 0) {
				setError("Bad link. No bill id given.");
			} else {
				setBillId(billId);
				const currBillRef = db.collection("bills").doc(billId);
				const billDoc = await currBillRef.get();
				if (!billDoc.exists) {
					setError("This bill was not found.");
				} else {
					setBillInfo(billDoc.data());
				}
			}
			setLoading(false);
		}
	}, []);

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return (
			<AppLayout>
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					{error}
				</div>
			</AppLayout>
		);
	}
	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<BillHeader
					billId={billId}
					category={billInfo.category}
					chamber={billInfo.chamber}
					cosponsors={billInfo.cosponsors}
					description={billInfo.description}
					introduced={billInfo.introduced}
					number={billInfo.number}
					party={billInfo.party}
					region={billInfo.region}
					relatedIssuesCounter={billInfo.relatedIssuesCounter}
					sponsor={billInfo.sponsor}
				/>

				{/* <IssueLearnMore
          description={billInfo.issueDescription}
          links={billInfo.links}
          relatedBills={billInfo.relatedBills}
          relatedVotes={billInfo.relatedVotes}
          relatedEvents={billInfo.relatedEvents}
        /> */}
			</div>
		</AppLayout>
	);
}

export default BillsLearnMorePage;
