import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";

import StatePage from "../../pages/civ/state/StatePage";

import StateTopIssuesPage from "../../pages/civ/state/issues/StateTopIssuesPage";
import StateLatestIssuesPage from "../../pages/civ/state/issues/StateLatestIssuesPage";
import StateIssuesTopicsPage from "../../pages/civ/state/issues/StateIssuesTopicsPage";
import StateIssuesTopicsListPage from "../../pages/civ/state/issues/StateIssuesTopicListPage"
import StateResolvedIssuesPage from "../../pages/civ/state/issues/StateResolvedIssuesPage";

import StateTopEventsPage from '../../pages/civ/state/events/StateTopEventsPage';
import StateLatestEventsPage from '../../pages/civ/state/events/StateLatestEventsPage';
import StateTypeEventsPage from '../../pages/civ/state/events/StateTypeEventsPage';
import StateTypeEventListPage from '../../pages/civ/state/events/StateTypeEventListPage';
import StateTopicEventsPage from '../../pages/civ/state/events/StateTopicEventsPage';
import StateTopicEventListPage from '../../pages/civ/state/events/StateTopicEventListPage';

import StateCategoryOrganizationPage from '../../pages/civ/state/organizations/StateCategoryOrganizationsPage';
import StateOrganizationListPage from '../../pages/civ/state/organizations/StateOrganizationListPage';
import StateTopOrganizationsPage from '../../pages/civ/state/organizations/StateTopOrganizationsPage';


import {StateProvider} from "../../contexts/StateContext";


const StateRoutes = () => {
  return (
    <StateProvider>
    <Switch>

      <PrivateRoute path="/state/issues/top" component={StateTopIssuesPage} />
      <PrivateRoute path="/state/issues/latest" component={StateLatestIssuesPage} />
      <PrivateRoute path="/state/issues/topics" component={StateIssuesTopicsPage} />
      <PrivateRoute path="/state/issues/list" component={StateIssuesTopicsListPage} />
      <PrivateRoute path="/state/issues/resolved" component={StateResolvedIssuesPage} />

      <PrivateRoute path="/state/events/top" component={StateTopEventsPage} />
      <PrivateRoute path="/state/events/latest" component={StateLatestEventsPage} />
      <PrivateRoute path="/state/events/type" component={StateTypeEventsPage} />
      <PrivateRoute path="/state/events/typelist" component={StateTypeEventListPage} />
      <PrivateRoute path="/state/events/topic" component={StateTopicEventsPage} />
      <PrivateRoute path="/state/events/topiclist" component={StateTopicEventListPage} />

      <PrivateRoute path="/state/organizations/top" component={StateTopOrganizationsPage} />
      <PrivateRoute path="/state/organizations/category" component={StateCategoryOrganizationPage} />
      <PrivateRoute path="/state/organizations/list" component={StateOrganizationListPage} />


      <PrivateRoute path="/state" component={StatePage} />
    </Switch>
    </StateProvider>
  )
}

export default StateRoutes;
