import React, { useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";

const VoteCard = (props) => {

  const formatDate = (timestamp) => {

    let date = new Date(timestamp.seconds * 1000);
    let dateStr =
      date.getMonth() < 10 ? "0" + date.getMonth() + 1: date.getMonth() + 1; //getMonth is 0-indexed for some dumb js reason
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2) + ", ";
    dateStr += date.getHours() + ":";
    dateStr += date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    dateStr += date.getHours() < 12 ? " a.m." : " p.m.";
    return dateStr;
  };

  let colorOutcome = ((props.outcome === "Passed" || props.outcome.includes("Agreed") || props.outcome.includes("Confirmed")) ? "px-2 mr-9 rounded bg-blue-600" : "px-2 mr-9 rounded bg-gray-400")
  let colorLegislator = (props.legislatorResponse === "Nay" ? "px-5 inline-flex items-center text-sm leading-5 font-medium rounded-full bg-red-600 text-white" : "px-5 inline-flex items-center text-sm leading-5 font-medium rounded-full bg-green-600 text-white");

  return (
    <Link to={`/bills-votes/votes/${props.voteId}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
      <div className="grid grid-cols-12 items-center px-4 py-4 sm:px-6">

        <div className="col-start-1 col-end-2">
          <div className={colorOutcome}>
            <h1 className="text-2xl text-center font-semibold text-white pt-3">
              #{props.number}
            </h1>
            <h1 className="text-sm text-center font-semibold text-white pb-3">
              ({(props.type === "senate") ? ((props.outcome.includes("Agreed") || props.outcome.includes("Confirmed"))  ?  'Agreed' : 'Rejected') : props.outcome})
            </h1>
          </div>
        </div>

        <div className="col-start-2 col-end-13">

          <div className="flex items-center justify-between">
            <div className="text-2xl py-2 leading-5 font-semibold text-gray-900 truncate">
              {/* {props.id ? props.id + ': ' + (props.altTitle ? props.altTitle : (props.title != '' ? props.title : props.purpose)) : (props.altTitle != '' ? props.altTitle : 'No Title')} */}
              {props.altTitle ? props.altTitle : (props.title ? props.title : (props.purpose ? props.purpose : "No Title"))}
            </div>
            <div className="ml-2 flex-shrink-0 flex">
              <span className="px-5 inline-flex text-lg leading-9 font-medium rounded-full bg-blue-600 text-white">
                {props.numIssues} Related Issues
              </span>
            </div>
          </div>

          <div className="mt-2 flex justify-between">
            <div className="sm:flex">
              <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                {props.date + ', ' + props.time}
              </div>
              <div className="mt-2 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: library --> */}
                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                </svg>
                <div className='truncate max-w-sm'>
                {props.purpose}
                </div>

              </div>
              <div className="ml-7 flex-shrink-0 flex">
              {/* <span className={colorLegislator}>
                My Legislator: {props.legislatorResponse}
              </span> */}
            </div>
            </div>
            <div className="mt-2 flex items-center text-md leading-8 font-semibold text-red-500 sm:mt-0">
              <span>
                View vote
              </span>
              <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </div>

        </div>

      </div>
    </Link>
  )
}
export default VoteCard;
