import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DropdownMenu from '../../global/issues/DropdownMenu';
// import firebase from "firebase";
import { useAuth } from '../../../../contexts/AuthContext'

const NoteCard2 = (props) => {
  const { currUser } = useAuth();
  // const initial = props.upvotedNotes.includes(props.id);
  let upvotes = props.upvotes;
  // if (initial) upvotes--;

  const [upvoted, setUpvoted] = useState(false);

  const formatDate = (timestamp) => {
    let date = new Date(timestamp.seconds * 1000);
    // console.log("date:", date.getMonth())

    let dateMonth = date.getMonth() + 1
    let dateStr =
    dateMonth < 10 ? "0" + dateMonth : dateMonth;
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);
    return dateStr;
  };

  useEffect(() => {

    if(props.upvotedNotes.includes(props.noteId)){
      console.log(props.noteId + " note has been upvoted")
      setUpvoted(true)
    }

  }, []);

  const handleUpvote = () => {

    props.upvotedNotesRef.doc(props.noteId).update({upvotes: upvotes + upvoted})
    if (upvoted) {
      setUpvoted(false)
      props.upvotedNotesRef.doc(props.noteId).delete();
    } else {
      setUpvoted(true)
      props.upvotedNotesRef.doc(props.noteId).set({});
    }

  }

  return (
    <Link
      to={`/issues/${props.issueId}/notes/${props.noteId}`}
      className="w-full flex-none mr-10 mb-10"
    >
      <div className="flex-none h-80 bg-yellow-300 shadow-sm hover:shadow-md px-8 py-10">
        <div className="flex justify-between">
          <h1 className="font-bold text-xl truncate mb-2">
            {props.noteTitle}
          </h1>
          {currUser &&
          <Link className="-mt-2" onClick={e => e.preventDefault()}>
            <DropdownMenu contentId={props.noteId} currUserId={currUser.uid} reportType={'note'} />
          </Link>
          }

        </div>
        <Link to={`/users/${props.creatorId}/issues`} className="font-medium text-md hover:underline">
          {props.createdBy}
        </Link>

        <h1 className="font-regular text-sm text-gray-800 mt-4 mb-6 h-32 overflow-ellipsis overflow-scroll">
          {props.noteBody}
        </h1>

        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xs">
            {props.dateCreated ? formatDate(props.dateCreated) : "Na/Na/Na"}
          </h1>

          <Link to={`/issues/${props.issueId}/notes/`}>
            {currUser ?
              <button className = {`focus:outline-none flex items-center bg-gray-100 hover:bg-white font-semibold text-xs px-3 py-1 rounded-md text-gray-900 ${upvoted ? "text-red-500":""}`}
                    onClick={handleUpvote}
                    >
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <span>{upvotes + upvoted}</span>
            </button>
            :
            <button
              className="focus:outline-none flex items-center bg-gray-100 hover:bg-white font-semibold text-xs px-3 py-1 rounded-md text-gray-900"
              onClick={props.toggleAuthPopup}
            >
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <span>{upvotes + upvoted}</span>
            </button>
            }
          </Link>
        </div>

      </div>
    </Link>
  );
};

export default NoteCard2;
