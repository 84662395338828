import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import defaultProfilePic from "../../../assets/DefaultProfilePic.png"

const MemberCard = (props) => {

  const [imgSrc, setImgSrc] = useState(defaultProfilePic);

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function() {
      // let status = request.status;
      if (request.status === 200) //if(statusText == OK)
      {
        setImgSrc(`https://theunitedstates.io/images/congress/225x275/${props.id}.jpg`)
      }
      // else {
      //   console.log("image doesn't exist");
      // }
    }
  }

  useEffect(() => {
    checkImage(`https://theunitedstates.io/images/congress/225x275/${props.id}.jpg`);
  }, [])

  return (
    <li className="col-span-1 bg-white rounded-lg shadow">
      <Link to={`/legislators/${props.id}/bills`}>
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <img
            className="w-10 h-10 bg-gray-300 rounded-full object-cover"
            // src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60"
            // src={`https://theunitedstates.io/images/congress/225x275/${props.id}.jpg`}
            src={imgSrc}
            alt=""
          />
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
                {props.firstName + " " + props.lastName}
              </h3>
              {/* <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">Admin</span> */}
            </div>
            <p className="mt-1 text-gray-500 text-sm leading-5 truncate">{`${
              props.region
            }-${props.chamber === "Senate" ? "Senate" : props.district} (${
              props.party
            })`}</p>
          </div>
        </div>
        {/* <div className="border-t border-gray-200">
          <div className="-mt-px flex">
            <div className="w-0 flex-1 flex border-r border-gray-200">
              <div
                href="#"
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg"
              > */}
                {/* <!-- Heroicon name: mail --> */}
                {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg> */}
                {/* <span className="ml-3">100 Issues</span>
              </div>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <div
                href="#"
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg"
              > */}
                {/* <!-- Heroicon name: phone --> */}
                {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg> */}
                {/* <span className="ml-3">70% Reply Rate</span>
              </div>
            </div>
          </div>
        </div> */}
      </Link>
    </li>
  );
};

export default MemberCard;
