import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

const IssuesNav = () => {
  // need to add "resolved tab"
  return (
    <div className="flex justify-between items-center mb-8">

      <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
        <NavLink
          to="/org/browse/state/events"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          exact
          activeClassName="text-gray-900"
        >
          My State
        </NavLink>
        <NavLink
          to="/org/browse/nation/events"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          activeClassName="text-gray-900"
        >
          National
        </NavLink>
      </div>

      <div className="ml-4 mt-2 flex space-x-4 items-end">
        {/* <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">
            Topic Filter
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>All Topics</option>
            <option>Topic 1</option>
            <option>Etc</option>
          </select>
        </div>
        <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">
            Type Filter
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>All Topics</option>
            <option>Topic 1</option>
            <option>Etc</option>
          </select>
        </div>
        <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">
            Sort
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>All Topics</option>
            <option>Topic 1</option>
            <option>Etc</option>
          </select>
        </div> */}
      </div>

    </div>
  );
};

export default IssuesNav;
