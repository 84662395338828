import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Legislators & legislation
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Legislator profiles 101</h2>
            <p>Legislators have access to their own accounts and profiles so they can better interact with you, the activists. Legislators and their profiles are a very important part of the GrassrootsGov platform. Following and staying up to date with your legislators is critical to becoming an aware activist. This set of legislator tutorials will teach you all about the ways you can interact with your legislators using GrassrootsGov. </p>
            <p>A great place to start is our tutorial named "View your legislators' profiles." Once you have familiarized yourself with this tutorial, then follow through this tutorial to learn all the ins and outs of legislator profiles on GrassrootsGov.</p>
            <p>When at a specific legislator's profile, you'll notice statistics in the upper right-hand side of the page. We constantly track every legislator's bills, as well as show you their active committee assignments.  </p>
            <p>As we move down the page, you can select "Recent Bills," "Recent Votes," and "Issues." Navigate through any of these sections to learn more about the legislator and see what they're up to. You can always expand any vote, bill, or issue to learn more about them.</p>

            <h2>How to browse all legislators</h2>
            <p>Select "Legislators" from the toolbar to go to the Legislator pages. You can navigate here from almost any page at anytime.  Scroll to view and interact with an expansive list of all members of congress.</p>

            <h2>How to view congressional committees</h2>
            <p>Select "Legislators" from the toolbar to go to the Legislator pages. You can navigate here from almost any page at anytime.  Scroll down past the "Responsiveness Rankings" and "All members of Congress" to "House Committees" and "Senate Committees." Here is a list of all congressional committees. Select any of these to learn more about them and view the current list of members.</p>

            <h2>Bills & Votes 101</h2>
            <p>GrassrootsGov is an excellent way to stay up to date with current legislation. Follow through this tutorial to learn all about the features that GrassrootsGov has related to bills & votes.</p>
            <p>First, navigate to the Bills & Votes pages. From here, you will have the option to view "House Bills," "Senate Bills," and "All Votes." </p>
            <p>In the "House Bills" and "Senate Bills"  sections, you can now sort the bills by "Upcoming," "Introduced," and "Past." If any bill catches your eye, or you just want to learn more about it, be sure to expand the bill by selecting "View Bill." </p>
            <p>From the specific bill's page, you will be shown: the name of the bill, the date it was introduced, the sponsor and co-sponsors of the bill, its current status, the latest action of the bill, a summary of the bill, related topics, committees associated with the bill, amendments, votes on the bill, similar bills, related issues, and any comments that your legislator left on the bill. You can view related issues to the bill, as well as create your own new issue related to the bill by utilizing the buttons in the top left of the page. You can approve or disapprove of the bill in the top right of the bill's page. You can also see the approval rating from other users in the top right. You can also follow a bill so that you don't miss anything important by selecting the "Follow Bill" button just to the right of the approve/disapprove buttons in the top right.</p>
            <p>In the "All Votes" section of the Bills & Votes, you can sort by either "House Votes" or "Senate Votes."  If you want to learn more about any specific vote, navigate to the vote's specific page by selecting "View Vote." </p>
            <p>From the specific vote's page, you will be shown: the date of the vote, the related bill, the purpose of the bill, the outcome of the vote, related issues, and any comments from legislators on the vote. You can view related issues to the vote, as well as create your own new issue related to the vote by utilizing the buttons in the top left of the page. You can approve or disapprove of the vote in the top right of the vote's page. You can also see the approval rating from other users in the top right.</p>

            <h2>How to view the bills and votes related to a particular issue</h2>
            <p>When at a specific issue page, click on the "Learn More" tab to find the "Related Bills" and "Related Votes" for the issue. Select "View Bill" or "View Issue" respectively to navigate to the bill/vote's specific page.</p>

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
