import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Get engaged
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Post your first issue</h2>
            <p>If you have an issue that you want to get your local, state, or national legislators to notice, click on "My Issues" on from the Homepage, and then "Create New Issue". </p>
            <p>Make sure to specify which legislator you want this issue to be directed towards at the top, and write the issue's name and description directly below that. If your issue has any tags that are applicable you can add those here as well. Feel free to add any current links from outside GrassrootsGov to support your issue, or link any other bills, votes, or events. After this, click on "Post Issue" at the bottom - you're all set!</p>

            <h2>Post your first note</h2>
            <p>Adding a note is one of the best ways to support someone's movement! Here you can bring your own perspective to often complicated issues.</p>
            <p>To post your first note, find your way to an issue page that you want to support. Then navigate to the "Notes" section, and click "Create New Note" on the right-hand corner. </p>
            <p>Fill out the title and body of the note. Click “Post Note” and you're all set!</p>

            <h2>Upvote notes to show your support</h2>
            <p>In order to show your support for notes on your feed, click on the “Up” arrow on the bottom-left corner of any note!  </p>

            <h2>Sign up for events</h2>
            <p>GrassrootsGov makes it easy to engage with organizations and attend events!</p>
            <p>In order to sign up for events in your district, state, or nation, first locate an event page. This can be from your feed, organizations you follow, or by browsing through the district, state, and nation pages. </p>
            <p>Click on the "Sign up!" button under "RSVP for this event". </p>
            <p>You can keep track of the events you've signed up for by clicking on the "My Events" section of your homepage.</p>

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
