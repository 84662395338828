import React, {useState, useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import { states_hash } from "../../../assets/states";
import { formatDate } from "../../../assets/usefulFunctions";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from 'firebase';
import defaultProfilePic from "../../../assets/DefaultProfilePic.png"

const CivilianHeader = (props) => {

  const { currUser } = useAuth();
  const [src, setSrc] = useState(defaultProfilePic)

  useEffect(async () => {

    let storageRef = firebase.storage().ref();
    let userPicRef = await storageRef.child(`images/civilian/profilePics/${props.uid}`)
    if (userPicRef.exists){
      userPicRef.getDownloadURL().then((url) => {
        setSrc(url)
        // setLoading(false);
      }).catch((error) => {
        console.log(error)
        // setLoading(false);
      })
    }

  })

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-10 items-center">
          <div className="bg-gray-400 h-40 w-40 rounded-full">
            <img id="profilePicImg" className="object-scale-down inline-block h-40 w-40 rounded-full" src={src} alt="profile"/>
          </div>
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              {props.firstName ? props.firstName : "FirstName"}{" "}
              {props.lastName ? props.lastName[0] : "LastName"}.
            </h1>
            <h1 className="text-2xl font-regular">
              {states_hash[props.state]}, District {props.district}
            </h1>
          </div>
        </div>
        {currUser.uid === props.uid && (
          <div className="mr-4 space-y-4 text-right">
            <Link
              to="/users/editprofile"
              className="btn-primary"
            >
              Edit Profile
            </Link>
            <h1 className="text-md">Joined {formatDate(props.dateCreated)}</h1>
          </div>
        )}
        {/* <h1 className="text-md">More information</h1> */}
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-8">
          <NavLink
            to={`/users/${props.uid}/issues`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Issues
          </NavLink>
          <NavLink
            to={`/users/${props.uid}/notes`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Notes
          </NavLink>
          <NavLink
            to={`/users/${props.uid}/organizations`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Organizations
          </NavLink>
        </div>

        <div className="flex space-x-4 items-end">
          <h1 className="text-xl font-medium">{props.counter}</h1>
          {/* <div>
            <label for="location" class="block text-sm font-medium text-justiceBlue-500">
              Filter
            </label>
            <select
              id="location"
              name="location"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            >
              <option selected>All Topics</option>
              <option>Topic 1</option>
              <option>Etc</option>
            </select>
          </div>
          <div>
            <label for="location" class="block text-sm font-medium text-justiceBlue-500">
              Sort By
            </label>
            <select
              id="location"
              name="location"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            >
              <option selected>Date Posted</option>
              <option>Popularity</option>
              <option>Etc</option>
            </select>
          </div> */}
        </div>
      </div>
      <hr className="border-solid mb-8" />
    </>
  );
};

export default CivilianHeader;
