import React from "react";
import { Link } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout";
import BrowseNav from "../../../components/org/browse/BrowseNav";
import IssuesNav from "../../../components/org/browse/IssuesNav";
import NationIssues from "../../../components/org/browse/NationIssues";

function BrowseStateIssuesPage(props) {
	return (
		<OrgLayout>
			<BrowseNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<IssuesNav />
				<NationIssues />
			</div>
		</OrgLayout>
	);
}

export default BrowseStateIssuesPage;
