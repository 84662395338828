import React from "react";
import BillCard from "../../org/global/bills/BillCardSmall";
import VoteCard from "../../org/global/votes/VoteCardSmall";
import EventCardSmall from "../../org/global/events/EventCardSmall";
// import { ReactTinyLink } from "react-tiny-link";
import { Link } from "react-router-dom";

const formatDate = (timestamp) => {
  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
    return f_dateString;
  } else {
    // if there isn't a date associated with the issue
    return "01/01/1979";
  }
};

const IssueLearnMore = (props) => {
	return (
		<>
			<div className="bg-gray-100 px-10 py-8 rounded-lg mb-12">
				<h1 className="text-lg mb-6">
					<span className="font-semibold">Posted by: </span>{" "}
					<Link
						className="hover:underline"
						to={`/org/users/${props.authorId}/issues`}
					>
						{props.authorName}
					</Link>
          <span> on {formatDate(props.dateCreated)}</span>
				</h1>

				<h1 className="text-2xl font-bold text-gray-900 mb-6">
					Description
				</h1>
				<p className="mb-8">
					{props.description ? (
						props.description
					) : (
						<p>Issue Description</p>
					)}
				</p>

				<h1 className="text-2xl font-bold text-gray-900 mb-6">Links</h1>
				<div className="grid grid-cols-3 gap-x-8">
					{/* <div>
            {props.links}
            <ReactTinyLink cardSize="small" showGraphic={true} maxLine={2} minLine={1} url={props.links }
          </div> */}

					{props.links.length > 0 ? (
						props.links.map((link) => {
							return (
								<a
									href={
										link.includes("https://")
											? link
											: "https://" + link
									}
									className="bg-white rounded-lg px-6 py-4 shadow-sm hover:shadow-md"
								>
									{link.includes("https://")
										? link
										: "https://" + link}
								</a>
							);
						})
					) : (
						<h1>This issue does not have any links.</h1>
					)}

					{/* turn these links into a component */}
				</div>
			</div>

			<div className="grid grid-cols-2 gap-6 items-start mb-12">
				<div className="flex-auto">
					<h1 className="text-2xl font-bold mb-4">Related Bills</h1>
					{props.relatedBills && props.relatedBills.length !== 0 ? (
						props.relatedBills.map((bill) => {
							return (
								<div className="my-2">
									<BillCard
										title={bill.number + ": " + bill.title}
										legislator={
											bill.sponsorName
												? bill.sponsorName
												: "Sponsor"
										}
										region={
											bill.sponsorState
												? bill.sponsorState
												: "State"
										}
										party={
											bill.sponsorParty
												? bill.sponsorParty
												: "Party"
										}
										status={
											bill.billStatus
												? bill.billStatus
												: "Status"
										}
										// dateIntroduced={bill.introduced ? bill.introduced : "Date Introduced"}
										billId={bill.objectID}
									/>
								</div>
							);
						})
					) : (
						<p className="text-lg">
							There are no related bills for this issue.
						</p>
					)}
				</div>

				<div className="flex-auto">
					<h1 className="text-2xl font-bold mb-4">Related Votes</h1>
					{/* <div className="bg-white shadow overflow-hidden sm:rounded-md"></div> */}
					{props.relatedVotes && props.relatedVotes.length !== 0 ? (
						props.relatedVotes.map((vote) => {
							return (
								<div className="my-2">
									<VoteCard
										title={
											vote.chamber +
											" Vote #" +
											vote.number +
											": " +
											vote.altTitle
										}
										outcome={
											vote.outcome
												? vote.outcome
												: "Passed"
										}
										// chamber={vote.chamber ? vote.chamber : "Senate"}
										dateVoted={vote.date ? vote.date : ""}
										voteId={vote.objectID}
									/>
								</div>
							);
						})
					) : (
						<p className="text-lg">
							There are no related votes for this issue.
						</p>
					)}
					{/* <div className="bg-white shadow overflow-hidden sm:rounded-md"></div> */}
				</div>
			</div>

			{/* <div clasName="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Updates</h1>
        <div className="bg-gray-100 px-10 py-8 rounded-lg">
          <h1 className="text-xl font-bold">
            Updates here... [design in progress]
          </h1> */}
			{/* complete after design finished */}
			{/* </div>
      </div> */}

			<div className="mb-10"></div>

			<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-4">
				Related Events
			</h1>
			<div
				className={
					props.relatedEvents.length === 3
						? "grid grid-cols-3 gap-6 items-start"
						: "grid grid-cols-2 gap-6 items-start"
				}
			>
				{props.relatedEvents.length === 0 ? (
					<div className="text-lg w-full">
						There are no related events for this issue.
					</div>
				) : (
					props.relatedEvents.map((event) => {
						return (
							<EventCardSmall
								eventTitle={event.eventTitle}
								organizerName={event.organizerName}
								organizerId={event.organizerId}
								isVirtual={event.isVirtual}
								eventId={event.objectID}
							/>
						);
					})
				)}
			</div>

			<div className="mb-10"></div>

			{/* <h1 className="text-2xl font-bold leading-tight text-gray-900 mb-4">
				Posts Mentioned In
			</h1> */}
		</>
	);
};

export default IssueLearnMore;
