import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import ReportCard from './ReportCard';

const DropdownMenu = (props) => {
  const [menuOn, setMenuOn] = useState(false);
  const [reporting, setReporting] = useState(false);

  function clickMenu() {
    console.log('clicked on menu!');
    setMenuOn(!menuOn);
  }

  function handleReport() {
    console.log('reporting is ', !reporting);
    addOverlay();
    setReporting(!reporting);
  }

  function setMenuOnFalse() {
    console.log('mouseleft!');
    setMenuOn(false);
  }

  function addOverlay() {
    const overlay = document.createElement('div');
    overlay.setAttribute('id', 'tempOverlay');
    overlay.setAttribute(
      'class',
      'z-50 inset-0 fixed bg-black bg-opacity-50 w-full h-full flex items-center justify-center'
    );
    const element = (
      <ReportCard
        contentId={props.contentId}
        currUserId={props.currUserId}
        handleReport={handleReport}
        reportType={props.reportType}
      />
    );
    ReactDOM.render(element, overlay);
    document.getElementById('root').appendChild(overlay);
  }
  return (
    <div>
      <div
        onClick={clickMenu}
        className='px-3 py-3 hover:bg-gray-100 cursor-pointer rounded'
      >
        <svg
          id='Layer_1'
          data-name='Layer 1'
          className='block'
          fill='#4a5568'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 11.64 53.41'
          height='1.2em'
        >
          <circle class='cls-1' cx='5.82' cy='5.82' r='5.82' />
          <circle class='cls-1' cx='5.82' cy='26.71' r='5.82' />
          <circle class='cls-1' cx='5.82' cy='47.6' r='5.82' />
        </svg>
      </div>
      {menuOn && (
        <div onMouseLeave={setMenuOnFalse}>
          <div
            onClick={handleReport}
            className='p-3 my-2 bg-white shadow border rounded hover:bg-gray-100 cursor-pointer font-medium text-gray-600 absolute'
          >
            Report
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
