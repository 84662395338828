import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import AppLayout from "../../../layouts/AppLayout";
import StateNav from "../../../components/civ/state/StateNav";
import StateDashboardSummary from "../../../components/civ/state/dashboard/StateDashboardSummary";
import StateTrending from "../../../components/civ/state/dashboard/StateTrending";
import StateRecommended from "../../../components/civ/state/dashboard/StateRecommended";

const StatePage = () => {
	return (
		<AppLayout>
			<StateNav />

			<StateDashboardSummary />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				{/* Stats component */}
				{/* Legislator component */}
				<StateTrending />
				<br />
				<StateRecommended />
			</div>
		</AppLayout>
	);
};

export default StatePage;
