import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import houseCommittees from "../../../assets/jsonData/houseCommittees.json"
import senateCommittees from "../../../assets/jsonData/senateCommittees.json";
import representatives from "../../../assets/jsonData/representatives.json";
import senators from "../../../assets/jsonData/senators.json";
import CommitteeMemberCard from '../../../components/org/legislator/CommitteeMemberCard'

const LegislatorsPage = ({match, history}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [committeeName, setCommitteeName] = useState('');
  const [chair, setChair] = useState(
    <h1 className='px-8 py-8 font-medium text-xl'>
      This committee currently has no chair.
    </h1>
  );
  const [members, setMembers] = useState(
    <h1 className='px-8 py-8 font-medium text-xl'>
      This committee currently has no members.
    </h1>
  );

  useEffect(() => {
    if (match && match.params.chamber && match.params.committee) {
      let chamber = match.params.chamber;
      let committeeId = match.params.committee;

      if (committeeId.length == 0) {
        setError('Bad link. No committee ID given.');
      } else {
        let committeeData = houseCommittees;
        let membersData = representatives;
        if (chamber == 'senate') {
          // console.log("Senate!")
          committeeData = senateCommittees;
          membersData = senators;
        }

        setCommitteeName(committeeData[committeeId].name);

        const chairId = committeeData[committeeId].chairId;

        if(membersData[chairId] != null){
          setChair(
            <CommitteeMemberCard
              legislatorId={chairId}
              legislatorName={
                membersData[chairId].firstName +
                ' ' +
                membersData[chairId].lastName
              }
              legislatorState={membersData[chairId].region}
              legislatorDistrict={
                membersData[chairId].district
                  ? '-' + membersData[chairId].district
                  : ''
              }
              legislatorParty={membersData[chairId].party}
            />
          )
        }

        let tempMembers = [];

        Object.keys(membersData).forEach((memberId) => {
          if (memberId != chairId) {
            // console.log("memberId:", memberId)
            // console.log("memberCommittees:", membersData[memberId].committees)
            let isMember = false;
            if (membersData[memberId].committees) {
              Object.values(membersData[memberId].committees).forEach(
                (committee) => {
                  if (committee.code == committeeId) {
                    isMember = true;
                  }
                }
              );
            }
            if (isMember) {
              tempMembers.push(
                <CommitteeMemberCard
                  legislatorId={membersData[memberId].id}
                  legislatorName={
                    membersData[memberId].firstName +
                    ' ' +
                    membersData[memberId].lastName
                  }
                  legislatorState={membersData[memberId].region}
                  legislatorDistrict={
                    membersData[memberId].district
                      ? '-' + membersData[memberId].district
                      : ''
                  }
                  legislatorParty={membersData[memberId].party}
                  key={membersData[memberId].id}
                />
              );
            }
          }
        });

        setMembers(tempMembers);
      }

      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        <div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16'></div>
      </div>
    );
  }
  if (error) {
    return <AppLayout>{error}</AppLayout>;
  }

  return (
    <AppLayout>
      <div className='mb-px py-8 mx-auto sm:px-8 lg:px-16'>
        <button
          className='flex space-x-2 items-center focus:outline-none'
          onClick={() => {
            history.goBack();
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='flex-shrink-0 h-5 w-5 text-gray-500'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              stroke-linecap='round'
              strokeLinejoin='round'
              stroke-width='2'
              d='M15 19l-7-7 7-7'
            />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className='text-3xl font-bold mt-6 mb-8'>{committeeName}</h1>

        <h1 className='text-2xl font-semibold mb-6'>Committee Chair</h1>
        <div className='bg-white shadow overflow-hidden sm:rounded-md mb-10'>
          <div className='divide-y divide-gray-200'>{chair}</div>
        </div>

        <h1 className='text-2xl font-semibold mb-6'>Committee Members</h1>
        <div className='bg-white shadow overflow-hidden sm:rounded-md'>
          <div className='divide-y divide-gray-200'>{members}</div>
        </div>
      </div>
    </AppLayout>
  );
};

export default LegislatorsPage;
