import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import { useHistory, Link } from "react-router-dom";
import { db } from "../../../config/firebase";
// import { states_districts, states_hash } from "../../../assets/states";
// import { formatDate } from "../../../assets/usefulFunctions";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png"

function OrganizationEditPage({props}) {
  const { currUserRef, currUser } = useAuth();
  const [credentials, setCredentials] = useState();
  // const [lastEdit, setLastEdit] = useState();
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(true);
  const [imageOriginalSrc, setImageOriginalSrc] = useState(defaultProfilePic);
  const history = useHistory();

  useEffect(async () => {
    if (currUserRef) {
      const userDoc = await currUserRef.get();
      const userData = userDoc.data();
      setCredentials(userData);

      // if (userData.lastEdited !== undefined) {
      //   let start = new Date(userData.lastEdited * 1000);
      //   let end = new Date(firebase.firestore.Timestamp.now() * 1000);
      //   const timeElapsed = end.getTime() - start.getTime();

      //   const daysElapsed = Math.trunc(timeElapsed / (1000 * 3600 * 24));

      //   console.log("days " + daysElapsed);
      //   setLastEdit(daysElapsed);

      //   if (daysElapsed < 364) {
      //     console.log("can edit false");
      //   }
      // }

      let storageRef = firebase.storage().ref();
      let userPicRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)
      // if (userPicRef.exists){
        userPicRef.getDownloadURL().then((url) => {
          setImageOriginalSrc(url)
          setLoading(false);
        }).catch((error) => {
          console.log(error)
          setLoading(false);
        })
      // }
      // else{
      //   setLoading(false);
      // }

    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing

    db.collection("organizations")
      .doc(currUser.uid)
      .set(
        {
          ...credentials,
          // lastEdited: firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      )
      .then((res) => {
        history.push(`/org/organizations/${currUser.uid}/latest`);
      })
      .catch((e) => setErr(e));
  setErr("");
  setLoading(false);

  }

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }

  function handlePicChange(event) {
    let storageRef = firebase.storage().ref();
    let userPicRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    var imgtag = document.getElementById("profilePicImg");
    imgtag.title = selectedFile.name;

    reader.onload = function(event) {
      imgtag.src = event.target.result;
    };

    reader.readAsDataURL(selectedFile);

    userPicRef.put(selectedFile).then((snapshot) => {
      console.log('Uploaded file!');
    });
    // console.log("PHOTO:", event.target.value)
  }

  function removeProfilePic(event) {
    let storageRef = firebase.storage().ref();
    let userPicRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)

    // if (userPicRef.exists){
    userPicRef.delete().then(() => {
      console.log("deleted photo")
      var imgtag = document.getElementById("profilePicImg");
      imgtag.src = defaultProfilePic
    }).catch((error) => {
      console.log(error)
    })
    // }

  }

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  // if (lastEdit < 364) {
    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
          <h1 className="text-2xl font-semibold md:text-4xl mb-8">
            Edit your profile
          </h1>

          {/* {lastEdit < 364 ? */}

          <div>
            <div className="mt-6">
            <form
              action="#"
              method="POST"
              className="space-y-6"
              onSubmit={handleSubmit}
            >

            <div className="mb-10">

              <label
              for="profilePic"
              className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-4"
              >
                Profile picture
              </label>

              <div className="mb-9 flex items-center">
                {/* <div className="inline-bloc w-20 h-20 overflow-hidden rounded-full">
                  <img id="profilePicImg" class="inline mx-0 my-auto h-full w-auto" src={defaultProfilePic} alt=""/>
                </div> */}
                <div className="bg-gray-400 h-20 w-20 rounded-full">
                  <img id="profilePicImg" className="object-scale-down inline-block h-20 w-20 rounded-full" src={imageOriginalSrc} alt=""/>
                </div>
                <div className="ml-4 flex">
                  <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
                    Change
                    <input onChange={handlePicChange} id="profilePic" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
                  </button>
                  <button onClick={removeProfilePic} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
                    Remove
                  </button>
                </div>
              </div>

              <div className="mb-6">
                <label
                  for="websiteURL"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Website link
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="websiteURL"
                    name="websiteURL"
                    type="text"
                    value={credentials.websiteURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="flex space-x-4 mb-6">
                <div className="flex-1">
                  <label
                    for="contactEmail"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                   Contact email address
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="contactEmail"
                      name="contactEmail"
                      type="email"
                      value={credentials.contactEmail}
                      onChange={handleChange}
                      placeholder="Enter email address"
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-end space-x-2 mb-2">
                    <label
                      for="contactPhone"
                      className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                    >
                      Contact phone number
                    </label>
                    {/* <div className="text-xs text-gray-700">(optional)</div> */}
                  </div>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="contactPhone"
                      name="contactPhone"
                      type="text"
                      value={credentials.contactPhone}
                      onChange={handleChange}
                      placeholder="Enter phone number (numbers only)"
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="twitterURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Twitter profile link
                  </label>
                  {/* <div className="text-xs text-gray-700">(optional)</div> */}
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="twitterURL"
                    name="twitterURL"
                    type="text"
                    value={credentials.twitterURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="instagramURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Instagram profile link
                  </label>
                  {/* <div className="text-xs text-gray-700">(optional)</div> */}
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="instagramURL"
                    name="instagramURL"
                    type="text"
                    value={credentials.instagramURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="facebookURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Facebook profile link
                  </label>
                  {/* <div className="text-xs text-gray-700">(optional)</div> */}
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="facebookURL"
                    name="facebookURL"
                    type="text"
                    value={credentials.facebookURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="description"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Organization description
                  </label>
                  {/* <div className="text-xs text-gray-700">(optional)</div> */}
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <textarea
                    id="description"
                    name="description"
                    type="text"
                    value={credentials.description}
                    maxLength="500"
                    rows={3}
                    onChange={handleChange}
                    placeholder="Enter a short description of your organization"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                  <h1 className="text-gray-500 float-right mt-2">
                    {credentials.description.length}/500 characters
                  </h1>
                </div>
              </div>
            </div>

              <div>
                <span className="block flex space-x-4">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn-primary"
                  >
                    Confirm Information
                  </button>
                  <button
                    disabled={loading}
                    // to={`/org/organizations/${currUser.uid}/latest`}
                    onClick={() => {history.goBack();}}
                    className="btn-white"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
            {err && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Holy smokes! </strong>
                <span className="block sm:inline">{err}</span>
                {/* <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                  <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                  </span> */}
              </div>
            )}
          </div>
          </div>

          {/* } */}

        </div>
      </AppLayout>
    );

}

export default OrganizationEditPage;
