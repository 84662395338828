import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";
import SemiPrivateRoute from "./CivSemiPrivateRoute";
import EventPage from "../../pages/civ/events/EventPage";

const EventRoutes = () => {
	return (
		<Switch>
			<SemiPrivateRoute path="/events/:eventId" component={EventPage} />
		</Switch>
	);
};

export default EventRoutes;
