import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import SupportersOverviewPage from '../../pages/org/supporters/SupportersOverviewPage'
import SupportersAttendeesPage from '../../pages/org/supporters/SupportersAttendeesPage'
import SupportersMailingPage from '../../pages/org/supporters/SupportersMailingPage'

const HomeRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/org/supporters/attendees" component={SupportersAttendeesPage}/>
      <PrivateRoute path="/org/supporters/mailing" component={SupportersMailingPage}/>
      {/* <PrivateRoute path="/org/supporters" component={SupportersOverviewPage}/> */}
    </Switch>
  )
}

export default HomeRoutes;
