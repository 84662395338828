import React from "react";
import NoteCard from "../global/notes/NoteCard1";

const CivilianNotes = (props) => {
  if (props.notes.length == 0) {
    return (
      <>
        <div className="bg-white overflow-hidden text-center md:my-28">
          <div className="text-xl">This user has not posted any notes.</div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="grid grid-cols-4 gap-x-12">
        {props.notes.map((doc) => {
          return (
            <>
              <NoteCard {...doc} />
            </>
          );
        })}
        {/* <NoteCard
          issueTitle="IssueTitleHere"
          noteTitle="NoteTitleHere"
          datePosted="01/01/20"
          upvotes="100"
        />
        <NoteCard
          issueTitle="IssueTitleHere"
          noteTitle="NoteTitleHere"
          datePosted="01/01/20"
          upvotes="100"
        />
        <NoteCard
          issueTitle="IssueTitleHere"
          noteTitle="NoteTitleHere"
          datePosted="01/01/20"
          upvotes="100"
        />
        <NoteCard
          issueTitle="IssueTitleHere"
          noteTitle="NoteTitleHere"
          datePosted="01/01/20"
          upvotes="100"
        />
        <NoteCard
          issueTitle="IssueTitleHere"
          noteTitle="NoteTitleHere"
          datePosted="01/01/20"
          upvotes="100"
        /> */}
      </div>
    </>
  );
};

export default CivilianNotes;
