import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import BillPage from '../../pages/org/billvote/bill/BillPage'
import BillCommentsPage from '../../pages/org/billvote//bill/BillCommentsPage'
import BillCosponsorsPage from '../../pages/org/billvote//bill/BillCosponsorsPage'
import BillAmendmentsPage from '../../pages/org/billvote//bill/BillAmendmentsPage'
import BillSimilarPage from '../../pages/org/billvote//bill/BillSimilarPage'
import BillRelatedIssuesPage from '../../pages/org/billvote//bill/BillRelatedIssuesPage'

import VotePage from '../../pages/org/billvote//vote/VotePage'
import VoteCommentsPage from '../../pages/org/billvote//vote/VoteCommentsPage'
import VoteRelatedIssuesPage from '../../pages/org/billvote//vote/VoteRelatedIssuesPage'

import {BillVoteAPIProvider, BillVoteDBProvider} from "../../contexts/BillVoteContext";


const BillVoteRoutes = () => {
  return (
    <BillVoteAPIProvider>
      <BillVoteDBProvider>
        <Switch>
          <PrivateRoute path="/org/bills-votes/votes/:vote/related-issues" component={VoteRelatedIssuesPage} />
          <PrivateRoute path="/org/bills-votes/votes/:vote/comments" component={VoteCommentsPage} />
          <PrivateRoute path="/org/bills-votes/votes/:vote" component={VotePage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill/related-issues" component={BillRelatedIssuesPage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill/similar" component={BillSimilarPage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill/amendments" component={BillAmendmentsPage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill/cosponsors" component={BillCosponsorsPage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill/comments" component={BillCommentsPage} />
          <PrivateRoute path="/org/bills-votes/bills/:bill" component={BillPage} />
        </Switch>
      </BillVoteDBProvider>
    </BillVoteAPIProvider>
  )
}

export default BillVoteRoutes;
