import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";


function IssueNoteDetailedPage({ match }) {
    let history = useHistory();

    const [noteInfo, setNoteInfo] = useState({});
    const [issueInfo, setIssueInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [issueId, setIssueId] = useState("");
    const [noteId, setNoteId] = useState("");
    // const [history, setHistory] = useHistory();
    const { currUser, currUserRef } = useAuth();

    const formatDate = (timestamp) => {
        if (timestamp) {
        let date = new Date(timestamp.seconds * 1000);
        let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
        let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
        yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
        uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
        let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
        return f_dateString;
        }
        else {
        return "01/01/1979";
        }
    }

    useEffect(async () => {
        if (match && match.params.note) {
        let issueId = match.params.issue;
        let noteId = match.params.note;

        if (issueId.length == 0) {
            setError("Bad link. No issue id given.");
        } else {
            setIssueId(issueId);
            setNoteId(noteId);

            const currNoteRef = db.collection("issues").doc(issueId).collection("notes").doc(noteId);
            const noteDoc = await currNoteRef.get();
            if (!noteDoc.exists) {
            setError("This note was not found.");
            } else {
            setNoteInfo(noteDoc.data());
            }

            const currIssueRef = db.collection("issues").doc(issueId);
            const issueDoc = await currIssueRef.get();
            if (!issueDoc.exists) {
            setError("This note was not found.");
            } else {
            setIssueInfo(issueDoc.data());
            }
        }
        setLoading(false);
        }
    }, []);

    if (loading) {
        return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
        </div>
        );
    }
    if (error) {
        return (
        <AppLayout>
            <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">{error}</div>
        </AppLayout>
        );
    }
    return (
        <AppLayout>

          <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

              <button
                className="flex space-x-2 items-center focus:outline-none"
                onClick={() => {
                  history.goBack();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 h-5 w-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <h1>Back</h1>
              </button>

              <h1 className="mt-8 text-3xl font-bold text-gray-900 mb-2">
                  {noteInfo.noteTitle}
              </h1>
              <div className="text-lg font-medium flex space-x-3 mb-5 items-center">
                  <h1>
                  By {' '}
                  <Link to={`/org/users/${noteInfo.createdBy}/issues`} className="hover:underline"> {noteInfo.createdByName}</Link>, {`${issueInfo.legislator.region} ${
                  issueInfo.legislator.chamber === "Senate"
                  ? "State-Wide"
                  : "District " + issueInfo.legislator.district
                  }`} • Posted {formatDate(noteInfo.dateCreated)}
                  </h1>
              </div>
              <div className="text-lg font-regular flex space-x-3 mb-5 items-center">
                  {noteInfo.noteBody}
              </div>

          </div>

        </AppLayout>
    );
}

export default IssueNoteDetailedPage;
