import React, { useState, useEffect, useContext } from "react";
import AppLayout from "../../../../layouts/AppLayout";
import IssueCard from "../../../../components/civ/global/issues/IssueCard";
import { db } from "../../../../config/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

const VoteRelatedIssuesPage = ({ match, history }) => {
	const { currUser, currUserRef } = useAuth();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [voteData, setVoteData] = useState({});
	const [regionalIssues, setRegionalIssues] = useState({});
	const [otherIssues, setOtherIssues] = useState(
		<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>
	);

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	useEffect(async () => {
		if (match && match.params.vote) {
			console.log("currUser:", currUser);
			const userState = (
				await db.collection("civilians").doc(currUser.uid).get()
			).data().state;

			let tempVoteId = match.params.vote;

			if (tempVoteId.length == 0) {
				setError("Bad link. No vote number given.");
			} else {
				const tempVoteData = await db
					.collection("votes")
					.doc(tempVoteId)
					.get();
				setVoteData(tempVoteData.data());

				const tempRegionalIssues = await db
					.collection("votes")
					.doc(tempVoteId)
					.collection("relatedIssues")
					.where("legislator.state", "==", userState)
					.get();
				const tempOtherIssues = await db
					.collection("votes")
					.doc(tempVoteId)
					.collection("relatedIssues")
					.where("legislator.state", "!=", userState)
					.get();

				if (
					tempRegionalIssues.docs &&
					tempRegionalIssues.docs.length > 0
				) {
					setRegionalIssues(
						tempRegionalIssues.docs.map((doc) => (
							<IssueCard
								issueTitle={doc.data().issueTitle}
								issueId={doc.data().issueId}
								legislator={
									doc.data().legislator.firstName +
									" " +
									doc.data().legislator.lastName
								}
								region={
									doc.data().legislator.state +
									"-" +
									doc.data().legislator.district
								}
								dateCreated={doc.data().dateCreated}
								copies={doc.data().regionalCopiesCount}
								supporters={doc.data().supportersCount}
							/>
						))
					);
				} else {
					setRegionalIssues(
						<h1 className="px-8 py-8 font-medium text-xl">
							There are no related issues in your region.
						</h1>
					);
				}

				if (tempOtherIssues.docs && tempOtherIssues.docs.length > 0) {
					// console.log('comments exist')
					// console.log("number of comments:", tempBillComments.docs.length)

					setOtherIssues(
						tempOtherIssues.docs.map((doc) => (
							<IssueCard
								issueTitle={doc.data().issueTitle}
								issueId={doc.data().issueId}
								legislator={
									doc.data().legislator.firstName +
									" " +
									doc.data().legislator.lastName
								}
								region={
									doc.data().legislator.state +
									"-" +
									doc.data().legislator.district
								}
								dateCreated={doc.data().dateCreated}
								copies={doc.data().regionalCopiesCount}
								supporters={doc.data().supportersCount}
							/>
						))
					);
				} else {
					setOtherIssues(
						<h1 className="px-8 py-8 font-medium text-xl">
							There are no related issues outside of your region.
						</h1>
					);
				}
			}

			setLoading(false);
		}
	}, [match]);

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<button
					className="flex space-x-2 items-center focus:outline-none"
					onClick={() => {
						history.goBack();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="flex-shrink-0 h-5 w-5 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
					<h1>Back</h1>
				</button>

				<h1 className="text-3xl font-bold mt-6 mb-8">
					{capitalizeFirstLetter(voteData.chamber)} Vote #
					{voteData.number} — {voteData.billInfo.number}:{" "}
					{voteData.altTitle
						? voteData.altTitle
						: voteData.billInfo.title}
				</h1>

				<h1 className="text-2xl font-semibold mb-6">
					Related Issues in Your Region
				</h1>
				<div className="bg-white shadow overflow-hidden sm:rounded-md mb-10">
					<div className="divide-y divide-gray-200">
						{regionalIssues}
					</div>
				</div>

				<h1 className="text-2xl font-semibold mb-6">
					Other Related Isssues
				</h1>
				<div className="bg-white shadow overflow-hidden sm:rounded-md">
					<div className="divide-y divide-gray-200">
						{otherIssues}
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default VoteRelatedIssuesPage;
