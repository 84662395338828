import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const OrganizationHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Organizations
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Find your way around GrassrootsGov
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Introduction </h2>
            <p>Welcome to GrassrootsGov! This platform is meant to be a place where you can come together with other members of your community and around the nation to advocate for your organization's issues, get people involved with your work, and gain valuable insights about your supporters. </p>
            <p>Our mission as a whole is to help facilitate the growth and success of movements across the nation by enabling transparent, actionable, and community-centered online activism and political engagement. We won't dictate exactly what you can post on here or advocate for, but to make this community a safe and productive place for all, you must comply with our Platform Values, Terms of Service, and Community Guidelines in order to use the platform. If you have any questions, please email us at contact@grassrootsgov.org.</p>

            <h2>Homepage</h2>
            <p>To view what's happening, utilize the "Dashboard" and "My Feed" pages! </p>
            <p>From the "Dashboard," you'll be shown your organization's upcoming events as well as trending issues and events in your community. GrassrootsGov makes it easy to see what is happening in the country and your community, while removing the clutter and complicated algorithms. </p>

            <h2>My events</h2>
            <p>Navigating to "My Events" on the top toolbar, you'll find a list of your upcoming and past events. </p>
            <p>Here you'll be able to automatically access your upcoming events, and clicking "Past" will bring you to past events you've held.  </p>
            <p>Pressing on "Create new event" will allow you to post a new event for your supporters to attend! </p>

            <h2>My supporters</h2>
            <p>As an organization, understanding your supporters is a top priority. "Event Attendees", you'll find the list of people who have RSVP'd for your events. </p>
            <p>Then, clicking on "Mailing List" will show you the list of people who have signed up for your mailing list. </p>

            <h2>Browse</h2>
            <p>As an organization, it's crucial that you also stay up to date with what's happening in your community and the nation. This can provide you with ideas for events and relevant issues to re-share. To browse, click "Browse" on the top toolbar. You'll be taken directly to issues, where you can toggle between state and national issues. Clicking on "view issue" will take you to its page. </p>
            <p>To browse events, click "Events", where you can also toggle between state and national events. </p>
            <p>To browse organizations, click "Organizations", and toggle between state and nation.</p>

            <h2>Your Profile</h2>
            <p>Your profile is an important part of your organization's account. This is where interested civilians will learn about who you are and what you stand for. Your latest posts will be shown when you click "Latest".</p>
            <p>Navigate to "About" where you can find your contact info as well as your organization's description.</p>

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default OrganizationHelpFindYourWayAroundPage;
