import React from 'react';
import OrgLayout from "../../../layouts/OrgLayout"
import EventsNav from "../../../components/org/events/EventsNav";
import UpcomingEvents from '../../../components/org/events/UpcomingEvents'

function UpcomingEventsPage(props) {
    return (
      <OrgLayout>
        <EventsNav />
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="divide-y divide-gray-200">
              <UpcomingEvents />
            </div>
          </div>
        </div>
      </OrgLayout>
    );
}

export default UpcomingEventsPage;
