import Reac, {useState, useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import defaultProfilePic from "../../../assets/DefaultProfilePic.png"

const LegislatorHeader = (props) => {

  const [committees, setCommittees] = useState([])
  const [imgSrc, setImgSrc] = useState(defaultProfilePic);

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function() {
      // let status = request.status;
      if (request.status === 200) //if(statusText == OK)
      {
        setImgSrc(`https://theunitedstates.io/images/congress/225x275/${props.legislatorId}.jpg`)
      }
      // else {
      //   console.log("image doesn't exist");
      // }
    }
  }

  useEffect(() => {

    checkImage(`https://theunitedstates.io/images/congress/225x275/${props.legislatorId}.jpg`);

    let tempCommittees = Object.values(props.committees).filter((committee) => !committee.name.includes("Joint"));
    setCommittees(tempCommittees)

  }, [])

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-10 items-center">
          <img
            className="rounded-full h-40 w-40 object-cover"
            src={imgSrc}
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              {props.firstName ? props.firstName : "Ayanna"}{" "}
              {props.lastName ? props.lastName : "Pressley"}
            </h1>
            <h1 className="text-2xl font-regular">
              {props.chamber} • {props.region} ({props.party})
            </h1>
          </div>
        </div>
        <div className="rounded-lg border px-4 py-4 text-left shadow-sm">
          <h1 className="text-lg font-semibold text-gray-800">Committees</h1>
          {committees.map((committee) => {
            return(
              <Link className="block hover:underline text-gray-700" to={`/org/legislators/committees/${props.chamber.toLowerCase()}/${committee.code}`}>{committee.name}</Link>
            )
          })}
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-8">
            <NavLink
                to={`/org/legislators/${props.legislatorId}/bills`}
                className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300"
                activeClassName="text-gray-900"
              >
              Recent Bills
            </NavLink>
            <NavLink
              to={`/org/legislators/${props.legislatorId}/votes`}
              className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300"
              activeClassName="text-gray-900"
            >
              Recent Votes
            </NavLink>
            <NavLink
              to={`/org/legislators/${props.legislatorId}/issues`}
              className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300"
              activeClassName="text-gray-900"
            >
              Issues
            </NavLink>
          </div>

          <div className="flex space-x-4 items-end">
            {/* <div>
              <label
                for="location"
                class="block text-sm font-medium text-red-500"
              >
                Filter
              </label>
              <select
                id="location"
                name="location"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              >
                <option selected>Bills & Votes</option>
                <option>Bills Only</option>
                <option>Votes Only</option>
              </select>
            </div> */}
            <div className="mb-1 text-lg font-medium">{props.headerData}</div>
            {/* <div>
              <label
                for="location"
                class="block text-sm font-medium text-justiceBlue-500"
              >
                Sort By
              </label>
              <select
                id="location"
                name="location"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              >
                <option selected>Most Recent</option>
              </select>
            </div> */}
          </div>
        </div>
        <hr className="border-solid mb-8" />
    </>
  );
};

export default LegislatorHeader;
