import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";

const PrivacyPolicyPage = () => {

  return (
    <LandingLayout>
      <div class="relative py-16 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="text-lg max-w-prose mx-auto">
            <h1>
              {/* <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Introducing</span> */}
              <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Community Guidelines</span>
            </h1>
          </div>
          <div class="mt-8 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <p>Welcome to GrassrootsGov! We're so excited for you to join our community. To use our platform, you must first respect our baseline <Link to="/about">value statements</Link>. The following guidelines apply to all user content, including posts, profile pictures, and other user interactions that take place on this site.</p>

            <h2>Civilian Community Guidelines</h2>

            <h3>Do's</h3>
            <p>Here are a few things we encourage users to do on our platform: </p>
            <ul>
              <li>Voice your concerns by creating issues and notes. Our platform was built to strengthen grassroots voices, so we encourage you to speak out and tell your story. Outlining how the problem affects you, and what can be done to stop it is one of the best ways to enact change.</li>
              <li>Support others' movements. When browsing through what's happening in your community, show your support by upvoting issues, attending events, following/joining organizations, and so much more!</li>
              <li>Spread the word. If you see something you're passionate about, share content with your friends and family.</li>
              <li>Update your supporters. If you've seen progress on one of your issues, let the world and your supporters know!</li>
            </ul>

            <h3>Don'ts</h3>
            <p>Here are things that you should not do on our platform. We have the right to take down content that violates these rules.</p>
            <ul>
              <li>No hate speech - content that attacks or expresses prejudice against a group of people based on race, ethnicity, disability, gender identity, sexual orientation, age, religion, or medical condition.</li>
              <li>Do not intimidate an individual or groups of people with violence. Healthy discourse is only attainable when everyone feels safe. For this reason, we have a policy against violent language. We classify the following as violent language: language that provokes, supports, or glorifies violence against anyone or any group. </li>
              <li>Do not post content (symbols/logos) from organizations deemed hate groups or individuals associated with them.</li>
              <li>Do not post misleading content. We will remove content that we can verify is incorrect, a can negatively impact the experience of users.</li>
              <li>Do not spam. Our platform is meant to help users get involved politically, not sell products/services.</li>
              <li>Do not post overly-graphic content or content that may harm children. </li>
              <li>Do not violate others' privacy or impersonate others, including creating multiple accounts, pretending you're someone else, or posting someone else's private information all violate our policies.</li>
              <li>Do not break the law. GrassrootsGov encourages users to speak out about legislation that needs to be changed, but remember to follow legal guidelines as you use our platform.</li>
            </ul>

            <h3>Reporting</h3>
            <p>If you see anything that violates our community guidelines, please either report the content in the site if possible, or reach out to us at contact@grassrootsgov.org.</p>
            <p>As the number of activists, organizations, and movements that join our platform increases, we will need to count on the help of users to report content that violates our Terms of Service and Community Guidelines. </p>
            <p>Thank you for contributing to a better community on GrassrootsGov!</p>

            <h2>Organization Community Guidelines</h2>

            <h3>Do's</h3>
            <p>Here are a few things we encourage users to do on our platform: </p>
            <ul>
              <li>Engage with your followers and members by creating events. Our platform was built to strengthen grassroots voices, so we encourage you to interact with your followers through events. </li>
              <li>Support others' movements. Re-share civilian issues that align with your organization to get your followers involved. </li>
              <li>Support other organizations. Re-share events from other organizations to maximize impact. </li>
            </ul>

            <h3>Don'ts</h3>
            <p>Here are things that you should not do on our platform. We have the right to take down content that violates these rules.</p>
            <ul>
              <li>No hate speech - content that attacks or expresses prejudice against a group of people based on race, ethnicity, disability, gender identity, sexual orientation, age, religion, or medical condition.</li>
              <li>Do not intimidate an individual or groups of people with violence. Healthy discourse is only attainable when everyone feels safe. For this reason, we have a policy against violent language. We classify the following as violent language: language that provokes, supports, or glorifies violence against anyone or any group. </li>
              <li>Do not post content (symbols/logos) from organizations deemed hate groups or individuals associated with them.</li>
              <li>Do not post misleading content. We will remove content that we can verify is incorrect, a can negatively impact the experience of users.</li>
              <li>Do not spam. Our platform is meant to help users get involved politically, not sell products/services.</li>
              <li>Do not post overly-graphic content or content that may harm children. </li>
              <li>Do not violate others' privacy or impersonate others, including creating multiple accounts, pretending you're someone else, or posting someone else's private information all violate our policies.</li>
              <li>Do not break the law. GrassrootsGov encourages users to speak out about legislation that needs to be changed, but remember to follow legal guidelines as you use our platform.</li>
            </ul>

            <h3>Reporting</h3>
            <p>If you see anything that violates our community guidelines, please either report the content in the site if possible, or reach out to us at contact@grassrootsgov.org.</p>
            <p>As the number of activists, organizations, and movements that join our platform increases, we will need to count on the help of users to report content that violates our Terms of Service and Community Guidelines. </p>
            <p>Thank you for contributing to a better community on GrassrootsGov!</p>

          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PrivacyPolicyPage;
