import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";
import { db } from "../../config/firebase";
import firebase from "firebase";

const JoinWaitlistPage = () => {
  const [userData, setUserData] = useState({
    email: "",
    occupation: "",
    affiliation: "",
    zipcode: "",
    firstName: "",
    lastName: "",
    referral: "",
    submissionDate: firebase.firestore.Timestamp.now()
  });
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    setErr("");
    setLoading(true);
    try {
      const res = await db.collection("/waitlistUsers").add(userData);
      console.log("added waitlist user with id: " + res.id);
      setUserData({
        email: "",
        occupation: "",
        affiliation: "",
        zipcode: "",
        firstName: "",
        lastName: "",
        referral: "",
        submissionDate: firebase.firestore.Timestamp.now()
      });
      setSuccess("Successfully submitted! We'll email you when GrassrootsGov is ready.");
    }
    catch {
      setErr("Failed to submit form.");
    }
    setLoading(false);
  }

  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }
  return (
    <LandingLayout>
      <div class="bg-gradient-to-t from-blue-100 to-blue-50 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div class="relative max-w-xl mx-auto">
          <svg
            class="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            class="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div class="text-center">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Join the waitlist
            </h2>
            <p class="mt-4 text-lg leading-6 text-gray-500">
              GrassrootsGov is launching soon. <br /> Be one of the first to
              find out by joining our waitlist.
            </p>
          </div>
          <div class="mt-12">
            {err && (
                <>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Holy smokes!</strong>
                    <span className="block sm:inline">{err}</span>
                  </div>
                  <br />
                </>
              )}
              {success && (
                <>
                  <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">{success}</span>
                  </div>
                  <br />
                </>
              )}
            <form
              onSubmit={handleSubmit}
              // action="#"
              // method="POST"
              class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  for="firstName"
                  class="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <div class="mt-1">
                  <input
                    required
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={userData.firstName}
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  for="lastName"
                  class="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <div class="mt-1">
                  <input
                    required
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    autocomplete="family-name"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="occupation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Occupation
                </label>
                <div class="mt-1">
                  <input
                    required
                    type="text"
                    name="occupation"
                    id="occupation"
                    value={userData.occupation}
                    onChange={handleChange}
                    autocomplete="occupation"
                    placeholder="Activist, student, etc."
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="affiliation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Affiliation (optional)
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="affiliation"
                    id="affiliation"
                    value={userData.affiliation}
                    onChange={handleChange}
                    autocomplete="affiliation"
                    placeholder="Affiliated organization, campaign, or legislator"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div >
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div class="mt-1">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    value={userData.email}
                    onChange={handleChange}
                    autocomplete="email"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div >
                <label
                  for="affiliation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Zip code
                </label>
                <div class="mt-1">
                  <input
                    required
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    value={userData.zipcode}
                    onChange={handleChange}
                    autocomplete="zipcode"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="referral"
                  class="block text-sm font-medium text-gray-700"
                >
                  How did you hear about us?
                </label>
                <div class="mt-1">
                  <input
                    required
                    id="text"
                    name="referral"
                    type="referral"
                    value={userData.referral}
                    onChange={handleChange}
                    placeholder="Social media, activist organization, etc."
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2 mt-4">
                <button
                  disabled={loading}
                  type="submit"
                  class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-justiceBlue-600 hover:bg-justiceBlue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue"
                >
                  Join waitlist
                </button>
              </div>
              <p className="sm:col-span-2 text-sm text-gray-500">
                We care about the protection of your data. Read our <Link to="/privacy-policy" className="underline">Privacy Policy</Link>.
              </p>
            </form>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default JoinWaitlistPage;
