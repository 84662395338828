import React from 'react';
import { NavLink, useLocation} from "react-router-dom";

const StateNav = () => {

  const {pathname} = useLocation();

  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">My State</h1>
      </header>
      <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex space-x-8">
        <NavLink to="/state" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" exact activeClassName="text-gray-900">
          What's Happening
        </NavLink>
        <NavLink to="/state/issues/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
          isActive={() => ["/state/issues/top", "/state/issues/latest", "/state/issues/topics", "/state/issues/resolved"].includes(pathname)}>
          Issues
        </NavLink>
        <NavLink to="/state/events/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
          isActive={() => ["/state/events/top", "/state/events/latest", "/state/events/topic", "/state/events/type"].includes(pathname)}>
          Events
        </NavLink>
        <NavLink to="/state/organizations/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
          isActive={() => ["/state/organizations/top", "/state/organizations/category"].includes(pathname)}>
          Organizations
        </NavLink>
      </div>
      <hr className="border-solid"/>
    </>
  )
}

export default StateNav;
