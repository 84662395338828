import React from "react";
import EventCard from '../global/events/EventCard'

const OrganizationEvents = (props) => {

  return (
    <div>
      {(props.orgEvents && props.orgEvents.length > 0 ) ?
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
          {props.orgEvents && (props.orgEvents).map((event, i) => {
            console.log("EVENT:", event)
              return(
                <EventCard
                  key = {i}
                  eventId={event.eventId}
                  title={event.eventTitle}
                  going={event.numAttending}
                  interested={event.numInterested}
                  date={event.mainStartTimeUTC}
                  time={event.mainStartTimeString + " " + event.timeZone}
                  organizer={event.organizerName}
                  isVirtual={event.isVirtual}
                  location={`${event.city}, ${event.state}`}
                />
              )
            })
          }
        </div>
      </div>
    :
    <div className="text-xl font-medium">This organization has not posted any events yet.</div>
    }
  </div>
  )

}

export default OrganizationEvents;
