import React from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeEventsNav from "../../../components/civ/home/events/HomeEventsNav.js";
import HomeEventsPastDetailed from "../../../components/civ/home/events/HomeEventsPastDetailed.js";

const HomePastEventsPage = () => {
	return (
		<AppLayout>
			<HomePageNav />
			{console.log("events Past page entered")}
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeEventsNav />
				<HomeEventsPastDetailed />
			</div>
		</AppLayout>
	);
};

export default HomePastEventsPage;
