import React, { useState, useEffect, useContext } from "react";
// import {Link, NavLink, useHistory} from "react-router-dom";
import AppLayout from "../../../layouts/AppLayout";
import BillCard from "../../../components/civ/global/bills/BillCard";
import BillVoteNav from "../../../components/civ/billsvotes/BillsVotesNav";
import HouseNav from "../../../components/civ/billsvotes/HouseNav";
import { BillVoteDBContext } from "../../../contexts/BillVoteContext";
import {db} from "../../../config/firebase"
// TODO: page for each bill w/ react-router

const config = {
  headers: {
    "X-API-Key": "8GB085vQhogAnz8RDRXPAGlso7E2diqNJzR7gAyv",
  },
};

const BillsHouseUpcoming = () => {
	// const houseData = useContext(BillVoteDBContext);
	// const [billsDisplay, setDisplay] = useState(
	// 	<p className="h-screen px-8 py-8 font-medium text-2xl">
	// 		{"Loading..."}
	// 	</p>
	// );
	const [sort, setSort] = useState("None");

	// create bill card for each introduced bill (update when sort applied)
	// const [page, setPage] = useState(1);
  //  const pagination = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  //  const [lastVisible, setLast] = useState(null);
   const [items, setItems] = useState();
  //  const [lastPage, setLastPage] = useState(0);

   useEffect(async () => {

    console.log("RUNNING")

    const res = await fetch("https://api.propublica.org/congress/v1/bills/upcoming/house", config);
    const billsJson = await res.json();
    const billsData = billsJson.results[0].bills;
    // const billsData = tempBillsData.filter((bill) => {
    //   return(bill.congress == "117")
    // })
    // setItems(billsData);
    console.log("# OF BILLS:", billsData.length)
    Promise.all(billsData.map(async (billContent) => {
      //TODO: once all bills loaded: change doc id to bill.bill_id
      // console.log("billcontent:", billContent)
      let billData = await db.collection("bills").doc(`${billContent.bill_id}`).get()
      if (billData.data()) {
        // console.log("TITLE:", billData.data().title)
        return ({
          id: billContent.bill_id,
          number: billContent.bill_number,
          title: billData.data().title,
          dateIntroduced: billData.data().dateIntroduced,
          sponsorName: billData.data().sponsorName,
          sponsorState: billData.data().sponsorState,
          sponsorParty: billData.data().sponsorParty,
          cosponsorCount: billData.data().cosponsorCount,
          relatedIssueCount: billData.data().relatedIssueCount,
        })
      }
      else {
        return(
          {
            id: billContent.bill_id,
            number: billContent.bill_number,
            title: "N/A",
            dateIntroduced: "N/A",
            sponsorName: "N/A",
            sponsorState: "N/A",
            sponsorParty: "N/A",
            cosponsorCount: "N/A",
            relatedIssueCount: "N/A",
          }
        )
      }
    })).then((result) => {
      console.log("RESULT:", result)
      setItems(result)
    })



    //   } else {
    //     setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No bills"}</p>)
    //   }
    // }

  }, []);

	return (
		<AppLayout>
			<BillVoteNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HouseNav setSort={setSort} />
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
         <div className="divide-y divide-gray-200">
         {items &&
         (items.length > 0 ?
           items.map((bill, i) =>
          (<BillCard
            key = {i}
            billId = {bill.id}
            number={bill.number}
            title={bill.title}
            relatedIssueCount={bill.relatedIssueCount}
            sponsorName={bill.sponsorName}
            sponsorState={bill.sponsorState}
            sponsorParty={bill.sponsorParty}
            dateIntroduced={bill.dateIntroduced}
            cosponsorCount={bill.cosponsorCount}
            />))
            :
            <h1>There are currently no upcoming bills in the House.</h1>)}
         </div>
         </div>

			</div>
		</AppLayout>
	);
};

export default BillsHouseUpcoming;
