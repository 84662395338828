import React, { useState, useEffect } from "react";
import NoteCard from "../global/notes/NoteCard2";
// import db from "../../config/firebase";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

const IssueNotes = (props) => {
  const [sortBy, setSortBy] = useState("dateCreated");
  const [allNotes, setAllNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userMadeNote, setUserMadeNote] = useState(false);
  const { userData } = useAuth();

  function userIsInDistrict (currDistrict, currState) {
    if (currDistrict) {
      return (userData.district === currDistrict && userData.state === currState)
    }
    else {
      return (userData.state === currState)
    }
  }

  useEffect(async () => {
    if (sortBy) {
      setAllNotes([]);
      const snapshot = await props.notesRef.orderBy(sortBy, "desc").get();
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          setAllNotes((allNotes) => [...allNotes, doc]);
          if (doc.data().createdBy === props.userId) {
            console.log("user already created");
            setUserMadeNote(true);
          }
        });
      } else {
        console.log("empty snapshot");
      }

      setLoading(false);
    }
  }, [sortBy]);

  function makeLink() {
    console.log(userMadeNote);
    var tempLink = "/issues/";
    tempLink += props.issueId;
    tempLink += "/";
    if (!userMadeNote) {
      tempLink += "createNote";
    } else {
      tempLink += "notes";
    }
    return tempLink;
  }


  if (loading) {
    // notAbleToCreateNotes();
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold leading-tight text-gray-900">
          All Notes of Support
        </h1>
        <div className="ml-4 flex space-x-4 items-end">
          <h1 className="text-xl font-medium mr-2 pb-2">
            {allNotes.length} {allNotes.length !== 1 ? 'Notes' : 'Note'}
          </h1>
          {/* <div>
            <label for="location" class="block text-sm font-medium text-justiceBlue-500">
              Sort By
            </label>
            <select
              id="location"
              name="location"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            >
              <option value="datePosted">Date Posted</option>
              <option value="upvotes">Popularity</option>
              <option value="">Etc</option>
            </select>
          </div> */}
          {!userMadeNote && (
            <span className="rounded-md shadow-sm">
              <div >
                <div>
                  {userData ?
                  (userIsInDistrict(props.issueDistrict, props.issueState) ?
                    <Link className="btn-primary" to={makeLink}>+ Add New Note to Support</Link> :
                    <Link className='btn-secondary' to={`/issues/copy/${props.issueId}`} >+ Create a regional copy of this issue</Link>)
                  :
                  (<button
                    className="btn-primary focus:outline-none"
                    onClick={props.toggleAuthPopup}>
                    + Add New Note to Support
                  </button>) }

                </div>
              </div>
            </span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-8">
        {allNotes.length === 0 ? (
          <div className="mt-6 text-lg w-full text-center">
            There are currently no notes for this issue.
          </div>
        ) : (
          allNotes.map((note) => {
            console.log("NOTE:", note.data())
            console.log("upvoted notes:", props.upvotedNotes)
            return (
              <NoteCard
                noteId={note.id}
                issueId={note.data().issue.id}
                noteTitle={note.data().noteTitle}
                noteBody={note.data().noteBody}
                dateCreated={note.data().dateCreated}
                createdBy={note.data().createdByName}
                creatorId={note.data().createdBy}
                upvotes={note.data().upvotes}
                notesRef={props.notesRef}
                upvotedNotes={props.upvotedNotes}
                upvotedNotesRef={props.upvotedNotesRef}
                toggleAuthPopup={props.toggleAuthPopup}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default IssueNotes;

// {/* <NoteCard notesRef={props.notesRef} upvotedNotes={props.upvotedNotes} upvotedNotesRef={props.upvotedNotesRef} id={note.id} {...note.data()} /> */}
