
import React, { useState, useEffect } from "react";
import NoteCard from "../global/issues/NoteCard";
// import db from "../../config/firebase";
import { Link } from 'react-router-dom';

const IssueNotes = (props) => {
  const [sortBy, setSortBy] = useState('dateCreated');
  const [allNotes, setAllNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userMadeNote, setUserMadeNote] = useState(false);
  // const [switchLink, setSwitchLink] = useState([]);

  useEffect(async () => {
    if (sortBy) {
      setAllNotes([]);
      const snapshot = await props.notesRef.orderBy(sortBy, 'desc').get();
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          setAllNotes((allNotes) => [...allNotes, doc]);
          if (doc.data().createdBy === props.userId) {
            console.log('user already created');
            setUserMadeNote(true);
          }
        });
      } else {
        console.log('empty snapshot');
      }

      // if (!allNotes.empty){
      //   setUserMadeNote(false);
      //   // console.log("user no note");
      //   // this.setState({userMadeNote: false});
      //   allNotes.forEach((note) => {
      //     console.log(note.data().createdBy);
      //     console.log(props.userId);
      //     if (note.data().createdBy === props.userId){
      //       setUserMadeNote(true);
      //       console.log("user already made note");
      //       // this.setState({userMadeNote: true});
      //     }
      //   });
      // }

      setLoading(false);
    }
  }, [sortBy]);

  function makeLink() {
    console.log(userMadeNote);
    var tempLink = '/issues/';
    tempLink += props.issueId;
    tempLink += '/';
    if (!userMadeNote) {
      tempLink += 'createNote';
    } else {
      tempLink += 'notes';
    }
    return tempLink;
  }

  // function notAbleToCreateNotes() {
  //   // console.log(loading);
  //   // console.log("userMadeNote");
  //   // console.log(userMadeNote);
  //   // if (userMadeNote.length === 0){
  //   //   return null;
  //   // }
  //   if (!loading && userMadeNote) {
  //     // return (
  //     // <div>
  //     //   + Add New Note
  //     // </div>
  //     setSwitchLink("createNote");
  //     // 'createNote'
  //     // true
  //     // `/issues/${props.issueId}/notes`
  //     // "http://localhost:3001/issues/424VOD8UMy2LYzMYtQuB/notes"
  //     // );
  //   } else {
  //     // return (
  //     // <Link
  //     //   to={`/issues/${props.issueId}/createNote`}
  //     // >
  //     //   + Add New Note
  //     // </Link>
  //     setSwitchLink("notes");
  //     // 'notes'
  //     // false
  //     // null
  //     // `/issues/${props.issueId}/createNote`
  //     // );
  //   }
  // }

  if (loading) {
    // notAbleToCreateNotes();
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        <div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16'></div>
      </div>
    );
  }

  return (
    <>
      <div className='flex justify-between items-center mb-8'>
        <h1 className='text-2xl font-bold leading-tight text-gray-900'>
          All Notes of Support
        </h1>
        <div className='ml-4 flex space-x-4 items-end'>
          <h1 className='text-xl font-medium mr-2 pb-2'>
            {allNotes.length} Notes
          </h1>
          {/* <div>
            <label
              for='location'
              class='block text-sm font-medium text-justiceBlue-500'
            >
              Sort By
            </label>
            <select
              id='location'
              name='location'
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              class='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
            >
              <option value='datePosted'>Date Posted</option>
              <option value='upvotes'>Popularity</option>
              <option value="">Etc</option>
            </select>
          </div> */}
          {!userMadeNote && (
            <span className='rounded-md shadow-sm'>
              <div className='btn-primary'>
                <div>
                  <Link to={makeLink}>+ Add New Note</Link>
                </div>
              </div>
            </span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-8">
        {allNotes.length === 0 ? (
          <div className='mt-6 text-lg w-full text-center'>
            There are currently no notes for this issue.
          </div>
        ) : (
          allNotes.map((note) => {
            return (
                <NoteCard
                  noteId={note.id}
                  issueId={note.data().issue.id}
                  noteTitle={note.data().noteTitle}
                  noteBody={note.data().noteBody}
                  dateCreated={note.data().dateCreated}
                  createdBy={note.data().createdByName}
                  creatorId={note.data().createdBy}
                  upvotes={note.data().upvotes}
                  notesRef={props.notesRef}
                  upvotedNotes={props.upvotedNotes}
                  upvotedNotesRef={props.upvotedNotesRef}
                  noteId={note.id}
                />
            );
          })
        )}
      </div>
    </>
  );
};

export default IssueNotes;

// {/* <NoteCard notesRef={props.notesRef} upvotedNotes={props.upvotedNotes} upvotedNotesRef={props.upvotedNotesRef} id={note.id} {...note.data()} /> */}
