import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HomePageNav = () => {
  const { pathname } = useLocation();
  return (
    <>
      <header className='sm:px-8 lg:px-16 py-6 shadow-md'>
        <h1 className='text-3xl font-bold'>Home</h1>
      </header>
      <div className='mb-px py-7 mx-auto sm:px-8 lg:px-16'>
        <div className='flex space-x-8'>
          <NavLink
            to='/home'
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            exact
            activeClassName='text-gray-900'
          >
            Dashboard
          </NavLink>
          <NavLink
            to='/home/issues/active'
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
            isActive={() =>
              ['/home/issues/active', '/home/issues/resolved'].includes(
                pathname
              )
            }
          >
            My Issues
          </NavLink>
          <NavLink
            to='/home/notes'
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            My Notes
          </NavLink>
          <NavLink
            to='/home/events/upcoming'
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
            isActive={() =>
              ['/home/events/upcoming', '/home/events/past', '/home/events/bookmarked'].includes(pathname)
            }
          >
            My Events
          </NavLink>
          <NavLink
            to='/home/following/organizations'
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
            isActive={() =>
              ['/home/following/organizations', '/home/following/issues', '/home/following/bills'].includes(pathname)
            }
          >
            Following
          </NavLink>
        </div>
      </div>
      <hr className='border-solid' />
    </>
  );
};

export default HomePageNav;
