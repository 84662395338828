import { React, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Transition } from '@headlessui/react';
import megaphoneIcon from '../assets/megaphone-icon.png';
import logo from '../assets/logo-transparent-white.png';

const LegLayout = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [profileIsOpen, setProfileIsOpen] = useState(false);

  const [err, setErr] = useState('');
  const { currUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setErr('');
    try {
      history.push("/login");
      await logout();
    } catch {
      setErr('Failed to log out');
    }
  }

  return (
    <>
      <div>
        <nav className='bg-justiceBlue-500 z-50'>
          <div className='mx-auto px-2 sm:px-4 lg:px-8'>
            <div className='relative flex items-center justify-between h-16'>
              <div className='flex items-center px-2 lg:px-0'>
                <div className='flex-shrink-0 mb-1 mr-6'>
                  <img
                    className='block lg:hidden h-8 w-auto'
                    src={megaphoneIcon}
                    alt='Logo'
                  />
                  <img
                    className='hidden lg:block h-9 w-auto'
                    src={logo}
                    alt='Logo'
                  />
                </div>
                <div className='hidden lg:block lg:ml-2'>
                  <div className='flex'>
                    <NavLink
                      to='/leg/inbox/issues/unresolved'
                      className='px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                      activeClassName='bg-justiceBlue-600 text-white'
                    >
                      Inbox
                    </NavLink>
                    <NavLink
                      to='/leg/bills-votes/bills/latest'
                      className='ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                      activeClassName='bg-justiceBlue-600 text-white'
                    >
                      My Bills & Votes
                    </NavLink>
                    <NavLink
                      to='/leg/insights'
                      className='ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                      activeClassName='bg-justiceBlue-600 text-white'
                    >
                      Insights
                    </NavLink>
                    <NavLink
                      to='/leg/browse/issues/state'
                      className='ml-4 px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                      activeClassName='bg-justiceBlue-600 text-white'
                    >
                      Browse
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className='flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end'>
                <div className='max-w-lg w-full lg:max-w-xs'>
                  <label for='search' className='sr-only'>
                    Search
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                      <svg
                        className='h-5 w-5 text-gray-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                          clip-rule='evenodd'
                        />
                      </svg>
                    </div>
                    <input
                      id='search'
                      className='block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-blue-50 text-gray-300 placeholder-gray-400 focus:bg-white focus:text-gray-900 sm:text-sm transition duration-150 ease-in-out'
                      placeholder='Search'
                      type='search'
                    />
                  </div>
                </div>
              </div>

              <div className='flex lg:hidden'>
                {/* <!-- Mobile menu button --> */}
                <button
                  onClick={() => setMenuIsOpen(!menuIsOpen)}
                  className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:bg-justiceBlue-400 focus:text-white transition duration-150 ease-in-out'
                  aria-label='Main menu'
                  aria-expanded='false'
                >
                  {/* <!-- Icon when menu is closed. -->
                  <!--
                    Heroicon name: menu

                    Menu open: "hidden", Menu closed: "block"
                  --> */}
                  <svg
                    className={`${menuIsOpen ? 'hidden' : 'block'} h-6 w-6`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                  {/* <!-- Icon when menu is open. -->
                  <!--
                    Heroicon name: x

                    Menu open: "block", Menu closed: "hidden"
                  --> */}
                  <svg
                    className={`${menuIsOpen ? 'block' : 'hidden'} h-6 w-6`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>

              <div className='hidden lg:block lg:ml-4'>
                <div className='flex items-center'>
                  <button
                    className='flex-shrink-0 p-1 border-2 border-transparent text-gray-200 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                    aria-label='Notifications'
                  >
                    {/* <!-- Heroicon name: bell --> */}
                    <svg
                      className='h-6 w-6'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        strokeLinejoin='round'
                        stroke-width='2'
                        d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                      />
                    </svg>
                  </button>
                  {/* TODO: add notifications dropdown here */}

                  {/* <!-- Profile dropdown --> */}
                  {/* some page elements were on top of the expanded menu bar, so made z-order higher to fix */}
                  <div
                    className='ml-4 relative flex-shrink-0'
                    style={{ zIndex: '10' }}
                  >
                    <div>
                      <button
                        onClick={() => setProfileIsOpen(!profileIsOpen)}
                        className='flex text-sm rounded-full text-white focus:outline-none focus:shadow-solid transition duration-150 ease-in-out'
                        id='user-menu'
                        aria-label='User menu'
                        aria-haspopup='true'
                      >
                        <img
                          className='h-8 w-8 rounded-full'
                          src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                          alt=''
                        />
                      </button>
                    </div>
                    <Transition
                      show={profileIsOpen}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <div className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg'>
                        {/* long email doesn't overflow div --> breaks text for now, but can also switch to just hiding the overflow (overflowX: 'hidden') */}
                        <div
                          className='py-1 rounded-md bg-white shadow-xs'
                          role='menu'
                          aria-orientation='vertical'
                          aria-labelledby='user-menu'
                          style={{ width: '100%' }}
                        >
                          <p
                            className='block px-4 py-2 text-sm leading-5 text-gray-500'
                            style={{
                              maxWidth: '100%',
                              overflowWrap: 'break-word',
                            }}
                          >
                            Signed in as {currUser.email}
                          </p>
                          <Link
                            to={`/users/${currUser.uid}/issues`}
                            className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                            role='menuitem'
                          >
                            My Profile
                          </Link>
                          <Link
                            to='/home'
                            className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                            role='menuitem'
                          >
                            Settings
                          </Link>
                          <button
                            onClick={handleLogout}
                            className='block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
                          >
                            Log Out
                          </button>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--
            Mobile menu, toggle classes based on menu state.

            Menu open: "block", Menu closed: "hidden"
          --> */}
          <div className={`${menuIsOpen ? 'block' : 'hidden'} lg:hidden`}>
            <div className='px-2 pt-2 pb-3'>
              <Link
                to='/home'
                className='block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                Home
              </Link>
              <Link
                to='/district'
                className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                District
              </Link>
              <Link
                to='/state'
                className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                State
              </Link>
              <Link
                to='/nation'
                className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                Nation
              </Link>
              <Link
                to='/bills-votes/house/upcoming'
                className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                Bills & Votes
              </Link>
              <Link
                to='/legislators'
                className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
              >
                Legislators
              </Link>
            </div>
            <div className='pt-4 pb-3 border-t border-gray-700'>
              <div className='flex items-center px-5'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full'
                    src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                    alt=''
                  />
                </div>
                <div className='ml-3'>
                  <div className='text-base font-medium leading-6 text-white'>
                    Tom Cook
                  </div>
                  <div className='text-sm font-medium leading-5 text-gray-400'>
                    tom@example.com
                  </div>
                </div>
              </div>
              <div className='mt-3 px-2'>
                <p className='block px-3 py-2 rounded-md text-base font-medium text-gray-300'>
                  Signed in as {currUser.email}
                </p>
                <a
                  href='#'
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                >
                  Your Profile
                </a>
                <a
                  href='#'
                  className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                >
                  Settings
                </a>
                <button
                  onClick={handleLogout}
                  className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-justiceBlue-400 focus:outline-none focus:text-white focus:bg-justiceBlue-400 transition duration-150 ease-in-out'
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div>
        {err && (
          <>
            <div className='rounded-md bg-red-50 p-4'>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  {/* <!-- Heroicon name: check-circle --> */}
                  <svg
                    className='h-5 w-5 text-red-400'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                      clip-rule='evenodd'
                    />
                  </svg>
                </div>
                <div className='ml-3'>
                  <p className='text-sm leading-5 font-medium text-red-800'>
                    Error: {err}
                  </p>
                </div>
                <div className='ml-auto pl-3'>
                  <div className='-mx-1.5 -my-1.5'>
                    <button
                      className='inline-flex rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150'
                      aria-label='Dismiss'
                    >
                      {/* <!-- Heroicon name: x --> */}
                      <svg
                        className='h-5 w-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                          clip-rule='evenodd'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div>{children}</div>
    </>
  );
};

export default LegLayout;
