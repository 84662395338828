import React from "react";

import AppLayout from "../../../../layouts/AppLayout";
import DistrictNav from "../../../../components/civ/district/DistrictNav";
import DistrictIssuesTopics from "../../../../components/civ/district/issues/DistrictIssuesTopics";

const DistrictIssuesTopicsPage = () => {
	return (
		<AppLayout>
			<DistrictNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<DistrictIssuesTopics />
			</div>
		</AppLayout>
	);
};

export default DistrictIssuesTopicsPage;
