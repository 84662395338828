import React, { useEffect, useState, useContext } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
// import StateIssuesNav from "./StateIssuesNav";
import EventCard from "../global/events/EventCard"
// import { db } from "../../../config/firebase";
// import { useAuth } from "../../../contexts/AuthContext";
import {NationDBContext} from "../../../contexts/NationContext"

const NationEvents = () => {

  const events = useContext(NationDBContext);
  let [topEvents, setTopEvents] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect(async () => {

    // console.log("activeIssues:", activeIssues)

    if(events[5] && events[5].length != 0) {
      console.log("exists")
      setTopEvents(events[5].map((doc, i) => {

        // let timeZoneAbbr = (doc.data().timeZone).split(" ")[(doc.data().timeZone).split(" ").length - 1]
        // let timeZoneAbbr_slice1 = timeZoneAbbr.slice(0, -1)
        // let timeZoneAbbr_final = timeZoneAbbr_slice1.slice(1)

        return (
          <EventCard
            key = {i}
            eventId={doc.id}
            title={doc.data().eventTitle}
            going={doc.data().numAttending}
            interested={doc.data().numInterested}
            date={doc.data().mainStartTimeUTC}
            time={doc.data().mainStartTimeString + " " + doc.data().timeZone}
            organizer={doc.data().organizerName}
            isVirtual={doc.data().isVirtual}
            location={`${doc.data().city}, ${doc.data().state}`}
          />
        )
      }));
    } else {
      setTopEvents(<h1 className="px-8 py-8 font-medium text-xl">There are currently no issues.</h1>)
    }

  }, [events[5]]);

  return (
    <>
      {/* <StateIssuesNav /> */}
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {topEvents}
          </div>
        </div>
      </>
    );

}


export default NationEvents;
