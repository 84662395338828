import React from 'react';
import {Link} from "react-router-dom";

const TopicCard = (props) => {

  // if(props.trendingTopic == true) {

  //   return (
  //     <li className="border-t border-gray-200">
  //       <a href="#" className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
  //         <div className="px-4 py-4 sm:px-6 flex flex-row w-full">

  //           <div className="flex items-center justify-between pr-10">

  //             <div className="text-2xl mr-7 leading-6 font-semibold text-gray-900 ">
  //               {props.data.name}
  //             </div>

  //             <div className="mt-2 sm:flex sm:justify-between">
  //               <div className="mr-1 flex items-center text-md leading-5 text-gray-500">
  //                 {/* <!-- Heroicon name: exclamation-circle -->
  //                 <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  //                 </svg> */}
  //                   {props.data.issueTags.length} Issues •
  //               </div>
  //               <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
  //                 {/* <!-- Heroicon name: annotation -->
  //                 <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
  //                 </svg> */}
  //                   {props.data.noteTags.length} Notes
  //               </div>
  //             </div>

  //           </div>

  //           <div className='flex-grow'/>

  //           <div className="mt-2 sm:flex sm:justify-between float-right">
  //             <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
  //             <Link to={{pathname: "/district/issues/list", state: props.data.name}}>
  //               <span>
  //                 View Related Issues
  //               </span>
  //               <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
  //               </svg>
  //               </Link>
  //             </div>
  //           </div>

  //         </div>
  //       </a>
  //     </li>
  //   );

  // }
  // else {

    return (
      <Link to={{pathname: "/district/issues/list", state: props.name}}>
        <div class="w-full mb-6 text-justiceBlue-500 bg-justiceBlue-100 rounded-lg shadow hover:bg-justiceBlue-50 hover:shadow-md px-8 py-6 flex justify-between items-center">

          <div class="">
            <div class="font-bold text-xl ">{props.name}</div>
            <div class="text-sm ">{props.data.length + (props.data.length != 1 ? " issues" : " issue")}</div>
          </div>

          <div className="flex items-center space-x-2">
            <h1 className="font-medium ">View issues</h1>
            <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>

        </div>
      </Link>
    );

  // }
}
export default TopicCard;
