import React from 'react';
import OrgLayout from "../../../layouts/OrgLayout"
import HomePageNav from "../../../components/org/home/HomeNav";
import DashboardUpcomingEvents from "../../../components/org/home/DashboardUpcomingEvents";
import DashboardTrending from "../../../components/org/home/DashboardTrending";

function HomePage(props) {
    return (
      <OrgLayout>
        <HomePageNav />
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
          <DashboardUpcomingEvents />
          <DashboardTrending />
        </div>
      </OrgLayout>
    );
}

export default HomePage;
