import React, { useState, useEffect } from "react";
import IssueCard from "./FollowingIssueCard";
import { useAuth } from "../../../../contexts/AuthContext";
import { db } from "../../../../config/firebase";

const IssuesFollowingDetailed = () => {

  const { currUser, currUserRef } = useAuth();
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (currUserRef && issues.length == 0) {

      const issuesFollowingRef = db.collection("civilians").doc(currUser.uid).collection("follows");
      const snapshot = await issuesFollowingRef.where("following", '==', "issue").get();
      if (snapshot.empty) {
        console.log('No matching documents.');
        setLoading(false);
        return;
      }

      setIssues(snapshot.docs)


    }
    setLoading(false);
  }, []);

  if(loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {/* <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="ml-4 mt-2">
              <h3 className="text-3xl font-bold leading-6 text-black">
                Issues Following
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm"> */}
                {/* <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                >
                  + Add New Issue
                </button> */}
                {/* query stuff here
              </span>
            </div>
          </div>
        </div> */}
        <div className="divide-y divide-gray-200">
          {issues.length > 0 ?
            issues.map((doc) => {
            return (
              <IssueCard
                issueTitle={doc.data().issueTitle}
                // supporters={doc.data().supporters}
                legislator={doc.data().legislator}
                region={doc.data().region}
                created={doc.data().dateCreated}
                // copies={doc.data().regionalCopies}
                issueId={doc.id}
              />
            );
            }
          )
          :
          <h1 className="text-lg py-6 px-6">You are currently not following any issues.</h1>
          }
        </div>
      </div>
    </>
  );
};

export default IssuesFollowingDetailed;
