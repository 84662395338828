import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import SharePopup from '../../../components/org/global/SharePopup.js'
import IssueHeader from "../../../components/org/issue/IssueHeader";
import IssueCopies from "../../../components/org/issue/IssueCopies";
import { db } from "../../../config/firebase";

function IssueCopiesPage({ match }) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [issueId, setIssueId] = useState("");
	const [issueInfo, setIssueInfo] = useState();
  const [sharePopup, setSharePopup] = useState(false);

	useEffect(async () => {
		if (match && match.params.issue) {
			let issueId = match.params.issue;

			if (issueId.length == 0) {
				setError("Bad link. No issue id given.");
			} else {
				setIssueId(issueId);
				const issueData = await db
					.collection("issues")
					.doc(issueId)
					.get();
				setIssueInfo(issueData.data());
			}
			setLoading(false);
		}
	}, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}
	return (
		<AppLayout>

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/issues/${issueId}/notes`}
          type="issue"
          >
        </SharePopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<IssueHeader
					issueId={issueId}
					title={issueInfo.issueTitle}
					legislator={`${issueInfo.legislator.firstName} ${issueInfo.legislator.lastName}`}
					legislatorId={issueInfo.legislator.id}
					region={`${issueInfo.legislator.region}, ${
						issueInfo.legislator.chamber === "Senate"
							? "State-Wide"
							: "District " + issueInfo.legislator.district
					}`}
          createdBy={issueInfo.createdBy}
          toggleSharePopup={toggleSharePopup}
				/>

				<IssueCopies issue={issueInfo} issueId={issueId} />
			</div>
		</AppLayout>
	);
}

export default IssueCopiesPage;
