import React from "react";
import { Link, NavLink } from "react-router-dom";

const SupportersNav = (props) => {

  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">My Supporters</h1>
      </header>
      <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex justify-between items-center">

        <div className="flex space-x-8">
          {/* <NavLink
            to="/org/supporters"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300"
            exact
            activeClassName="text-gray-900"
          >
            Overview
          </NavLink> */}
          <NavLink
            to="/org/supporters/attendees"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Event Attendees
          </NavLink>
          <NavLink
            to="/org/supporters/mailing"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Mailing List
          </NavLink>
        </div>

        <div>
          <span className="rounded-md shadow-sm">
            <button
              className="btn-primary"
              onClick={() => props.export(props.csvName)}
            >
              Export Data
            </button>
          </span>
        </div>
      </div>
      <hr className="border-solid" />
    </>
  );
};

export default SupportersNav;
