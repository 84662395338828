import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

const LegislatorActivityVoteCard = (props) => {
  return (
    <Link className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
      <div className="flex items-center">
        {/* <div className="ml-8 mr-2 py-6 bg-yellow-600 rounded-lg">
          <div className="transform -rotate-90 text-lg font-semibold text-white">
            VOTE
          </div>
        </div> */}
        <div className="flex-auto px-4 py-4 truncate sm:px-6">
          <div className="flex items-center justify-between">
            <div className="text-2xl leading-5 font-semibold text-gray-900 h-7 truncate">
              {props.number}: {props.title}
            </div>
            <div className="ml-2 flex-shrink-0 flex">
              <span className="px-5 inline-flex text-sm leading-8 font-medium rounded-full bg-justiceBlue-600 text-white">
                {props.issues} related issues
              </span>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                Voted {props.date}
              </div>
              <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                Purpose: {props.purpose}
              </div>
              <div className="mt-2 mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                {props.outcome}
              </div>
              {props.position === "Yes" ? (
                <div className="px-4 text-white font-semibold leading-9 bg-green-600 rounded-full">
                  Voted Yea
                </div>
              ) : (
                <div className="px-4 text-white font-semibold leading-9 bg-red-600 rounded-full">
                  Voted Nay
                </div>
              )}
            </div>
            <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
              <span>View vote</span>
              <svg
                className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default LegislatorActivityVoteCard;
