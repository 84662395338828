import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import AppLayout from "../../../../layouts/AppLayout";
import StateTopIssues from "../../../../components/civ/state/issues/StateTopIssues";
import StateNav from "../../../../components/civ/state/StateNav";

const StateTopIssuesPage = () => {
	return (
		<AppLayout>
			<StateNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<StateTopIssues />
			</div>
		</AppLayout>
	);
};

export default StateTopIssuesPage;
