import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout"
import { db } from "../../config/firebase"

export const formatDate = (timestamp) => {
  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
    return f_dateString;
  } else {
    // if there isn't a date associated with the issue
    return "Date N/A";
  }
};

const AdminReportedContentPage = () => {

  const [loading, setLoading] = useState(true);
  const [reportedContentCount, setReportedContentCount] = useState(0);
  const [reportedContent, setReportedContent] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>)

  useEffect(async () => {

    const tempReportedContent = await db.collection("reportedContent").get();

    if (tempReportedContent.docs && tempReportedContent.docs.length > 0){

      setReportedContentCount(tempReportedContent.docs.length)

      Promise.all(tempReportedContent.docs.map(async (doc) => {

        return (
        <tr>
          <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {doc.id}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().contentType}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().contentId}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {formatDate(doc.data().dateReported)}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().userInput}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().reporterId}
          </td>
        </tr>
        )
      }
      ))
      .then((result) => {
        setReportedContent(result)
      })
    }
    else {

    }

    setLoading(false);

  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
      <AdminLayout>
        <div className="mb-px py-8 px-12">

          <div className="flex justify-between items-end mb-8">
            <h1 className="text-2xl font-semibold">Reported Content</h1>
            <h2 className="text-lg font-medium">{reportedContentCount} pieces of content</h2>
          </div>

          <div class="flex flex-col max-w-full">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Report ID
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Content Type
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Content ID
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date Reported
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reporter Comment
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reporter User ID
                        </th>
                        {/* <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date of Last Login
                        </th> */}
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {reportedContent}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </AdminLayout>
  )
}

export default AdminReportedContentPage;
