import React from "react";

import AppLayout from "../../../../layouts/AppLayout";
import DistrictNav from "../../../../components/civ/district/DistrictNav";
import DistrictTopIssues from "../../../../components/civ/district/issues/DistrictTopIssues";

const DistrictTopIssuesPage = () => {
	return (
		<AppLayout>
			<DistrictNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<DistrictTopIssues />
			</div>
		</AppLayout>
	);
};

export default DistrictTopIssuesPage;
