import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeNotesContainer from "../../../components/civ/home/notes/HomeNotesContainer";
import { useAuth } from "../../../contexts/AuthContext";

const HomeNotesPage = () => {
	const { currUserRef } = useAuth();
	const [notes, setNotes] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(async () => {
		if (currUserRef && notes.length == 0) {
			const notesRef = currUserRef.collection("notes");
			const snapshot = await notesRef.get();
			if (!snapshot.empty) {
				snapshot.forEach((doc) => {
					setNotes((notes) => [...notes, doc.data()]);
				});
			} else {
				console.log("empty snapshot");
			}
			setLoading(false);
		}
	}, []);

	if (loading) {
		return <></>;
	} else {
		return (
			<AppLayout>
				<HomePageNav />
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					<HomeNotesContainer
						// profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
						// name="Ayanna Pressley"
						// region="MA-7"
						notes={notes}
					/>
					{/* <HomeNotesContainer
          profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
          name="Elizabeth Warren"
          region="Massachusetts"
        />
        <HomeNotesContainer
          profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
          name="Edward Markey"
          region="Massachusetts"
        /> */}
				</div>
			</AppLayout>
		);
	}
};

export default HomeNotesPage;
