import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";
import SemiPrivateRoute from "./CivSemiPrivateRoute";

import BillPage from "../../pages/civ/billsvotes/bill/BillPage";
import BillCommentsPage from "../../pages/civ/billsvotes/bill/BillCommentsPage";
import BillCosponsorsPage from "../../pages/civ/billsvotes/bill/BillCosponsorsPage";
import BillAmendmentsPage from "../../pages/civ/billsvotes/bill/BillAmendmentsPage";
import BillSimilarPage from "../../pages/civ/billsvotes/bill/BillSimilarPage";
import BillRelatedIssuesPage from "../../pages/civ/billsvotes/bill/BillRelatedIssuesPage";

import VotePage from "../../pages/civ/billsvotes/vote/VotePage";
import VoteCommentsPage from "../../pages/civ/billsvotes/vote/VoteCommentsPage";
import VoteRelatedIssuesPage from "../../pages/civ/billsvotes/vote/VoteRelatedIssuesPage";

import BillsHouseUpcoming from "../../pages/civ/billsvotes/BillsHouseUpcomingPage";
import BillsHouseIntroduced from "../../pages/civ/billsvotes/BillsHouseIntroducedPage";
import BillsHousePast from "../../pages/civ/billsvotes/BillsHousePastPage";

import BillsSenateUpcoming from "../../pages/civ/billsvotes/BillsSenateUpcomingPage";
import BillsSenateIntroduced from "../../pages/civ/billsvotes/BillsSenateIntroducedPage";
import BillsSenatePast from "../../pages/civ/billsvotes/BillsSenatePastPage";

import VotesHousePage from "../../pages/civ/billsvotes/VotesHousePage";
import VotesSenatePage from "../../pages/civ/billsvotes/VotesSenatePage";

import BillsLearnMorePage from "../../pages/civ/billsvotes/BillsLearnMorePage";

import {
	BillVoteAPIProvider,
	BillVoteDBProvider,
} from "../../contexts/BillVoteContext";

const BillVoteRoutes = () => {
	return (
		<BillVoteAPIProvider>
			<BillVoteDBProvider>
				<Switch>
					<PrivateRoute
						path="/bills-votes/votes/:vote/related-issues"
						component={VoteRelatedIssuesPage}
					/>
					<PrivateRoute
						path="/bills-votes/votes/:vote/comments"
						component={VoteCommentsPage}
					/>
					<SemiPrivateRoute
						path="/bills-votes/votes/:vote"
						component={VotePage}
					/>
					<PrivateRoute
						path="/bills-votes/bills/:bill/related-issues"
						component={BillRelatedIssuesPage}
					/>
					<PrivateRoute
						path="/bills-votes/bills/:bill/similar"
						component={BillSimilarPage}
					/>
					<PrivateRoute
						path="/bills-votes/bills/:bill/amendments"
						component={BillAmendmentsPage}
					/>
					<PrivateRoute
						path="/bills-votes/bills/:bill/cosponsors"
						component={BillCosponsorsPage}
					/>
					<PrivateRoute
						path="/bills-votes/bills/:bill/comments"
						component={BillCommentsPage}
					/>
					<SemiPrivateRoute
						path="/bills-votes/bills/:bill"
						component={BillPage}
					/>
					<PrivateRoute
						path="/bills-votes/senate/upcoming"
						component={BillsSenateUpcoming}
					/>
					<PrivateRoute
						path="/bills-votes/senate/upcoming"
						component={BillsSenateUpcoming}
					/>
					<PrivateRoute
						path="/bills-votes/senate/introduced"
						component={BillsSenateIntroduced}
					/>
					<PrivateRoute
						path="/bills-votes/senate/past"
						component={BillsSenatePast}
					/>
					<PrivateRoute
						path="/bills-votes/house/introduced"
						component={BillsHouseIntroduced}
					/>
					<PrivateRoute
						path="/bills-votes/house/past"
						component={BillsHousePast}
					/>
					<PrivateRoute
						path="/bills-votes/vote/house"
						component={VotesHousePage}
					/>
					<PrivateRoute
						path="/bills-votes/vote/senate"
						component={VotesSenatePage}
					/>
					{/* has to be last since it includes the additional /bills-votes tag */}
					<PrivateRoute
						path={["/bills-votes/house/upcoming", "/bills-votes"]}
						component={BillsHouseUpcoming}
					/>
				</Switch>
			</BillVoteDBProvider>
		</BillVoteAPIProvider>
	);
};

export default BillVoteRoutes;
