import React, { useEffect, useState, useContext } from 'react';
import app from "../config/firebase"
// import {AuthContext} from "./AuthContext"
import { useAuth } from "./AuthContext";

const dbRef = app.firestore();




// DB call for trending issues & events
async function getTrending(userState) {
  if (dbRef) {

    const topicsRef = dbRef.collection("topics");
    const snapshotActive = await topicsRef.where("state", "==", userState).get();

    let topics = [];

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {
        topics.push(doc.data());
      });
      topics.sort((a,b) => (a.issueTags.length < b.issueTags.length) ? 1 : -1);
    } else {
      console.log("empty snapshot");
    }

    return topics.slice(0,4);

  }
}

// DB call for all active issues (categorized by topic)
async function getIssues(userState) {

    if (dbRef) {

      // console.log("GETTING ISSUES")

      let activeIssues = [];

      const issuesRef = dbRef.collection("issues");
      const snapshotActive = await issuesRef.where("active", "==", true).where("region", "==", userState).get();

      let issuesByTopic = {'No Topic' : []};

      if (!snapshotActive.empty) {
        snapshotActive.forEach((doc) => {

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in issuesByTopic) {
                issuesByTopic[topic].push(doc);
              } else {
                issuesByTopic[topic] = [doc];
              }
          });

          if(doc.data().topicTags.length == 0) {
            issuesByTopic['No Topic'].push(doc);
          }

          activeIssues.push(doc);

        });

        if(issuesByTopic['No Topic'].length == 0)
          delete issuesByTopic['No Topic'];

      } else {
        console.log("empty snapshot");
      }

      let topIssues = [...activeIssues];
      topIssues.sort((a,b) => (a.data().supporters < b.data().supporters) ? 1 :  -1);
      topIssues.forEach(doc => console.log(doc.data()));

      let latestIssues = [...activeIssues];
      latestIssues.sort((a,b) => (a.data().dateCreated < b.data().dateCreated) ? 1 : -1);

      return [topIssues, latestIssues, issuesByTopic];

    }

}

// DB call for all resolved issues
async function getResolvedIssues(userState) {


    if (dbRef) {
        const issuesRef = dbRef.collection("issues");
        const snapshotResolved = await issuesRef.where("active", "==", false).where("region", "==", userState).get();

        let tempData = [];

        if(!snapshotResolved.empty) {
          snapshotResolved.forEach((doc) => { tempData.push(doc); });
        } else {
          console.log("empty snapshot");
        }

        return tempData;
    }

}

async function getEvents(userState) {

  if (dbRef) {

    // console.log("GETTING EVENTS")

    let tempEvents = [];

    const eventsRef = dbRef.collection("events");
    const snapshotActive = await eventsRef.where("state", "==", userState).get();

    let eventsByTopic = {'No Topic' : []};
    let eventsByType = {};

    if (!snapshotActive.empty) {
      // console.log("Events exist!")
      // console.log("number of events:", snapshotActive.docs.length)
      snapshotActive.forEach((doc) => {

        // console.log("DOC ID:", doc.id)

        const currentTime = new Date();

        if (doc.data().mainStartTimeUTC.toDate() >= currentTime){

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in eventsByTopic) {
                eventsByTopic[topic].push(doc);
              } else {
                eventsByTopic[topic] = [doc];
              }
          });

          if(doc.data().topicTags.length == 0) {
            eventsByTopic['No Topic'].push(doc);
          }

          // console.log("events by type:", eventsByType)
          // console.log("event type:", doc.data().eventType)
          if(doc.data().eventType in eventsByType) {
            eventsByType[doc.data().eventType].push(doc);
            // console.log("UPDATED:", eventsByType)
          } else {
            eventsByType[doc.data().eventType] = [doc];
            // console.log("UPDATED:", eventsByType)
          }
          // eventsByType[doc.data().eventType].push(doc)

          tempEvents.push(doc);

        }

      });

      if(eventsByTopic['No Topic'].length == 0)
        delete eventsByTopic['No Topic'];

    } else {
      console.log("empty snapshot");
    }

    // console.log("TEMPEVENTS:", tempEvents)

    let topEvents = [...tempEvents];
    topEvents.sort((a,b) => (a.data().numAttending < b.data().numAttending) ? 1 :  -1);
    topEvents.forEach(doc => console.log(doc.data()));

    let latestEvents = [...tempEvents];
    latestEvents.sort((a,b) => (a.data().mainStartTimeUTC > b.data().mainStartTimeUTC) ? 1 : -1);

    // console.log("TOP EVENTS:", topEvents)
    // console.log("LATEST EVENTS:", latestEvents)
    // console.log("EVENTS BY TOPIC:", eventsByTopic)
    // console.log("EVENTS BY TYPE:", eventsByType)
    return [topEvents, latestEvents, eventsByTopic, eventsByType];

  }

}

// async function getOrganizations(userState) {

//   if (dbRef) {

//     let allEvents = [];

//     const issuesRef = dbRef.collection("events");
//     const snapshotActive = await issuesRef.where("state", "==", userState).get();
//     //const snapshotActive = await issuesRef.get();

//     let eventsByTopic = {'No Topic' : []};
//     let eventsByType = {'No Type' : []};

//     if (!snapshotActive.empty) {
//       snapshotActive.forEach((doc) => {

//         doc.data().topicTags.forEach(topic =>
//         {
//             if(topic in eventsByTopic) {
//               eventsByTopic[topic].push(doc);
//             } else {
//               eventsByTopic[topic] = [doc];
//             }
//         });

//         if(doc.data().eventType in eventsByType) {
//           eventsByType[doc.data().eventType].push(doc);
//         } else {
//           eventsByType[doc.data().eventType] = [doc];
//         }


//         if(doc.data().eventTypes.length == 0) {
//           eventsByType['No Type'].push(doc);
//         }

//         if(doc.data().topicTags.length == 0) {
//           eventsByTopic['No Topic'].push(doc);
//         }

//         allEvents.push(doc);

//       });

//       if(eventsByTopic['No Topic'].length == 0)
//         delete eventsByTopic['No Topic'];

//       if(eventsByType['No Type'].length == 0)
//         delete eventsByType['No Type'];


//     } else {
//       console.log("empty snapshot");
//     }

//     let topEvents = [...allEvents];
//     topEvents.sort((a,b) => (a.data().numInterested < b.data().numInterested) ? 1 :  -1);

//     let latestEvents = [...allEvents];
//     latestEvents.sort((a,b) => (a.data().dateCreated < b.data().dateCreated) ? 1 : -1);

//     return [topEvents, latestEvents , eventsByTopic, eventsByType];

//   }

// }

async function getOrganizations(userState) {

  if (dbRef) {

    let allOrganizations = [];

    const organizationsRef = dbRef.collection("organizations");
    const snapshotActive = await organizationsRef.where("hqState", "==", userState).get();
    // const snapshotActive = await organizationsRef.where("scope", "==", "District").where("hqState", "==", userState).get();
    // const snapshotActive2 = await organizationsRef.where("scope", "==", "State").where("hqState", "==", userState).get();
    //const snapshotActive = await organizationsRef.get();

    let organizationsByCategory = {};

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {
        allOrganizations.push(doc);
        if(doc.data().type in organizationsByCategory) {
          organizationsByCategory[doc.data().type].push(doc);
          // console.log("UPDATED:", eventsByType)
        } else {
          organizationsByCategory[doc.data().type] = [doc];
          // console.log("UPDATED:", eventsByType)
        }
      })
    } else {
      console.log("empty snapshot");
    }

    // if(!snapshotActive2.empty) {
    //   snapshotActive.forEach((doc) => {
    //     allOrganizations.push(doc);
    //     if(doc.data().type in organizationsByCategory) {
    //       organizationsByCategory[doc.data().type].push(doc);
    //       // console.log("UPDATED:", eventsByType)
    //     } else {
    //       organizationsByCategory[doc.data().type] = [doc];
    //       // console.log("UPDATED:", eventsByType)
    //     }
    //   })
    // }

    // if(organizationsByCategory['No Category'].length == 0)
    //   delete organizationsByCategory['No Category'];

    let topOrganizations = [...allOrganizations];
    topOrganizations.sort((a,b) => (a.data().followerCount < b.data().followerCount) ? 1 :  -1);

    return [topOrganizations, organizationsByCategory];

    } else {
      console.log("empty snapshot");
    }

}


// context used for all DB data
export const StateDBContext = React.createContext(null);

// Provider used for all DB data
export const StateProvider = (props) => {

    const [topicsDb, setTrending] = useState(null);
    const [activeIssuesDb, setActiveIssues] = useState([null, null, null]);
    const [resolvedIssuesDb, setResolvedIssues] = useState(null);
    const [eventsDb, setEvents] = useState([null, null, null]);
    const [organizationsDb, setOrganizations] = useState([null, null]);

    const { currUserAdminInfo, currUserRef } = useAuth();

    useEffect(async () => {
        const userDoc = await currUserRef.get();
        let userState;
        if (currUserAdminInfo.type != "organization"){
          userState = userDoc.data().state;
        }
        else {
          userState = userDoc.data().hqState;
          // console.log("userState:", userState)
        }

        getTrending(userState).then(setTrending);
        getIssues(userState).then(setActiveIssues);
        getResolvedIssues(userState).then(setResolvedIssues);
        getEvents(userState).then(setEvents)
        getOrganizations(userState).then(setOrganizations);

    }, []);

    return(
      <div>
      {topicsDb && activeIssuesDb && resolvedIssuesDb && eventsDb && organizationsDb &&
        <StateDBContext.Provider value={[topicsDb, activeIssuesDb[0], activeIssuesDb[1], activeIssuesDb[2], resolvedIssuesDb, eventsDb[0], eventsDb[1], eventsDb[2], eventsDb[3], organizationsDb[0], organizationsDb[1]]}>
            {props.children}
        </StateDBContext.Provider>
      }
      </div>
    );

}
