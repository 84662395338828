import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeEngagementBanner from "../../../components/civ/home/home/HomeEngagementBanner";
import HomeRegionInfo from "../../../components/civ/home/home/HomeRegionInfo";
import HomeUpcomingEvents from "../../../components/civ/home/home/HomeUpcomingEvents";
import HomeFeed from "../../../components/civ/home/home/HomeFeed";
import { useAuth } from "../../../contexts/AuthContext";

const HomePage = () => {
	// const [err, setErr] = useState("");
	const [loading, setLoading] = useState(true);

	const { userData } = useAuth();
	// const [userData, setUserData] = useState({});
	useEffect(() => {
		setLoading(true);
		if (userData) {
			setLoading(false);
		}
	}, [userData]);

	if (loading || userData === undefined) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}

	return (
		<AppLayout>
			<HomePageNav />

			{/* <HomeEngagementBanner /> */}

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeRegionInfo
					district={userData.district}
					state={userData.state}
					districtRep={userData.districtRep}
					senateRep={userData.senateRep}
				/>
				<br />
				<HomeUpcomingEvents />
			</div>

			<hr className="border-solid border-gray-300 mt-5" />

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeFeed />
			</div>
		</AppLayout>
	);
};

export default HomePage;
