import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import { useHistory, Link } from "react-router-dom";
import { db } from "../../../config/firebase";

function CivilianSettingsPage(props) {
  const { currUserRef, currUser } = useAuth();
  const [deleting, setDeleting] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [status, setStatus] = useState({});
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(async () => {
    if (currUserRef) {

      setLoading(false);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    setLoading(true);

    var cred = firebase.auth.EmailAuthProvider.credential(currUser.email, oldPassword);
    currUser.reauthenticateWithCredential(cred).then(() => {
      currUser.updatePassword(newPassword)
      setStatus({ success: "You have successfully updated your password." });
    })
    .catch((error) => {
      if (error.code === "auth/wrong-password"){
        setErr("Incorrect current password. Please try again.")
      }
      else {
        setErr(error.message)
      }
    });

    setErr("");
    setLoading(false);
  }

  function toggleDelete(){
    setDeleting(!deleting)
  }

  function handleDelete(e){

    //TODO: find way to push history after below finishes so error has chance to show
    history.push(`/login`)

    currUser.delete().then(
      db.collections("users").doc(currUser.uid).delete().then(() => {
        db.collections("civilians").doc(currUser.uid).delete()
      })
      //TODO: also delete profile pic if needed
    ).catch((error) => {
      setErr(error.message)
    })

  }

  function handleOldPasswordChange(e) {
    setOldPassword(e.target.value)
  }

  function handleNewPasswordChange(e) {
    setNewPassword(e.target.value)
  }

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <AppLayout>

      { deleting &&
        <div className="absolute h-full w-full top-0 left-0 bg-gray-700 bg-opacity-30 z-50">
          {/* <div className="absolute top-1/2 left-1/2 -mt-36 -ml-96"> */}
          <div className="absolute w-full h-full flex flex-col justify-center items-center text-center">
          {/* <div className="flex flex-col justify-center"> */}
            <div className="bg-gray-100 px-20 py-20 rounded-lg shadow">

              <h1 className="flex justify-center text-3xl font-semibold mb-4">
                Do you really want to delete your account?
              </h1>

              <h1 className="flex justify-center text-lg mb-8">
                If you have any feedback for us, please email us at contact@grassrootsgov.org.
              </h1>

              <div className="flex justify-center space-x-4">
                <button
                  disabled={loading}
                  onClick={handleDelete}
                  className="relative inline-flex items-center px-4 py-2 leading-7 font-medium text-white rounded-md bg-red-500 shadow-sm hover:bg-red-400 active:bg-red-600 focus:ring-4 focus:ring-red-50 focus:ring-opacity-50 focus:outline-none"
                >
                  Confirm deletion
                </button>
                <button className="btn-white" onClick={toggleDelete}>Cancel</button>
              </div>

            </div>

          </div>
        </div>
      }

      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <h1 className="text-2xl font-semibold md:text-4xl mb-4">
          Account Settings
        </h1>
        {/* <h1 className="text-lg font-normal italic md:text-xl">
          Note: You can only edit your profile once per year
        </h1> */}

        <div className="mt-6">
          <form
            action="#"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit}
          >

            <h1 className="text-2xl font-semibold">Password</h1>

            <div className="mb-6">
                <label
                  for="oldPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Old password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    type="text"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    placeholder="Enter your old password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  for="newPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  New password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="newPassword"
                    name="newPassword"
                    type="text"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    placeholder="Enter new password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

          {status.success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{status.success}</span>
            </div>
          )}
          {err && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{err}</span>
            </div>
          )}

            <div>
              <span className="block flex space-x-4">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn-primary"
                >
                  Submit
                </button>
              </span>
            </div>

          </form>

          <h1 className="pt-4 my-6 text-2xl font-semibold">Delete your account</h1>

            <button
              disabled={loading}
              onClick={toggleDelete}
              className="relative inline-flex items-center px-4 py-2 leading-7 font-medium text-white rounded-md bg-red-500 shadow-sm hover:bg-red-400 active:bg-red-600 focus:ring-4 focus:ring-red-50 focus:ring-opacity-50 focus:outline-none"
            >
              Delete my account
            </button>

        </div>
      </div>
    </AppLayout>
  );
}

export default CivilianSettingsPage;
