import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"

function PrivateRoute({ component: Component, ...rest }) {
  const { currUserAdminInfo, currUser } = useAuth();

  // console.log("uid:", currUser)
  // console.log("currUserAdminInfo:",currUserAdminInfo)

  return (
    <Route
      {...rest}
      render={(props) => {
        return currUser ? (
          (currUserAdminInfo.type === "legislator" ?
          (currUserAdminInfo.profileFinished ? (
            <Component {...props} />
          ) : (
            <Redirect to="/leg-finish-profile" />
          )) : <Redirect to="/error" />
          )
        ) : (
          <Redirect to="/error" />
        );
      }}
    ></Route>
  );
}

export default PrivateRoute;
