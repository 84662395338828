import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import BillCard from "../../global/bills/BillCard";
import { useAuth } from "../../../../contexts/AuthContext";
import { db } from "../../../../config/firebase";

const IssuesFollowingDetailed = () => {

  const { currUser, currUserRef } = useAuth();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (currUserRef && bills.length == 0) {

      const billsFollowingRef = db.collection("civilians").doc(currUser.uid).collection("follows");
      const snapshot = await billsFollowingRef.where("following", '==', "bill").get();
      if (snapshot.empty) {
        console.log('No matching documents.');
        setLoading(false);
        return;
      }

      setBills(snapshot.docs)

    }
    setLoading(false);
  }, []);

  if(loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {bills.length > 0 ?
              bills.map((bill, i) => {
              return (
                  <BillCard
                    key={i}
                    billId={bill.id}
                    number={bill.data().number}
                    title={bill.data().title}
                    sponsorName={bill.data().sponsorName}
                    sponsorState={bill.data().sponsorState}
                    sponsorParty={bill.data().sponsorParty}
                    dateIntroduced={bill.data().dateIntroduced}
                    cosponsorCount={bill.data().cosponsorCount}
                  />
              );
              }
            )
            :
            <h1 className="text-lg py-6 px-6">You are currently not following any bills.</h1>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default IssuesFollowingDetailed;
