import React, { useState, useEffect } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import AppLayout from '../../../../layouts/AppLayout'
import NationNav from '../../../../components/civ/nation/NationNav'
import NationOrganizationNav from '../../../../components/civ/nation/organization/NationOrganizationsNav'
import NationCategoryOrganizations from '../../../../components/civ/nation/organization/NationCategoryOrganizations'
// import addBtnImg from '../../../../assets/addBtnImg.png';

const NationCategoryOrganizationsPage = () => {

  return (
    <AppLayout>
      <NationNav/>

      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <NationOrganizationNav />
        <NationCategoryOrganizations />
        <div className="fixed bottom-0 right-0 w-15 h-15 mr-12 mb-8 cursor-pointer" onClick={()=> {console.log('a')}}>
          {/* <img className='w-11 h-11' src={addBtnImg}/> */}
        </div>
      </div>
    </AppLayout>
  )
}

export default NationCategoryOrganizationsPage;
