import React, { useEffect, useState } from "react";
import { db } from "../../../config/firebase";
import OrgLayout from "../../../layouts/OrgLayout";
import SharePopup from '../../../components/org/global/SharePopup.js'
import MyEventNav from "../../../components/org/events/MyEventNav";
import BrowseEventNav from "../../../components/org/events/BrowseEventNav";
import EventInfo from "../../../components/org/events/EventInfo";
import { useAuth } from "../../../contexts/AuthContext";

const EventPage = ({ match, history }) => {
	const { currUser, userData } = useAuth();
	const [eventData, setEventData] = useState({});
	const [eventTimes, setEventTimes] = useState([]);
	const [isMyEvent, setIsMyEvent] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
	let eventId = match.params.event;

	useEffect(async () => {
		const currEventRef = db.collection("events").doc(eventId);
		const eventDoc = await currEventRef.get();
		if (!eventDoc.exists) {
			// setError('This event  was not found.');
			console.log("no event doc exists");
		} else {
			setEventData(eventDoc.data());
			// const timeArr = eventDoc.data().eventTimes;
			// console.log(timeArr);
			// setEventTimes(timeArr);

			// db.collection('events').doc(eventId).collection("eventTimes").get().then((snapshot) => {
			//   snapshot.docs.forEach()
			// })

			if (currUser.uid === eventDoc.data().organizerId) {
				setIsMyEvent(true);
			}
		}
	}, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

	return (
		<OrgLayout>

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/events/${match.params.event}`}
          type="event"
          >
        </SharePopup>
      }

			<div className="mb-px pt-8 pb-4 mx-auto sm:px-8 lg:px-16">
				<button
					className="flex space-x-2 items-center focus:outline-none"
					onClick={() => {
						history.goBack();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="flex-shrink-0 h-5 w-5 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
					<h1>Back</h1>
				</button>

				{isMyEvent ? (
					<MyEventNav
						title={eventData.eventTitle}
						eventId={match.params.event}
						organizerId={eventData.organizerId}
            toggleSharePopup={toggleSharePopup}
					/>
				) : (
					<BrowseEventNav
						title={eventData.eventTitle}
						eventId={match.params.event}
						organizer={eventData.organizerName}
						organizerId={eventData.organizerId}
            toggleSharePopup={toggleSharePopup}
					/>
				)}
			</div>

			<hr className="border-solid" />

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<EventInfo
					eventId={match.params.event}
					isVirtual={eventData.isVirtual}
					streetAddress={
						eventData.streetAddress
							? eventData.streetAddress
							: "N/A"
					}
					city={eventData.city}
					state={eventData.state}
					zipCode={eventData.zipCode}
					description={eventData.description}
					topicTags={eventData.topicTags}
					numAttending={eventData.numAttending}
					numInterested={eventData.numInterested}
					mainTime={eventData.mainStartTimeUTC}
				/>
			</div>
		</OrgLayout>
	);
};

export default EventPage;
