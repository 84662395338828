import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import CivilianHeader from "../../../components/org/civilian/CivilianHeader";
import CivilianOrganizations from "../../../components/org/civilian/CivilianOrganizations";
import { db } from "../../../config/firebase";

function CivilianOrganizationsPage({ match }) {
	const [civilianInfo, setCivilianInfo] = useState();
  const [organizations, setOrganizations] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(async () => {
		if (match && match.params.user) {
			// pulls name and issue infomation for now
			// future optimization for less reads: pass in the name so we don't have to get that document
			let userId = match.params.user;
			if (userId.length == 0) {
				setError("Bad link. No user id given.");
			} else {
				const currUserRef = db.collection("civilians").doc(userId);
				const userDoc = await currUserRef.get();
				if (!userDoc.exists) {
					setError("This user was not found.");
				} else {
					setCivilianInfo(userDoc.data());

          const snapshot = await db
						.collection("civilians")
            .doc(userId)
						.collection("orgsFollowing")
						.get();
					if (!snapshot.empty) {
						console.log(snapshot.docs);
						console.log("# of orgs:", snapshot.docs.length);
						// Promise.all(
						// 	snapshot.docs.map((issue) => {
						// 		return issue;
						// 	})
						// ).then((result) => {
						// 	setOrganizations(result);
						// });
            setOrganizations(snapshot.docs)
				  }
          else {
						console.log("empty snapshot");
					}
			}
    }
			setLoading(false);
		}
	}, []);

	if (loading) {
		return (
			<AppLayout>
				<></>
			</AppLayout>
		);
	}
	if (error) {
		return (
			<AppLayout>
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					{error}
				</div>
			</AppLayout>
		);
	}
	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<CivilianHeader {...civilianInfo} />

				<CivilianOrganizations organizations={organizations} />
			</div>
		</AppLayout>
	);
}

export default CivilianOrganizationsPage;

