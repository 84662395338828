import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import AppLayout from "../../../../layouts/AppLayout";
import StateLatestIssues from "../../../../components/civ/state/issues/StateLatestIssues";
import StateNav from "../../../../components/civ/state/StateNav";

const StatLatestIssuesPage = () => {
	return (
		<AppLayout>
			<StateNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<StateLatestIssues />
			</div>
		</AppLayout>
	);
};

export default StatLatestIssuesPage;
