import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AdminPrivateRoute from "./AdminPrivateRoute"

// import AdminLoginPage from "../../pages/admin/AdminLoginPage"
import WaitlistPage from "../../pages/admin/AdminWaitlistPage"
import UsersPage from "../../pages/admin/AdminUsersPage"
import UserActivityPage from "../../pages/admin/AdminUserActivityPage"
import OrgSignupsPage from "../../pages/admin/AdminOrgSignupsPage"
import LegSignupsPage from "../../pages/admin/AdminLegSignupsPage"
import OrgCreateAccountPage from "../../pages/admin/OrgCreateAccountPage"
import LegCreateAccountPage from "../../pages/admin/LegCreateAccountPage"
import ReportedContentPage from "../../pages/admin/AdminReportedContentPage"

const AdminRoutes = () => {
  return (
    <>
      <AdminPrivateRoute path="/admin/waitlist" component={WaitlistPage} />
      <AdminPrivateRoute path="/admin/users" component={UsersPage} />
      <AdminPrivateRoute path="/admin/user-activity" component={UserActivityPage} />
      <AdminPrivateRoute path="/admin/org-signups" component={OrgSignupsPage} />
      <AdminPrivateRoute path="/admin/org/create-account/:id" component={OrgCreateAccountPage} />
      <AdminPrivateRoute path="/admin/leg-signups" component={LegSignupsPage} />
      <AdminPrivateRoute path="/admin/leg/create-account/:id" component={LegCreateAccountPage} />
      <AdminPrivateRoute path="/admin/reported-content" component={ReportedContentPage} />
      {/* <Route path="/admin/login" component={AdminLoginPage} /> */}
    </>
  );
};

export default AdminRoutes;
