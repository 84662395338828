import React, {useState} from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import jd from '../../../assets/jd.png';
import { db } from '../../../config/firebase';
import { useAuth } from '../../../contexts/AuthContext';
import firebase from 'firebase'

const OrganizationHeader = (props) => {
  const { currUser } = useAuth();

  const [following, setFollowing] = useState(props.following);
  // const [src, setSrc] = useState(defaultProfilePic)
  // console.log("following:", following)

  console.log("PROPS:", props)

  const [ organizationData, setOrganizationData] = useState({
    orgId: props.orgId,
    orgName: props.orgName,
    activeIssueCount: props.activeIssueCount,
    upcomingEventCount: props.upcomingEventCount,
    followerCount: props.followerCount,
  });

  // handle follow button
  async function followOrg() {

    console.log("following upon trigger:", following)
    if (following) {
      db.collection("organizations").doc(currUser.uid).collection("orgsFollowing").doc(props.orgId).delete().then(() => {
        setFollowing(false)
      });
      db.collection("organizations").doc(props.orgId).update({followerCount: firebase.firestore.FieldValue.increment(-1)})
    }
    else {
      db.collection("organizations").doc(currUser.uid).collection("orgsFollowing").doc(props.orgId).set({
        orgId: props.orgId,
        orgName: props.orgName,
        activeIssueCount: props.activeIssueCount,
        upcomingEventCount: props.upcomingEventCount,
        followerCount: props.followerCount}).then(() => {
        setFollowing(true)
      });
      db.collection("organizations").doc(props.orgId).update({followerCount: firebase.firestore.FieldValue.increment(1)})
    }

  }

  return (
    <>
      <div className='bg-blue-50 flex justify-between items-end px-16 py-8'>
        <div className='flex items-end space-x-10'>
          <img src={props.profilePicUrl} className='h-24 w-24 rounded-lg' />
          <h1 className='font-bold text-3xl'>
            {props.orgName ? props.orgName : 'Organization Name'}
          </h1>
        </div>
        <div className='text-right'>
          <h1 className='font-medium'>
            {props.followerCount ? props.followerCount : '0'}{' '}
            {(props.followerCount !== 1) ? "followers" : "follower" }
          </h1>
          <h1 className='font-medium'>
            {props.hqCity ? props.hqCity : 'City'},{' '}
            {props.hqState ? props.hqState : 'State'}
          </h1>
          <div className='mb-3'></div>
          <div className="flex space-x-4">
            {
              props.isUser ?
              <Link
                to="/org/organizations/edit-profile"
                className={following ? "btn-secondary focus:outline-none" : "btn-primary focus:outline-none"}>
                Edit Profile
              </Link>
              :
              <button
                onClick={followOrg}
                className={following ? "btn-secondary focus:outline-none" : "btn-primary focus:outline-none"}>
                {following ? "Following" : "Follow"}
              </button>
            }
            <button className="btn-white" onClick={props.toggleSharePopup}>
              <div className="flex space-x-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                  </svg>
                <div>Share</div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className='flex justify-between items-center px-16'>
        <div className='flex space-x-14 py-6'>
          <NavLink
            to={`/org/organizations/${props.orgId}/latest`}
            className='font-bold text-2.5xl text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Latest
          </NavLink>
          <NavLink
            to={`/org/organizations/${props.orgId}/issues`}
            className='font-bold text-2.5xl text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Issues
          </NavLink>
          <NavLink
            to={`/org/organizations/${props.orgId}/events`}
            className='font-bold text-2.5xl text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Events
          </NavLink>
          <NavLink
            to={`/org/organizations/${props.orgId}/about`}
            className='font-bold text-2.5xl text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            About
          </NavLink>
        </div>

        <div className='flex space-x-4 items-end'>
          {/* <div>
            <label
              for='location'
              class='text-sm font-medium text-justiceBlue-500'
            >
              Sort By
            </label>
            <select
              id='location'
              name='location'
              class='mt-1 w-full text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
            >
              <option selected>Date Posted</option>
              <option>Popularity</option>
              <option>Etc</option>
            </select>
          </div> */}
          {/* <span className="rounded-md shadow-sm">
            <button type="button" className="items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:shadow-outline-red-500 focus:border-indigo-red-600 active:bg-red-600">
              + Suggest Issue/Event
            </button>
          </span> */}
        </div>
      </div>

      <hr className='border-solid mb-1' />
    </>
  );
};

export default OrganizationHeader;
