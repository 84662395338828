import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import SharePopup from '../../../components/org/global/SharePopup.js'
import OrganizationHeader from "../../../components/org/organization/OrganizationHeader";
import OrganizationEvents from "../../../components/org/organization/OrganizationEvents";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png";

function OrganizationLatestPage({ match }) {
	const { currUser } = useAuth();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [organizationInfo, setOrganizationInfo] = useState({});
	const [following, setFollowing] = useState();
	const [orgEvents, setOrgEvents] = useState([]);
	const [isUser, setIsUser] = useState(false);
	const [src, setSrc] = useState(defaultProfilePic);
  const [sharePopup, setSharePopup] = useState(false);

	useEffect(async () => {
		if (match && match.params.organization) {
			let organizationId = match.params.organization;

			if (organizationId.length == 0) {
				setError("Bad link. No organization id given.");
			} else {
				if (organizationId == currUser.uid) {
					setIsUser(true);
				}

				let storageRef = firebase.storage().ref();
				let userPicRef = storageRef.child(
					`images/organization/profilePics/${organizationId}`
				);
				try {
					let result = await userPicRef.getDownloadURL();
					setSrc(result);
				} catch (error) {
					console.log(error);
				}

				db.collection("organizations")
					.doc(organizationId)
					.get()
					.then((organizationData) => {
						setOrganizationInfo(organizationData.data());

						return db
							.collection("organizations")
							.doc(currUser.uid)
							.collection("orgsFollowing")
							.doc(organizationId)
							.get()
							.then((result) => {
								if (result.exists) {
									setFollowing(true);
									// console.log("doc found!")
								} else {
									setFollowing(false);
									// console.log("doc not found!")
								}

								db.collection("organizations")
									.doc(organizationId)
									.get()
									.then((organizationData) => {
										setOrganizationInfo(
											organizationData.data()
										);

										db.collection("events")
											.where(
												"organizerId",
												"==",
												organizationId
											)
											.get()
											.then((events) => {
												console.log(
													"# of events:",
													events.docs.length
												);

												return (
													events.docs &&
													events.docs.map(
														(event) => ({
															eventId: event.id,
															...event.data(),
														})
													)
												);
											})
											.then((orgEventsList) => {
												//TODO: switch to datePosted
												orgEventsList.sort((a, b) =>
													a.mainStartTimeUTC <
													b.mainStartTimeUTC
														? 1
														: -1
												);

												console.log(
													"orgEventsList:",
													orgEventsList
												);
												setOrgEvents(orgEventsList);
												// console.log("number of posts:", orgPostsList)
											});
									});
							});
					})
					.then(() => {
						setLoading(false);
					});
			}
		}
	}, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/organizations/${match.params.organization}/latest`}
          type="organization"
          >
        </SharePopup>
      }

			{(following===true || following===false) && organizationInfo && <OrganizationHeader
				orgId={match.params.organization}
				orgName={organizationInfo.name}
				activeIssueCount={organizationInfo.activeIssueCount}
				upcomingEventCount={organizationInfo.upcomingEventCount}
				followerCount={organizationInfo.followerCount}
				hqState={organizationInfo.hqState}
				hqCity={organizationInfo.hqCity}
				following={following}
				isUser={isUser}
				profilePicUrl={src}
        toggleSharePopup={toggleSharePopup}
			/> }
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				{/* {console.log({orgEvents})} */}
				{orgEvents && <OrganizationEvents orgEvents={orgEvents} />}
			</div>
		</AppLayout>
	);
}

export default OrganizationLatestPage;
