import React, { useEffect, useState, useContext } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
import StateIssuesNav from "./StateIssuesNav";
import IssueCard from "../../global/issues/IssueCard";
// import { db } from "../../../config/firebase";
// import { useAuth } from "../../../contexts/AuthContext";
import {StateDBContext} from '../../../../contexts/StateContext';

const StateLatestIssues = () => {

  const activeIssues = useContext(StateDBContext);
  let [latestIssues, setLatestIssues] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);


  useEffect(async () => {

    if(activeIssues[2] != null && activeIssues[2].length != 0) {
      setLatestIssues(activeIssues[2].map((doc) =>
        <IssueCard
          issueTitle={doc.data().issueTitle}
          supporters={doc.data().supporters}
          legislator={`${doc.data().legislator.firstName} ${doc.data().legislator.lastName}`}
          region={`${doc.data().region}-${doc.data().district}`}
          created={doc.data().dateCreated}
          copies={doc.data().regionalCopies}
          issueId={doc.id}
        />));
    } else {
      setLatestIssues(<h1 className="px-8 py-8 font-medium text-xl">There are currently no active issues in your state.</h1>)
    }

  }, [activeIssues[2]]);

  return (
    <>
      <StateIssuesNav />
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {latestIssues}
          </div>
        </div>
      </>
    );

}


export default StateLatestIssues;
