import React, { useContext, useEffect, useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import { DistrictDBContext } from '../../../../contexts/DistrictContext'

const DistrictOrganizationsNav = (props) => {


  return (
    <div className="flex justify-between items-center mb-8">
      <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
        <NavLink
          to="/district/organizations/top"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          exact
          activeClassName="text-gray-900"
        >
          Most Popular
        </NavLink>
        <NavLink
          to="/district/organizations/category"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          activeClassName="text-gray-900"
        >
          By Category
        </NavLink>
      </div>
      <div className="ml-4 mt-2 flex space-x-4 items-end">
        {/* <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">
            Filter
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>All Categories</option>
            <option>Category 1</option>
            <option>Etc</option>
          </select>
        </div> */}
        {/* <span className="rounded-md shadow-sm">
          <button type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-usaRed hover:bg-usaRedLighter focus:outline-none focus:shadow-outline-usaRed focus:border-indigo-usaRedDarker active:bg-usaRedDarker">
            + Add New Issue
          </button>
        </span> */}
      </div>
    </div>
  )
}

export default DistrictOrganizationsNav;
