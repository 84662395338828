import React, {useState, useEffect} from "react";
import { useAuth } from "../contexts/AuthContext";
import LandingLayout from '../layouts/LandingLayout'
import CivLayout from '../layouts/AppLayout'
import OrgLayout from '../layouts/OrgLayout'
import LegLayout from '../layouts/LegLayout'
import AdminLayout from '../layouts/AdminLayout'

const ErrorPage = ({history}) => {

  const [userType, setUserType] = useState("");
  const { currUserAdminInfo } = useAuth();

  useEffect(() => {
    if (currUserAdminInfo){
      setUserType(currUserAdminInfo.type)
    }
    else {
      setUserType("no user")
    }
  }, [])

  const renderSwitch = (type, children) => {
    switch (type) {
      case "civilian":
        return(<CivLayout>{children}</CivLayout>)
      case "organization":
        return(<OrgLayout>{children}</OrgLayout>)
      case "legislator":
        return(<LegLayout>{children}</LegLayout>)
      case "admin":
        return(<AdminLayout>{children}</AdminLayout>)
      default:
        return(<LandingLayout>{children}</LandingLayout>)
    }
  }

  return (
    <div>
      {renderSwitch(userType,
        <div>
          <div className="text-center mb-px py-72 mx-auto sm:px-8 lg:px-16">
            <h1 className="text-3xl font-bold mb-10">404 Error</h1>
            <h1 className="text-2xl mb-10 ">Oh no! This page doesn't exist.</h1>
            <button className="btn-primary" onClick={() => {history.goBack();}}>
              <h1>Return to Previous Page</h1>
            </button>
          </div>
        </div>)}
    </div>
  )

}

export default ErrorPage;
