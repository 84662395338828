import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import AppLayout from "../../../../layouts/AppLayout";
import NationNav from "../../../../components/civ/nation/NationNav";
import NationIssuesNav from "../../../../components/civ/nation/issues/NationIssuesNav";
import NationTopicIssues from "../../../../components/civ/nation/issues/NationTopicIssues";
// import AddBtn from "../../../components/civ/global/buttons/AddButton";

const NationTopicIssuesPage = () => {
	return (
		<AppLayout>
			<NationNav />

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<NationIssuesNav />
				<NationTopicIssues />
				{/* <AddBtn onClick={() => {console.log('a')}}/> */}
			</div>
		</AppLayout>
	);
};

export default NationTopicIssuesPage;
