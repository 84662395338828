import React from 'react';
import {Link} from "react-router-dom";

const BillCardSmall = (props) => {
  return (
    <Link to={`/org/bills-votes/bills/${props.billId}`}>
      <div className="shadow hover:shadow-md rounded-lg px-8 py-6">
        <h1 className="text-2xl font-semibold mb-4 truncate">{props.title}</h1>
        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center">
            <img className="rounded-full h-8 w-8" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60"/>
            <h1 className="text-lg font-medium">
              {props.legislator} ({props.region}, {props.party})
            </h1>
            <h1 className="text-lg text-gray-500">
              {/* Introduced {props.dateIntroduced} */}
              {"Status: " + props.status}
            </h1>
          </div>
          <div className="flex items-center text-red-500">
            <Link className="text-md font-semibold">
              View Bill
            </Link>
            <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default BillCardSmall;
