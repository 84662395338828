import React, { useEffect, useState, useContext } from 'react';
import app from "../config/firebase"
import { useAuth } from "./AuthContext";

const dbRef = app.firestore();



// DB call for trending issues & events
async function getTrending(userDistrict, userState) {
  if (dbRef) {

    const topicsRef = dbRef.collection("topics");
    const snapshotActive = await topicsRef.where("state", "==", userState).where("district", "==", userDistrict).get();

    let topics = [];

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {
        topics.push(doc.data());
      });
      topics.sort((a,b) => (a.issueTags.length < b.issueTags.length) ? 1 : -1);
    } else {
      console.log("empty snapshot");
    }

    return topics.slice(0,4);

  }
}

// DB call for all active issues (categorized by topic)
async function getIssues(userDistrict, userState) {

    if (dbRef) {

      let activeIssues = [];

      const issuesRef = dbRef.collection("issues");
      const snapshotActive = await issuesRef.where("active", "==", true).where("region", "==", userState).where
        ("district", "==", userDistrict).get();

      let issuesByTopic = {'No Topic' : []};

      if (!snapshotActive.empty) {
        snapshotActive.forEach((doc) => {

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in issuesByTopic) {
                issuesByTopic[topic].push(doc);
              } else {
                issuesByTopic[topic] = [doc];
              }
          });

          if(doc.data().topicTags.length == 0) {
            issuesByTopic['No Topic'].push(doc);
          }

          activeIssues.push(doc);

        });

        if(issuesByTopic['No Topic'].length == 0)
            delete issuesByTopic['No Topic'];
      } else {
        console.log("empty snapshot");
      }

      let topIssues = [...activeIssues];
      topIssues.sort((a,b) => (a.data().supporters < b.data().supporters) ? 1 :  -1);
      topIssues.forEach(doc => console.log(doc.data()));

      let latestIssues = [...activeIssues];
      latestIssues.sort((a,b) => (a.data().dateCreated < b.data().dateCreated) ? 1 : -1);

      return [topIssues, latestIssues, issuesByTopic];

    }

}

// DB call for all resolved issues
async function getResolvedIssues(userDistrict, userState) {


    if (dbRef) {
        const issuesRef = dbRef.collection("issues");
        const snapshotResolved = await issuesRef.where("active", "==", false).where("district", "==", userDistrict).where
            ("region", "==", userState).get();

        let tempData = [];

        if(!snapshotResolved.empty) {
          snapshotResolved.forEach((doc) => { tempData.push(doc); });
        } else {
          console.log("empty snapshot");
        }

        return tempData;
    }

}

async function getEvents(userDistrict, userState) {

  if (dbRef) {

    let allEvents = [];

    const issuesRef = dbRef.collection("events");
    const snapshotActive = await issuesRef.where("state", "==", userState).where("district", "==", userDistrict).get();
    //const snapshotActive = await issuesRef.get();

    let eventsByTopic = {'No Topic' : []};
    let eventsByType = {'No Type' : []};

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {

        const currentTime = new Date();

        if (doc.data().mainStartTimeUTC.toDate() >= currentTime){

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in eventsByTopic) {
                eventsByTopic[topic].push(doc);
              } else {
                eventsByTopic[topic] = [doc];
              }
          });

          if(doc.data().eventType in eventsByType) {
            eventsByType[doc.data().eventType].push(doc);
          } else {
            eventsByType[doc.data().eventType] = [doc];
          }

          if(doc.data().topicTags.length == 0) {
            eventsByTopic['No Topic'].push(doc);
          }

          allEvents.push(doc);

        }

      });

      if(eventsByTopic['No Topic'].length == 0)
        delete eventsByTopic['No Topic'];

      if(eventsByType['No Type'].length == 0)
        delete eventsByType['No Type'];


    } else {
      console.log("empty snapshot");
    }

    let topEvents = [...allEvents];
    topEvents.sort((a,b) => (a.data().numInterested < b.data().numInterested) ? 1 :  -1);

    let latestEvents = [...allEvents];
    latestEvents.sort((a,b) => (a.data().mainStartTimeUTC > b.data().mainStartTimeUTC) ? 1 : -1);

    return [topEvents, latestEvents , eventsByTopic, eventsByType];

  }



}

async function getOrganizations(userDistrict, userState) {

  if (dbRef) {

    let allOrganizations = [];

    const organizationsRef = dbRef.collection("organizations");
    const snapshotActive = await organizationsRef.where("scope", "==", "District").where("state", "==", userState).where("district", "==", userDistrict).get();
    //const snapshotActive = await organizationsRef.get();

    let organizationsByCategory = {};

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {
        allOrganizations.push(doc);
        if(doc.data().type in organizationsByCategory) {
          organizationsByCategory[doc.data().type].push(doc);
          // console.log("UPDATED:", eventsByType)
        } else {
          organizationsByCategory[doc.data().type] = [doc];
          // console.log("UPDATED:", eventsByType)
        }
      })

    } else {
      console.log("empty snapshot");
    }

    let popOrganizations = [...allOrganizations];
    popOrganizations.sort((a,b) => (a.data().followerCount < b.data().followerCount) ? 1 :  -1);

    return [popOrganizations, organizationsByCategory];

  }

}


// context used for all DB data
export const DistrictDBContext = React.createContext(null);

// Provider used for all DB data
export const DistrictProvider = (props) => {

    const [topicsDb, setTrending] = useState(null);
    const [activeIssuesDb, setActiveIssues] = useState([null, null, null]);
    const [resolvedIssuesDb, setResolvedIssues] = useState(null);
    const [eventsDb, setEvents] = useState([null, null, null]);
    const [organizationsDb, setOrganizations] = useState([null, null]);

    const { currUserRef } = useAuth();

    useEffect(async () => {
        const userDoc = await currUserRef.get();
        const userDistrict = userDoc.data().district;
        const userState = userDoc.data().state;
        getTrending(userDistrict, userState).then(setTrending);
        getIssues(userDistrict, userState).then(setActiveIssues);
        getResolvedIssues(userDistrict, userState).then(setResolvedIssues);
        getEvents(userDistrict, userState).then(setEvents);
        getOrganizations(userDistrict, userState).then(setOrganizations);

    }, []);

    return(
        <DistrictDBContext.Provider value={[topicsDb, activeIssuesDb[0], activeIssuesDb[1], activeIssuesDb[2], resolvedIssuesDb, eventsDb[0], eventsDb[1], eventsDb[2], eventsDb[3], organizationsDb[0], organizationsDb[1]]}>
            {props.children}
        </DistrictDBContext.Provider>
    );

}
