import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "../org/OrgPrivateRoute";

import BrowseStateIssuesPage from "../../pages/org/browse/BrowseStateIssuesPage";
import BrowseStateEventsPage from "../../pages/org/browse/BrowseStateEventsPage";
import BrowseStateOrganizationsPage from "../../pages/org/browse/BrowseStateOrganizationsPage";
import { StateProvider } from "../../contexts/StateContext";

const HomeRoutes = () => {
	return (
		<StateProvider>
			<Switch>
				<PrivateRoute
					path="/org/browse/state/organizations"
					component={BrowseStateOrganizationsPage}
				/>
				<PrivateRoute
					path="/org/browse/state/events"
					component={BrowseStateEventsPage}
				/>
				<PrivateRoute
					path="/org/browse/state/issues"
					component={BrowseStateIssuesPage}
				/>
			</Switch>
		</StateProvider>
	);
};

export default HomeRoutes;
