import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import HomePage from '../../pages/org/home/HomePage'
import FeedPage from '../../pages/org/home/FeedPage'

const HomeRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/org/home/feed" component={FeedPage}/>
      <PrivateRoute path="/org/home" component={HomePage}/>
    </Switch>
  )
}

export default HomeRoutes;
