import React, { useState, useEffect } from 'react';
import OrgLayout from '../../../layouts/OrgLayout';
import HomePageNav from '../../../components/org/home/HomeNav';
import OrganizationIssuePost from '../../../components/org/global/organizations/OrganizationIssuePost';
import OrganizationEventPost from '../../../components/org/global/organizations/OrganizationEventPost';
import firebase from 'firebase';
import { db } from '../../../config/firebase';
import { useAuth } from '../../../contexts/AuthContext';
// import { issue } from 'firebase-functions/lib/providers/crashlytics';
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png"

function FeedPage(props) {
  // const [loading, setLoading] = useState(true);
  const [orgPosts, setOrgPosts] = useState([]);
  const { currUser } = useAuth();
  const [orgInfo, setOrgInfo] = useState([]);

  // useEffect(async () => {
  //   let orgsFollowing = await db
  //     .collection('organizations')
  //     .doc(currUser.uid)
  //     .collection('orgsFollowing')
  //     .get();

  //   console.log('number of orgs following:', orgsFollowing.docs.length);

  //   Promise.all(
  //     orgsFollowing.docs.map(async (org) => {
  //       console.log('org id:', org.data().orgId);

  //       let orgSrc = defaultProfilePic;
  //       let storageRef = firebase.storage().ref();
  //       let userPicRef = storageRef.child(
  //         `images/organization/profilePics/${org.data().orgId}`
  //       );
  //       try {
  //         let result = await userPicRef.getDownloadURL();
  //         orgSrc = result;
  //       } catch (error) {
  //         console.log(error);
  //       }

  //       return db
  //         .collection('organizations')
  //         .doc(org.data().orgId)
  //         .collection('posts')
  //         .orderBy('datePosted', 'desc')
  //         .get()
  //         .then((posts) => {
  //           return posts.docs.map((post) => {
  //             let fullPost = post.data();
  //             fullPost.orgName = org.data().orgName;
  //             fullPost.orgId = org.data().orgId;
  //             return fullPost;
  //           });
  //         });
  //     })
  //   ).then((orgPostsList) => {
  //     const tempOrgPostsList = [];

  //     orgPostsList.forEach((org) => {
  //       org.forEach((post) => {
  //         tempOrgPostsList.push(post);
  //       });
  //     });

  //     tempOrgPostsList.sort((a, b) => (a.datePosted < b.datePosted ? 1 : -1));

  //     setPosts(tempOrgPostsList);
  //     setLoading(false);
  //     console.log('number of posts:', tempOrgPostsList);
  //   });

  //   // gets org info
  // }, []);

  useEffect(async () => {

    let orgsFollowing = await db.collection("organizations").doc(currUser.uid).collection("orgsFollowing").get()

    console.log("number of orgs following:", orgsFollowing.docs.length)

    Promise.all(orgsFollowing.docs.map(async (org) => {

      console.log("org id:", org.data().orgId)

      let orgSrc = defaultProfilePic

      let storageRef = firebase.storage().ref();
      let userPicRef = storageRef.child(`images/organization/profilePics/${org.data().orgId}`)
      try {
        let result = await userPicRef.getDownloadURL()
        orgSrc = result
      } catch(error) {
        console.log(error)
      }

      return (db.collection("organizations").doc(org.data().orgId).collection("posts").orderBy("datePosted", "desc").get().then((posts) => {

        return(posts.docs.map((post) => (

          {orgId: org.data().orgId,
          orgName: org.data().orgName,
          orgSrc: orgSrc,
          ...post.data()}

        )))

      })
      )

    })).then((orgPostsList) => {

      const tempOrgPostsList = []

      orgPostsList.forEach((org) => {
        org.forEach((post) => {
          tempOrgPostsList.push(post)
        })
      })

      tempOrgPostsList.sort((a,b) => (a.datePosted < b.datePosted) ? 1 :  -1)

      setOrgPosts(tempOrgPostsList)
      console.log("number of posts:", tempOrgPostsList)

    })


  }, [])

  // if (loading) {
  //   return (
  //     <OrgLayout>
  //       <HomePageNav />
  //       <div className='mb-px py-8 mx-auto sm:px-8 lg:px-16'> Loading </div>
  //     </OrgLayout>
  //   );
  // }

  if (orgPosts.length === 0) {
    return (
      <OrgLayout>
        <HomePageNav />
        <div className='mb-px py-8 mx-auto sm:px-8 lg:px-16 text-lg font-medium'> You currently have no new posts to see. Follow some organizations to get started! </div>
      </OrgLayout>
    );
  }
  return (
    <OrgLayout>
      {/* {console.log('posts is ', posts)} */}
      <HomePageNav />

      <div className='mb-px py-8 mx-auto sm:px-8 lg:px-16'>
      {orgPosts.map((post) => {
          if (post.contentType === "issue") {
            return(
              <OrganizationIssuePost
                orgId={post.orgId}
                orgName={post.orgName}
                profilePicUrl={post.orgSrc}
                datePosted={post.datePosted}
                postBody={post.postBody}
                issueTitle={post.issue.issueTitle}
                legislatorName={post.issue.legislatorName}
                legislatorParty={post.issue.legislatorParty}
                legislatorState={post.issue.legislatorState}
                issueDateCreated={post.issue.issueDateCreated}
                regionalCopyCount={post.issue.regionalCopyCount}
                supporterCount={post.issue.supporterCount}
                issueTopicTags={post.issue.issueTopicTags}
                issueId={post.issue.issueId}
              />
            )
          }
          else {
            return(
              <OrganizationEventPost
                orgId={post.orgId}
                orgName={post.orgName}
                profilePicUrl={post.orgSrc}
                datePosted={post.datePosted}
                postBody={post.postBody}
                eventTitle={post.event.eventTitle}
                eventOrgName={post.event.eventOrgName}
                eventDateTime={post.event.eventDateTime}
                eventIsVirtual={post.event.eventIsVirtual}
                eventCity={post.event.eventCity}
                eventState={post.event.eventState}
                attendingCount={post.event.attendingCount}
                interestedCount={post.event.interestedCount}
                eventTopicTags={post.event.eventTopicTags}
                eventId={post.event.eventId}
              />
            )
          }
        })
        }
      </div>
    </OrgLayout>
  );
}

export default FeedPage;
