import React, { useState, useEffect } from "react";
import StateMembers from "./StateMembers";
import houseCommittees from "../../../assets/jsonData/houseCommittees.json";
import senateCommittees from "../../../assets/jsonData/senateCommittees.json";
import { Link } from "react-router-dom";

const Committees = () => {
  const [houseCommitteesList, setHouseCommitteesList] = useState(<h1>Loading...</h1>);
  const [senateCommitteesList, setSenateCommitteesList] = useState(<h1>Loading...</h1>);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(async () => {

    let houseTempData = [];

    Object.keys(houseCommittees).forEach((id) => {
      // console.log(id)
      let committee = houseCommittees[id];
      // console.log(committee)
      houseTempData.push(committee);
    });

    setHouseCommitteesList(
      houseTempData.map((committee) => (
        <li>
          <Link to={`/legislators/committees/house/${committee.id}`}>
            {/* <div className="rounded-lg text-lg px-6 py-4 bg-white hover:bg-gray-50"> */}
            <div className="w-full truncate shadow-sm items-center pl-4 pr-6 py-2 leading-7 font-medium text-justiceBlue-500 rounded-md bg-justiceBlue-100 hover:bg-justiceBlue-50 active:bg-justiceBlue-200 focus:ring-4 focus:ring-justiceBlue-100 focus:ring-opacity-50">
              {committee.name}
            </div>
          </Link>
        </li>
      )));


    let senateTempData = [];

    Object.keys(senateCommittees).forEach((id) => {
      let committee = senateCommittees[id];
      senateTempData.push(committee);
    });

    setSenateCommitteesList(
      senateTempData.map((committee) => (
        <li>
          <Link to={`/legislators/committees/senate/${committee.id}`}>
            {/* <div className="rounded-lg text-lg px-6 py-4 bg-white hover:bg-gray-50"> */}
            <div className="w-full truncate shadow-sm items-center pl-4 pr-6 py-2 leading-7 font-medium text-justiceBlue-500 rounded-md bg-justiceBlue-100 hover:bg-justiceBlue-50 active:bg-justiceBlue-200 focus:ring-4 focus:ring-justiceBlue-100 focus:ring-opacity-50">
              {committee.name}
            </div>
          </Link>
        </li>
      )));

    setLoading(false);
  }, []);

  if (error) {
    return (
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">{error}</div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-x-16 pt-8">

      <div className="col-start-1 col-end-2">
        <h1 className="text-3xl font-semibold mb-6">
          House Committees
        </h1>
        {/* <ul className="rounded-lg shadow-md divide-y divide-gray-200"> */}
        <ul className="grid grid-cols-2 gap-4">
          {houseCommitteesList}
        </ul>

      </div>

      <div className="col-start-2 col-end-3">
        <h1 className="text-3xl font-semibold mb-6">
          Senate Committees
        </h1>
        {/* <ul className="rounded-lg shadow-md divide-y divide-gray-200"> */}
        <ul className="grid grid-cols-2 gap-4">
          {senateCommitteesList}
        </ul>

      </div>

    </div>
  );
};

export default Committees;
