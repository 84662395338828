import React, { useState, useEffect, useContext } from 'react';
import IssueCard from "../global/issues/IssueCard"
import {NationDBContext} from '../../../contexts/NationContext'

const NationTopIssues = () => {

  const topIssues = useContext(NationDBContext);
  let [issuesDisplayActive, showIssuesActive] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  // update component results when data fetched & passed
  useEffect( () => {
    if(topIssues[1] != null && topIssues[1].length != 0) {
      showIssuesActive(topIssues[1].map((issue, i) => (
        <IssueCard
          key = {i}
          issueId={issue.id}
          issueTitle={issue.data().issueTitle}
          supporters={issue.data().supporters}
          created={issue.data().dateCreated}
          copies={issue.data().regionalCopies}
          legislator={issue.data().legislator.firstName + ' ' + issue.data().legislator.lastName}
          region={issue.data().legislator.region + '-' + issue.data().district}
        />)));
    } else {
      showIssuesActive(<h1 className="px-8 py-8 font-medium text-xl">{"There are currently no active issues."}</h1>)
    }
  }, [topIssues]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
            {issuesDisplayActive}
        </div>
      </div>
    </>
  )
}

export default NationTopIssues;
