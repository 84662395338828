import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";

const TermsOfServicePage = () => {

  return (
    <LandingLayout>
      <div class="relative py-16 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="text-lg max-w-prose mx-auto">
            <h1>
              {/* <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Introducing</span> */}
              <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</span>
            </h1>
          </div>
          <div class="mt-8 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>This page informs you of our policies regarding the collection, use and disclosure of personal information we receive from users of this website, grassrootsgov.org,
            while in its beta state.</p>

            <h2>Who May Use the Services</h2>
            <p>You may create an account and use GrassrootsGov if you are an individual over 13 years old and located in the United States. When creating an account, you must provide accurate and complete information. This includes providing your real name, age, and location; you are not allowed to impersonate anyone or claim an identity different from your own. You may only have one account associated with your identity. You are responsible for the user activity, including content posted and compliance with our Community Guidelines, of your account. Do not allow anyone else to use your account or gain access to your login details. If you violate any of these terms, your account may be terminated.</p>

            <h2>User Activity & Content Rules</h2>
            <p>You will be responsible for what you post and share on our platform. Any content your account uploads should follow all Community Guidelines and the law, as well as respect the intellectual property rights of others. If we are notified of any repeated or major offenses, we may take down your content and suspend your account.</p>
            <p>We may terminate or suspend your permission to use GrassrootsGov and disable your account at any time if we find that your content violates the law or rights of others, if your content violates our Community Guidelines, or if we believe your content is inappropriate or objectionable. Our Content Moderation Policy details our moderation process.</p>
            <p>In addition, you may not do any of the following actions: run bots or automated spam programs on the platform, crawl or scrape any portion of the platform, violate the security of any feature of the platform, or obtain, copy, tamper with, or reverse engineer the source code of the platform.</p>

            <h2>Content Moderation Policy</h2>
            <p>GrassrootsGov aims to implement fair and transparent content moderation by heeding the Santa Clara Principles on Transparency and Accountability in Content Moderation. Creating a safe environment for users is our number one priority and we require users to agree to our Community Guidelines and other content rules upon account creation.</p>
            <p>We will provide the following data to the public every month:</p>
            <ul>
              <li>The total number of discrete posts and accounts flagged</li>
              <li>The number of discrete posts and accounts flagged by category of rule violated, format of content at issue, source of flag, and user location.</li>
              <li>The number of discrete posts removed and accounts suspended</li>
            </ul>
            <p>To users whose posts have been taken down or accounts suspended, we will provide the following information:</p>
            <ul>
              <li>URL and excerpt of the content in question</li>
              <li>The specific clause of the Terms of Service that the content violated</li>
              <li>How the content was detected and removed</li>
              <li>Explanation of the decision appeal process</li>
            </ul>
            <p>Users who flagged the content will also be notified when content is removed, accounts are suspended, and/or appeals are made. Appeals will consist of the following process:</p>
            <ul>
              <li>Human review by GrassrootsGov team members</li>
              <li>The opportunity to present additional information to be considered</li>
              <li>Notification of the results of the review and reasoning behind the decision</li>
            </ul>

            <h2>Limitations of Liability</h2>
            <p>In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the site, even if we have been advised of the possibility of such damages. NOTWITHSTANDING anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action will at all times be limited to 50 USD. Certain us state laws and international laws do not allow limitations on implied warranties or the exclusion or limitations of certain damages. If these laws apply to you, some or all of the above disclaimers or limitations may not apply to you, and you may have additional rights.</p>

            <h2>Contact</h2>
            <p>If you have any questions about this Privacy Policy, please <Link to="/contact" className="underline">contact us</Link>.</p>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default TermsOfServicePage;
