import React from 'react';
import {Link} from "react-router-dom";

const BillCard = (props) => {
  const formatDate = (timestamp) => {

    if(timestamp.length == 10)
      return timestamp;

    let date = new Date(timestamp.seconds * 1000);
    let dateStr =
      date.getMonth() < 10 ? "0" + date.getMonth() + 1: date.getMonth() + 1; //getMonth is 0-indexed for some dumb js reason
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);
    return dateStr;
  };


  return (
    <Link to={`/bills-votes/bills/${props.billId}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="text-2xl py-2 leading-5 font-semibold text-gray-900 truncate">
            {props.number}: {' ' + props.title}
          </div>
          <div className="ml-2 flex-shrink-0 flex">
            {(props.relatedIssueCount >= 0) &&
            <span className="px-5 inline-flex text-sm leading-8 font-medium rounded-full bg-justiceBlue-600 text-white">
              {props.relatedIssueCount} related issues
            </span>
            }
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <div className="mr-6 flex items-center text-md leading-5 text-gray-500">
              {/* <!-- Heroicon name: users --> */}
              <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              {props.sponsorName} ({props.sponsorParty}-{props.sponsorState})
            </div>
            <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
              {/* <!-- Heroicon name: location-marker --> */}
              <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              Introduced {props.dateIntroduced && formatDate(props.dateIntroduced)}
            </div>
            <div className="mt-2 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
              {/* <!-- Heroicon name: location-marker --> */}
              <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              {props.cosponsorCount != 1 ? props.cosponsorCount + ' cosponsors' : props.cosponsorCount + ' cosponsor'}
            </div>
          </div>
          <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
            <span>
              View bill
            </span>
            <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default BillCard;
