import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                View what's happening
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>View issues in your region</h2>
            <p>At GrassrootsGov, we believe that learning about and listening to the issues that everybody around you is passionate about is one of the best ways to get started with digital activism. Viewing issues in your region is also a great way to stay on top of what is going on in your community. This is why our team at GrassrootsGov made viewing popular issues amongst your fellow citizens so easy!</p>
            <p>First, navigate to the main page corresponding to the region you want to view trending issues (District, State, or Nation).</p>
            <p>If you selected District or State, the next step is to select "Issues" from the Taskbar. And just like that, GrassrootsGov automatically shows you a list of issues in your district or state. You can now sort by: Top Issues, Latest Issues, Issues By Topic, and Resolved Issues. You also have the option to create a new issue!</p>
            <p>If you selected Nation, then just scroll down to see Trending Topics nationwide. From there, you can expand any of those topics to see national issues that correspond to the selected topic. You can also follow the same procedure as outlined above for the District or State pages.</p>

            <h2>View events in your region</h2>
            <p>One of the best ways to create change in your community is by attending events! Whether it's a protest, rally, sit-in, training, phonebank, or any other type of event, GrassrootsGov is your new all-in-one platform to discover, and attend events in your area!</p>
            <p>To view upcoming events near you, first navigate to the main page corresponding to the region you want to view events (District, State, or Nation)</p>
            <p>Once at the District, State, or Nation page, the next step is to select "Events" from the Taskbar. You'll instantly be shown a list of upcoming events in your district, your state, or the nation. You can now sort by: Top Events, Latest Events, Events By Topic, and Events By Type. </p>

            <h2>Find and follow organizations</h2>
            <p>Following political organizations is easy and one of the best ways to stay up to date! In GrassrootsGov, there are many ways to find organizations that you might want to follow. </p>
            <p>First navigate to the District, State or Nation pages. Then, select "Organizations." Now you can sort these organizations by "Most Popular" or by "Category".</p>
            <p>If you find an organization that you'd like to stay up to date with, you can follow them by selecting "view" on the organization and then pressing the "Follow" button in the upper right-hand corner.</p>
            <p>You can also search for organizations that you already know of by using the universal search bar in the upper right-hand corner any time.</p>

            <h2>Viewing your legislators' profiles is a great way to hold your leaders accountable.</h2>
            <p>Navigate to the "Legislators" page. From here, you can learn more about any Legislator from any state by selecting their name. You'll now be brought to their profile where you can view "Recent Votes," "Recent Bills," or "Issues".</p>
            <p>Unsure of who your legislators are? Navigate to the Home pages, and select "Dashboard." You'll automatically be shown your Senators and your Representative. You can now select any of their names to view their profile pages. You can now view "Recent Votes," "Recent Bills," or "Issues".</p>

            <h2>Stay updated on upcoming legislation</h2>
            <p>Staying updated on upcoming legislation in both the House and Senate is really important for an activist. </p>
            <p>Navigate to the Bills & Votes pages. You can now select either "House Bills," "Senate Bills," or "All Votes." You can filter both the "House Bills" and "Senate Bills" pages by "Upcoming," "Introduced," or "Past." You can filter the "All Votes" page by "House Votes" and "Senate Votes".</p>



          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
