import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import { DistrictDBContext } from '../../../../contexts/DistrictContext';

const DistrictEventsNav = () => {

  const events = useContext(DistrictDBContext);
  const [type, setTypes] = useState(<option>None</option>);

  useEffect(() => {

    let temp = [];
    if(events[8] != null) {
      for(var typeEvent in events[8]){
        temp.push(<option>{typeEvent}</option>)
      }
    }

    setTypes(temp);

  }, [events]);


  return (
    <div className="flex justify-between items-center mb-8">
      <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
        <NavLink to="/district/events/top" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" exact activeClassName="text-gray-900">
          Top Events
        </NavLink>
        <NavLink to="/district/events/latest" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Latest Events
        </NavLink>
        <NavLink to="/district/events/topic" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Events by Topic
        </NavLink>
        <NavLink to="/district/events/type" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Events by Type
        </NavLink>
      </div>
      <div className="ml-4 mt-2 flex space-x-4 items-end">
        {/* <div>
          <label for="location" class="block text-sm font-medium text-usaRed">Filter</label>
          <select id="location" name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-usaRed focus:border-usaRed sm:text-sm rounded-md">
            {type}
          </select>
        </div> */}
      </div>
    </div>
  )
}

export default DistrictEventsNav;
