import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import VerifyRoute from "./VerifyRoute";
// import AuthRoute from "./AuthRoute";

import LandingHomePage from "../pages/landing/LandingHomePage";
import LandingFeaturesPage from "../pages/landing/LandingFeaturesPage";
import LandingContactPage from "../pages/landing/LandingContactPage";
import LandingAboutPage from "../pages/landing/LandingAboutPage";
import LoginPage from "../pages/landing/LoginPage";
import SignupPage from "../pages/landing/SignupPage";
import OrgLegSignupPage from "../pages/landing/OrgLegSignupPage";
import LandingJoinWaitlistPage from "../pages/landing/LandingJoinWaitlistPage";

import CivilianHelpMainPage from '../pages/landing/CivilianHelp/CivilianHelpMainPage'
import CivilianHelpFindYourWayAroundPage from '../pages/landing/CivilianHelp/CivilianHelpFindYourWayAroundPage'
import CivilianHelpViewWhatsHappeningPage from '../pages/landing/CivilianHelp/CivilianHelpViewWhatsHappeningPage'
import CivilianHelpGetEngagedPage from '../pages/landing/CivilianHelp/CivilianHelpGetEngagedPage'
// import CivilianHelpNewUserFAQPAge from '../pages/landing/CivilianHelp/CivilianHelpNewUserFAQPAge'
import CivilianHelpIssuesNotesPage from '../pages/landing/CivilianHelp/CivilianHelpIssuesNotesPage'
import CivilianHelpLegislatorsLegislationPage from '../pages/landing/CivilianHelp/CivilianHelpLegislatorsLegislationPage'
import CivilianHelpOrganizationsPage from '../pages/landing/CivilianHelp/CivilianHelpOrganizationsPage'
import CivilianHelpEventsPage from '../pages/landing/CivilianHelp/CivilianHelpEventsPage'
import CivilianHelpUserProfilesPage from '../pages/landing/CivilianHelp/CivilianHelpUserProfilesPage'
import CivilianHelpLoginPasswordPage from '../pages/landing/CivilianHelp/CivilianHelpLoginPasswordPage'
// import CivilianHelpAccountSettingsPage from '../pages/landing/CivilianHelp/CivilianHelpAccountSettingsPage'
import CivilianHelpDeletingYourAccountPage from '../pages/landing/CivilianHelp/CivilianHelpDeletingYourAccountPage'

import OrganizationHelpMainPage from '../pages/landing/OrganizationHelp/OrganizationHelpMainPage'
import OrganizationFindYourWayAroundPage from '../pages/landing/OrganizationHelp/OrganizationHelpFindYourWayAroundPage'
import OrganizationEventsPage from '../pages/landing/OrganizationHelp/OrganizationHelpEventsPage'
import OrganizationSupportersPage from '../pages/landing/OrganizationHelp/OrganizationHelpSupportersPage'
import OrganizationBrowsingPage from '../pages/landing/OrganizationHelp/OrganizationHelpBrowsingPage'
import OrganizationProfilePage from '../pages/landing/OrganizationHelp/OrganizationHelpProfilePage'
import OrganizationLoginPasswordPage from '../pages/landing/OrganizationHelp/OrganizationHelpLoginPasswordPage'
import OrganizationDeletingYourAccountPage from '../pages/landing/OrganizationHelp/OrganizationHelpDeletingYourAccountPage'

import LandingTermsOfServicePage from "../pages/landing/LandingTermsOfServicePage";
import LandingPrivacyPolicyPage from "../pages/landing/LandingPrivacyPolicyPage";
import LandingCommunityGuidelinesPage from "../pages/landing/LandingCommunityGuidelinesPage";

import ForgotPasswordPage from "../pages/landing/ForgotPasswordPage";
import CivFinishProfile from "../pages/verify/CivFinishProfilePage";
import CivWelcomePage from "../pages/verify/CivWelcomePage";
import OrgFinishProfile from "../pages/verify/OrgFinishProfilePage";
import OrgWelcomePage from "../pages/verify/OrgWelcomePage";
import LegFinishProfile from "../pages/verify/LegFinishProfilePage";

import ErrorPage from "../pages/404ErrorPage";
import Verify from "../pages/verify/VerifyPage";

const LandingRoutes = () => {
  return (
    <Switch>
      {/* <Route path="/welcome/legislator" component={CivFinishProfile} /> */}
      <VerifyRoute path="/leg-finish-profile" component={LegFinishProfile} />
      <VerifyRoute path="/welcome/organization" component={OrgWelcomePage} />
      <VerifyRoute path="/org-finish-profile" component={OrgFinishProfile} />
      <VerifyRoute path="/welcome/civilian" component={CivWelcomePage} />
      <VerifyRoute path="/finish-profile" component={CivFinishProfile} />
      <VerifyRoute path="/verify" component={Verify} />

      <Route path="/features" component={LandingFeaturesPage} />
      <Route path="/contact" component={LandingContactPage} />
      <Route path="/about" component={LandingAboutPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/org-leg-signup" component={OrgLegSignupPage} />
      <Route path="/join-waitlist" component={LandingJoinWaitlistPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />

      <Route path="/help/civilian/find-your-way-around" component={CivilianHelpFindYourWayAroundPage} />
      <Route path="/help/civilian/view-whats-happening" component={CivilianHelpViewWhatsHappeningPage} />
      <Route path="/help/civilian/get-engaged" component={CivilianHelpGetEngagedPage} />
      {/* <Route path="/help/civilian/new-user-faq" component={CivilianHelpNewUserFAQPAge} /> */}
      <Route path="/help/civilian/issues-notes" component={CivilianHelpIssuesNotesPage} />
      <Route path="/help/civilian/legislators-legislation" component={CivilianHelpLegislatorsLegislationPage} />
      <Route path="/help/civilian/organizations" component={CivilianHelpOrganizationsPage} />
      <Route path="/help/civilian/events" component={CivilianHelpEventsPage} />
      <Route path="/help/civilian/user-profiles" component={CivilianHelpUserProfilesPage} />
      <Route path="/help/civilian/login-password" component={CivilianHelpLoginPasswordPage} />
      {/* <Route path="/help/civilian/account-settings" component={CivilianHelpAccountSettingsPage} /> */}
      <Route path="/help/civilian/deleting-your-account" component={CivilianHelpDeletingYourAccountPage} />
      <Route path="/help/civilian" component={CivilianHelpMainPage} />

      <Route path="/help/organization/find-your-way-around" component={OrganizationFindYourWayAroundPage} />
      <Route path="/help/organization/events" component={OrganizationEventsPage} />
      <Route path="/help/organization/supporters" component={OrganizationSupportersPage} />
      <Route path="/help/organization/browsing" component={OrganizationBrowsingPage} />
      <Route path="/help/organization/profile" component={OrganizationProfilePage} />
      <Route path="/help/organization/login-password" component={OrganizationLoginPasswordPage} />
      {/* <Route path="/help/organization/account-settings" component={OrganizationHelpMainPage} /> */}
      <Route path="/help/organization/deleting-your-account" component={OrganizationDeletingYourAccountPage} />
      <Route path="/help/organization" component={OrganizationHelpMainPage} />

      <Route path="/terms-of-service" component={LandingTermsOfServicePage} />
      <Route path="/privacy-policy" component={LandingPrivacyPolicyPage} />
      <Route path="/community-guidelines" component={LandingCommunityGuidelinesPage} />

      <Route exact path="/" component={LandingHomePage} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default LandingRoutes;
