import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import SharePopup from '../../../components/org/global/SharePopup.js'
import IssueHeader from "../../../components/org/issue/IssueHeader";
import IssueDetails from "../../../components/org/issue/IssueDetails";
import { db } from "../../../config/firebase";

function IssueLearnMorePage({ match }) {
	const [issueInfo, setIssueInfo] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [issueId, setIssueId] = useState("");
  const [sharePopup, setSharePopup] = useState(false);

	useEffect(async () => {
		if (match && match.params.issue) {
			let issueId = match.params.issue;

			if (issueId.length == 0) {
				setError("Bad link. No issue id given.");
			} else {
				setIssueId(issueId);
				const currIssueRef = db.collection("issues").doc(issueId);
				const issueDoc = await currIssueRef.get();
				if (!issueDoc.exists) {
					setError("This issue was not found.");
				} else {
					setIssueInfo(issueDoc.data());
				}
			}
			setLoading(false);
		}
	}, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return (
			<AppLayout>
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					{error}
				</div>
			</AppLayout>
		);
	}
	return (
		<AppLayout>

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/issues/${issueId}/notes`}
          type="issue"
          >
        </SharePopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<IssueHeader
					issueId={issueId}
					title={issueInfo.issueTitle}
					description={issueInfo.issueDescription}
					dateCreated={issueInfo.dateCreated}
					topicTags={issueInfo.topicTags}
					links={issueInfo.links}
					active={issueInfo.active}
					supporters={issueInfo.supporters}
					regionalCopies={issueInfo.regionalCopies}
					legislator={`${issueInfo.legislator.firstName} ${issueInfo.legislator.lastName}`}
					legislatorId={issueInfo.legislator.id}
					region={`${issueInfo.legislator.region}, ${
						issueInfo.legislator.chamber === "Senate"
							? "State-Wide"
							: "District " + issueInfo.legislator.district
					}`}
          toggleSharePopup={toggleSharePopup}
				/>

				<IssueDetails
					authorName={
						issueInfo.createdByName
							? issueInfo.createdByName
							: "User Name"
					}
					authorId={issueInfo.createdBy}
          dateCreated={issueInfo.dateCreated}
					description={issueInfo.issueDescription}
					links={issueInfo.links}
					relatedBills={issueInfo.relatedBills}
					relatedVotes={issueInfo.relatedVotes}
					relatedEvents={issueInfo.relatedEvents}
				/>
			</div>
		</AppLayout>
	);
}

export default IssueLearnMorePage;
