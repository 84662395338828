import React, { useEffect, useState, useContext } from 'react';
import app from "../config/firebase"



// API call for national stories
async function getNationalStories() {
    let query = encodeURIComponent("USA");

    //webit news API
    const response = await fetch(`https://webit-news-search.p.rapidapi.com/search?q=${query}&language=en`, {
      "method": "GET",
      "headers": {
        "x-rapidapi-key": "48c9b7adf9mshb0810dc4441fc73p125736jsn96e1f377816e",
        "x-rapidapi-host": "webit-news-search.p.rapidapi.com"
      }
    });

    const content = await response.json();
    return content.data.results;
}

// Context used specifically for national stories fetching
export const StoriesContext = React.createContext(null);


// Provider used specifically for national stories fetching
export const StoriesProvider = (props) => {

    const [storiesList, setStories] = useState(null);

    // fetches events from API on component load (only once)
    useEffect(() => {
        getNationalStories().then(setStories);
    }, []);

    return(
        <StoriesContext.Provider value={storiesList}>
            {props.children}
        </StoriesContext.Provider>
    );

}






const dbRef = app.firestore();



// DB call for trending topics
async function getTrendingTopics() {

    if (dbRef) {

      const topicsRef = dbRef.collection("topics");
      const snapshotActive = await topicsRef.get();

      let topics = [];

      if (!snapshotActive.empty) {
        snapshotActive.forEach((doc) => {
          topics.push(doc.data());
        });
        topics.sort((a,b) => (a.issueTags.length < b.issueTags.length) ? 1 : -1);
      } else {
        console.log("empty snapshot");
      }

      return topics.slice(0,4);

    }

}

// DB call for all active issues (categorized by topic)
async function getIssues() {

    if (dbRef) {

      let activeIssues = [];

      const issuesRef = dbRef.collection("issues");
      const snapshotActive = await issuesRef.where("active", "==", true).get();

      let issuesByTopic = {'No Topic' : []};

      if (!snapshotActive.empty) {
        snapshotActive.forEach((doc) => {

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in issuesByTopic) {
                issuesByTopic[topic].push(doc);
              } else {
                issuesByTopic[topic] = [doc];
              }
          });

          if(doc.data().topicTags.length == 0) {
            issuesByTopic['No Topic'].push(doc);
          }

          activeIssues.push(doc);

        });

        if(issuesByTopic['No Topic'].length == 0)
          delete issuesByTopic['No Topic'];

      } else {
        console.log("empty snapshot");
      }

      let topIssues = [...activeIssues];
      topIssues.sort((a,b) => (a.data().supporters < b.data().supporters) ? 1 :  -1);

      let latestIssues = [...activeIssues];
      latestIssues.sort((a,b) => (a.data().dateCreated < b.data().dateCreated) ? 1 : -1);
      console.log(topIssues.slice(0,2));
      return [topIssues, latestIssues, issuesByTopic, topIssues.slice(0,2)];

    }

}

// DB call for all resolved issues
async function getResolvedIssues() {

    if (dbRef) {
        const issuesRef = dbRef.collection("issues");
        const snapshotResolved = await issuesRef.where("active", "==", false).get();

        let tempData = [];

        if(!snapshotResolved.empty) {
          snapshotResolved.forEach((doc) => { tempData.push(doc); });
        } else {
          console.log("empty snapshot");
        }

        return tempData;
      }

}


// DB call for all events
async function getEvents() {

  if (dbRef) {

    let allEvents = [];

    let tempEvents = [];

    const eventsRef = dbRef.collection("events");
    const snapshotActive = await eventsRef.get();

    let eventsByTopic = {'No Topic' : []};
    let eventsByType = {};

    if (!snapshotActive.empty) {
      // console.log("Events exist!")
      // console.log("number of events:", snapshotActive.docs.length)
      snapshotActive.forEach((doc) => {

        // console.log("DOC ID:", doc.id)
        const currentTime = new Date();

        if (doc.data().mainStartTimeUTC.toDate() >= currentTime){

          doc.data().topicTags.forEach(topic =>
          {
              if(topic in eventsByTopic) {
                eventsByTopic[topic].push(doc);
              } else {
                eventsByTopic[topic] = [doc];
              }
          });

          if(doc.data().topicTags.length == 0) {
            eventsByTopic['No Topic'].push(doc);
          }

          // console.log("events by type:", eventsByType)
          // console.log("event type:", doc.data().eventType)
          if(doc.data().eventType in eventsByType) {
            eventsByType[doc.data().eventType].push(doc);
            // console.log("UPDATED:", eventsByType)
          } else {
            eventsByType[doc.data().eventType] = [doc];
            // console.log("UPDATED:", eventsByType)
          }
          // eventsByType[doc.data().eventType].push(doc)

          tempEvents.push(doc);

        }

      });

      if(eventsByTopic['No Topic'].length == 0)
        delete eventsByTopic['No Topic'];

    } else {
      console.log("empty snapshot");
    }

    // console.log("TEMPEVENTS:", tempEvents)

    let topEvents = [...tempEvents];
    topEvents.sort((a,b) => (a.data().numAttending < b.data().numAttending) ? 1 :  -1);
    topEvents.forEach(doc => console.log(doc.data()));

    let latestEvents = [...tempEvents];
    latestEvents.sort((a,b) => (a.data().mainStartTimeUTC > b.data().mainStartTimeUTC) ? 1 : -1);

    console.log("TOP EVENTS:", topEvents)
    console.log("LATEST EVENTS:", latestEvents)
    console.log("EVENTS BY TOPIC:", eventsByTopic)
    console.log("EVENTS BY TYPE:", eventsByType)
    return [topEvents, latestEvents, eventsByTopic, eventsByType];

    // return [popOrganizations, organizationsByCategory];

  }

}

// DB call for all events
// async function getTypeEvents() {


//   if (dbRef) {

//     let allData = [];

//     const issuesRef = dbRef.collection("events");
//     const snapshotActive = await issuesRef.get();

//     let tempData = {};

//     if (!snapshotActive.empty) {
//       snapshotActive.forEach((doc) => {
//         if(doc.data().topicTag in tempData)
//             tempData[doc.data().type].push(doc);
//         else
//             tempData[doc.data().type] = [doc];
//         });
//     } else {
//       console.log("empty snapshot");
//     }

//     allData.push(tempData);

//     let tempTopArr = [];
//     for(const type in tempData) {
//       let tempArr = tempData[type];
//       tempTopArr.push.apply(tempTopArr, tempArr);
//     }
//     tempTopArr.sort((a,b) => (a.data().numInterested < b.data().numInterested) ? 1 :  -1);

//     let tempLatestArr = [];
//     for(const topic in tempData) {
//       let tempArr = tempData[topic];
//       tempLatestArr.push.apply(tempLatestArr, tempArr);
//     }
//     tempLatestArr.sort((a,b) => (a.data().datetime < b.data().datetime) ? 1 : -1);

//     allData.push(tempTopArr);
//     allData.push(tempLatestArr);
//     return allData;

//   }



// }

async function getOrganizations() {

  if (dbRef) {

    let organizations = [];
    let organizationsByCategory = {};

    const organizationsRef = dbRef.collection("organizations");
    const snapshotActive = await organizationsRef.get();

    if (!snapshotActive.empty) {
      snapshotActive.forEach((doc) => {

        if(doc.data().type in organizationsByCategory) {
          organizationsByCategory[doc.data().type].push(doc);
          // console.log("UPDATED:", eventsByType)
        } else {
          organizationsByCategory[doc.data().type] = [doc];
          // console.log("UPDATED:", eventsByType)
        }

        organizations.push(doc);

      });

    } else {
      console.log("empty snapshot");
    }

    let topOrganizations = [...organizations];
    topOrganizations.sort((a,b) => (a.data().followerCount < b.data().followerCount) ? 1 :  -1);
    topOrganizations.forEach(doc => console.log(doc.data()));

    return [topOrganizations, organizationsByCategory];

  }

}


// context used for all DB data
export const NationDBContext = React.createContext(null);

// Provider used for all DB data
export const NationProvider = (props) => {

    const [topicsDb, setTopics] = useState(null);
    const [activeIssuesDb, setActiveIssues] = useState([null, null, null]);
    const [resolvedIssuesDb, setResolvedIssues] = useState(null);
    const [eventsDb, setEvents] = useState([null, null, null]);
    const [organizationsDb, setOrganizations] = useState([null, null]);

    useEffect(async () => {
        getTrendingTopics().then(setTopics);
        getIssues().then(setActiveIssues);
        getResolvedIssues().then(setResolvedIssues);
        //getTypeEvents().then(setEvents);
        getOrganizations().then(setOrganizations);
        getEvents().then(setEvents)
    }, []);

    return(
      <div>
        {topicsDb && activeIssuesDb && resolvedIssuesDb && eventsDb &&
          <NationDBContext.Provider value={[topicsDb, activeIssuesDb[0], activeIssuesDb[1], activeIssuesDb[2], resolvedIssuesDb, eventsDb[0], eventsDb[1], eventsDb[2], eventsDb[3], organizationsDb[0], organizationsDb[1]]}>
            {props.children}
        </NationDBContext.Provider>
        }
      </div>
    );

}
