import React from "react";
import { Link } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout";
import BrowseNav from "../../../components/org/browse/BrowseNav";
import EventsNav from "../../../components/org/browse/EventsNav";
import NationEvents from "../../../components/org/browse/NationEvents";

function BrowseNationalEventsPage(props) {
	return (
		<OrgLayout>
			<BrowseNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<EventsNav />
				<NationEvents />
			</div>
		</OrgLayout>
	);
}

export default BrowseNationalEventsPage;
