import React from "react";
import { Link } from "react-router-dom";

const SmallIssueCard = (props) => {

  return (
    <Link
      to={`/issues/${props.issueId}/notes`}
    >
      <div className="ml-1 mr-6 bg-white shadow hover:shadow-md px-8 py-8 rounded-lg w-96">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-medium truncate pr-2">{props.issueTitle}</h1>
          <h1 className="bg-indigo-500 px-3 py-1 text-white font-medium rounded-lg">Issue</h1>
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-sm">{props.supporterCount} supporters</h1>
          <div className="flex items-center space-x-1 text-sm font-medium text-red-500">
            <h1>View issue</h1>
            <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default SmallIssueCard;
