import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { db } from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";
import "../../styles/signup.css";
import { states_districts, states_hash } from "../../assets/states";
import defaultProfilePic from "../../assets/DefaultProfilePic.png"

function FinishProfile(props) {

  useEffect(async () => {
    window.localStorage.setItem('refresh', null);
  }, [])

  const { currUser, currUserAdminInfo } = useAuth();
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    uid: currUser.uid,
    email: currUser.email,
    state: "",
    district: "",
    zipCode: "",
    dateCreated: firebase.firestore.Timestamp.now(),
    engagementLevel: "",
    interests: [],
    discoveryMethod: []
  });
  const [selectedPhoto, setSelectedPhoto] = useState()
  const [preview, setPreview] = useState()
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!selectedPhoto) {
        setPreview(defaultProfilePic)
        return
    }

    const objectUrl = URL.createObjectURL(selectedPhoto)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedPhoto])

  async function findLegislators() {
    if (credentials.district && credentials.state) {
      let reps = [];
      const snapHouse = await db
        .collection("legislators")
        .where("chamber", "==", "House")
        .where("region", "==", credentials.state)
        .where("district", "==", credentials.district)
        .get();
      snapHouse.forEach((doc) => {
        // setCredentials({ ...credentials, districtRep: doc.data() });
        reps.push(doc.data());
      });

      const snapSenate = await db
        .collection("legislators")
        .where("chamber", "==", "Senate")
        .where("region", "==", credentials.state)
        .get();

      // let senators = [];
      snapSenate.forEach((doc) => {
        // senators.push(doc.data());
        reps.push(doc.data());
      });
      // setCredentials({ ...credentials, senateRep: senators });
      console.log("my reps are: " + reps);
      return reps;
    }
  }

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    findLegislators().then((reps) => {
      // console.log("credentials:", credentials)
      // console.log("REPS:", reps);
      if (reps.length === 3)
        db.collection("civilians")
          .doc(currUser.uid)
          .set({
            ...credentials,
            districtRep: reps[0],
            senateRep: [reps[1], reps[2]],
            representative: reps[0],
            senators: [reps[1], reps[2]],
            legislatorIds: [reps[0].id, reps[1].id, reps[2].id]
          })
          .then((res) => {
            // history.push("/verify");
            db.collection("users").doc(currUser.uid).update({profileFinished: true}).then(() => {
              let storageRef = firebase.storage().ref();
              let photoRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)
              photoRef.put(selectedPhoto).then((snapshot) => {
                console.log('Uploaded photo!');
                history.push("/welcome/civilian");
              });
            })
          })
          .catch((e) => setErr(e));
      else
        db.collection("civilians")
          .doc(currUser.uid)
          .set({ ...credentials, representative: reps[0] })
          .then((res) => {
            // history.push("/verify");
            db.collection("users").doc(currUser.uid).update({profileFinished: true}).then(() => {
              let storageRef = firebase.storage().ref();
              let photoRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)
              photoRef.put(selectedPhoto).then((snapshot) => {
                console.log('Uploaded photo!');
                history.push("/welcome/civilian");
              });
            })
          })
          .catch((e) => setErr(e));
      setErr("");
      setLoading(false);
    });
  }

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    // console.log("credentials:", credentials)
  }

  function handleInterestsChange(e) {
    const currInterests = credentials.interests
    var index = currInterests.indexOf(e.target.value);
    if (index !== -1) {
      currInterests.splice(index, 1);
    }
    else {
      currInterests.push(e.target.value)
    }
    setCredentials({ ...credentials, interests: currInterests });
  }

  function handleDiscoveryChange(e) {
    const currDiscoveryMethod = credentials.discoveryMethod
    var index = currDiscoveryMethod.indexOf(e.target.value);
    if (index !== -1) {
      currDiscoveryMethod.splice(index, 1);
    }
    else {
      currDiscoveryMethod.push(e.target.value)
    }
    setCredentials({ ...credentials, discoveryMethod: currDiscoveryMethod });
  }

  // function handlePicChange(event) {
  //   let storageRef = firebase.storage().ref();
  //   let userPicRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)
  //   var selectedFile = event.target.files[0];
  //   var reader = new FileReader();

  //   var imgtag = document.getElementById("profilePicImg");
  //   imgtag.title = selectedFile.name;

  //   reader.onload = function(event) {
  //     imgtag.src = event.target.result;
  //   };

  //   reader.readAsDataURL(selectedFile);

  //   userPicRef.put(selectedFile).then((snapshot) => {
  //     console.log('Uploaded file!');
  //   });
  //   // console.log("PHOTO:", event.target.value)
  // }

  // function removeProfilePic(event) {
  //   let storageRef = firebase.storage().ref();
  //   let userPicRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)

  //   // if (userPicRef.exists){
  //   userPicRef.delete().then(() => {
  //     console.log("deleted photo")
  //     var imgtag = document.getElementById("profilePicImg");
  //     imgtag.src = defaultProfilePic
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  //   // }

  // }

  function handlePicChange(event) {
    if (event.target.files[0]){
      setSelectedPhoto(event.target.files[0])
    }
  }

  function removeProfilePic(event) {
    setSelectedPhoto(null)
  }

  // if (currUser.emailVerified) {
  if (currUserAdminInfo.profileFinished) {
    return <Redirect to="/home" />;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="mx-auto w-11/12 py-5 md:py-10 md:w-10/12">
        <h1 className="text-4xl font-semibold">
          Complete your profile!
        </h1>

        <div className="mt-8">
          <form
            action="#"
            method="POST"
            className="space-y-8"
            onSubmit={handleSubmit}
          >

            <h1 className="text-2xl font-semibold">Basic information</h1>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label
                  for="firstName"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                >
                  First name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={credentials.firstName}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="flex-1">
                <label
                  for="lastName"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                >
                  Last name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={credentials.lastName}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label
                  for="state"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                >
                  Select your state
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <select
                    id="state"
                    name="state"
                    value={credentials.state}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option value=""></option>
                    {Object.keys(states_hash).map((state) => {
                      return <option value={state}>{states_hash[state]}</option>;
                    })}
                  </select>
                </div>
              </div>
              {credentials.state && (
                <div className="flex-1 flex items-end space-x-4">
                  <div className="w-full flex-1">
                    <label
                      for="district"
                      className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                    >
                      Enter your district
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <select
                        id="district"
                        name="district"
                        value={credentials.district}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      >
                        <option value=""></option>
                        {
                          states_districts[credentials.state] === "At-Large" ?
                          <option value="At-Large">At Large</option>
                          :
                        Array(states_districts[credentials.state])
                          .fill()
                          .map((_, idx) => {
                            return <option value={idx + 1}>{idx + 1}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="flex-1">
                    <label
                      for="zipCode"
                      className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                    >
                      ZIP Code
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        value={credentials.zipCode}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <a
                    href="https://www.house.gov/representatives/find-your-representative"
                    className="flex space-x-1 items-center text-justiceBlue-500 text-lg font-semibold hover:text-justiceBlue-400">
                    <div>
                      Don't know your district? Click here
                    </div>
                    <svg className="flex-shrink-0 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                  </div>
              )}
            </div>

            <div className="flex items-end">
              <label
                for="profilePic"
                className="block text-sm font-medium leading-6 text-gray-700 text-xl"
                >
                Profile picture
              </label>
              <label className="ml-2 text-gray-700">(optional)</label>
            </div>

            <div className="mb-9 flex items-center">
              <div className="bg-gray-400 h-20 w-20 rounded-full">
                <img id="profilePicImg" className="object-scale-down inline-block h-20 w-20 rounded-full" src={preview} alt=""/>
              </div>
              <div className="ml-4 flex">
                <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
                  {selectedPhoto ? "Change" : "Upload"}
                  <input onChange={handlePicChange} id="profilePic" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
                </button>
                <button onClick={removeProfilePic} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
                  Remove
                </button>
              </div>
            </div>

            <h1 className="text-2xl font-semibold pt-2">For a better GrassrootsGov experience</h1>

            <div>
              <label
                for="engagementLevel"
                className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-6"
              >
                Select your current level of political engagement
              </label>
              <div className="flex items-center space-x-8">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="lowEngagement"
                    name="engagementLevel"
                    value="low"
                    onChange={handleChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "organization"}
                  />
                  <label
                    for="lowEngagement"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Low
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="medEngagement"
                    name="engagementLevel"
                    value="medium"
                    onChange={handleChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="medEngagement"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Medium
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="highEngagement"
                    name="engagementLevel"
                    value="high"
                    onChange={handleChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="highEngagement"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    High
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label
                for="engagementLevel"
                className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-6"
              >
                Select all topics you're interested in
              </label>
              <div className="flex items-center space-x-8">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="climate"
                    name="interests"
                    value="Climate"
                    onChange={handleInterestsChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "organization"}
                  />
                  <label
                    for="climate"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Climate
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="healthcare"
                    name="interests"
                    value="Healthcare"
                    onChange={handleInterestsChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="healthcare"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Healthcare
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="economy"
                    name="interests"
                    value="Economy"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="economy"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Economy
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="criminalJustice"
                    name="interests"
                    value="Criminal Justice"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="criminalJustice"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Criminal Justice
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="foreignPolicy"
                    name="interests"
                    value="Foreign Policy"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="foreignPolicy"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Foreign Policy
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="education"
                    name="interests"
                    value="Education"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="education"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Education
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="reproductiveRights"
                    name="interests"
                    value="Reproductive Rights"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="reproductiveRights"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Reproductive Rights
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="lgbtq"
                    name="interests"
                    value="LGBTQ+"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="lgbtq"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    LGBTQ+
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="immigration"
                    name="interests"
                    value="Immigration"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="immigration"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Immigration
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="votingElections"
                    name="interests"
                    value="Voting/Elections"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="votingElections"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Voting & Elections
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="infrastructure"
                    name="interests"
                    value="Infrastructure"
                    onChange={handleInterestsChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="infrastructure"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Infrastructure
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label
                for="engagementLevel"
                className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-6"
              >
                How did you discover GrassrootsGov? (Select all that apply)
              </label>
              <div className="flex items-center space-x-8">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Friend/Family/Colleague"
                    name="discoveryMethod"
                    value="Friend/Family/Colleague"
                    onChange={handleDiscoveryChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "organization"}
                  />
                  <label
                    for="Friend/Family/Colleague"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Friend/Family/Colleague
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Instagram"
                    name="discoveryMethod"
                    value="Instagram"
                    onChange={handleDiscoveryChange}
                    className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="Instagram"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Instagram
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Twitter"
                    name="discoveryMethod"
                    value="Twitter"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="Twitter"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Twitter
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="LinkedIn"
                    name="discoveryMethod"
                    value="LinkedIn"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="LinkedIn"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    LinkedIn
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="TikTok"
                    name="discoveryMethod"
                    value="TikTok"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="TikTok"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    TikTok
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Other Social Media"
                    name="discoveryMethod"
                    value="Other Social Media"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="Other Social Media"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Other Social Media
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Internet Search"
                    name="discoveryMethod"
                    value="Internet Search"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="Internet Search"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Internet Search
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="News Article"
                    name="discoveryMethod"
                    value="News Article"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="News Article"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    News Article
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="Other"
                    name="discoveryMethod"
                    value="Other"
                    onChange={handleDiscoveryChange}
                    className="focus:outline-none h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                    // checked={userData.accountType === "legislator"}
                  />
                  <label
                    for="Other"
                    className="ml-2 block leading-5 text-gray-700"
                  >
                    Other
                  </label>
                </div>

              </div>
            </div>

            <div className="pt-4">
              <button
                disabled={loading}
                type="submit"
                className="btn-primary"
              >
                Continue
              </button>
            </div>

          </form>
          {err && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{err}</span>
              {/* <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </span> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinishProfile;
