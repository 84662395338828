import React from "react";

const AmendmentCard = (props) => {

  return(
    <a
      href={props.congressGovUrl}
      className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-100 ease-in-out divide-y divide-gray-200">
      <div className="px-8 py-6 flex justify-between items-center">

        <div className="space-y-3">
          <h1 className="text-xl font-medium max-w-5xl truncate">{props.amendmentTitle ? `${props.amendmentNumber}: ${props.amendmentTitle}` : props.amendmentNumber} </h1>
          <div className="flex space-x-6 items-center">
            <div className="text-xl font-medium">
              {props.sponsorName ? `${props.sponsorName} (${props.sponsorParty}-${props.sponsorState})` : "Sponsor N/A"}
            </div>
            <div>
              Introduced {props.dateIntroduced}
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 text-lg font-medium text-red-500">
          <h1>View amendment on Congress.gov</h1>
          <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>

      </div>
    </a>
  )
}

export default AmendmentCard;
