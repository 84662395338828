import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import OrganizationCard from '../../global/organizations/OrganizationCard'
import {StateDBContext} from '../../../../contexts/StateContext'
import CategoryCard from './CategoryCard'

const StateCategoryOrganizations = () => {

    const organizations = useContext(StateDBContext);
    let [categoryDisplay, showCategories] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    useEffect(() => {

      let categorylist = [];
      if(organizations[10] != null && organizations[10].length != 0) {
        for(const category in organizations[10]){
            categorylist.push(<CategoryCard name={category} data={organizations[10][category]} trendingTopic={false}/>);
        }
      } else {
        showCategories(<h1 className="px-8 py-8 font-medium text-xl">{"There are currently no organizations in your state."}</h1>)
      }

      showCategories(categorylist);

    }, [organizations]);



    return (
      <div className="grid grid-cols-4 gap-x-5">
        {categoryDisplay}
      </div>
    )
}

export default StateCategoryOrganizations;
