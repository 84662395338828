import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import AuthPopup from '../../../components/civ/global/AuthPopup'
import IssueHeader from "../../../components/civ/issue/IssueHeader";
import IssueNotes from "../../../components/civ/issue/IssueNotes";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { Redirect } from "react-router-dom";

function IssueNotesPage({ match }) {
	const [allNotesRef, setAllNotesRef] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [issueId, setIssueId] = useState("");
	const [upvotedNotes, setUpvotedNotes] = useState([]);
	const [upvotedNotesRef, setUpvotedNotesRef] = useState(null);
  const [authPopup, setAuthPopup] = useState(false);

	const { currUser, currUserAdminInfo } = useAuth();

	const [issueInfo, setIssueInfo] = useState({});
	useEffect(async () => {
		console.log("why here :(");
		if (match && match.params.issue) {
			let issueId = match.params.issue;

			{
				if (issueId.length == 0) {
					setError("Bad link. No issue id given.");
				} else {
					setIssueId(issueId);
					setAllNotesRef(
						db.collection("issues").doc(issueId).collection("notes")
					);
					const issueData = await db
						.collection("issues")
						.doc(issueId)
						.get();
					setIssueInfo(issueData.data());
				}
			}
      if (currUser) {
			setUpvotedNotesRef(
				db
					.collection("civilians")
					.doc(currUser.uid)
					.collection("upvotedNotes")
			);
			const snapshot = await db
				.collection("civilians")
				.doc(currUser.uid)
				.collection("upvotedNotes")
				.get();
			setUpvotedNotes(
				snapshot.docs.map((doc) => {
					console.log("UPVOTED:", doc.id);
					// setUpvotedNotes((upvotedNotes) => [...upvotedNotes, doc.id]);
					return doc.id;
				})
			);
      }
			setLoading(false);
		}
	}, []);

  function toggleAuthPopup(){
    setAuthPopup(!authPopup)
    // console.log("toggled:", authPopup)
  }

  if (currUser && currUserAdminInfo.type === "organization") {
    return <Redirect to={`/org/issues/${match.params.issue}/notes`} />
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>

      {authPopup &&
        <AuthPopup
          toggleAuthPopup={toggleAuthPopup}
          type="issue"
          >
        </AuthPopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<IssueHeader
					issueId={issueId}
					title={issueInfo.issueTitle}
					legislatorId={issueInfo.legislatorId}
					legislator={`${issueInfo.legislator.firstName} ${issueInfo.legislator.lastName}`}
					region={`${issueInfo.legislator.region}, ${
						issueInfo.legislator.chamber === "Senate"
							? "State-Wide"
							: "District " + issueInfo.legislator.district
					}`}
          createdBy={issueInfo.createdBy}
          dateCreated={issueInfo.dateCreated}
          toggleAuthPopup={toggleAuthPopup}
				/>

				<IssueNotes
					notesRef={allNotesRef}
					upvotedNotes={upvotedNotes}
					upvotedNotesRef={upvotedNotesRef}
					issueId={match.params.issue}
					userId={currUser ? currUser.uid : null}
					issueDistrict={issueInfo.legislator.district}
					issueState={issueInfo.state}
          toggleAuthPopup={toggleAuthPopup}
				/>
				{/* <IssueNotes notesRef={allNotesRef} issueId={match.params.issue} userId={currUser.uid}/> */}
			</div>
		</AppLayout>
	);
}

export default IssueNotesPage;
