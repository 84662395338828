import React, { useState, useEffect } from "react";
import OrganizationIssuePost from '../../global/organizations/OrganizationIssuePost'
import OrganizationEventPost from '../../global/organizations/OrganizationEventPost'
import { db } from '../../../../config/firebase'
import { useAuth } from '../../../../contexts/AuthContext'
import firebase from "firebase";
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png"

const HomeFeed = () => {

  const { currUser } = useAuth();

  const [orgPosts, setOrgPosts] = useState([]);

  useEffect(async () => {

    let orgsFollowing = await db.collection("civilians").doc(currUser.uid).collection("orgsFollowing").get()

    console.log("number of orgs following:", orgsFollowing.docs.length)

    Promise.all(orgsFollowing.docs.map(async (org) => {

      console.log("org id:", org.data().orgId)

      let orgSrc = defaultProfilePic

      let storageRef = firebase.storage().ref();
      let userPicRef = storageRef.child(`images/organization/profilePics/${org.data().orgId}`)
      try {
        let result = await userPicRef.getDownloadURL()
        orgSrc = result
      } catch(error) {
        console.log(error)
      }

      return (db.collection("organizations").doc(org.data().orgId).collection("posts").orderBy("datePosted", "desc").get().then((posts) => {

        return(posts.docs.map((post) => (

          {orgId: org.data().orgId,
          orgName: org.data().orgName,
          orgSrc: orgSrc,
          ...post.data()}

        )))

      })
      )

    })).then((orgPostsList) => {

      const tempOrgPostsList = []

      orgPostsList.forEach((org) => {
        org.forEach((post) => {
          tempOrgPostsList.push(post)
        })
      })

      tempOrgPostsList.sort((a,b) => (a.datePosted < b.datePosted) ? 1 :  -1)

      setOrgPosts(tempOrgPostsList)
      console.log("number of posts:", tempOrgPostsList)

    })


  }, [])

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">My Feed</h1>
      <div>
        {orgPosts.length > 0 ?
        orgPosts.map((post) => {
          if (post.contentType === "issue") {
            return(
              <OrganizationIssuePost
                orgId={post.orgId}
                orgName={post.orgName}
                profilePicUrl={post.orgSrc}
                datePosted={post.datePosted}
                postBody={post.postBody}
                issueTitle={post.issue.issueTitle}
                legislatorName={post.issue.legislatorName}
                legislatorParty={post.issue.legislatorParty}
                legislatorState={post.issue.legislatorState}
                issueDateCreated={post.issue.issueDateCreated}
                regionalCopyCount={post.issue.regionalCopyCount}
                supporterCount={post.issue.supporterCount}
                issueTopicTags={post.issue.issueTopicTags}
                issueId={post.issue.issueId}
              />
            )
          }
          else {
            return(
              <OrganizationEventPost
                orgId={post.orgId}
                orgName={post.orgName}
                profilePicUrl={post.orgSrc}
                datePosted={post.datePosted}
                postBody={post.postBody}
                eventTitle={post.event.eventTitle}
                eventOrgName={post.event.eventOrgName}
                eventDateTime={post.event.eventDateTime}
                eventIsVirtual={post.event.eventIsVirtual}
                eventCity={post.event.eventCity}
                eventState={post.event.eventState}
                attendingCount={post.event.attendingCount}
                interestedCount={post.event.interestedCount}
                eventTopicTags={post.event.eventTopicTags}
                eventId={post.event.eventId}
              />
            )
          }
        })
        :
        <h1 className="text-lg">No posts to show yet. Follow some organizations to get started!</h1>
        }
      </div>
    </div>
  )
  //TODO later: infinite scroll but with loader
}

export default HomeFeed;
