import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./LegPrivateRoute";

import BillsLeastPopular from '../../pages/leg/billsvotes/BillsLeastPopular'; // Need to build
import BillsMostPopular from '../../pages/leg/billsvotes/BillsMostPopular'; // Need to build
import BillsLatest from '../../pages/leg/billsvotes/BillsLatest'; // Need to build
import VotesMostPopular from '../../pages/leg/billsvotes/VotesMostPopular';
import VotesLeastPopular from '../../pages/leg/billsvotes/VotesLeastPopular';
import VotesLatest from '../../pages/leg/billsvotes/VotesLatest'; // Need to build

const BillsVotesRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/leg/bills-votes/votes/least-popular" component={VotesLeastPopular}/>
      <PrivateRoute path="/leg/bills-votes/votes/most-popular" component={VotesMostPopular}/>
      <PrivateRoute path="/leg/bills-votes/votes/latest" component={VotesLatest}/>
      <PrivateRoute path="/leg/bills-votes/bills/least-popular" component={BillsLeastPopular}/>
      <PrivateRoute path="/leg/bills-votes/bills/most-popular" component={BillsMostPopular}/>
      <PrivateRoute path="/leg/bills-votes/bills/latest" component={BillsLatest}/>
    </Switch>
  )
}

export default BillsVotesRoutes;
