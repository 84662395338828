import React from 'react'
import { NavLink, useLocation } from "react-router-dom";


const BillVoteNav = () => {

  const {pathname} = useLocation();

  return (
      <>
        <header className="sm:px-8 lg:px-16 py-6 shadow-md">
          <h1 className="text-3xl font-bold">Bills & Votes</h1>
        </header>
        <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex space-x-8">
          <NavLink to="/bills-votes/house/upcoming" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" exact activeClassName="text-gray-900"
                    isActive={() => ["/bills-votes", "/bills-votes/house/upcoming","/bills-votes/house/introduced", "/bills-votes/house/past"].includes(pathname)} >
            House Bills
          </NavLink>
          <NavLink to="/bills-votes/senate/upcoming" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
                    isActive={() => ["/bills-votes/senate/upcoming", "/bills-votes/senate/introduced", "/bills-votes/senate/past"].includes(pathname)} >
            Senate Bills
          </NavLink>
          <NavLink to="/bills-votes/vote/house" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
                    isActive={() => ["/bills-votes/vote/house", "/bills-votes/vote/senate"].includes(pathname)} >
            All Votes
          </NavLink>
        </div>
        <hr className="border-solid"/>
      </>
      )
}

export default BillVoteNav;
