import React, { useState, useEffect } from "react";
// import { db } from "../../../config/firebase";
// import { NavLink } from "react-router-dom";
import AppLayout from "../../../layouts/AppLayout";
import AuthPopup from '../../../components/civ/global/AuthPopup'
import LegislatorHeader from "../../../components/civ/legislators/profile/LegislatorHeader";
import LegislatorActivity from "../../../components/civ/legislators/profile/LegislatorActivity";
import legislators from "../../../assets/jsonData/legislators.json";
import { useAuth } from "../../../contexts/AuthContext";
const axios = require("axios");

const config = {
	headers: {
		"X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
	},
};

const LegislatorActivityPage = ({ match }) => {
  const { currUser } = useAuth();
	const [legislator, setLegislator] = useState({});
	const [votesCount, setVotesCount] = useState();
	const [headerData, setHeaderData] = useState("");
	const [legislatorId, setLegislatorId] = useState("");
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
  const [authPopup, setAuthPopup] = useState(false);

	useEffect(async () => {
		let legislatorId = match.params.legislator;
		if (legislatorId.length == 0) {
			setError("Bad link. No user id given.");
		} else {
			setLegislatorId(legislatorId);
			// const currLegRef = db.collection("legislators").doc(legislatorId);
			const legislatorDoc = legislators[legislatorId];
			if (!legislatorDoc) {
				setError("This legislator was not found.");
			} else {
				setLegislator(legislatorDoc);
				axios
					.get(
						`https://api.propublica.org/congress/v1/members/${legislatorId}.json`,
						config
					)
					.then((response) => {
						console.log("leg DATA:", response.data.results[0]);
						setVotesCount(
							response.data.results[0].roles[0].total_votes
						);
						setHeaderData(
							response.data.results[0].roles[0].total_votes +
								" Votes (117th Congress)"
						);
					});
			}
		}
		setLoading(false);
	}, []);

  function toggleAuthPopup(){
    setAuthPopup(!authPopup)
    // console.log("toggled:", authPopup)
  }

	if (loading) {
		return (
			<AppLayout>
				<></>
			</AppLayout>
		);
	}
	if (error) {
		return (
			<AppLayout>
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					{error}
				</div>
			</AppLayout>
		);
	}
	return (
		<AppLayout>

      {authPopup &&
        <AuthPopup
          toggleAuthPopup={toggleAuthPopup}
          type="legislator"
          >
        </AuthPopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<LegislatorHeader
					{...legislator}
					legislatorId={legislatorId}
					headerData={headerData}
          toggleAuthPopup={toggleAuthPopup}
				/>

				<LegislatorActivity
					id={legislatorId}
					count={votesCount}
					activity={"votes"}
				/>
			</div>
		</AppLayout>
	);
};

export default LegislatorActivityPage;
