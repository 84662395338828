import React, { useContext, useEffect, useState } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
import DistrictIssuesNav from "./DistrictIssuesNav";
import IssueCard from "../../global/issues/IssueCard";
import { DistrictDBContext } from "../../../../contexts/DistrictContext";

const DistrictTopIssues = () => {

  const issues = useContext(DistrictDBContext);
  let [topIssues, setTopIssues] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect(async () => {

    if(issues[1] != null && issues[1].length != 0) {
        setTopIssues(issues[1].map((doc) =>
        <IssueCard
          issueTitle={doc.data().issueTitle}
          supporters={doc.data().supporters}
          legislator={`${doc.data().legislator.firstName} ${doc.data().legislator.lastName}`}
          region={`${doc.data().region}-${doc.data().district}`}
          created={doc.data().dateCreated}
          copies={doc.data().regionalCopies}
          issueId={doc.id}
        />));
    } else {
        setTopIssues(<h1 className="px-8 py-8 font-medium text-xl">There are currently no active issues in your district.</h1>)
    }

  }, [issues[1]]);

  return (
    <>
      <DistrictIssuesNav />
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {topIssues}
          </div>
        </div>
      </>
    );

};

export default DistrictTopIssues;
