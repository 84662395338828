import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./LegPrivateRoute";

import InboxUnresolvedIssuesPage from '../../pages/leg/inbox/InboxUnresolvedIssuesPage'

const InboxRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/leg/inbox/votes/drafts" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/votes/dismissed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/votes/addressed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/votes/unaddressed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/bills/drafts" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/bills/dismissed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/bills/addressed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/bills/unaddressed" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/issues/drafts" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/issues/resolved" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/inbox/issues/unresolved" component={InboxUnresolvedIssuesPage}/>
    </Switch>
  )
}

export default InboxRoutes;
