import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";
import SemiPrivateRoute from "./CivSemiPrivateRoute";

import OrganizationLatestPage from "../../pages/civ/organization/OrganizationLatestPage";
import OrganizationIssuesPage from "../../pages/civ/organization/OrganizationIssuesPage";
import OrganizationEventsPage from "../../pages/civ/organization/OrganizationEventsPage";
import OrganizationAboutPage from "../../pages/civ/organization/OrganizationAboutPage";

const OrganizationRoutes = () => {
	return (
		<Switch>
			<SemiPrivateRoute
				path="/organizations/:organization/latest"
				component={OrganizationLatestPage}
			/>
			<SemiPrivateRoute
				path="/organizations/:organization/issues"
				component={OrganizationIssuesPage}
			/>
			<SemiPrivateRoute
				path="/organizations/:organization/events"
				component={OrganizationEventsPage}
			/>
			<SemiPrivateRoute
				path="/organizations/:organization/about"
				component={OrganizationAboutPage}
			/>
		</Switch>
	);
};

export default OrganizationRoutes;
