import React, { useState, useEffect } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import AppLayout from '../../../../layouts/AppLayout'
import DistrictNav from '../../../../components/civ/district/DistrictNav'
import DistrictOrganizationNav from '../../../../components/civ/district/organizations/DistrictOrganizationsNav'
import DistrictTopOrganizations from '../../../../components/civ/district/organizations/DistrictTopOrganizations'
// import addBtnImg from '../../../../assets/addBtnImg.png';

const DistrictPopularOrganizationsPage = () => {

  return (
    <AppLayout>
      <DistrictNav/>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <DistrictOrganizationNav />
        <DistrictTopOrganizations />
        <div className="fixed bottom-0 right-0 w-15 h-15 mr-12 mb-8 cursor-pointer" onClick={()=> {console.log('a')}}>
          {/* <img className='w-11 h-11' src={addBtnImg}/> */}
        </div>
      </div>
    </AppLayout>
  )
}

export default DistrictPopularOrganizationsPage;
