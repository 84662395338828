import React from "react";
import { Link } from "react-router-dom";

const NoteCard1 = (props) => {
  console.log(props);
  const formatDate = (timestamp) => {
    let date = timestamp.toDate();

    let dateStr =
      date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth();
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);
    return dateStr;
  };
  return (
    <Link to={`/org/issues/${props.issue.id}/notes/${props.noteId}`} className="w-full flex-none mb-10">
      <div className="flex-none h-72 bg-yellow-300 shadow-sm hover:shadow-md px-8 py-10">
        <div className="flex">
          <Link
            to={`/org/issues/${props.issue.id}/notes`}
            className="flex-auto truncate flex items-center shadow-md bg-white hover:bg-gray-50 rounded-md text-lg px-5 py-3"
          >
            <h1 className="truncate">{props.issue.issueTitle}</h1>
            <svg
              className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </Link>
        </div>
        <h1 className="font-medium text-lg mt-8 mb-2 truncate">{props.noteTitle}</h1>
        <h1 className="font-normal text-xs mb-10">
          Posted {props.dateCreated ? formatDate(props.dateCreated) : "Na/Na/Na"}
        </h1>

        <div className="flex justify-between items-center">
          <h1 className="font-medium text-sm">{props.upvotes} upvotes</h1>
          {/* <Link
            to={`/issues/${props.issue.id}/notes/${props.noteId.noteId}`}
            className="bg-gray-50 hover:bg-white font-semibold text-xs px-3 py-1 rounded-md text-red-500"
          >
            View note →
          </Link> */}
        </div>
      </div>
    </Link>
  );
};

export default NoteCard1;
