import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";

import DistrictPage from "../../pages/civ/district/DistrictPage";

import DistrictTopIssuesPage from "../../pages/civ/district/issues/DistrictTopIssuesPage";
import DistrictLatestIssuesPage from "../../pages/civ/district/issues/DistrictLatestIssuesPage";
import DistrictIssuesTopicsPage from "../../pages/civ/district/issues/DistrictIssuesTopicsPage";
import DistrictIssuesTopicListPage from "../../pages/civ/district/issues/DistrictIssueTopicListPage"
import DistrictResolvedPage from "../../pages/civ/district/issues/DistrictResolvedPage";

import DistrictTopEventsPage from '../../pages/civ/district/events/DistrictTopEventsPage';
import DistrictLatestEventsPage from '../../pages/civ/district/events/DistrictLatestEventsPage';
import DistrictTypeEventsPage from '../../pages/civ/district/events/DistrictTypeEventsPage';
import DistrictTypeEventListPage from '../../pages/civ/district/events/DistrictTypeEventListPage';
import DistrictTopicEventsPage from '../../pages/civ/district/events/DistrictTopicEventsPage';
import DistrictTopicEventListPage from '../../pages/civ/district/events/DistrictTopicEventListPage';

import DistrictCategoryOrganizationPage from '../../pages/civ/district/organizations/DistrictCategoryOrganizationsPage';
import DistrictOrganizationListPage from '../../pages/civ/district/organizations/DistrictOrganizationListPage';
import DistrictTopOrganizations from '../../pages/civ/district/organizations/DistrictTopOrganizationsPage';

import {DistrictProvider} from "../../contexts/DistrictContext";

const DistrictRoutes = () => {
  return (
    <DistrictProvider>
    <Switch>
        <PrivateRoute path="/district/issues/top" component={DistrictTopIssuesPage} />
        <PrivateRoute path="/district/issues/latest" component={DistrictLatestIssuesPage} />
        <PrivateRoute path="/district/issues/topics" component={DistrictIssuesTopicsPage} />
        <PrivateRoute path="/district/issues/list" component={DistrictIssuesTopicListPage} />
        <PrivateRoute path="/district/issues/resolved" component={DistrictResolvedPage} />

        <PrivateRoute path="/district/events/top" component={DistrictTopEventsPage} />
        <PrivateRoute path="/district/events/latest" component={DistrictLatestEventsPage} />
        <PrivateRoute path="/district/events/type" component={DistrictTypeEventsPage} />
        <PrivateRoute path="/district/events/typelist" component={DistrictTypeEventListPage} />
        <PrivateRoute path="/district/events/topic" component={DistrictTopicEventsPage} />
        <PrivateRoute path="/district/events/topiclist" component={DistrictTopicEventListPage} />

        <PrivateRoute path="/district/organizations/top" component={DistrictTopOrganizations} />
        <PrivateRoute path="/district/organizations/category" component={DistrictCategoryOrganizationPage} />
        <PrivateRoute path="/district/organizations/list" component={DistrictOrganizationListPage} />

        <PrivateRoute path="/district" component={DistrictPage} />
    </Switch>
    </DistrictProvider>
  )
}

export default DistrictRoutes;
