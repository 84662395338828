import React, { useState, useEffect } from "react";
import megaphone from '../../../assets/megaphone-icon.png'
import { Link, NavLink, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const SharePopup = (props) => {

  const [copied, setCopied] = useState(false);

  let mainMessage = "Share this:"

  //message switch case based on type of page?
  switch(props.type) {
    case "issue":
      mainMessage="Share this issue:"
      break;
    case "event":
      mainMessage="Share this event:"
      break;
    case "organization":
      mainMessage="Share this organization:"
      break;
    case "legislator":
      mainMessage="Share this legislator:"
      break;
    case "bill":
      mainMessage="Share this bill:"
      break;
    case "vote":
      mainMessage="Share this vote:"
      break;
    default:
      break;
  }

  return (
    <div className="min-h-screen fixed h-full w-full top-0 left-0 bg-gray-700 bg-opacity-30 z-50">
      <div className="absolute w-full h-full flex flex-col justify-center items-center">
        <div className="bg-white w-1/2 h-1/3 px-7 py-7 rounded-lg shadow">

          <div className="justify-start">
            <button className="focus:outline-none" onClick={props.toggleSharePopup}>
              <svg className="flex-shrink-0 h-8 w-8 text-justiceBlue-600 hover:text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="py-1 text-center">
            {/* <div className="flex justify-center pb-8">
              <img src={megaphone} className="h-20" />
            </div> */}
            <div className="text-3xl font-bold pb-5">{mainMessage}</div>
            <div className="text-xl mb-6 px-2 py-2 bg-gray-100 rounded-lg">{props.url}</div>
            {/* <div className="flex justify-center pb-2">
              <Link to="/signup" className="inline-flex justify-center items-center w-1/2 py-2 leading-7 font-medium text-white rounded-md bg-justiceBlue-500 shadow-sm hover:bg-justiceBlue-400 active:bg-justiceBlue-600 focus:ring-4 focus:ring-justiceBlue-50 focus:ring-opacity-50 focus:outline-none">Sign up</Link>
            </div> */}
            <CopyToClipboard
              text={props.url}
              onCopy={() => setCopied(true)}
            >
              <button className='inline-flex justify-center items-center w-1/2 py-2 leading-7 font-medium text-white rounded-md bg-justiceBlue-500 shadow-sm hover:bg-justiceBlue-400 active:bg-justiceBlue-600 focus:ring-4 focus:ring-justiceBlue-50 focus:ring-opacity-50 focus:outline-none'>
                Copy link to clipboard
              </button>
            </CopyToClipboard>
            {copied && (
              <div className="mt-4 text-green-600 flex space-x-1 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                </svg>
                <h1 className='text-center text-sm'>
                  Link copied to clipboard!
                </h1>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default SharePopup;
