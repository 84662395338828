import React, { useState, useEffect } from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import EventCardSmall from '../global/events/SmallEventCard'
import OrganizationIssue from '../global/organizations/OrganizationIssue'

const IssueTakeAction = (props) => { //function IssueTakeAction({ props }) {
  // const [allEvents, setAllEvents] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(async () => {
  //   setAllEvents([]);
  //   const snapshot = await props.eventsRef.get();
  //   if (!snapshot.empty) {
  //     snapshot.forEach((doc) => {
  //       setAllEvents((allEvents) => [...allEvents, doc]);
  //     });
  //   } else {
  //     console.log("empty snapshot");
  //   }
  //   setLoading(false);
  // }, []);

  return (
    <>
      {/* <h1 className="text-2xl font-bold text-gray-900 mb-4">Links</h1>
      <div className="flex space-x-4">
        <a href="#" className="bg-white px-8 py-4 border-2 border-gray-100 rounded-lg flex-auto">
          <h1 className="text-2xl font-semibold">Link</h1>
        </a>
        <a href="#" className="bg-white px-8 py-4 border-2 border-gray-100 rounded-lg flex-auto">
          <h1 className="text-2xl font-semibold">Link</h1>
        </a>
        <a href="#" className="bg-white px-8 py-4 border-2 border-gray-100 rounded-lg flex-auto">
          <h1 className="text-2xl font-semibold">Link</h1>
        </a> */}
        {/* turn these links into a component */}
      {/* </div> */}

      <div className="mb-10"></div>

      <h1 className="text-2xl font-bold leading-tight text-gray-900 mb-4">Related Events</h1>
      <div className={props.relatedEvents.length === 3 ? "grid grid-cols-3 gap-6 items-start": "grid grid-cols-2 gap-6 items-start"}>
        {props.relatedEvents.length === 0 ? (
          <div className="text-lg w-full">
            There are no related events for this issue.
          </div>
        ) : (
          props.relatedEvents.map((event) => {
            return (
              <EventCardSmall
                eventTitle={event.eventTitle}
                organizerName={event.organizerName}
                organizerId={event.organizerId}
                isVirtual={event.isVirtual}
                eventId={event.objectID}
              />
            );
          })
        )}
      </div>

      <div className="mb-10"></div>

      {/* <h1 className="text-2xl font-bold leading-tight text-gray-900 mb-4">Posts Mentioned In</h1>
      <OrganizationIssue /> */}

    </>
  )
}

export default IssueTakeAction;
