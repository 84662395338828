import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import CivilianIssuesPage from "../../pages/org/civilian/CivilianIssuesPage";
import CivilianNotesPage from "../../pages/org/civilian/CivilianNotesPage";
import CivilianOrganizationsPage from "../../pages/org/civilian/CivilianOrganizationsPage";

const CivilianRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/org/users/:user/issues" component={CivilianIssuesPage} />
      <PrivateRoute path="/org/users/:user/notes" component={CivilianNotesPage} />
      <PrivateRoute path="/org/users/:user/organizations" component={CivilianOrganizationsPage} />
    </Switch>
  );
};

export default CivilianRoutes;
