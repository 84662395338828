import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Issues & notes
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>What are issues and notes?</h2>
            <p>Issues are the flagship activism feature that GrassrootsGov offers. Through issues, you take take a stance on a real world problem that people in your community, state, or even the nation can support. You can find these issues at the bottom of your homepage. Issues are organized into two categories: active and resolved. Active issues are issues that still need attention and resolved issues are issues that have been covered by your legislator. </p>
            <p>One of our goals is to empower each user with the opportunity to start a movement - issues help users accomplish just that. When a user wishes to express their thoughts on an important issue, they can create an issue that communities can rally behind.</p>
            <p>Our notes feature is an additional way to express your views or document your reaction to an issue. If you agree with someone's issue, supporting them by adding a note can help the movement!</p>

            <h2>How to view issues in your district or state</h2>
            <p>After clicking on "District" or "State" buttons at the top of your home page, you will be led to your District's or State's landing page. From there, if you click on "Issues", you can now view issues in your district or state.</p>
            <p>You can filter issues by top, latest, topic, and also by if they have been resolved by a legislator or not. </p>

            <h2>How to view issues from across the nation</h2>
            <p>Navigate towards the bar at the top and click on the "Nation" tab. </p>
            <p>You can filter issues by top, latest, topic, and also by if they have been resolved by a legislator or not. </p>

            <h2>How to view the bills, votes, and events related to an issue</h2>
            <p>If you are interested in bills, votes, or events related to an issue, you can either search for a specific issue using the search bar or browse through your district and state. Once you click on the issue you are interested in, if you click on "Learn More" you will be able to find more information about the issue plus any related bills or votes.</p>
            <p>If you click on "Take Action" you will be directed to any events related to the bill.</p>
            <p>If you click on "Regional Copies," you will be able to see the number of people across the number of districts who support the issue.</p>

            <h2>How to create regional copies of issues</h2>
            <p>Click on an issue and go to "Regional Copies" in the left. Here, you can see all the other regional copies that other people use to support this issue in their district. </p>
            <p>To do the same, click on "Create New Copy". You'll then be taken to this page where you can add additional info. Click "Post Issue" when you're done!</p>

            <h2>How to follow issues</h2>
            <p>To follow an issue, go to the "Issues" page and click on the blue "Follow" button near the top right corner!</p>

            <h2>How to search for issues</h2>
            <p>Do you have an issue you would like to search for? You can do exactly that by writing your issue in the search bar at the top right corner of the page!</p>

            <h2>How to view all the issues you've posted</h2>
            <p>If you'd like to view the issues you've posted, navigate to the "My Issues" tab from the homepage. Here you'll be able to toggle between active and resolved issues, as well as filter by topic and sort by date posted/popularity.</p>

            <h2>How to view all the notes you've posted</h2>
            <p>If you'd like to view all the notes you've posted, from your home page, click on "My Notes!" Here you'll find all your notes separated by legislator. </p>

            <h2>How to view all the issues you're following</h2>
            <p>From the homepage, click on the "Following" option near the right of the page, then click "Issues". All the issues you are following will be displayed there. You can filter and sort how you'd like them to be viewed using our "Filter" and "Sort" options.</p>

            <h2>How to view all the issues assigned to a legislator</h2>
            <p>To view all the issues assigned to a legislator, click on a legislator's profile, and you will find the "Issues" section in the right of the gray bar below of their profile. A list of all the issues assigned to that legislator will pop up, and you can then use filter and sort to find what you're looking for !</p>

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
