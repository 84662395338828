import React from 'react';
import { Link } from 'react-router-dom';
import LandingLayout from '../../layouts/LandingLayout';
import stateScreenshot from '../../assets/state.png';

const LandingFeaturesPage = () => {
  return (
    <LandingLayout>
      <div className='bg-gray-50'>
        <div className='relative overflow-hidden'>
          <div className='block absolute inset-y-0 h-full w-full'>
            <div className='relative h-full'>
              <svg
                className='absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full'
                width='404'
                height='784'
                fill='none'
                viewBox='0 0 404 784'
              >
                <defs>
                  <pattern
                    id='e229dbec-10e9-49ee-8ec3-0286ca089edf'
                    x='0'
                    y='0'
                    width='20'
                    height='20'
                    patternUnits='userSpaceOnUse'
                  >
                    <rect
                      x='0'
                      y='0'
                      width='4'
                      height='4'
                      className='text-gray-200'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  width='404'
                  height='784'
                  fill='url(#ad9a0a02-b58e-4a1d-8c36-1b649889af63)'
                />
              </svg>
              <svg
                className='absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4'
                width='404'
                height='784'
                fill='none'
                viewBox='0 0 404 784'
              >
                <defs>
                  <pattern
                    id='d2a68204-c383-44b1-b99f-42ccff4e5365'
                    x='0'
                    y='0'
                    width='20'
                    height='20'
                    patternUnits='userSpaceOnUse'
                  >
                    <rect
                      x='0'
                      y='0'
                      width='4'
                      height='4'
                      className='text-gray-200'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  width='404'
                  height='784'
                  fill='url(#478e97d6-90df-4a89-8d63-30fdbb3c7e57)'
                />
              </svg>
            </div>
          </div>

          <div className='relative pt-6 pb-12 lg:pb-20'>
            <div className='mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20'>
              <div className='text-center'>
                <h2 className='text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
                  Government run by
                  <br />
                  <span className='text-justiceBlue-600'>the people</span>
                </h2>
                <p className='mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
                  A platform for political engagement that puts the people
                  first.
                </p>
              </div>
            </div>
          </div>
          <div className='relative'>
            <div className='absolute inset-0 flex flex-col'>
              <div className='flex-1'></div>
              <div className='flex-1 w-full bg-justiceBlue-600'></div>
            </div>
            <div className='max-w-screen-xl mx-auto px-4 sm:px-6'>
              <img
                className='relative rounded-lg shadow-lg'
                src={stateScreenshot}
                alt='App screenshot'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-justiceBlue-600'>
        <div className='max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20'>
          <div className='max-w-4xl mx-auto text-center'>
            <h2 className='text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10'>
              Effective activism and organizing:
            </h2>
            {/* <p className="mt-3 text-xl leading-7 text-gray-100 sm:mt-4">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.
                    </p> */}
          </div>
          <dl className='mt-10 text-center sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-1'>
            <div className='flex flex-col'>
              <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-100'>
                for legislators
              </dt>
              <dd className='order-1 text-5xl leading-none font-extrabold text-white'>
                Accountability
              </dd>
            </div>
            <div className='flex flex-col mt-10 sm:mt-0'>
              <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-100'>
                centered organizing
              </dt>
              <dd className='order-1 text-5xl leading-none font-extrabold text-white'>
                Community
              </dd>
            </div>
            <div className='flex flex-col mt-10 sm:mt-0'>
              <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-100'>
                in communication
              </dt>
              <dd className='order-1 text-5xl leading-none font-extrabold text-white'>
                Transparency
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className='py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-gray-100 overflow-hidden'>
        <div className='max-w-max-content lg:max-w-7xl mx-auto'>
          <div className='relative z-10 mb-8 md:mb-2 md:px-6'>
            <div className='text-base max-w-prose lg:max-w-none'>
              <p className='leading-6 text-justiceBlue-500 font-semibold tracking-wide uppercase'>
                Why GrassrootsGov?
              </p>
              <h1 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
                Current digital platforms for activism are failing us.
              </h1>
            </div>
          </div>
          <div className='relative'>
            {/* <svg className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                    <defs>
                        <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
                    </svg>
                    <svg className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                    <defs>
                        <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
                    </svg> */}
            <div className='relative md:bg-gray-100 md:p-6'>
              <div className='lg:grid lg:grid-cols-2 lg:gap-6'>
                <div className='prose prose-lg text-gray-600 mb-6 lg:max-w-none lg:mb-0'>
                  <p>
                    Your elected officials should serve you, not the other way
                    around. But our current systems of political engagement are
                    obviously broken: why is it that politicians still feel
                    comfortable breaking their promises after elections and
                    everyday people still feel as if their voices aren't being
                    heard?
                  </p>
                  <p>
                    Existing outlets for digital activism and engagement aren't
                    helping, either. There's always a crucial element or two
                    missing from their design.
                  </p>
                  <ol>
                    <li>
                      Calling & emailing lawmakers by yourself lacks the
                      effectiveness of strength in numbers. (Who knows if you'll
                      get a reply?)
                    </li>
                    <li>
                      Petitions lack transparency and actual methods of
                      accountability.
                    </li>
                    <li>
                      Social media is disorganized and often just leads to flame
                      wars.
                    </li>
                    <li>
                      None of these methods preserve the feeling of in-person
                      community and teamwork that is so necessary for actually
                      getting things done.
                    </li>
                  </ol>
                  <p>
                    Through GrassrootsGov, we believe a better way is possible.
                  </p>
                </div>
                <div className='prose prose-lg text-gray-600'>
                  <p>
                    GrassrootsGov is a one-stop platform for modern, effective
                    activism, organizing, and political engagement. We want to
                    provide a way for everyday people to voice their concerns,
                    rally together, hold their elected officials accountable,
                    and create movements that lead to real change.
                  </p>
                  <p>
                    We've built this platform with three key principles in mind:
                  </p>
                  <p>
                    <span className='font-bold'>Accountability: </span>
                    GrassrootsGov ties constituent concerns directly to specific
                    legislators, bills, and votes to ensure that the public's
                    demands are clear and actionable. Now everyone can see if
                    progress is actually being made, and legislators have no
                    more excuses.
                  </p>
                  <p>
                    <span className='font-bold'>Community: </span>GrassrootsGov
                    gets people involved in where it matters most—their own
                    local communities. By breaking issues and events down by
                    district and state, GrassrootsGov enables regional
                    communities to cut through the chaos while still linking
                    everything together nationally.
                  </p>
                  <p>
                    <span className='font-bold'>Transparency: </span>
                    GrassrootsGov ensures that communication between
                    constituents and legislators is transparent every step of
                    the way. Through regionally-focused issues, supporter counts
                    are broken down by district and state, and legislators'
                    actions (and inaction) are viewable by all.
                  </p>
                </div>
              </div>
              {/* <div className="inline-flex rounded-md shadow">
                        <a href="#" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out">
                        Contact sales
                        </a>
                    </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='bg-white'>
        <div className='max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8'>
          <div>
            <h2 className='text-base leading-6 font-semibold text-justiceBlue-500 uppercase tracking-wide'>
              How is GrassrootsGov better?
            </h2>
            <p className='mt-2 text-3xl leading-9 font-extrabold text-gray-900'>
              A platform for the people
            </p>
            <p className='mt-4 text-lg leading-7 text-gray-500'>
              Designed for the needs of everyday people wanting to make a
              difference in America.
            </p>
          </div>
          <div className='mt-12 lg:mt-0 lg:col-span-2'>
            <dl className='space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8'>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Voice your opinions
                  </dt>
                  <dd className='flex space-x-3 lg:py-0 lg:pb-4'>
                    <span className='text-base leading-6 text-gray-500'>
                      Give direct feedback to your representatives and senators
                      by posting & supporting issues in your district and state.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Team up with others in your region
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      Utilize the power of strength in numbers to push for
                      change and hold your legislators accountable.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Get involved in your community
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      Find events and organizations in your region and engage
                      with initiatives in your community.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Grow national support
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      See your efforts multiply as your local and statewide
                      movements grow nationally.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Track your legislators
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      Keep track of what your elected officials are actually
                      doing in Washington every day.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Stay in the loop on congressional activity
                  </dt>
                  <dd className='flex space-x-3 lg:border-t-0 lg:py-0 lg:pb-4'>
                    <span className='text-base leading-6 text-gray-500'>
                      Stay updated on (and create issues related to) bills and
                      votes in Congress.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    No flame wars
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      Your privacy and safety are protected—no angry DMs or
                      messy comment threads.
                    </span>
                  </dd>
                </div>
              </div>
              <div className='flex space-x-3'>
                {/* <!-- Heroicon name: check --> */}
                <svg
                  className='flex-shrink-0 h-6 w-6 text-green-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    stroke-linecap='round'
                    strokeLinejoin='round'
                    stroke-width='2'
                    d='M5 13l4 4L19 7'
                  />
                </svg>
                <div className='space-y-2'>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    Go beyond online activism
                  </dt>
                  <dd className='flex space-x-3'>
                    <span className='text-base leading-6 text-gray-500'>
                      See an issue that you want to get more involved in?
                      Discover additional ways to help out offline.
                    </span>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class='bg-blue-50'>
        <div class='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8'>
          <div class='max-w-3xl mx-auto text-center'>
            <h2 class='text-3xl font-extrabold text-gray-900'>
              For organizations too
            </h2>
            <p class='mt-4 text-lg text-gray-500'>
              Whether you're a local or national organization, GrassrootsGov
              boosts your reach and impact.
            </p>
          </div>
          <dl class='mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8'>
            <div class='flex'>
              {/* <!-- Heroicon name: check --> */}
              <svg
                class='flex-shrink-0 h-6 w-6 text-green-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  strokeLinejoin='round'
                  stroke-width='2'
                  d='M5 13l4 4L19 7'
                />
              </svg>
              <div class='ml-3'>
                <dt class='text-lg leading-6 font-medium text-gray-900'>
                  Recruit members
                </dt>
                <dd class='mt-2 text-base text-gray-500'>
                  Reach people in your target regions by posting events &
                  boosting issues.
                </dd>
              </div>
            </div>

            <div class='flex'>
              {/* <!-- Heroicon name: check --> */}
              <svg
                class='flex-shrink-0 h-6 w-6 text-green-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  strokeLinejoin='round'
                  stroke-width='2'
                  d='M5 13l4 4L19 7'
                />
              </svg>
              <div class='ml-3'>
                <dt class='text-lg leading-6 font-medium text-gray-900'>
                  Post events
                </dt>
                <dd class='mt-2 text-base text-gray-500'>
                  Collect user signups, send reminder emails, and reshare events
                  from other organizations.
                </dd>
              </div>
            </div>

            <div class='flex'>
              {/* <!-- Heroicon name: check --> */}
              <svg
                class='flex-shrink-0 h-6 w-6 text-green-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  strokeLinejoin='round'
                  stroke-width='2'
                  d='M5 13l4 4L19 7'
                />
              </svg>
              <div class='ml-3'>
                <dt class='text-lg leading-6 font-medium text-gray-900'>
                  Engage supporters
                </dt>
                <dd class='mt-2 text-base text-gray-500'>
                  Post action items, share resources, and view follower
                  analytics.
                </dd>
              </div>
            </div>

            <div class='flex'>
              {/* <!-- Heroicon name: check --> */}
              <svg
                class='flex-shrink-0 h-6 w-6 text-green-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  strokeLinejoin='round'
                  stroke-width='2'
                  d='M5 13l4 4L19 7'
                />
              </svg>
              <div class='ml-3'>
                <dt class='text-lg leading-6 font-medium text-gray-900'>
                  100% free
                </dt>
                <dd class='mt-2 text-base text-gray-500'>
                  Get all these features and more without having to pay a thing.
                  You can't beat that!
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>

      <div className='bg-justiceBlue-600'>
        <div className='max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8'>
          <h2 className='text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10'>
            <span className='block'>Ready to make your voice heard?</span>
          </h2>
          <p className='mt-4 mb-6 text-lg leading-6 text-gray-50'>
            Try out the beta version now!
          </p>
          <Link
            // to='/join-waitlist'
            to="/signup"
            className='w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-500 bg-white shadow-sm hover:text-white hover:bg-red-400 focus:outline-none focus:border-red-500 focus:shadow-red-500 active:bg-red-500 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto'
          >
            Sign up
          </Link>
        </div>
      </div>
    </LandingLayout>
  );
};

export default LandingFeaturesPage;
