import React, { useEffect, useState, useContext } from 'react';
import app from "../config/firebase"


/* API based */


async function getUpcomingData(chamber) {

    // retrieve upcoming bill list
    const response = await fetch(`https://api.propublica.org/congress/v1/bills/upcoming/house.json`, {
        "method": "GET",
        "headers": {
        "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
        }
    });
    const content = await response.json();
    let upcomingBills = content.results[0].bills;

    // if upcoming bill list empty
    if(upcomingBills.length == 0){
        return([]);
    }

    // retrieve specific info for each upcoming bill
    let tempData = [];

    for(const bill of upcomingBills) {

        if(chamber === "house") {

            if(bill.bill_type === "s")
                continue;
            const billInfo = await fetch(`https://api.propublica.org/congress/v1/117/bills/${bill.bill_slug}.json`, {
                "method": "GET",
                "headers": {
                    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
                }
            });
            const billContent = await billInfo.json();
            tempData.push(billContent.results[0]);

        } else if(chamber === "senate") {

            if(bill.bill_type === "hr" || bill.bill_type === "hres")
                continue;
            const billInfo = await fetch(`https://api.propublica.org/congress/v1/117/bills/${bill.bill_slug}.json`, {
                "method": "GET",
                "headers": {
                    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
                }
            });
            const billContent = await billInfo.json();
            tempData.push(billContent.results[0]);

        }
    }

    return tempData;

}

async function getIntroducedData(chamber) {

    // fetch call
    const response = await fetch(`https://api.propublica.org/congress/v1/117/${chamber}/bills/introduced.json`, {
      "method": "GET",
      "headers": {
        "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
      }
    });

    // get response data
    const content = await response.json();
    let introducedBills = content.results[0].bills;

    // update state
    return introducedBills;

}

async function getPastData(chamber) {

    // retrieve vetoed & enacted bills in 2 requests
    const responseEnacted = await fetch(`https://api.propublica.org/congress/v1/117/${chamber}/bills/enacted.json`, {
      "method": "GET",
      "headers": {
        "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
      }
    });
    const responseVetoed = await fetch(`https://api.propublica.org/congress/v1/117/${chamber}/bills/vetoed.json`, {
      "method": "GET",
      "headers": {
        "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
      }
    });

    // save each content into respective arrays
    const contentEnacted = await responseEnacted.json();
    let enactedBills = contentEnacted.results[0].bills;
    const contentVetoed = await responseVetoed.json();
    let vetoedBills = contentVetoed.results[0].bills;

    // update state
    return enactedBills.concat(vetoedBills);

}

async function getVotesData(chamber) {

    // retrieve upcoming vote list
    const response = await fetch(`https://api.propublica.org/congress/v1/${chamber}/votes/recent.json`, {
      "method": "GET",
      "headers": {
      "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa"
      }
    });
    const content = await response.json();
    let votes = content.results.votes;

    // if recent vote list empty
    if(votes.length == 0){
      return([]);
    }

    return votes;

}


export const BillVoteAPIContext = React.createContext(null);

export const BillVoteAPIProvider = (props) => {

    const [houseUpcoming, setHouseUpcoming] = useState(null);
    const [houseIntroduced, setHouseIntroduced] = useState(null);
    const [housePast, setHousePast] = useState(null);
    const [senateUpcoming, setSenateUpcoming] = useState(null);
    const [senateIntroduced, setSenateIntroduced] = useState(null);
    const [senatePast, setSenatePast] = useState(null);
    const [houseVotes, setHouseVotes] = useState(null);
    const [senateVotes, setSenateVotes] = useState(null);


    useEffect(()=> {

        // getUpcomingData("house").then(setHouseUpcoming);
        // getIntroducedData("house").then(setHouseIntroduced);
        // getPastData("house").then(setHousePast);

        // getUpcomingData("senate").then(setSenateUpcoming);
        // getIntroducedData("senate").then(setSenateIntroduced);
        // getPastData("senate").then(setSenatePast);

        // getVotesData("house").then(setHouseVotes);
        // getVotesData("senate").then(setSenateVotes);

    }, []);

    return(
        <BillVoteAPIContext.Provider value={[houseUpcoming, houseIntroduced, housePast, senateUpcoming, senateIntroduced, senatePast, houseVotes, senateVotes]}>
            {props.children}
        </BillVoteAPIContext.Provider>
    );

}





/* DB-based  */


const dbRef = app.firestore();


async function getUpcomingDataDB(chamber) {

    if (dbRef) {

        const billsRef = dbRef.collection("bills").where("upcoming", "==", true).where("chamber", '==', `${chamber}`).limit(20);
        const snapshot = await billsRef.get();

        let bills = [];

        if (!snapshot.empty) {
            snapshot.forEach((doc) => { bills.push(doc); });
        } else {
          console.log("empty snapshot");
        }

        return bills;

    }

}

async function getIntroducedDataDB(chamber) {

    if(dbRef) {

        const billsRef = dbRef.collection('bills').where("billStatus", "==", "Introduced").where("chamber", "==", `${chamber}`).orderBy("dateIntroducedUTC", "desc").limit(20);
        const snapshot = await billsRef.get();

        let bills = [];

        if(!snapshot.empty) {
            snapshot.forEach((doc) => { bills.push(doc); });
        } else {
            console.log('empty snapshot');
        }

        return bills;

    }
}

async function getPastDataDB(chamber) {

    if(dbRef) {

        const billsRef = dbRef.collection('bills').where('billStatus', "==", "Past").where("chamber", "==", `${chamber}`).orderBy("dateIntroducedUTC", "desc").limit(20);
        const snapshot = await billsRef.get();

        let bills = [];

        if(!snapshot.empty) {
            snapshot.forEach((doc) => { bills.push(doc); });
        } else {
            console.log('empty snapshot');
        }

        return bills;

    }
}

async function getVotesDataDB(chamber) {

    if(dbRef) {

        const votesRef = dbRef.collection('votes').where("chamber", "==", `${chamber}`).orderBy("dateUTC", "desc").limit(20);
        const snapshot = await votesRef.get();

        let votes = [];

        if(!snapshot.empty) {
            snapshot.forEach((doc) => { votes.push(doc); });
        } else {
            console.log('empty snapshot');
        }

        return votes;

    }
}


export const BillVoteDBContext = React.createContext(null);

export const BillVoteDBProvider = (props) => {


    const [houseUpcoming, setHouseUpcoming] = useState(null);
    const [houseIntroduced, setHouseIntroduced] = useState(null);
    const [housePast, setHousePast] = useState(null);

    const [senateUpcoming, setSenateUpcoming] = useState(null);
    const [senateIntroduced, setSenateIntroduced] = useState(null);
    const [senatePast, setSenatePast] = useState(null);

    const [houseVotes, setHouseVotes] = useState(null);
    const [senateVotes, setSenateVotes] = useState(null);


    useEffect(() => {

        getUpcomingDataDB("House").then(setHouseUpcoming);
        getIntroducedDataDB("House").then(setHouseIntroduced);
        getPastDataDB("House").then(setHousePast);

        getUpcomingDataDB("Senate").then(setSenateUpcoming);
        getIntroducedDataDB("Senate").then(setSenateIntroduced);
        getPastDataDB("Senate").then(setSenatePast);

        getVotesDataDB("House").then(setHouseVotes);
        getVotesDataDB("Senate").then(setSenateVotes);

    }, []);


    return(
        <BillVoteDBContext.Provider value={[houseUpcoming, houseIntroduced, housePast, senateUpcoming, senateIntroduced, senatePast, houseVotes, senateVotes]}>
            {props.children}
        </BillVoteDBContext.Provider>
    );


}


