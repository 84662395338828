import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LandingLayout from '../../layouts/LandingLayout';
import emailjs from 'emailjs-com';

const LandingContactPage = () => {
  const [contact, setContact] = useState({
    from_name: '',
    reply_to: '',
    message: '',
  });
  const [success, setSuccess] = useState('');
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    console.log('handle submit called');
    // e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    e.preventDefault(); //prevents form from refreshing

    setSuccess('');
    setErr('');
    setLoading(true);

    emailjs
      .sendForm(
        'service_fr9w77i',
        'template_im7xmn9',
        e.target,
        'user_tUMfEDgwKYJjJzesqdQOE'
      )
      .then(
        (result) => {
          setSuccess('Successfully sent message! Expect to hear back soon.');
        },
        (error) => {
          setErr(error);
        }
      );
    console.log(e.target);
    setContact({
      from_name: '',
      reply_to: '',
      message: '',
    });
    e.target.reset();
    setLoading(false);
  };

  function handleChange(e) {
    setContact({ ...contact, [e.target.name]: e.target.value });
  }
  return (
    <LandingLayout>
      <div className='bg-white border'>
        <div className='max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <h1 className='text-base leading-6 font-semibold text-justiceBlue-500 tracking-wide uppercase'>
              Questions?
            </h1>
            <p className='mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl'>
              Contact & FAQs
            </p>
            {/* <p className="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">Contact & FAQs.</p> */}
          </div>
        </div>
      </div>

      <div className='relative bg-white'>
        <div className='absolute inset-0'>
          <div className='absolute inset-y-0 left-0 w-1/2 bg-gray-50'></div>
        </div>
        <div className='relative max-w-7xl mx-auto lg:grid lg:grid-cols-5'>
          <div className='bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
            <div className='max-w-lg mx-auto'>
              <h2 className='text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9'>
                Contact Us
              </h2>

              <p className='mt-8 text-lg leading-6 text-gray-500'>
                Interested in supporting us, partnering with us, or learning
                more? Email us at:
              </p>
              {/* <p className="mt-3 text-lg leading-6 text-gray-500">
                You can email us at:
              </p> */}
              <dl className='mt-4 text-base leading-6 text-gray-500'>
                <div className='mt-3'>
                  <dt className='sr-only'>Email</dt>
                  <dd className='flex'>
                    {/* <!-- Heroicon name: mail --> */}
                    <svg
                      className='flex-shrink-0 h-6 w-6 text-gray-400'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        stroke-linecap='round'
                        strokeLinejoin='round'
                        stroke-width='2'
                        d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                      />
                    </svg>
                    <span className='ml-3'>
                      <a href='mailto:contact@grassrootsgov.org'>
                        contact@grassrootsgov.org
                      </a>
                    </span>
                  </dd>
                </div>
              </dl>

              <div className='mt-8 flex space-x-2'>
                <p className='text-lg leading-6 font-medium text-gray-500'>
                  Or, you can leave us a message here
                </p>
                <svg
                  className='flex-shrink-0 h-6 w-6 text-gray-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M17 8l4 4m0 0l-4 4m4-4H3'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
            <div className='max-w-lg mx-auto lg:max-w-none'>
              {err && (
                <>
                  <div
                    className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                    role='alert'
                  >
                    <strong className='font-bold'>Holy smokes! </strong>
                    <span className='block sm:inline'>{err}</span>
                  </div>
                  <br />
                </>
              )}
              {success && (
                <>
                  <div
                    className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative'
                    role='alert'
                  >
                    <span className='block sm:inline'>{success}</span>
                  </div>
                  <br />
                </>
              )}
              <form
                onSubmit={handleSubmit}
                // action="#"
                // method="POST"
                className='grid grid-cols-1 gap-y-6'
              >
                <div>
                  <label for='from_name' class='sr-only'>
                    Full name
                  </label>
                  <input
                    required
                    type='text'
                    name='from_name'
                    // id="from_name"
                    value={contact.from_name}
                    onChange={handleChange}
                    // autocomplete="name"
                    class='block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md'
                    placeholder='Full name'
                  />
                </div>
                <div>
                  <label for='reply_to' class='sr-only'>
                    Email
                  </label>
                  <input
                    required
                    // id="reply_to"
                    name='reply_to'
                    type='email'
                    value={contact.reply_to}
                    onChange={handleChange}
                    // autocomplete="email"
                    class='block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md'
                    placeholder='Email'
                  />
                </div>
                <div>
                  <label for='message' class='sr-only'>
                    Message
                  </label>
                  <textarea
                    required
                    // id="message"
                    name='message'
                    value={contact.message}
                    onChange={handleChange}
                    rows='4'
                    class='block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md'
                    placeholder='Message'
                  ></textarea>
                </div>
                <div>
                  <button
                    disabled={loading}
                    type='submit'
                    class='inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-justiceBlue-600'>
        <div className='max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
          <h2 className='text-3xl leading-9 font-extrabold text-white'>
            Frequently asked questions
          </h2>
          <div className='mt-6 border-t border-gray-100 border-opacity-25 pt-10'>
            <dl className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12'>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  Who is GrassrootsGov for?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  Civilians, legislators, campaigns, and activist organizations. If you have any questions about how GrassrootsGov could be useful to you, feel free to contact us!
                </dd>
              </div>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  Who are the creators of GrassrootsGov?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  We're a team of college and high school students passionate
                  about activism and technology. You can learn more about us{' '}
                  <Link to='/about' className='underline'>
                    here
                  </Link>
                  .
                </dd>
              </div>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  When is GrassrootsGov launching?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  We have now released our beta version! Click{' '}
                  <Link to='/signup' className='underline'>
                    here
                  </Link>{' '}
                  to create an account.
                </dd>
              </div>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  Do I have to pay anything?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  Not a cent—the site is completely free, and you'll never see a
                  single ad.
                </dd>
              </div>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  How can I use the site?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  Check out our help docs for guides and tutorials! We have versions for{' '}
                  <Link to='/help/civilian' className='underline'>civilians</Link>{' '}and{' '}
                  <Link to='/help/organization' className='underline'>organizations</Link>.
                </dd>
              </div>
              <div className='space-y-2'>
                <dt className='text-lg leading-6 font-medium text-white'>
                  How can I support GrassrootsGov?
                </dt>
                <dd className='text-base leading-6 text-gray-100'>
                  Spread the word to as many people (friends, family, coworkers,
                  neighbors) as possible! We will also be accepting donations
                  soon. :)
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className='bg-gradient-to-b from-white to-blue-50'>
        <div className='max-w-7xl pt-20 mx-auto px-4 sm:px-6 lg:px-8'>
          <h1 className='text-2xl font-bold text-justiceBlue-600 sm:text-2xl sm:tracking-tight lg:text-3xl'>
            Interested in joining the team?
          </h1>
        </div>

        <div class='max-w-7xl mx-auto grid grid-cols-2 gap-4 pt-10 pb-20 px-4 sm:px-6 lg:px-8'>
          <div class='max-w-2xl mx-auto'>
            <div class='bg-justiceBlue-500 rounded-lg shadow-xl overflow-hidden'>
              <div class='pt-10 pb-12 px-2 sm:px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-16'>
                <div class='text-center lg:self-center'>
                  <h2 class='text-xl font-extrabold text-white sm:text-4xl'>
                    {/* <span class="block">Ready to dive in?</span> */}
                    <span class='block'>
                      Join our <br /> executive team!
                    </span>
                  </h2>
                  <p class='mt-4 text-md sm:text-lg leading-6 text-gray-100'>
                    We're looking for web developers, product managers,
                    marketing specialists and more to volunteer with us.
                  </p>
                  <a
                    href='https://forms.gle/QzzYu452oExY4uSKA'
                    class='mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-red-500 hover:bg-gray-50'
                  >
                    Apply now
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class='max-w-2xl mx-auto'>
            <div class='bg-justiceBlue-500 rounded-lg shadow-xl overflow-hidden'>
              <div class='pt-10 pb-12 px-2 sm:px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-16'>
                <div class='text-center lg:self-center'>
                  <h2 class='text-xl font-extrabold text-white sm:text-4xl'>
                    {/* <span class="block">Ready to dive in?</span> */}
                    <span class='block'>Become a campus ambassador!</span>
                  </h2>
                  <p class='mt-4 text-md sm:text-lg leading-6 text-gray-100'>
                    Want to spread the word at your school? <br /> Become a
                    campus ambassador today.
                  </p>
                  <a
                    href='https://forms.gle/ADivv7xdg4wWu32F6'
                    class='mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-red-500 hover:bg-gray-50'
                  >
                    Apply now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default LandingContactPage;
