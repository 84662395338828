import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "../config/firebase";

const AuthContext = React.createContext();

// hook to use the auth
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currUserAdminInfo, setCurrUserAdminInfo] = useState();
  const [currUser, setCurrUser] = useState();
  const [currUserRef, setCurrUserRef] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true); // by default we load first

  function signup(email, password) {
    // returns a promise to use with other parts of the app that needs signup
    return auth.createUserWithEmailAndPassword(email, password);
  }
  function login(email, password) {
    // returns a promise to use with other parts of the app that needs login
    return auth.signInWithEmailAndPassword(email, password);
  }
  function logout() {
    // returns a promise to use with other parts of the app that needs sign out
    return auth.signOut();
  }
  function resetPassword(email) {
    // returns a promise to use with other parts of the app that needs sign out
    return auth.sendPasswordResetEmail(email);
  }

  // we only want this to run once, hence useEffect
  useEffect(() => {
    // firebase tells us that user has changed
    // onAuthStateChanged returns a method that can be called to unsubscribe onAuthChanged event
    const unsubscribe = auth.onAuthStateChanged((user) => {

      let tempUserType = "";

      if (user) {
        //user is signed in
        setCurrUser(user);
        console.log("user:", user)
        console.log("user id:", user.uid)
        db.collection("users").doc(user.uid).get().then((res) => {
          tempUserType = res.data().type
          setCurrUserAdminInfo(res.data())
          console.log("ADMIN INFO:", res.data())
          console.log("tempUserType:", tempUserType)
          if (tempUserType === "civilian"){
            setCurrUserRef(db.collection("civilians").doc(user.uid));
            db.collection("civilians").doc(user.uid).get().then((res)=> {
              setUserData(res.data());
              setLoading(false);
              console.log(user.uid + " civilian successfully logged in");
            })
          }
          else if (tempUserType === "organization"){
            setCurrUserRef(db.collection("organizations").doc(user.uid));
            db.collection("organizations").doc(user.uid).get().then((res)=> {
              setUserData(res.data());
              setLoading(false);
              console.log(user.uid + " organization successfully logged in");
            })
          }
          else if (tempUserType === "legislator"){
            setCurrUserRef(db.collection("legislators").doc(user.uid));
            db.collection("legislators").doc(user.uid).get().then((res)=> {
              setUserData(res.data());
              setLoading(false);
              console.log(user.uid + " legislator successfully logged in");
            })
          }
          else if (tempUserType === "admin"){
            setCurrUserRef(db.collection("admins").doc(user.uid));
            db.collection("admins").doc(user.uid).get().then((res)=> {
              setUserData(res.data());
              setLoading(false);
              console.log(user.uid + " admin successfully logged in");
            })
          }
          else {
            console.log("this user does not have a type!")
          }
        });
      } else {
        console.log("no user is signed in");
        setCurrUser(undefined);
        setCurrUserRef(undefined);
        setUserData(undefined);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    // we store the current user to use everywhere in the app; we can add more contexts if needed
    currUserAdminInfo,
    currUser,
    currUserRef,
    userData,
    signup,
    login,
    logout,
    resetPassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading ? (
        children
      ) : (
        <div className="w-screen h-screen flex items-center justify-center">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
        </div>
      )}
    </AuthContext.Provider>
  );
}