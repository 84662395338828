import React from "react";
import { Link, NavLink } from "react-router-dom";

const EventsNav = () => {

  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">My Events</h1>
      </header>
      <div className="mb-px py-4 mx-auto sm:px-8 lg:px-16 flex justify-between items-center">

        <div className="flex space-x-8">
          <NavLink
            to="/org/events/upcoming"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            exact
            activeClassName="text-gray-900"
          >
            Upcoming
          </NavLink>
          <NavLink
            to="/org/events/past"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Past
          </NavLink>
        </div>

        <div>
          <div className="ml-4 flex space-x-4 items-end">
            {/* <div>
              <label for="location" class="block text-sm font-medium text-justiceBlue-500">
                Sort By
              </label>
              <select
                id="location"
                name="location"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
              >
                <option selected>Date Posted</option>
                <option>Popularity</option>
                <option>Etc</option>
              </select>
            </div> */}
            <span className="rounded-md shadow-sm">
              <Link
                to="/org/events/create"
                className="btn-primary"
              >
                + Create New Event
              </Link>
            </span>
          </div>
        </div>
      </div>
      <hr className="border-solid" />
    </>
  );
};

export default EventsNav;
