// import React, { useState } from 'react';
// import {Link, NavLink, useHistory} from "react-router-dom";
// import jd from '../../../../assets/jd.png'

// const formatPostDate = (timestamp, isVirtual) => {
//   const daysOfWeek = [
//     'Sunday',
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday',
//   ];
//   if (timestamp) {
//     let date = new Date(timestamp.seconds * 1000);
//     let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
//     let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
//     yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
//     uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
//     let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

//     let isAm = true;
//     let hours = timestamp.toDate().getHours();
//     if (hours >= 12) {
//       isAm = false;
//       if (hours !== 12) {
//         hours = hours - 12;
//       }
//     } else if (hours === 0) {
//       hours = 12;
//     }

//     let minutes;
//     if (timestamp.toDate().getMinutes() !== 0) {
//       if (timestamp.toDate().getMinutes() < 10) {
//         minutes = ':0' + timestamp.toDate().getMinutes();
//       }
//       else{
//         minutes = ':' + timestamp.toDate().getMinutes();
//       }
//     } else {
//       minutes = ':00';
//     }
//     const numOfDayOfWeek = timestamp.toDate().getDay();

//     let amOrPm = 'pm';
//     if (isAm) {
//       amOrPm = 'am';
//     }
//     const fullString =
//       daysOfWeek[numOfDayOfWeek] +
//       ' ' +
//       f_dateString +
//       ' at ' +
//       hours +
//       minutes +
//       amOrPm ;

//     return fullString;
//   } else {
//     // if there isn't a date associated with the issue
//     return '01/01/1979';
//   }
// };

// const formatEventDate = (timestamp) => {
//   const daysOfWeek = [
//     'Sunday',
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday',
//   ];
//   if (timestamp) {
//     let date = new Date(timestamp.seconds * 1000);
//     let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
//     let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
//     yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
//     uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
//     let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

//     let isAm = true;
//     let hours = timestamp.toDate().getHours();
//     if (hours >= 12) {
//       isAm = false;
//       if (hours !== 12) {
//         hours = hours - 12;
//       }
//     } else if (hours === 0) {
//       hours = 12;
//     }

//     let minutes;
//     if (timestamp.toDate().getMinutes() !== 0) {
//       minutes = ':' + timestamp.toDate().getMinutes();
//     } else {
//       minutes = ':00';
//     }
//     const numOfDayOfWeek = timestamp.toDate().getDay();

//     // let virtualString = 'In person';
//     // if (isVirtual) {
//     //   virtualString = 'Virtual';
//     // }

//     let amOrPm = 'pm';
//     if (isAm) {
//       amOrPm = 'am';
//     }
//     const fullString =
//       daysOfWeek[numOfDayOfWeek] +
//       ' ' +
//       f_dateString +
//       ' @ ' +
//       hours +
//       minutes +
//       amOrPm ;
//       // ' • '
//       // virtualString; // format from notion

//     return fullString;
//   } else {
//     // if there isn't a date associated with the issue
//     return '01/01/1979';
//   }
// };

// const OrganizationPost = (props) => {
//   return (
//     <div className="bg-gray-100 px-8 py-6 mb-8 rounded-lg shadow-sm">

//       <div className="flex justify-between items-center mb-4">
//         <div className="flex space-x-4 items-center">
//           <img className="rounded-lg h-14 w-14 border-2 border-gray-100" src={jd}/>
//           <div>
//             <Link
//               to={`/organizations/${props.orgId}/latest`}
//               className="text-xl font-semibold hover:underline">{props.orgName}</Link>
//             <h1 className="text-xs mt-1">{formatPostDate(props.datePosted)}</h1>
//           </div>
//         </div>
//         <div className="cursor-default bg-yellow-500 px-4 py-2 rounded-lg text-white font-semibold">
//           <h1>Event</h1>
//         </div>
//       </div>

//       <p className="mb-4">{props.postBody}</p>

//       <Link to={`/events/${props.eventId}`}>
//         <div className="bg-white shadow hover:shadow-md rounded-lg px-8 py-6">
//           <div className="flex items-center justify-between">
//             <div className="text-xl font-semibold text-gray-900 truncate">
//               {props.eventTitle}
//             </div>
//             <span className="text-xl font-semibold text-gray-800">
//               {props.attendingCount} attending • {props.interestedCount} interested
//             </span>
//           </div>
//           <div className="mt-4 sm:flex sm:justify-between">
//             <div className="sm:flex">
//               <div className='mr-6 flex flex-row items-center justify-center'>
//                 <img
//                   className='rounded-med h-10 w-10 border-2 border-gray-100'
//                   src={jd}
//                 />
//                 <h3 className='text-gray-700 text-medium ml-3'>{props.eventOrgName}</h3>
//               </div>
//               <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
//                 {/* <!-- Heroicon name: location-marker --> */}
//                 <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
//                 </svg>
//                   {formatEventDate(props.eventDateTime)}
//               </div>
//               <div className="mt-2 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
//                 {/* <!-- Heroicon name: location-marker --> */}
//                 <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
//                   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
//                 </svg>
//                 {props.eventIsVirtual ? "Virtual Event" : `${props.eventCity}, ${props.EventState}`}
//               </div>
//             </div>
//             <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
//               <span>
//                 View event
//               </span>
//               <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
//               </svg>
//             </div>
//           </div>
//         </div>
//       </Link>


//     </div>
//   )
// }

// export default OrganizationPost;

import React, { useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
// import jd from '../../../../assets/jd.png'
import { Transition } from "@headlessui/react";
import { db } from "../../../../config/firebase"

const formatPostDate = (timestamp, isVirtual) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

    let isAm = true;
    let hours = timestamp.toDate().getHours();
    if (hours >= 12) {
      isAm = false;
      if (hours !== 12) {
        hours = hours - 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    let minutes;
    if (timestamp.toDate().getMinutes() !== 0) {
      if (timestamp.toDate().getMinutes() < 10) {
        minutes = ':0' + timestamp.toDate().getMinutes();
      }
      else{
        minutes = ':' + timestamp.toDate().getMinutes();
      }
    } else {
      minutes = ':00';
    }
    const numOfDayOfWeek = timestamp.toDate().getDay();

    let amOrPm = 'pm';
    if (isAm) {
      amOrPm = 'am';
    }
    const fullString =
      daysOfWeek[numOfDayOfWeek] +
      ' ' +
      f_dateString +
      ' at ' +
      hours +
      minutes +
      amOrPm ;

    return fullString;
  } else {
    // if there isn't a date associated with the issue
    return '01/01/1979';
  }
};

const formatEventDate = (timestamp) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

    let isAm = true;
    let hours = timestamp.toDate().getHours();
    if (hours >= 12) {
      isAm = false;
      if (hours !== 12) {
        hours = hours - 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    let minutes;
    if (timestamp.toDate().getMinutes() !== 0) {
      if (timestamp.toDate().getMinutes() < 10) {
        minutes = ':0' + timestamp.toDate().getMinutes();
      }
      else{
        minutes = ':' + timestamp.toDate().getMinutes();
      }
    } else {
      minutes = ':00';
    }
    const numOfDayOfWeek = timestamp.toDate().getDay();

    // let virtualString = 'In person';
    // if (isVirtual) {
    //   virtualString = 'Virtual';
    // }

    let amOrPm = 'pm';
    if (isAm) {
      amOrPm = 'am';
    }
    const fullString =
      daysOfWeek[numOfDayOfWeek] +
      ' ' +
      f_dateString +
      ' @ ' +
      hours +
      minutes +
      amOrPm ;
      // ' • '
      // virtualString; // format from notion

    return fullString;
  } else {
    // if there isn't a date associated with the issue
    return '01/01/1979';
  }
};

const OrganizationPost = (props) => {

  const [optionsOpen, setOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  }


  return (
    <div className="bg-gray-100 px-8 py-6 mb-8 rounded-lg shadow-sm">

      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4 items-center">
          <img className="rounded-lg h-14 w-14 border-2 border-gray-100" src={props.profilePicUrl}/>
          <div>
            <Link
              to={`/organizations/${props.orgId}/latest`}
              className="text-xl font-semibold hover:underline">{props.orgName}</Link>
            <h1 className="text-xs mt-1">{formatPostDate(props.datePosted)}</h1>
          </div>
        </div>
        <div className="flex space-x-4 items-center">

          <div className="cursor-default bg-yellow-500 px-4 py-2 rounded-lg text-white font-semibold">
            <h1>Event</h1>
          </div>
          <div class="relative inline-block text-left">
            <div>
              <button onClick={toggleOptions} type="button" class="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="options-menu" aria-expanded="true" aria-haspopup="true">
                <span class="sr-only">Open options</span>
                {/* <!-- Heroicon name: solid/dots-vertical --> */}
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
            </div>
            <Transition
            show={optionsOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
              <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="py-1" role="none">
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Report content</a>
                </div>
              </div>
            </Transition>
          </div>

        </div>
      </div>

      <p className="mb-4">{props.postBody}</p>

      <Link to={`/events/${props.eventId}`}>
        <div className="bg-white shadow hover:shadow-md rounded-lg px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="text-xl font-semibold text-gray-900 truncate">
              {props.eventTitle}
            </div>
            <span className="text-xl font-semibold text-gray-800">
              {props.attendingCount} attending • {props.interestedCount} interested
            </span>
          </div>
          <div className="mt-4 sm:flex sm:justify-between">
            <div className="sm:flex">
              <div className='mr-6 flex flex-row items-center justify-center'>
                {/* <img
                  className='rounded-med h-10 w-10 border-2 border-gray-100'
                  src={jd}
                /> */}
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                <h3 className='text-gray-500 text-medium ml-3'>{props.eventOrgName}</h3>
              </div>
              <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                  {formatEventDate(props.eventDateTime)}
              </div>
              <div className="mt-2 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                {props.eventIsVirtual ? "Virtual Event" : `${props.eventCity}, ${props.EventState}`}
              </div>
            </div>
            <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
              <span>
                View event
              </span>
              <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </div>
        </div>
      </Link>


    </div>
  )
}

export default OrganizationPost;
