import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout"
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import Select from "react-select";
// import AsyncSelect from "react-select/async";
// import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
// import { index } from "../../../config/algolia";
import { states_districts, states_hash } from "../../../assets/states"
import DatePicker from "react-datepicker";
import "../../../styles/datepicker.css"

const topicTags = [
  { value: "Climate", label: "Climate" },
  { value: "Criminal Justice", label: "Criminal Justice" },
  { value: "Economy", label: "Economy" },
  { value: "Education", label: "Education" },
  { value: "Foreign Policy", label: "Foreign Policy" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Immigration", label: "Immigration" },
  { value: "Infrastructure", label: "Infrastructure" },
  { value: "LGBTQ+", label: "LGBTQ+" },
  { value: "Reproductive Rights", label: "Reproductive Rights" },
  { value: "Voting/Elections", label: "Voting/Elections" },
];

const CreateEvent = ({ match, history }) => {

  const { currUser, userData } = useAuth();
  const [selectedTags, setSelectedTags] = useState([]);
  const [event, setEvent] = useState({
    title: "",
    type: "",
    timeZone: "",
    hasMultipleTimes: false,
    date: "",
    dateTimes: [],
    isVirtual: false,
    streetAddress: "",
    city: "",
    state: "",
    district: "",
    zipCode: "",
    description: "",
    topicTags: []
  });
  const [firstTime, setFirstTime] = useState({startTime: "", startAMPM: "", endTime: "", endAMPM: ""})
  const [additionalTimeEntries, setAdditionalTimeEntries] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedFile])

  const convertTimeToUTC = (timeZone, dateStamp, timeString, AMPM) => {
    // console.log("datestring:", dateString)
    // let year = parseInt(dateString.split("-")[0])
    // console.log("year:", year)
    // let month = parseInt(dateString.split("-")[1])
    // month -= 1
    // console.log("month:", month)
    // let day = parseInt(dateString.split("-")[2])
    // console.log("day:", day)
    let year = dateStamp.getFullYear()
    let month = dateStamp.getMonth()
    let day = dateStamp.getDate()
    // console.log("year:", year)
    // console.log("month:", month)
    // console.log("day:", day)
    let hour = parseInt(timeString.split(":")[0])
    if (hour === 12 && AMPM === "AM"){
      hour = 0
    }
    if (AMPM === "PM" && hour !== 12){
      hour += 12
    }
    // console.log("hour before timezone adjustment:", hour)
    // console.log(timeZone)
    switch (timeZone) {
      case "Puerto Rico Atlantic Standard Time (AST)":
        hour += 4
        break
      case "Eastern Daylight Time (EDT)":
        hour += 4
        break
      case "Central Daylight Time (CDT)":
        hour += 5
        break
      case "Mountain Daylight Time (MDT)":
        hour += 6
        break
      case "Arizona Mountain Standard Time (MST)":
        hour += 7
        break
      case "Pacific Daylight Time (PDT)":
        hour += 7
        break
      case "Alaska Daylight Time (AKDT)":
        hour += 8
        break
      case "Aluetian Daylight Time (HADT)":
        hour += 9
        break
      case "Hawaii Standard Time (HST)":
        hour += 10
        break
      case "Samoa Standard Time (SST)":
        hour += 11
        break
      case "Chamorro Standard Time (CHST)":
        hour -= 10
        hour += 24
        day -= 1
        break
      default:
        setStatus("Must choose a timezone.")
        break
    }
    // console.log("hour:", hour)
    let minute = parseInt(timeString.split(":")[1])
    // console.log("minute:", minute)
    // let date = new Date(year, month, day, hour, minute)
    let date = new Date(Date.UTC(year, month, day, hour, minute))
    // console.log("DATE:", date)
    return firebase.firestore.Timestamp.fromDate(date)
  }

  function handleChange(e) {
    // console.log(e.target.value)
    setEvent({ ...event, [e.target.name]: e.target.value });
    // console.log("USER DATA:", userData)
  }

  function handleDateChange(date) {
    // console.log("date:", date)
    setEvent({ ...event, date: date });
  }

  function handleVirtualChange(e) {
    setEvent({ ...event, isVirtual: !event.isVirtual })
  }

  function handleMultipleShiftsChange(e) {
    setEvent({ ...event, hasMultipleTimes: !event.hasMultipleTimes })
  }

  function addTimeEntry() {
    // console.log("current list of entries:", additionalTimeEntries)
    setAdditionalTimeEntries([...additionalTimeEntries, {startTime: "", startAMPM: "", endTime: "", endAMPM: "", id: Math.floor(Math.random()*10000)}])
  }

  function handleAdditionalTimeEntryChange(e, id) {
    const items = [...additionalTimeEntries];
    // console.log("items before:", items)
    let count = 0;
    let itemIndex = 0;
    additionalTimeEntries.forEach((entry) => {
      if (entry.id === id){
        itemIndex = count
      }
      count += 1;
    })
    // const itemIndex = additionalTimeEntries.indexOf(id);
    // console.log("editing:", items[itemIndex])
    let field = String(e.target.name)
    items[itemIndex][field] = e.target.value
    // console.log("items after:", items)
    setAdditionalTimeEntries([...items])
  }

  function deleteTimeEntry(id) {
    const items = [...additionalTimeEntries];
    // console.log("items:", items)
    // console.log("id to remove:", id)
    let count = 0;
    let itemIndex = 0;
    additionalTimeEntries.forEach((entry) => {
      if (entry.id === id){
        itemIndex = count
      }
      count += 1;
    })
    // const itemIndex = additionalTimeEntries.indexOf(id);
    // console.log("removing:", items[itemIndex])
    items.splice(itemIndex, 1);
    // console.log("final array:", items)
    setAdditionalTimeEntries([...items]);
  }

  function handleChangeTopics(selectedOption) {
    if (selectedOption) {
      // console.log("selected option:", selectedOption)
      // selectedOption = selectedOption.slice(-3);
      // console.log("selected option after slice:", selectedOption)
      //TODO: implement 3 max -- problem is that onChange doesn't get triggered when removing only 1 option
      let selectedArr = [];
      selectedOption.map((option) => {
        selectedArr.push(option.label);
      });
      setEvent({ ...event, topicTags: selectedArr });
      setSelectedTags(selectedOption);
    }
  }

  function handlePhotoChange(event) {
    setSelectedFile(event.target.files[0])
  }

  function removePhoto(event) {
    setSelectedFile(null)
  }

  async function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    setLoading(true);

    let finalDateTimes = []
    finalDateTimes.push({startTimeString: (firstTime.startTime + (firstTime.startAMPM).toLowerCase()), startTimeUTC: convertTimeToUTC(event.timeZone, event.date, firstTime.startTime, firstTime.startAMPM), endTimeString: (firstTime.endTime + (firstTime.endAMPM).toLowerCase()), endTimeUTC: convertTimeToUTC(event.timeZone, event.date, firstTime.endTime, firstTime.endAMPM)})
    additionalTimeEntries.forEach((entry) => {
      finalDateTimes.push({startTimeString: (entry.startTime + (entry.startAMPM).toLowerCase()), startTimeUTC: convertTimeToUTC(event.timeZone, event.date, entry.startTime, entry.startAMPM), endTimeString: (entry.endTime + (entry.endAMPM).toLowerCase()), endTimeUTC: convertTimeToUTC(event.timeZone, event.date, entry.endTime, entry.endAMPM)})
    })

    let docRef = db.collection("events").doc()

    let timeZoneAbbr = (event.timeZone).split(" ")[(event.timeZone).split(" ").length - 1]
    let timeZoneAbbr_slice1 = timeZoneAbbr.slice(0, -1)
    let timeZoneAbbr_final = timeZoneAbbr_slice1.slice(1)
    // console.log("time zone:", timeZoneAbbr_final)

    docRef.set({
      dateCreated: firebase.firestore.Timestamp.now(),
      organizerName: userData.name,
      organizerId: currUser.uid,
      eventTitle: event.title,
      eventType: event.type,
      timeZoneFull: event.timeZone,
      timeZone: timeZoneAbbr_final,
      hasMultipleTimes: event.hasMultipleTimes,
      date: event.date,
      mainStartTimeString: firstTime.startTime + (firstTime.startAMPM).toLowerCase(),
      mainStartTimeUTC: convertTimeToUTC(event.timeZone, event.date, firstTime.startTime, firstTime.startAMPM),
      mainEndTimeString: firstTime.endTime + (firstTime.endAMPM).toLowerCase(),
      mainEndTimeUTC: convertTimeToUTC(event.timeZone, event.date, firstTime.endTime, firstTime.endAMPM),
      isVirtual: event.isVirtual,
      streetAddress: event.streetAddress,
      city: event.city,
      state: event.state,
      district: event.district,
      zipCode: event.zipCode,
      description: event.description,
      topicTags: event.topicTags,
      numAttending: 0,
      numInterested: 0
    }
    ).then(() => {
      finalDateTimes.forEach((item) => {
        let subDocRef = docRef.collection("eventTimes").doc()
        subDocRef.set({
          startTimeString: item.startTimeString,
          startTimeUTC: item.startTimeUTC,
          endTimeString: item.endTimeString,
          endTimeUTC: item.endTimeUTC,
          timeZone: timeZoneAbbr_final
        })
      })
    }).then(() => {
      db.collection("organizations").doc(currUser.uid).collection("events").doc(docRef.id).set({
        eventTitle: event.title,
        organizerName: userData.name,
        organizerId: currUser.uid,
        eventType: event.type,
        mainStartTimeString: firstTime.startTime + (firstTime.startAMPM).toLowerCase(),
        mainStartTimeUTC: convertTimeToUTC(event.timeZone, event.date, firstTime.startTime, firstTime.startAMPM),
        timeZone: timeZoneAbbr_final,
        isVirtual: event.isVirtual,
        city: event.city,
        state: event.state,
        numAttending: 0,
        numInterested: 0
      })
    }).then(() => {
      let storageRef = firebase.storage().ref();
      let photoRef = storageRef.child(`images/organization/events/${docRef.id}`)
      photoRef.put(selectedFile).then((snapshot) => {
        console.log('Uploaded photo!');
      });
    }).then(() => {
      db.collection("organizations").doc(currUser.uid).update({
        upcomingEventCount: firebase.firestore.FieldValue.increment(1)
      })
    })

    setLoading(false);
    setStatus({ success: "You have successfully posted this event." });
    setTimeout(() => history.goBack(), 500);

  }

  return (
    <OrgLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-6 mb-8">Create New Event</h1>

        <form
          // action="#"
          // method="POST"
          class="space-y-8"
          onSubmit={handleSubmit}
        >

          <div className="mt-4">
            <label
              for="title"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Title
            </label>
            <input
              id="title"
              name="title"
              placeholder="Enter event title here..."
              type="text"
              value={event.title}
              maxLength="100"
              onChange={handleChange}
              required
              // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <h1 className="text-gray-500 float-right mt-2">
              {event.title.length}/100 characters
            </h1>
          </div>

          <div className="mt-4">
            <label
              for="type"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Type
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select
                id="type"
                name="type"
                value={event.type}
                onChange={handleChange}
                required
                className="w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
              >
                <option value="" disabled selected hidden></option>
                <option value="Rally">Rally</option>
                <option value="Phone/Text Bank">Phone/Text Bank</option>
                <option value="Fundraiser">Fundraiser</option>
                <option value="Canvass">Canvass</option>
                <option value="Town Hall">Town Hall</option>
                <option value="Watch Party">Watch Party</option>
                <option value="Workshop/Training">Workshop/Training</option>
                <option value="Voter Registration">Voter Registration</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label
              for="type"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Date
            </label>
            {/* <input
              required
              type="date"
              id="date"
              name="date"
              value={event.date}
              onChange={handleChange}
            /> */}
            <DatePicker
              required
              name="date"
              id="date"
              selected={event.date}
              onChange={handleDateChange}
            />
          </div>

          <div className="mt-4">

            <label
              for="type"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Time
            </label>

            <div className="flex space-x-20 items-center mb-6">
              <div className="flex items-center">
                <h1 className="col-start-1 col-end-5 pr-6 text-gray-800 block text-right">
                  Time zone:
                </h1>
                <select
                  id="timezone"
                  name="timeZone"
                  value={event.timeZone}
                  onChange={handleChange}
                  required
                  className="col-start-7 col-end-9 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled selected hidden></option>
                  <option disabled>Time zone</option>
                  <option value="Puerto Rico Atlantic Standard Time (AST)">Puerto Rico Atlantic Standard Time</option>
                  <option value="Eastern Daylight Time (EDT)">Eastern Daylight Time (EDT)</option>
                  <option value="Central Daylight Time (CDT)">Central Daylight Time (CDT)</option>
                  <option value="Mountain Daylight Time (MDT)">Mountain Daylight Time (MDT)</option>
                  <option value="Arizona Mountain Standard Time (MST)">Arizona Mountain Standard Time (MST)</option>
                  <option value="Pacific Daylight Time (PDT)">Pacific Daylight Time (PDT)</option>
                  <option value="Alaska Daylight Time (AKDT)">Alaska Daylight Time (AKDT)</option>
                  <option value="Aluetian Daylight Time (HADT)">Aluetian Daylight Time (HADT)</option>
                  <option value="Hawaii Standard Time (HST)">Hawaii Standard Time (HST)</option>
                  <option value="Samoa Standard Time (SST)">Samoa Standard Time (SST)</option>
                  <option value="Chamorro Standard Time (CHST)">Chamorro Standard Time (CHST)</option>
                </select>
              </div>
              <div className="flex space-x-3 items-center">
                <input
                  type="checkbox"
                  id="hasMultipleTimes"
                  name="hasMultipleTimes"
                  className="text-justiceBlue-500 transition duration-150 ease-in-out focus:outline-none"
                  onChange={handleMultipleShiftsChange}
                />
                <label
                  for="title"
                  className="text-gray-800"
                >
                  This event has multiple shifts
                </label>
              </div>
            </div>

            <div className="flex space-x-12 items-center">
              <div className="flex space-x-2 items-center">
                <label
                  for="title"
                  className="text-gray-800"
                >
                  Begins at:
                </label>
                <select
                id="startTime"
                name="startTime"
                value={firstTime.startTime}
                onChange={(e) => {
                  setFirstTime({...firstTime, startTime: e.target.value})
                }}
                required
                className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled selected hidden></option>
                  <option value="1:00">1:00</option>
                  <option value="1:30">1:30</option>
                  <option value="2:00">2:00</option>
                  <option value="2:30">2:30</option>
                  <option value="3:00">3:00</option>
                  <option value="3:30">3:30</option>
                  <option value="4:00">4:00</option>
                  <option value="4:30">4:30</option>
                  <option value="5:00">5:00</option>
                  <option value="5:30">5:30</option>
                  <option value="6:00">6:00</option>
                  <option value="6:30">6:30</option>
                  <option value="7:00">7:00</option>
                  <option value="7:30">7:30</option>
                  <option value="8:00">8:00</option>
                  <option value="8:30">8:30</option>
                  <option value="9:00">9:00</option>
                  <option value="9:30">9:30</option>
                  <option value="10:00">10:00</option>
                  <option value="10:30">10:30</option>
                  <option value="11:00">11:00</option>
                  <option value="11:30">11:30</option>
                  <option value="12:00<">12:00</option>
                  <option value="12:30">12:30</option>
                </select>
                <select
                  id="startAMPM"
                  name="startAMPM"
                  value={firstTime.startAMPM}
                  onChange={(e) => {
                    setFirstTime({...firstTime, startAMPM: e.target.value})
                    // console.log("TIMESTAMP:", convertTimeToUTC(event.timeZone, event.date, firstTime.startTime, e.target.value))
                  }}
                  required
                  className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                  >
                  <option value="" disabled selected hidden></option>
                  <option value="PM">PM</option>
                  <option value="AM">AM</option>
                </select>
              </div>
              <div className="flex space-x-2 items-center">
                <label
                  for="title"
                  className="text-gray-800"
                >
                  Ends at:
                </label>
                <select
                id="endTime"
                name="endTime"
                value={firstTime.endTime}
                onChange={(e) => {
                  setFirstTime({...firstTime, endTime: e.target.value})
                }}
                required
                className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled selected hidden></option>
                  <option value="1:00">1:00</option>
                  <option value="1:30">1:30</option>
                  <option value="2:00">2:00</option>
                  <option value="2:30">2:30</option>
                  <option value="3:00">3:00</option>
                  <option value="3:30">3:30</option>
                  <option value="4:00">4:00</option>
                  <option value="4:30">4:30</option>
                  <option value="5:00">5:00</option>
                  <option value="5:30">5:30</option>
                  <option value="6:00">6:00</option>
                  <option value="6:30">6:30</option>
                  <option value="7:00">7:00</option>
                  <option value="7:30">7:30</option>
                  <option value="8:00">8:00</option>
                  <option value="8:30">8:30</option>
                  <option value="9:00">9:00</option>
                  <option value="9:30">9:30</option>
                  <option value="10:00">10:00</option>
                  <option value="10:30">10:30</option>
                  <option value="11:00">11:00</option>
                  <option value="11:30">11:30</option>
                  <option value="12:00<">12:00</option>
                  <option value="12:30">12:30</option>
                </select>
                <select
                  id="endAMPM"
                  name="endAMPM"
                  value={firstTime.endAMPM}
                  onChange={(e) => {
                    setFirstTime({...firstTime, endAMPM: e.target.value})
                  }}
                  required
                  className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                  >
                  <option value="" disabled selected hidden></option>
                  <option value="PM">PM</option>
                  <option value="AM">AM</option>
                </select>
              </div>
            </div>
            {additionalTimeEntries.map((entry) => (
              <div className="flex space-x-6 items-center mt-4">
                {/* <h1>{entry.id}</h1> */}
                  {/* value={entry.start}
                  onChange={(e) => handleAdditionalTimeEntryStartChange(e, entry.id)} */}
                <div className="flex space-x-12 items-center">
                  <div className="flex space-x-2 items-center">
                    <label
                      for="title"
                      className="text-gray-800"
                    >
                      Begins at:
                    </label>
                    <select
                    id="startTime"
                    name="startTime"
                    value={entry.startTime}
                    onChange={(e) => handleAdditionalTimeEntryChange(e, entry.id)}
                    required
                    className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="" disabled selected hidden></option>
                      <option value="1:00">1:00</option>
                      <option value="1:30">1:30</option>
                      <option value="2:00">2:00</option>
                      <option value="2:30">2:30</option>
                      <option value="3:00">3:00</option>
                      <option value="3:30">3:30</option>
                      <option value="4:00">4:00</option>
                      <option value="4:30">4:30</option>
                      <option value="5:00">5:00</option>
                      <option value="5:30">5:30</option>
                      <option value="6:00">6:00</option>
                      <option value="6:30">6:30</option>
                      <option value="7:00">7:00</option>
                      <option value="7:30">7:30</option>
                      <option value="8:00">8:00</option>
                      <option value="8:30">8:30</option>
                      <option value="9:00">9:00</option>
                      <option value="9:30">9:30</option>
                      <option value="10:00">10:00</option>
                      <option value="10:30">10:30</option>
                      <option value="11:00">11:00</option>
                      <option value="11:30">11:30</option>
                      <option value="12:00<">12:00</option>
                      <option value="12:30">12:30</option>
                    </select>
                    <select
                      id="startAMPM"
                      name="startAMPM"
                      value={entry.startAMPM}
                      onChange={(e) => handleAdditionalTimeEntryChange(e, entry.id)}
                      required
                      className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                      >
                      <option value="" disabled selected hidden></option>
                      <option value="PM">PM</option>
                      <option value="AM">AM</option>
                    </select>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <label
                      for="title"
                      className="text-gray-800"
                    >
                      Ends at:
                    </label>
                    <select
                    id="endTime"
                    name="endTime"
                    value={entry.endTime}
                    onChange={(e) => handleAdditionalTimeEntryChange(e, entry.id)}
                    required
                    className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="" disabled selected hidden></option>
                      <option value="1:00">1:00</option>
                      <option value="1:30">1:30</option>
                      <option value="2:00">2:00</option>
                      <option value="2:30">2:30</option>
                      <option value="3:00">3:00</option>
                      <option value="3:30">3:30</option>
                      <option value="4:00">4:00</option>
                      <option value="4:30">4:30</option>
                      <option value="5:00">5:00</option>
                      <option value="5:30">5:30</option>
                      <option value="6:00">6:00</option>
                      <option value="6:30">6:30</option>
                      <option value="7:00">7:00</option>
                      <option value="7:30">7:30</option>
                      <option value="8:00">8:00</option>
                      <option value="8:30">8:30</option>
                      <option value="9:00">9:00</option>
                      <option value="9:30">9:30</option>
                      <option value="10:00">10:00</option>
                      <option value="10:30">10:30</option>
                      <option value="11:00">11:00</option>
                      <option value="11:30">11:30</option>
                      <option value="12:00<">12:00</option>
                      <option value="12:30">12:30</option>
                    </select>
                    <select
                      id="endAMPM"
                      name="endAMPM"
                      value={entry.endAMPM}
                      onChange={(e) => handleAdditionalTimeEntryChange(e, entry.id)}
                      required
                      className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                      >
                      <option value="" disabled selected hidden></option>
                      <option value="PM">PM</option>
                      <option value="AM">AM</option>
                    </select>
                  </div>
                </div>
                <button
                  className="text-justiceBlue-500 hover:text-justiceBlue-400"
                  onClick={() => deleteTimeEntry(entry.id)}>
                  Delete Time
                </button>
              </div>
            ))}
            {event.hasMultipleTimes &&
              <button
                className="btn-secondary mt-6"
                onClick={addTimeEntry}>
                Add additional time
              </button>
            }
          </div>



          <div className="mt-4">
            <label
              for="title"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Location
            </label>
            <div className="flex space-x-4 items-center">
              <div className="flex space-x-3 items-center">
                <input
                  type="checkbox"
                  id="isVirtual"
                  name="isVirtual"
                  className="text-justiceBlue-500 transition duration-150 ease-in-out focus:outline-none"
                  onChange={handleVirtualChange}
                />
                <label
                  for="title"
                  className="text-gray-800"
                >
                  Virtual
                </label>
              </div>
              <div className="grid grid-cols-12 gap-x-4 w-full items-center">
                {event.isVirtual ?
                  <h1 className="col-start-1 col-end-5 pr-6 text-gray-800 block text-right">
                    Hosted From:
                  </h1>
                  :
                    <input
                    id="streetAddress"
                    name="streetAddress"
                    placeholder="Street address"
                    type="text"
                    value={event.streetAddress}
                    onChange={handleChange}
                    required
                    // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    className="col-start-2 col-end-5 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                    />
                }

                <input
                  id="city"
                  name="city"
                  placeholder="City"
                  type="text"
                  value={event.city}
                  onChange={handleChange}
                  required
                  // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  className="col-start-5 col-end-7 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                />
                <select
                  id="state"
                  name="state"
                  value={event.state}
                  onChange={handleChange}
                  required
                  className="col-start-7 col-end-9 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled selected hidden></option>
                  <option disabled>State</option>
                  {Object.keys(states_hash).map((state) => {
                    return <option value={state}>{states_hash[state]}</option>;
                  })}
                </select>
                <select
                  id="district"
                  name="district"
                  value={event.district}
                  onChange={handleChange}
                  required
                  className="col-start-9 col-end-11 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" disabled selected hidden></option>
                  <option disabled>District #</option>
                  {
                    states_districts[event.state] === "At-Large" ?
                    <option value="At-Large">At Large</option>
                    :
                  Array(states_districts[event.state])
                    .fill()
                    .map((_, idx) => {
                      return <option value={idx + 1}>{idx + 1}</option>;
                    })}
                </select>
                <input
                  id="zipCode"
                  name="zipCode"
                  placeholder="ZIP code"
                  type="text"
                  value={event.zipCode}
                  onChange={handleChange}
                  required
                  // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  className="col-start-11 col-end-13 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <label
              for="topicTags"
              className="whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Topic Tags
            </label>
            <label className="ml-2">(optional)</label>
            <div className="w-full flex space-x-4 items-center mt-3">
              <Select
                isMulti={true}
                backspaceRemovesValue={true}
                isSearchable={false}
                isClearable={true}
                //TODO: implement 3 max -- problem is that onChange doesn't get triggered when removing only 1 option
                // value={selectedTags}
                className="flex-auto shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block sm:text-sm border-gray-300 rounded-md"
                onChange={handleChangeTopics}
                options={topicTags}
              />
              <div className="text-gray-500 text-sm">
                <h1>({event.topicTags.length}/3 max)</h1>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <label
              for="eventImg"
              className="whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Photo
            </label>
            <label className="ml-2">(optional - recommended size: 770x385)</label>
            <div className="mt-3 mb-9 flex space-x-10 items-center">
              <div className="bg-gray-100 h-96 w-192 rounded-lg">
                <img id="eventImg" className="object-scale-down inline-block w-full rounded-lg" src={preview} alt=""/>
              </div>
              <div className="ml-4 flex">
                <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
                  {preview ? "Change" : "Upload"}
                  <input onChange={handlePhotoChange} id="eventImg" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
                </button>
                <button onClick={removePhoto} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
          <label
              for="description"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Event Description
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <textarea
                required
                id="description"
                name="description"
                type="text"
                value={event.description}
                maxLength="2000"
                rows={7}
                onChange={handleChange}
                placeholder="Enter event description/details here..."
                className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <h1 className="text-gray-500 float-right mt-2">
                {event.description.length}/2000 characters
              </h1>
            </div>
          </div>

          {status.error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{status.error}</span>
            </div>
          )}
          {status.success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{status.success}</span>
            </div>
          )}
          <div className="flex space-x-4">
            <button
              disabled={loading}
              type="submit"
              className="btn-primary"
            >
              Post Event
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn-white"
            >
              Cancel
            </button>
          </div>

        </form>

      </div>
    </OrgLayout>
  );
};

export default CreateEvent;
