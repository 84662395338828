import React, { useState, useEffect, useContext } from "react";
import AppLayout from "../../../../layouts/AppLayout";
import BillCard from "../../../../components/civ/global/bills/BillCard";
import { db } from "../../../../config/firebase";
const axios = require("axios");

const config = {
	headers: {
		"X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
	},
};

const BillSimilarBillsPage = ({ match, history }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [billName, setBillName] = useState("");
	const [similarBills, setSimilarBills] = useState(
		<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>
	);

	useEffect(async () => {
		if (match && match.params.bill) {
			let tempBillId = match.params.bill;

			if (tempBillId.length == 0) {
				setError("Bad link. No bill ID given.");
			} else {
				const billNumber = await (
					await db.collection("bills").doc(tempBillId).get()
				).data().number;
				const billTitle = await (
					await db.collection("bills").doc(tempBillId).get()
				).data().title;
				const billSlug = await (
					await db.collection("bills").doc(tempBillId).get()
				).data().slug;
				setBillName(billNumber + ": " + billTitle);

				// const tempAmendments = await db.collection("bills").doc(tempBillId).collection("amendments").get();
				// console.log("DATA:", tempBillComments.docs.map(doc => doc.data()))
				axios
					.get(
						`https://api.propublica.org/congress/v1/117/bills/${billSlug}/related.json`,
						config
					)
					// axios.get(`https://api.propublica.org/congress/v1/115/bills/hr1628/amendments.json`, config)
					.then((response) => {
						const tempSimilarBills =
							response.data.results[0].related_bills;

						if (tempSimilarBills && tempSimilarBills.length > 0) {
							// console.log(tempAmendments.docs)
							setSimilarBills(
								tempSimilarBills.map((bill) => (
									<BillCard
										billId={bill.bill_id}
										number={bill.number}
										title={
											bill.short_title === ""
												? bill.title
												: bill.short_title
										}
										sponsorName={bill.sponsor}
										sponsorState={bill.sponsor_state}
										sponsorParty={bill.sponsor_party}
										dateIntroduced={bill.introduced_date}
										cosponsorCount={bill.cosponsors}
									/>
								))
							);
						} else {
							setSimilarBills(
								<h1 className="px-8 py-8 font-medium text-xl">
									This bill has no similar bills.
								</h1>
							);
						}
					});

				setLoading(false);
			}
		}
	}, [match]);

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}
	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<button
					className="flex space-x-2 items-center focus:outline-none"
					onClick={() => {
						history.goBack();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="flex-shrink-0 h-5 w-5 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
					<h1>Back</h1>
				</button>

				<h1 className="text-3xl font-bold mt-6 mb-6">{billName}</h1>

				<div className="flex justify-between items-end mb-6">
					<h1 className="text-2xl font-semibold">Similar Bills</h1>

					<div>
						<label
							for="location"
							class="block text-sm font-medium text-justiceBlue-500"
						>
							Sort
						</label>
						<select
							id="location"
							name="location"
							class="dropdown-select"
						>
							<option selected>Most Recent</option>
							<option>Etc</option>
							<option>Etc</option>
						</select>
					</div>
				</div>

				<div className="bg-white shadow overflow-hidden sm:rounded-md mb-10">
					<div className="divide-y divide-gray-200">
						{similarBills}
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default BillSimilarBillsPage;
