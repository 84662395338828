import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";

const PrivacyPolicyPage = () => {

  return (
    <LandingLayout>
      <div class="relative py-16 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="text-lg max-w-prose mx-auto">
            <h1>
              {/* <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Introducing</span> */}
              <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</span>
            </h1>
          </div>
          <div class="mt-8 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Civilian User Data</h2>
            <p>Data transparency and personal privacy is important to us at GrassrootsGov. To create a GrassrootsGov account, you must use your own identity and provide real and complete information (this is central to the site functioning as intended). We will never sell your data or use your data for ads. In fact, we will never even put ads on the site!</p>
            <p>The following information will be shared with other users (publicly available on the site):</p>
            <ul>
              <li>Your first name and last initial</li>
              <li>Your federal congressional district</li>
              <li>Your posts and a list of the organizations you follow on the site</li>
            </ul>
            <p>The following information will be shared only with your legislators and/or organizations whose events or mailing list your have signed up for (not publicly available on the site):</p>
            <ul>
              <li>Your first and last name</li>
              <li>Your email address</li>
              <li>Your zip code (this will also be shared with organizations you follow, but in an aggregated and de-identified way)</li>
              <li>Topics you have indicated your interest in</li>
            </ul>

            <h2>Organization User Data</h2>
            <p>Data transparency and personal privacy is important to us at GrassrootsGov. In order to be approved for a GrassrootsGov organization account, you must provide proof of the organization's legitimacy, which will only be shared with the team at GrassrootsGov.</p>
            <p>The following information will be shared with other users and organizations (publicly available on the site):</p>
            <ul>
              <li>The name of your organization</li>
              <li>Your organization's description</li>
              <li>Your profile photo</li>
              <li>The congressional district or region of your headquarters</li>
              <li>Your posts, events, and a list of the organizations you follow on the site</li>
              <li>Your contact email address and phone number (optional)</li>
              <li>Any social media accounts you choose to link (optional)</li>
            </ul>

            <h2>Legal</h2>

            <p>Last updated: April 24, 2021</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h3>Interpretation and Definitions</h3>
            <h4>Interpretation</h4>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h4>Definitions</h4>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
              <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
              <li>Application means the software program provided by the Company downloaded by You on any electronic device, named GrassrootsGov</li>
              <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to GrassrootsGov.</li>
              <li>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
              <li>Country refers to: United States</li>
              <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li>Personal Data is any information that relates to an identified or identifiable individual.</li>
              <li>Service refers to the Application or the Website or both.</li>
              <li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
              <li>Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
              <li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
              <li>Website refers to GrassrootsGov, accessible from https://grassrootsgov.org.</li>
              <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            {/* <h3>Collecting and Using Your Personal Data</h3>
            <h4>Types of Data Collected</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p> */}
            <h3>Information Collection And Use</h3>
            <p>All personally identifiable information collected through the waitlist or contact forms will not be shared with third parties, though we may use your data to improve our service.</p>
            <h3>Communications</h3>
            <p>We may use your personal information to contact you with newsletters, marketing or promotional materials and other information through email. You may contact us at any time to opt out of this.</p>
            <h3>Website Analytics </h3>
            <p>We may also partner with selected third-party vendors such as Google Analytics to allow tracking technologies and remarketing services on the site through the use of first party
            cookies and third-party cookies, to, among other things, analyze and track users’ use of the site, determine the popularity of certain content and better understand online activity.
            By accessing this site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly
            for responses to your questions. We do not transfer personal information to these third-party vendors. </p>
            <h3>Security</h3>
            <p>The security of your personal information is important to us, but remember that no method of
            transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to
            use commercially acceptable means to protect your personal information, we cannot guarantee its
            absolute security.</p>
            <h3>Changes To This Policy</h3>
            <p>This Privacy Policy is effective as of 4/24/21 and will remain in effect except with respect to any
            changes in its provisions in the future (such as when the full site version launches), which will be in effect immediately after being posted on this
            page. We reserve the right to update or change our Privacy Policy at any time and you should check this
            Privacy Policy periodically. Your continued use of the service after we post any modifications to the
            Privacy Policy on this page will constitute your acknowledgment of the modifications and your
            consent to abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the email
            address you have provided us, or by placing a prominent notice on our website.</p>


            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please <Link to="/contact" className="underline">contact us</Link>.</p>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default PrivacyPolicyPage;
