import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../../layouts/OrgLayout'
import { db } from '../../../../config/firebase'
import { Link } from "react-router-dom";
import LegislatorBillVoteComment from '../../../../components/org/global/legislators/LegislatorBillVoteCommentCard'
import { useAuth } from "../../../../contexts/AuthContext"
const axios = require("axios");

const config = {
  headers: {
    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
  },
};

const VotePage = ({match, history}) => {

  const { currUser, currUserRef, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [voteId, setVoteId] = useState();
  const [voteChamber, setVoteChamber] = useState("");
  const [voteNumber, setVoteNumber] = useState("");
  const [voteData, setVoteData] = useState({});

  const [userLegislatorVotes, setUserLegislatorVotes] = useState(<h1 className="text-lg">None of your legislators participated in this vote.</h1>)
  const [legislatorComments, setLegislatorComments] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  const [following, setFollowing] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(async () => {
    if (match && match.params.vote) {

      // const userState = (await db.collection("civilians").doc(currUser.uid).get()).data().state;

      let tempVoteId = match.params.vote;
      setVoteId(tempVoteId)

      if (tempVoteId.length == 0) {
        setError("Bad link. No vote id given.");
      } else {

        const tempVoteData = await db.collection("votes").doc(tempVoteId).get();
        setVoteData(tempVoteData.data());
        setVoteChamber(tempVoteData.data().chamber);
        setVoteNumber(tempVoteData.data().number);

        //TODO LATER: change this to .legislatorIds
        // const legislatorIds = [userData.districtRep.id, userData.senateRep[0].id, userData.senateRep[1].id]

        // console.log("(tempVoteData.data().chamber).toLowerCase():", (tempVoteData.data().chamber).toLowerCase())
        // console.log("tempVoteData.data().number:", tempVoteData.data().number)
        axios.get(`https://api.propublica.org/congress/v1/117/${(tempVoteData.data().chamber).toLowerCase()}/sessions/1/votes/${tempVoteData.data().number}.json`, config)
        .then((response) => {

          // console.log("RESPONSE:", response.data.results.votes.vote)
          const tempVotesList = response.data.results.votes.vote.positions;
          console.log("tempVotesList:", tempVotesList)
          // console.log("legislatorIds:", legislatorIds)

          const tempUserLegislatorVotesList = []
          tempVotesList.forEach((legislator) => {
            if(legislator.state === userData.hqState){
              console.log("HERE!!")
              tempUserLegislatorVotesList.push(<h1 className="text-lg flex-shrink-0"><Link className="hover:underline" to={`/org/legislators/${legislator.member_id}/votes`}>{legislator.name} ({legislator.party}-{legislator.state})</Link> voted <span className={legislator.vote_position == "Yes" ? "ml-2 text-white font-medium bg-green-500 px-4 py-1 rounded-full" : (legislator.vote_position == "No" ? "ml-2 text-white font-medium bg-red-500 px-4 py-1 rounded-full" : "ml-2 text-white font-medium bg-gray-500 px-4 py-1 rounded-full")}>{legislator.vote_position}</span></h1>)
            }
          })
          setUserLegislatorVotes(tempUserLegislatorVotesList)

        })

        const tempVoteComments = await db.collection("votes").doc(tempVoteId).collection("comments").limit(3).get();

        if (tempVoteComments.docs && tempVoteComments.docs.length > 0){
          // console.log('comments exist')
          // console.log("number of comments:", tempBillComments.docs.length)
          console.log(tempVoteComments.docs)
          setLegislatorComments(tempVoteComments.docs.map((doc) =>
            <LegislatorBillVoteComment
              legislatorId={doc.data().legislatorId}
              legislatorName={doc.data().legislatorName}
              legislatorState={doc.data().legislatorState}
              legislatorDistrict={doc.data().legislatorDistrict ? doc.data().legislatorDistrict : "Senate"}
              legislatorParty={doc.data().legislatorParty}
              datePosted={doc.data().datePosted}
              body={doc.data().body}
              color="white"
            />
          ))
        }
        else{
          setLegislatorComments(
            <div className="bg-white px-2 py-1 rounded-lg flex space-x-2 items-center">
              <h1 className="px-8 py-8 font-medium text-xl">There are no comments regarding this vote from legislators at this time.</h1>
              <Link className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600" to={`/org/bills-votes/votes/${tempVoteId}/related-issues`}>
                <div className="flex items-center space-x-2">
                  <h1>View related issues</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </Link>
            </div>
          )
        }

      }

    setLoading(false);
    }
  }, [match]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  if (error) {
    return <AppLayout>{error}</AppLayout>;
  }


  return(
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-5 mb-5">
          {capitalizeFirstLetter(voteChamber)} Vote #{voteData.number} — {voteData.billInfo.number ? `${voteData.billInfo.number}:` : ""} {voteData.altTitle ? voteData.altTitle : voteData.billInfo.title}
        </h1>

        <div className="flex space-x-2">
          <Link className="btn-primary" to={`/org/bills-votes/votes/${voteId}/related-issues`}>
            <div className="flex items-center space-x-2">
              <h1>View related issues</h1>
              <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </Link>
          {/* TODO: make fields in issue create form autofill */}
          {/* <Link className="btn-primary" to="/issues/create">
            + Create related issue
          </Link> */}
          <a className="btn-secondary" href={voteData.congressGovUrl}>
            <div className="flex items-center space-x-2">
              <h1> View {capitalizeFirstLetter(voteChamber)}.gov page</h1>
              <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </a>
        </div>
      </div>

      <hr className="border-solid"/>

      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16 space-y-7">

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Date of Vote
          </div>
          <div class="col-start-2 pt-1">
            <h1 className="text-lg">
              {voteData.date}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start mb-2">
          <div class="block text-2xl text-right font-medium">
            Related Bill
          </div>
          {voteData.billInfo.number ?
          (<div class="col-start-2 col-end-9 pt-1">
            <div className="flex space-x-6 items-center">
              <div className="text-lg">
                {voteData.billInfo.number}{voteData.billInfo.title ? `: ${voteData.billInfo.title}` : ""}
              </div>
              {(voteData.api_uri !== "") && <Link
                className="flex-shrink-0 text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600"
                to={`/org/bills-votes/bills/${voteData.billInfo.bill_id}`}>
                <div className="flex items-center space-x-2">
                  <h1>View bill</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </Link>}
            </div>
            <div className="mt-2 text-lg"><span className="font-semibold">Latest action on this bill:</span> {voteData.billInfo.latest_action ? voteData.billInfo.latest_action : "N/A"}</div>
          </div>) : (<div class="col-start-2 col-end-9 pt-1 text-lg">N/A</div>)}
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Purpose of Vote
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <h1 className="text-lg">
              {voteData.purpose}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start mb-2">
          <div class="block text-2xl text-right font-medium">
            Outcome
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <div className="flex items-center space-x-8">
              {(() => {
                switch (voteData.outcome) {
                  case "Passed":
                    return(<h1 className="bg-green-500 rounded-full w-32 px-4 py-1 font-medium text-lg text-white text-center">{voteData.outcome}</h1>)
                  case "Failed":
                    return(<h1 className="bg-red-500 rounded-full w-32 px-4 py-1 font-medium text-lg text-white text-center">{voteData.outcome}</h1>)
                  {/* case "Past":
                    return(<h1 className="bg-indigo-500 rounded-full w-32 px-4 py-1 text-lg text-white text-center">{voteData.outcome}</h1>) */}
                  default:
                    return(<h1 className="bg-gray-500 rounded-full px-4 py-1 font-medium text-lg text-white">{voteData.outcome}</h1>)
                }
              })()}
              <div className="text-lg">
                <span
                  className="font-semibold">Yea:
                </span> {Math.round(100*(voteData.totalVotes.yes)/(voteData.totalVotes.yes+voteData.totalVotes.no+voteData.totalVotes.present+voteData.totalVotes.not_voting))}% ({voteData.totalVotes.yes} {voteData.totalVotes.yes > 1 ? "votes" : "vote"}) • <span className="font-semibold">
                  No:
                </span> {Math.round(100*(voteData.totalVotes.no)/(voteData.totalVotes.yes+voteData.totalVotes.no+voteData.totalVotes.present+voteData.totalVotes.not_voting))}% ({voteData.totalVotes.no} {voteData.totalVotes.no > 1 ? "votes" : "vote"}) • <span className="font-semibold">
                  Present/Not Voting:
                </span> {Math.round(100*(voteData.totalVotes.present + voteData.totalVotes.not_voting)/(voteData.totalVotes.yes+voteData.totalVotes.no+voteData.totalVotes.present+voteData.totalVotes.not_voting))}% ({(voteData.totalVotes.present + voteData.totalVotes.not_voting)} {(voteData.totalVotes.present + voteData.totalVotes.not_voting) > 1 ? "votes" : "vote"})
              </div>
            </div>
            <div className="mt-4 text-lg grid grid-cols-11">
              <div className="font-semibold col-start-1 col-end-3">
                <h1>Yea:</h1>
                <h1>Nay:</h1>
                <h1>Present/No Vote:</h1>
              </div>
              <div className="col-start-3 col-end-5">
                <h1 className="text-blue-500"><span className="font-medium">{voteData.dVotes.yes}</span> Democrats</h1>
                <h1 className="text-blue-500"><span className="font-medium">{voteData.dVotes.no}</span> Democrats</h1>
                <h1 className="text-blue-500"><span className="font-medium">{(voteData.dVotes.present + voteData.dVotes.not_voting)}</span> Democrats</h1>
              </div>
              <div className="text-red-500 col-start-5 col-end-7">
                <h1 className="text-red-500"><span className="font-medium">{voteData.rVotes.yes}</span> Republicans</h1>
                <h1 className="text-red-500"><span className="font-medium">{voteData.rVotes.no}</span> Republicans</h1>
                <h1 className="text-red-500"><span className="font-medium">{(voteData.rVotes.present + voteData.rVotes.not_voting)}</span> Republicans</h1>
              </div>
              <div className="text-purple-500 col-start-7 col-end-9">
                <h1 className="text-purple-500"><span className="font-medium">{voteData.iVotes.yes}</span> Independents</h1>
                <h1 className="text-purple-500"><span className="font-medium">{voteData.iVotes.no}</span> Independents</h1>
                <h1 className="text-purple-500"><span className="font-medium">{(voteData.iVotes.present + voteData.iVotes.not_voting)}</span> Independents</h1>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Related Issues
          </div>
          <div class="col-start-2 col-end-8 pt-1 flex space-x-6 items-center">
            <h1 className="text-lg">
              There are {voteData.relatedIssueCount} issues related to this vote.
            </h1>
            <Link className="btn-primary" to={`/org/bills-votes/votes/${voteId}/related-issues`}>
              <div className="flex items-center space-x-2">
                <h1>View related issues</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
          </div>
        </div>

        <div className="bg-gray-100 rounded-lg px-10 py-8 mt-10 flex space-x-8">
          {/* <div className="flex justify-between items-center max-w-full"> */}
            {/* <div className="flex items-center space-x-8 max-w-full"> */}
              <div className="flex-shrink-0 text-2xl font-medium">
                How legislators in your state voted:
              </div>
              <div className="flex space-x-8 items-center overflow-x-scroll">
                {userLegislatorVotes}
              </div>
            {/* </div> */}
            <Link className="flex-shrink-0 text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600" to={`/org/bills-votes/votes/${voteId}related-issues`}>
              <div className="flex items-center space-x-2">
                <h1>View related issues</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
          {/* </div> */}
        </div>

        <div className="bg-gray-100 rounded-lg px-10 py-8 mt-10">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-medium">Comments from legislator(s) on this vote:</h1>
            <Link className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600" to={`/org/bills-votes/votes/${voteId}/comments`}>
              <div className="flex items-center space-x-2">
                <h1>View all legislator comments</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
          </div>
          {legislatorComments}
        </div>

      </div>
    </AppLayout>
  )
}

export default VotePage;
