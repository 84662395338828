import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./LegPrivateRoute";

import InboxUnresolvedIssuesPage from '../../pages/leg/inbox/InboxUnresolvedIssuesPage'

const InsightsRoutes = () => {
  return (
    <Switch>

      <PrivateRoute path="/leg/insights" component={InboxUnresolvedIssuesPage}/>
    </Switch>
  )
}

export default InsightsRoutes;
