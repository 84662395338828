import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";
import protestAlt from "../../assets/protest-alt.jpg";
import megaphoneIcon from "../../assets/megaphone-icon.png";
import { db } from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";
import "../../styles/signup.css";

const SignupPage = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const { signup } = useAuth();
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    if (credentials.password.length < 8) {
      return setErr("Please create a password that is 8 letters or longer");
    }
    // try {
    setErr("");
    setLoading(true);
    signup(credentials.email, credentials.password)
      .then((user) => {
        db.collection("users").doc(user.user.uid).set({type: "civilian", profileFinished: false}).then(() => {
          // setTimeout(() => history.push("/finish-profile"), 500);
          setLoading(false);
          history.push("/verify");
        })
      })
      .catch((e) => {
        setErr(e.message);
        setLoading(false);
      });
  }

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }
  return (
    <LandingLayout>
      <div className="min-h-screen bg-white flex mt-0.5">
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={protestAlt}
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-14 w-auto" src={megaphoneIcon} alt="Workflow" />
              <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                Create your account
              </h2>
              <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                Or
                <Link
                  to="/login"
                  className="ml-1 font-medium text-red-500 hover:text-red-400 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  sign in here
                </Link>
              </p>
            </div>

            <div className="mt-8">
              {/* <div>
                <div>
                  <p className="text-sm leading-5 font-medium text-gray-700">
                    Sign in with
                  </p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          aria-label="Sign in with Facebook"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          aria-label="Sign in with Twitter"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </button>
                      </span>
                    </div>

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                          aria-label="Sign in with GitHub"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm leading-5">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="mt-6">
                <form
                  action="#"
                  method="POST"
                  className="space-y-6"
                  onSubmit={handleSubmit}
                >
                  {/* <div className="flex space-x-4">
                    <div className="flex-1">
                      <label
                        for="firstName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        First name
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={credentials.firstName}
                          onChange={handleChange}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <label
                        for="lastName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Last name
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id="lastName"
                          name="lastName"
                          type="lastName"
                          value={credentials.lastName}
                          onChange={handleChange}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                  </div> */}

                  <div>
                    <label
                      for="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for="password"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-xs leading-4 text-gray-600 max-w">
                      By signing up for an account, you agree to GrassrootsGov's <Link to="/terms-of-service" className="underline" >Terms of Service</Link> and <Link to="/privacy-policy" className="underline" >Privacy Policy</Link>.
                    </div>
                  </div>
                  {/* <div>
                    <label
                      for="zip"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Zip code
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="zip"
                        name="zip"
                        type="number"
                        value={credentials.zip}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div> */}

                  {/* <div className="flex items-center justify-between">
                            <div className="flex items-center">
                            <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-red-500 transition duration-150 ease-in-out"/>
                            <label for="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                                Remember me
                            </label>
                            </div>

                            <div className="text-sm leading-5">
                            <a href="#" className="font-medium text-red-500 hover:text-red-400 focus:outline-none focus:underline transition ease-in-out duration-150">
                                Forgot your password?
                            </a>
                            </div>
                        </div> */}

                  <div>
                    <span className="block w-full rounded-md shadow-sm">
                      <button
                        disabled={loading}
                        type="submit"
                        // className="btn-primary"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-justiceBlue-500 hover:bg-justiceBlue-400 focus:outline-none focus:border-justiceBlue-500 focus:shadow-outline-justiceBlue-500 active:bg-justiceBlue-600 transition duration-150 ease-in-out"
                      >
                        Create Account
                      </button>
                    </span>
                  </div>
                </form>
                <br />
                {err && (
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Holy smokes! </strong>
                    <span className="block sm:inline">{err}</span>
                  </div>
                )}
              </div>

              <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                Creating an organization or legislator account?
                <Link
                  to="/org-leg-signup"
                  className="ml-1 font-medium text-red-500 hover:text-red-400 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Click here!
                </Link>
              </p>

            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default SignupPage;
