import React from "react";
import { Link } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout";
import BrowseNav from "../../../components/org/browse/BrowseNav";
import EventsNav from "../../../components/org/browse/EventsNav";
import StateEvents from "../../../components/org/browse/StateEvents";

function BrowseStateEventsPage(props) {
	return (
		<OrgLayout>
			<BrowseNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<EventsNav />
				<StateEvents />
			</div>
		</OrgLayout>
	);
}

export default BrowseStateEventsPage;
