import React, { useEffect, useState, useContext } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
import StateIssuesNav from "./StateIssuesNav";
import TopicCard from './TopicCard';
import {StateDBContext} from '../../../../contexts/StateContext';

const StateIssuesTopics = () => {

  const activeIssues = useContext(StateDBContext);
  let [topicsDisplay, setTopicsDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);


  useEffect(async () => {

    let topicsList = [];
    if(activeIssues[3] != null && activeIssues[3].length != 0) {
      for(const topic in activeIssues[3]){
        topicsList.push(<TopicCard name={topic} data={activeIssues[3][topic]} trendingTopic={false}/>);
      }

      setTopicsDisplay(topicsList);
    } else {
      setTopicsDisplay(<h1 className="px-8 py-8 font-medium text-xl">{"There are currently no active issues."}</h1>)
    }

  }, [activeIssues[3]]);

  return (
    <>
      <StateIssuesNav />
      <div className="grid grid-cols-4 gap-x-5">
        {topicsDisplay}
      </div>
    </>
    );

}


export default StateIssuesTopics;
