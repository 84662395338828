// export const algoliaEnv = {
//   appId: "IX2OC0ZF0H",
//   apikey: "43e763a6855b59b2e434323211b18699",
// };
import algoliasearch from "algoliasearch";
export const searchClient = algoliasearch(
  "IX2OC0ZF0H",
  "43e763a6855b59b2e434323211b18699"
);
export const index = searchClient.initIndex("grassrootsgov");
// export const eventsIndex = searchClient.initIndex("grassrootsgov_events");
// export const eventsIndex = searchClient.initIndex("grassrootsgov_events");
// export const votesIndex = searchClient.initIndex("grassrootsgov_votes");
// export const allIndex = searchClient.initIndex("grassrootsgov_all");
