import React from "react";

import AppLayout from "../../../../layouts/AppLayout";
import DistrictNav from "../../../../components/civ/district/DistrictNav";
import DistrictLatestIssues from "../../../../components/civ/district/issues/DistrictLatestIssues";

const DistrictLatestIssuesPage = () => {
	return (
		<AppLayout>
			<DistrictNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<DistrictLatestIssues />
			</div>
		</AppLayout>
	);
};

export default DistrictLatestIssuesPage;
