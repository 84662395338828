import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout"
import { db } from "../../config/firebase"

const AdminWaitlistPage = () => {

  const [loading, setLoading] = useState(true);
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [waitlist, setWaitlist] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>)

  useEffect(async () => {

    const tempWaitlist = await db.collection("waitlistUsers").get();

    if (tempWaitlist.docs && tempWaitlist.docs.length > 0){

      setWaitlistCount(tempWaitlist.docs.length)

      setWaitlist(tempWaitlist.docs.map((doc) => (
        <tr>
          <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {doc.data().firstName} {doc.data().lastName}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().occupation}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().affiliation}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().email}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().zipcode}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().referral}
          </td>
        </tr>
      )))
    }
    else {

    }

    setLoading(false);

  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
      <AdminLayout>
        <div className="mb-px py-8 px-12">

          <div className="flex justify-between items-end mb-8">
            <h1 className="text-2xl font-semibold">User Waitlist</h1>
            <h2 className="text-lg font-medium">{waitlistCount} signups</h2>
          </div>

          <div class="flex flex-col max-w-full">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Occupation
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Affiliation
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          ZIP Code
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Referral
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {waitlist}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </AdminLayout>
  )
}

export default AdminWaitlistPage;
