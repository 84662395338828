import React from 'react'
import { NavLink, useLocation } from "react-router-dom";


const LegBillVoteNav = () => {

  const {pathname} = useLocation();
  console.log("pn", pathname);
  return (
      <>
        <header className="sm:px-8 lg:px-16 py-6 shadow-md">
          <h1 className="text-3xl font-bold">My Bills & Votes</h1>
        </header>
        <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex space-x-8">
          <NavLink to="/leg/bills-votes/bills/latest" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300" exact activeClassName="text-gray-900"
                    isActive={() => ["/leg/bills-votes/bills/latest", "/leg/bills-votes/bills/least-popular","/leg/bills-votes/bills/most-popular"].includes(pathname)} >
            Bills
          </NavLink>
          <NavLink to="/leg/bills-votes/votes/latest" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300" activeClassName="text-gray-900"
                    isActive={() => ["/leg/bills-votes/votes/latest", "/leg/bills-votes/votes/least-popular","/leg/bills-votes/votes/most-popular"].includes(pathname)} >
            Votes
          </NavLink>
        </div>
        <hr className="border-solid"/>
      </>
      )
}

export default LegBillVoteNav;
