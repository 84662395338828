import React, { useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
// import jd from '../../../../assets/jd.png'

const OrganizationCard = (props) => {
  return (
    <Link
      to={`/organizations/${props.organizationId}/latest`}
      className='block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out'
    >
      <div className='flex items-center px-4 py-4 sm:px-6'>
        {/* <img className='ml-2 mr-9 rounded-md h-20 w-20' src={jd} /> */}
        <img className="ml-2 mr-9 rounded-md h-20 w-20" src={props.profilePicUrl} />

        <div className="w-auto flex-1">
          <div className="flex items-center justify-between">
            <div className="text-2xl leading-9 font-semibold text-gray-900 truncate">
              {props.name}
            </div>
            <div className='mr-9 px-5 inline-flex font-medium text-md leading-8 font-normal text-gray-700'>
              {(props.activeIssues !== 1) ? props.activeIssues + " active issues" : props.activeIssues + " active issue"}
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='mr-6 flex items-center text-md leading-5 text-gray-500'>
              {/* <!-- Heroicon name: users --> */}
              <svg
                className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' />
              </svg>
              {props.followers ? props.followers + ` ${(props.followers !== 1) ? "followers" : "follower"}` : "0 followers"}
            </div>
            <div className='mr-9 px-5 inline-flex font-medium text-md leading-8 font-normal text-gray-700'>
              {(props.upcomingEvents !== 1) ? props.upcomingEvents + " upcoming events" : props.upcomingEvents + " upcoming event"}
            </div>
          </div>
        </div>

        <div className='mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0'>
          <span>View</span>
          <svg
            className='flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M14 5l7 7m0 0l-7 7m7-7H3'
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};
export default OrganizationCard;
