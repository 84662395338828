import React from "react";
import LegislatorCard from "../../global/legislators/LegislatorCard";

const RegionInfo = (props) => {
  // const [loading, setLoading] = useState(true);
  // const [houseRep, setHouseRep] = useState({});
  // const [senateRep, setSenateRep] = useState([]);
  // useEffect(async () => {
  //   console.log(props.district, props.state);
  //   if (props.district && props.state) {
  //     const snapHouse = await db
  //       .collection("legislators")
  //       .where("chamber", "==", "House")
  //       .where("region", "==", props.state)
  //       .where("district", "==", props.district)
  //       .get();
  //     snapHouse.forEach((doc) => {
  //       setHouseRep(doc.data());
  //     });

  //     const snapSenate = await db
  //       .collection("legislators")
  //       .where("chamber", "==", "Senate")
  //       .where("region", "==", props.state)
  //       .get();
  //     snapSenate.forEach((doc) => {
  //       setSenateRep((senate) => [...senate, doc.data()]);
  //     });
  //     setLoading(false);
  //   }
  // }, [props]);
  // if (loading) {
  //   return <>Loading...</>;
  // }
  return (
    <div className="w-full grid grid-cols-3 gap-x-8 items-center bg-gray-100 px-10 py-6 rounded-lg">
      <div className="col-start-1 col-end-2">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">
          My District: {props.state}-{props.district}
        </h1>
        <LegislatorCard
          profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
          name={props.districtRep.firstName + " " + props.districtRep.lastName}
          party={props.districtRep.party}
          id={props.districtRep.id}
        />
      </div>
      <div className="col-start-2 col-end-4">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">
          My State: {props.state}
        </h1>
        <div className="grid grid-cols-2 gap-x-4">
          <LegislatorCard
            className="col-start-1 col-end-2"
            profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
            name={
              props.senateRep[0].firstName + " " + props.senateRep[0].lastName
            }
            party={props.senateRep[0].party}
            id={props.senateRep[0].id}
          />
          <LegislatorCard
            className="col-start-2 col-end-3"
            profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
            name={
              props.senateRep[1].firstName + " " + props.senateRep[1].lastName
            }
            party={props.senateRep[1].party}
            id={props.senateRep[1].id}
          />
        </div>
      </div>
    </div>

    // {/* <>
    //   <div className="bg-white shadow overflow-hidden sm:rounded-md">
    //     <div className="bg-gray-100 px-4 py-8 pb-10 border-b border-gray-200 sm:px-6">
    //       <div className="-ml-4 -mt-2 flex justify-between flex-wrap sm:flex-no-wrap grid grid-cols-3 gap-4">
    //         <div className="ml-8 mt-4">
    //           <h3 className="text-3xl font-bold leading-6 text-black">
    //             My District: {props.district}
    //           </h3>
    //           <br />
    //           {/* <div className="bg-white hover:shadow-md p-3 rounded-lg"> */}
    //             <Representative
    //               profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
    //               name="Elon Musky"
    //               party="D"
    //             />
    //           {/* </div> */}
    //         </div>
    //         <div className="ml-8 mt-4 col-span-2">
    //           <h3 className="text-3xl font-bold leading-6 text-black">
    //             My State: {props.state}
    //           </h3>
    //           <br />
    //           {/* <div className="bg-white hover:shadow-md p-3 rounded-lg flex justify-between"> */}
    //             {/* <div className="flex-1 lg:border-r lg:border-gray-300"> */}
    //               <Representative
    //                 profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
    //                 name="Elon Musky"
    //                 party="R"
    //               />
    //             {/* </div> */}
    //             {/* <div className="flex-1 lg:pl-5"> */}
    //               <Representative
    //                 profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
    //                 name="Elon Musky"
    //                 party="R"
    //               />
    //             {/* </div> */}
    //           {/* </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </> */}
  );
};

export default RegionInfo;
