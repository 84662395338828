import React, {useState} from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import { Transition } from "@headlessui/react";
import { db } from "../../../config/firebase"
import firebase from 'firebase';

const MyEventNav = (props) => {

  const history = useHistory()

  const [optionsOpen, setOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  }

  const handleDelete = () => {
    db.collection("events").doc(props.eventId).delete().then(() => {
      db.collection("organizations").doc(props.organizerId).collection("events").doc(props.eventId).delete().then(() => {
        // console.log("deleted event ", props.eventId)
        let storageRef = firebase.storage().ref();
        let photoRef = storageRef.child(`images/organization/events/${props.eventId}`)
        photoRef.delete().then(() => {
          history.goBack()
        })
      })
    })
  }

  return (
    <div className="mt-6">
      <h1 className="text-3xl font-bold mb-6">
        {props.title}
      </h1>
      <div className="flex justify-between items-center">
        <div className="flex space-x-6 text-2xl font-bold items-center">
          <NavLink
            to={`/org/events/${props.eventId}/details`}
            className='font-bold text-gray-400 hover:underline'
            exact
            activeClassName='text-gray-900'
          >
            Details
          </NavLink>
          <NavLink
            to={`/org/events/${props.eventId}/signups`}
            className='font-bold text-gray-400 hover:underline'
            exact
            activeClassName='text-gray-900'
          >
            Signups
          </NavLink>
        </div>
        <div className="flex space-x-2 items-center">
          <Link
            to={`/org/events/${props.eventId}/reshare`}
            className="btn-primary">
            Reshare Event
          </Link>
          <button className="btn-white" onClick={props.toggleSharePopup}>
            <div className="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                </svg>
              <div>Share</div>
            </div>
          </button>
          <div class="relative inline-block text-left pl-2">
            <div>
              <button onClick={toggleOptions} type="button" class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="options-menu" aria-expanded="true" aria-haspopup="true">
                <span class="sr-only">Open options</span>
                {/* <!-- Heroicon name: solid/dots-vertical --> */}
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
            </div>
            <Transition
            show={optionsOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            >
              <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="py-1" role="none">
                  <button onClick={handleDelete} class="focus:outline-none block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Delete Event
                  </button>
                  {/* <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Report content</a> */}
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );

};

export default MyEventNav;
