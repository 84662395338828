import React from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeFollowingNav from "../../../components/civ/home/following/HomeFollowingNav";
import IssuesFollowingDetailed from "../../../components/civ/home/following/HomeIssuesFollowing";

function HomeIssuesFollowingPage() {
	return (
		<AppLayout>
			<HomePageNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeFollowingNav />
				<IssuesFollowingDetailed />
			</div>
		</AppLayout>
	);
}

export default HomeIssuesFollowingPage;
