import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../../layouts/OrgLayout'
import LegislatorBillVoteComment from '../../../../components/org/global/legislators/LegislatorBillVoteCommentCard'
import { db } from '../../../../config/firebase'

const VoteCommentsPage = ({match, history}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [voteData, setVoteData] = useState({});
  const [commentsList, setCommentsList] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(async () => {
    if (match && match.params.vote) {

      let tempVoteId = match.params.vote;

      if (tempVoteId.length == 0) {
        setError("Bad link. No vote id given.");
      } else {

        const tempVoteData = await db.collection("votes").doc(tempVoteId).get();
        setVoteData(tempVoteData.data());

        const tempVoteComments = await db.collection("votes").doc(tempVoteId).collection("comments").get();
        // console.log("DATA:", tempBillComments.docs.map(doc => doc.data()))

        if (tempVoteComments.docs && tempVoteComments.docs.length > 0){
          // console.log('comments exist')
          // console.log("number of comments:", tempBillComments.docs.length)
          console.log(tempVoteComments.docs)
          setCommentsList(tempVoteComments.docs.map((doc) =>
            <LegislatorBillVoteComment
              legislatorId={doc.data().legislatorId}
              legislatorName={doc.data().legislatorName}
              legislatorState={doc.data().legislatorState}
              legislatorDistrict={doc.data().legislatorDistrict ? doc.data().legislatorDistrict : "Senate"}
              legislatorParty={doc.data().legislatorParty}
              datePosted={doc.data().datePosted}
              body={doc.data().body}
            />
          ))
        }
        else{
          setCommentsList(<h1 className="px-8 py-8 font-medium text-xl">No legislator comments at this time.</h1>)
        }

      }

    setLoading(false);
    }
  }, [match]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  if (error) {
    return <AppLayout>{error}</AppLayout>;
  }

  return(
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-6 mb-6">
          {capitalizeFirstLetter(voteData.chamber)} Vote #{voteData.number} — {voteData.billInfo.number}: {voteData.altTitle ? voteData.altTitle : voteData.billInfo.title}
        </h1>

        <div className="flex justify-between items-end mb-8">

          <h1 className="text-2xl font-bold">All Legislator Comments</h1>

          <div>
            <label for="location" class="block text-sm font-medium text-justiceBlue-500">
              Sort
            </label>
            <select
              id="location"
              name="location"
              class="dropdown-select"
            >
              <option selected>Most Recent</option>
              <option>Etc</option>
              <option>Etc</option>
            </select>
          </div>

        </div>

        {commentsList}

      </div>
    </AppLayout>
  )
}

export default VoteCommentsPage;