import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";
import SemiPrivateRoute from "./CivSemiPrivateRoute";

import IssueNotesPage from "../../pages/civ/issue/IssueNotesPage";
import IssueLearnMorePage from "../../pages/civ/issue/IssueLearnMorePage";
import IssueTakeActionPage from "../../pages/civ/issue/IssueTakeActionPage";
import IssueCopiesPage from "../../pages/civ/issue/IssueCopiesPage";
import CreateIssuePage from "../../pages/civ/issue/CreateIssuePage";
import CreateNotePage from "../../pages/civ/issue/CreateNotePage";
import IssueNoteDetailedPage from "../../pages/civ/issue/IssueNoteDetailedPage";

const IssueRoutes = () => {
	return (
		<Switch>
			<SemiPrivateRoute
				path="/issues/:issue/notes/:note"
				component={IssueNoteDetailedPage}
			/>
			<SemiPrivateRoute
				path="/issues/:issue/notes"
				component={IssueNotesPage}
			/>
			<SemiPrivateRoute
				path="/issues/:issue/learnmore"
				component={IssueLearnMorePage}
			/>
			<SemiPrivateRoute
				path="/issues/:issue/takeaction"
				component={IssueTakeActionPage}
			/>
			<SemiPrivateRoute
				path="/issues/:issue/copies"
				component={IssueCopiesPage}
			/>
			<PrivateRoute path="/issues/create" component={CreateIssuePage} />
			<PrivateRoute
				path="/issues/:issue/createNote"
				component={CreateNotePage}
			/>
			<PrivateRoute
				path="/issues/copy/:issue"
				component={CreateIssuePage}
			/>
		</Switch>
	);
};

export default IssueRoutes;
