import React, { useState, useEffect } from "react";
import LegislatorActivityBillCard from "../../global/bills/LegislatorActivityBillCard";
import LegislatorActivityVoteCard from "../../global/votes/LegislatorActivityVoteCard";
import {db} from '../../../../config/firebase'

const config = {
  headers: {
    "X-API-Key": "8GB085vQhogAnz8RDRXPAGlso7E2diqNJzR7gAyv",
  },
};

// function merge(bills, votes) {
//   let i = 0,
//     j = 0;
//   let retArr = [];
//   while (i < bills.length && j < votes.length) {
//     if (bills[i].introduced_date.localeCompare(votes[j].date) > 0) {
//       retArr.push(bills[i]);
//       i++;
//     } else {
//       retArr.push(votes[j]);
//       j++;
//     }
//   }

//   while (i < bills.length) {
//     retArr.push(bills[i]);
//     i++;
//   }

//   while (j < votes.length) {
//     retArr.push(votes[j]);
//     j++;
//   }

//   return retArr;
// }

const LegislatorActivity = (props) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  let maxPage = Math.ceil(props.count/20)
  let pagination = Array.from({length: maxPage}, (_, i) => i + 1)

  // useEffect(async () => {
  //   setLoading(true);
  //   if (props.id) {
  //     setItems([]);
  //     setPage(1);
  //     if (props.activity === "bills") {
  //       const res = await fetch(
  //         `https://api.propublica.org/congress/v1/members/${props.id}/bills/introduced.json`,
  //         config
  //       );
  //       const billsJson = await res.json();
  //       const billsData = billsJson.results[0].bills;
  //       setItems(billsData.map(async (bill) => {
  //         return (db.collection("bills").doc("hr1002-117").get().then((doc) => {
  //           console.log("DOC:", doc.data())
  //           let relatedIssueCount = doc.data().relatedIssueCount
  //           return ({
  //             ...bill,
  //             relatedIssueCount: relatedIssueCount
  //           })
  //         })
  //       )
  //       }))
  //       // setItems(billsData);
  //     } else {
  //       const res = await fetch(
  //         `https://api.propublica.org/congress/v1/members/${props.id}/votes.json`,
  //         config
  //       );
  //       const votesJson = await res.json();
  //       const votesData = votesJson.results[0].votes;
  //       setItems(votesData);
  //     }
  //     // setItems(merge(billsData, votesData));

  //     setLoading(false);
  //   }
  // }, [props.activity]);

  useEffect(async () => {
    setLoading(true);
    if (props.id) {
      setItems([]);
      if (props.activity === "bills") {
        const res = await fetch(
          `https://api.propublica.org/congress/v1/members/${
            props.id
          }/bills/introduced.json?offset=${(page - 1) * 20}`,
          config
        );
        const billsJson = await res.json();
        const tempBillsData = billsJson.results[0].bills;
        const billsData = tempBillsData.filter((bill) => {
          return(bill.congress == "117")
        })
        // setItems(billsData);
        Promise.all(billsData.map(async (bill) => {
          //TODO: once all bills loaded: change doc id to bill.bill_id
          let relatedIssueCount = await (await db.collection("bills").doc("hr1002-117").get()).data().relatedIssueCount
          return ({
            ...bill,
            relatedIssueCount: relatedIssueCount
          })
        })).then((result) => {
          setItems(result)
        })
      } else if(props.activity === "votes") {
        const res = await fetch(
          `https://api.propublica.org/congress/v1/members/${
            props.id
          }/votes.json?offset=${(page - 1) * 20}`,
          config
        );
        const votesJson = await res.json();
        const tempVotesData = votesJson.results[0].votes;
        const votesData = tempVotesData.filter((vote) => {
          return(vote.congress == "117")
        })
        // setItems(votesData);
        Promise.all(votesData.map(async (vote) => {
          let relatedIssueCount = await (await db.collection("votes").doc(`${vote.chamber}-${vote.roll_call}-${vote.congress}`).get()).data().relatedIssueCount
          return ({
            ...vote,
            relatedIssueCount: relatedIssueCount
          })
        })).then((result) => {
          setItems(result)
        })
      }
      // setItems(merge(billsData, votesData));

      setLoading(false);
    }
  }, [page]);

  useEffect(() => {}, [page]);

  if (loading) {
    return <>loading</>;
  }
  if (items.length === 0) {
    return <>This legislator has not had any recent activity</>;
  }
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
          {items.map((item) => {
            {/* console.log("ITEM:", item) */}
            if (props.activity === "bills") {
              return (
                <LegislatorActivityBillCard
                  billId={item.bill_id}
                  number={item.number}
                  title={item.short_title}
                  issues={item.relatedIssueCount}
                  introduced={item.introduced_date}
                  status={`Introduced, ${item.active ? "Active" : "Inactive"}`}
                  cosponsors={item.cosponsors}
                />
              );
            }
            else {
              return (
                <LegislatorActivityVoteCard
                  voteId={`${item.chamber}-${item.roll_call}-${item.congress}`}
                  number={`Vote #${item.roll_call}`}
                  bill={item.bill ? item.bill.number : ""}
                  title={item.description}
                  issues={item.relatedIssueCount}
                  date={item.date}
                  purpose={item.question}
                  outcome={item.result}
                  position={item.position}
                />
              );
            }
          })}
          {/* <LegislatorActivityBillCard
            title="Bill Title"
            issues="10"
            introduced="10/10/20"
            status="Introduced"
            cosponsors="7"
          /> */}
          {/* <LegislatorActivityVoteCard
            title="Bill Title"
            issues="10"
            date="10/10/20"
            purpose="On Passage of the Bill in the House"
            outcome="Passed"
          /> */}
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <div className="text-xl">{items.length} results</div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          {pagination.map((num) => {
            return (
              <button
                className={`${
                  num === page ? "bg-gray-200" : "bg-white"
                } focus:outline-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50`}
                onClick={() => setPage(num)}
              >
                {num.toString()}
              </button>
            );
          })}
        </nav>
        {/* <div className="flex space-x-4">
          {pagination.map((num) => {
            return (
              <div
                className={`${
                  num === page ? "bg-red-500" : "bg-red-300"
                } rounded-full w-8 h-8 text-white flex items-center justify-center cursor-pointer hover:bg-red-400`}
                onClick={() => setPage(num)}
              >
                {num.toString()}
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
};

export default LegislatorActivity;
