import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";

import CivilianSettingsPage from "../../pages/civ/civilian/CivilianSettingsPage";
import CivilianEditPage from "../../pages/civ/civilian/CivilianEditPage";
import CivilianIssuesPage from "../../pages/civ/civilian/CivilianIssuesPage";
import CivilianNotesPage from "../../pages/civ/civilian/CivilianNotesPage";
import CivilianOrganizationsPage from "../../pages/civ/civilian/CivilianOrganizationsPage";

const CivilianRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/users/:user/issues"
				component={CivilianIssuesPage}
			/>
			<PrivateRoute
				path="/users/:user/notes"
				component={CivilianNotesPage}
			/>
			<PrivateRoute
				path="/users/:user/organizations"
				component={CivilianOrganizationsPage}
			/>
			<PrivateRoute
				path="/users/editprofile"
				component={CivilianEditPage}
			/>
			<PrivateRoute
				path="/users/settings"
				component={CivilianSettingsPage}
			/>
		</Switch>
	);
};

export default CivilianRoutes;
