// import React, {useState, useEffect} from 'react';
// import protest from '../../../assets/protest.jpg' // temp event photo
// import { db } from '../../../config/firebase'

// const formatDate = (timestamp) => {
//   const daysOfWeek = [
//     'Sunday',
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday',
//   ];
//   if (timestamp) {
//     let date = new Date(timestamp.seconds * 1000);
//     let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
//     let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
//     yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
//     uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
//     let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

//     const numOfDayOfWeek = timestamp.toDate().getDay();


//     const fullString =
//       daysOfWeek[numOfDayOfWeek] +
//       ' ' +
//       f_dateString;

//     return fullString;
//   } else {
//     // if there isn't a date associated with the issue
//     return 'Date N/A';
//   }
// };

// const EventInfo = (props) => {

//   let hostLocationInfo;

//   if (props.isVirtual) {
//     hostLocationInfo = (
//       <div className="pt-2">
//         <h2 className='text-lg mb-2'>
//           <span className='font-medium'>Virtual Event</span><span> • Join from anywhere!</span>
//         </h2>
//         <h2 className='text-lg'>
//           Hosted from {props.city}, {props.state} {props.zipCode}
//         </h2>
//       </div>
//     );
//   } else {
//     hostLocationInfo = (
//       <div className="pt-2">
//         <h2 className='text-lg mb-2'>
//           <span className='font-medium'>In-Person Event</span>
//         </h2>
//         <h2 className='text-lg'>
//           Hosted at {props.streetAddress} {props.city}, {props.state} {props.zipCode}
//         </h2>
//       </div>
//     );
//   }

//   const [times, setTimes] = useState(<h1 className="text-lg mt-2">Loading...</h1>);

//   let tagsList = props.topicTags && (props.topicTags).map((tag) => {

//     let styling = ""
//     switch (tag) {
//       case "Climate":
//         styling = "bg-blue-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Healthcare":
//         styling = "bg-pink-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Economy":
//         styling = "bg-yellow-400 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Criminal Justice":
//         styling = "bg-purple-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Education":
//         styling = "bg-green-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Reproductive Rights":
//         styling = "bg-indigo-700 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "LGBTQ+":
//         styling = "bg-yellow-600 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Immigration":
//         styling = "bg-pink-700 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       case "Voting/Elections":
//         styling = "bg-red-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//       default:
//         styling = "bg-gray-500 px-5 py-2 rounded-full text-white font-medium"
//         break;
//     }

//     return (
//       <div className={styling}>{tag}</div>
//     )
//   })

//   useEffect(async () => {

//     db.collection("events").doc(props.eventId).collection("eventTimes").get().then((snapshot) => {
//       setTimes(snapshot.docs.map((doc) =>
//         <div className="text-lg mt-2">
//           {doc.data().startTimeString}—{doc.data().endTimeString} {doc.data().timeZone}
//         </div>
//       ))
//       })
//   }, [])

//   return (
//     <div>
//       <div className='grid grid-cols-2 gap-x-8 h-full'>

//         <div className="col-start-1 col-end-2">
//           <div className='flex items-center shadow-sm bg-gray-100 px-8 py-7 rounded-lg mb-3'>
//             <div>
//               <h1 className='text-2xl font-bold mb-4'>Time</h1>
//               <h2 className="font-medium text-lg">
//                 {formatDate(props.mainTime)}
//               </h2>
//               {console.log(props.eventTimes)}
//               {times}
//               {/* {customDateFormat(props.eventTimes)} */}
//             </div>
//           </div>
//           <div className='flex items-center shadow-sm bg-gray-100 px-8 py-7 rounded-lg'>
//             <div>
//               <h1 className='text-2xl font-bold mb-4'>Location</h1>
//               {hostLocationInfo}
//             </div>
//           </div>
//         </div>

//         <img
//           src={protest}
//           className='col-start-2 col-end-3 rounded-lg' />

//       </div>

//       <div className="my-10">
//         <h1 className="text-2xl font-bold mb-4">Description & Details</h1>
//         <div>{props.description}</div>
//       </div>

//       <div className="grid grid-cols-2 gap-x-8">
//         <div className="col-start-1 col-end-2">
//           <h1 className="text-2xl font-bold mb-4">Topic Tags</h1>
//           <div className="flex items-center space-x-4">
//             {tagsList}
//           </div>
//         </div>
//         <div className="col-start-2 col-end-3">
//           <h1 className="text-2xl font-bold mb-4">Attendance</h1>
//           <div>{props.numAttending} attending (RSVPed) • {props.numInterested} interested (bookmarked)</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EventInfo;

import React, {useState, useEffect} from 'react';
import protest from '../../../assets/protest.jpg' // temp event photo
import { db } from '../../../config/firebase'
import firebase from 'firebase';

const formatDate = (timestamp) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

    const numOfDayOfWeek = timestamp.toDate().getDay();


    const fullString =
      daysOfWeek[numOfDayOfWeek] +
      ' ' +
      f_dateString;

    return fullString;
  } else {
    // if there isn't a date associated with the issue
    return 'Date N/A';
  }
};

const EventInfo = (props) => {

  let hostLocationInfo;

  if (props.isVirtual) {
    hostLocationInfo = (
      <div className="pt-2">
        <h2 className='text-lg mb-2'>
          <span className='font-medium'>Virtual Event</span><span> • Join from anywhere!</span>
        </h2>
        <h2 className='text-lg'>
          Hosted from {props.city}, {props.state} {props.zipCode}
        </h2>
      </div>
    );
  } else {
    hostLocationInfo = (
      <div className="pt-2">
        <h2 className='text-lg mb-2'>
          <span className='font-medium'>In-Person Event</span>
        </h2>
        <h2 className='text-lg'>
          Hosted at {props.streetAddress} {props.city}, {props.state} {props.zipCode}
        </h2>
      </div>
    );
  }

  const [photoSrc, setPhotoSrc] = useState();
  const [times, setTimes] = useState(<h1 className="text-lg mt-2">Loading...</h1>);

  let tagsList = props.topicTags && (props.topicTags).map((tag) => {

    let styling = ""
    switch (tag) {
      case "Climate":
        styling = "bg-blue-500 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Healthcare":
        styling = "bg-pink-500 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Economy":
        styling = "bg-yellow-400 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Criminal Justice":
        styling = "bg-purple-500 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Education":
        styling = "bg-green-500 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Reproductive Rights":
        styling = "bg-indigo-700 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "LGBTQ+":
        styling = "bg-yellow-600 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Immigration":
        styling = "bg-pink-700 px-5 py-2 rounded-full text-white font-medium"
        break;
      case "Voting/Elections":
        styling = "bg-red-500 px-5 py-2 rounded-full text-white font-medium"
        break;
      default:
        styling = "bg-gray-500 px-5 py-2 rounded-full text-white font-medium"
        break;
    }

    return (
      <div className={styling}>{tag}</div>
    )
  })

  useEffect(async () => {

    db.collection("events").doc(props.eventId).collection("eventTimes").get().then((snapshot) => {
      setTimes(snapshot.docs.map((doc) =>
        <div className="text-lg mt-2">
          {doc.data().startTimeString}—{doc.data().endTimeString} {doc.data().timeZone}
        </div>
      ))
    }).then(() => {
      let storageRef = firebase.storage().ref();
      let photoRef = storageRef.child(`images/organization/events/${props.eventId}`)
      photoRef.getDownloadURL().then((url) => {
        setPhotoSrc(url)
      }).catch((error) => {
        console.log(error)
      })
    })
  }, [])

  return (
    <div>
      <div className='grid grid-cols-2 gap-x-8 h-full mb-10'>

        <div className="col-start-1 col-end-2">
          <div className="grid grid-cols-2 gap-x-6">

            <div className="col-start-1 col-end-2">
              <div className='items-start shadow-sm bg-gray-100 px-8 py-7 rounded-lg h-full'>
                <div>
                  <h1 className='text-2xl font-bold mb-4'>Time</h1>
                  <h2 className="font-medium text-lg">
                    {formatDate(props.mainTime)}
                  </h2>
                  {times}
                </div>
              </div>
            </div>

            <div className="col-start-2 col-end-3">
              <div className='items-start shadow-sm bg-gray-100 px-8 py-7 rounded-lg h-full'>
                <div>
                  <h1 className='text-2xl font-bold mb-4'>Location</h1>
                  {hostLocationInfo}
                </div>
              </div>
            </div>

          </div>

          <div className="my-10">
            <h1 className="text-2xl font-bold mb-4">Description & Details</h1>
            <div>{props.description}</div>
          </div>

        </div>

        <div className="col-start-2 col-end-3 w-full bg-gray-100 h-96 w-full rounded-lg">
          <img
            src={photoSrc}
            className='col-start-2 col-end-3 object-scale-down inline-block h-96 w-full rounded-lg' />
        </div>

      </div>

      {/* <div className="my-10">
        <h1 className="text-2xl font-bold mb-4">Description & Details</h1>
        <div>{props.description}</div>
      </div> */}

      <div className="grid grid-cols-2 gap-x-8">

        <div className="col-start-1 col-end-2">
          <h1 className="text-2xl font-bold mb-4">Topic Tags</h1>
          <div className="flex items-center space-x-4">
            {tagsList}
          </div>
        </div>

        <div className="col-start-2 col-end-3">
          <h1 className="text-2xl font-bold mb-4">Attendance</h1>
          <div>{props.numAttending} attending (RSVPed) • {props.numInterested} interested (bookmarked)</div>
        </div>

      </div>

    </div>
  );
};

export default EventInfo;
