import React, { useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";

const VoteCardSmall = (props) => {
  return (
    <Link to={`/bills-votes/votes/${props.voteId}`}>
      <div className="shadow hover:shadow-md rounded-lg px-8 py-6">
        <h1 className="text-2xl font-semibold mb-4 truncate">{props.title}</h1>
        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center">
            <div className="bg-gray-500 px-4 py-1 rounded-lg text-white font-medium">
              {props.outcome}
            </div>
            {/* <h1 className="text-lg font-medium">
              {props.chamber}
            </h1> */}
            <h1 className="text-lg text-gray-500">
              Voted {props.dateVoted}
            </h1>
          </div>
          <div className="flex items-center text-red-500">
            <Link className="text-md font-semibold">
              View Vote
            </Link>
            <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default VoteCardSmall;
