import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import AuthPopup from '../../../components/civ/global/AuthPopup'
import OrganizationHeader from "../../../components/civ/organization/OrganizationHeader";
import OrganizationIssues from "../../../components/civ/organization/OrganizationIssues";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png";

function OrganizationIssuesPage({ match }) {
	const { currUser } = useAuth();

	// const [issues, setIssues] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [organizationInfo, setOrganizationInfo] = useState({});
	const [following, setFollowing] = useState();
	const [orgPosts, setOrgPosts] = useState([]);
	const [src, setSrc] = useState(defaultProfilePic);
  const [authPopup, setAuthPopup] = useState(false);

	useEffect(async () => {
		if (match && match.params.organization) {
			let organizationId = match.params.organization;

			if (organizationId.length == 0) {
				setError("Bad link. No organization id given.");
			} else {
				let storageRef = firebase.storage().ref();
				let userPicRef = storageRef.child(
					`images/organization/profilePics/${organizationId}`
				);
				try {
					let result = await userPicRef.getDownloadURL();
					setSrc(result);
				} catch (error) {
					console.log(error);
				}

				db.collection("organizations")
					.doc(organizationId)
					.get()
					.then((organizationData) => {
						setOrganizationInfo(organizationData.data());

              db.collection("organizations")
                .doc(match.params.organization)
                .collection("posts")
                .where("contentType", "==", "issue")
                .get()
                .then((posts) => {
                  // console.log(posts.docs.length)

                  return (
                    posts.docs &&
                    posts.docs.map((post) => ({
                      orgId: organizationId,
                      orgName: organizationData.data()
                        .name,
                      postId: post.id,
                      ...post.data(),
                    }))
                  );
                })
                .then((orgPostsList) => {
                  orgPostsList.sort((a, b) =>
                    a.datePosted < b.datePosted
                      ? 1
                      : -1
                  );

                  setOrgPosts(orgPostsList);

                  if (currUser){
                  db
                  .collection("civilians")
                  .doc(currUser.uid)
                  .collection("orgsFollowing")
                  .doc(organizationId)
                  .get()
                  .then((result) => {
                    if (result.exists) {
                      setFollowing(true);
                      // console.log("doc found!")
                    } else {
                      setFollowing(false);
                      // console.log("doc not found!")
                    }
                  })
                  }
                  // console.log("number of posts:", orgPostsList)
                });
            })
					.then(() => {
						setLoading(false);
					});
			}
		}
	}, []);

  function toggleAuthPopup(){
    setAuthPopup(!authPopup)
    // console.log("toggled:", authPopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}

	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>

      {authPopup &&
        <AuthPopup
          toggleAuthPopup={toggleAuthPopup}
          type="organization"
          >
        </AuthPopup>
      }

      {currUser ?
        <div>
          {(following===true || following===false) && <OrganizationHeader
            orgId={match.params.organization}
            orgName={organizationInfo.name}
            activeIssueCount={organizationInfo.activeIssueCount}
            upcomingEventCount={organizationInfo.upcomingEventCount}
            followerCount={organizationInfo.followerCount}
            hqState={organizationInfo.hqState}
            hqCity={organizationInfo.hqCity}
            following={following}
            profilePicUrl={src}
            toggleAuthPopup={toggleAuthPopup}
          /> }
        </div>
			  :
        <OrganizationHeader
            orgId={match.params.organization}
            orgName={organizationInfo.name}
            activeIssueCount={organizationInfo.activeIssueCount}
            upcomingEventCount={organizationInfo.upcomingEventCount}
            followerCount={organizationInfo.followerCount}
            hqState={organizationInfo.hqState}
            hqCity={organizationInfo.hqCity}
            following={following}
            profilePicUrl={src}
            toggleAuthPopup={toggleAuthPopup}
          />
      }
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<OrganizationIssues orgPosts={orgPosts} profilePicUrl={src} />
			</div>
		</AppLayout>
	);
}

export default OrganizationIssuesPage;
