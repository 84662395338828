import React from "react";

import AppLayout from "../../../layouts/AppLayout";
import RankingBoard from "../../../components/civ/legislators/RankingBoard";
import Members from "../../../components/civ/legislators/Members";
import Committees from "../../../components/civ/legislators/Committees";

const LegislatorsPage = () => {
	return (
		<AppLayout>
			<header className="sm:px-8 lg:px-16 py-6 shadow-md">
				<h1 className="text-3xl font-bold">Legislators</h1>
			</header>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				{/* <RankingBoard /> */}
				{/* <br /> */}
				<Members />
				<br />
				<Committees />
			</div>
		</AppLayout>
	);
};

export default LegislatorsPage;
