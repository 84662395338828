import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import defaultProfilePic from "../../../../assets/DefaultProfilePic.png"
import { db } from "../../../../config/firebase"

function BigLegislatorCard(props) {
  console.log("this is pic: " + props.profilePic);

  const [issueCount, setIssueCount] = useState("N/A")
  const [imgSrc, setImgSrc] = useState(defaultProfilePic);

  function checkImage(url) {
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function() {
      // let status = request.status;
      if (request.status === 200) //if(statusText == OK)
      {
        setImgSrc(`https://theunitedstates.io/images/congress/225x275/${props.id}.jpg`)
      }
      // else {
      //   console.log("image doesn't exist");
      // }
    }
  }

  useEffect(async () => {

    checkImage(`https://theunitedstates.io/images/congress/225x275/${props.id}.jpg`);

    db.collection("legislators").doc(props.id).get().then((result) => {
      setIssueCount(result.data().issueCount)
    })

  }, [])


  return (
    <Link
      to={`/legislators/${props.id}/bills`}
    >
    <div className="w-full flex space-x-6 items-center bg-white shadow hover:shadow-md md:px-8 md:py-5 rounded-lg" >
      <img
        // src={props.profilePic}
        src={imgSrc}
        className="flex-none w-24 h-24 bg-gray-300 rounded-full object-cover"
      />

      <div className="w-full">
        <div className="">
          <h1 className="text-xl font-semibold">
            {props.name} ({props.party})
          </h1>
          <h2 className="text-sm mt-1">{issueCount} issues</h2>
        </div>

        <div className="flex items-center justify-end mt-4 text-sm font-semibold text-red-500">
          <h2>View profile</h2>
          <svg
            className="flex-shrink-0 ml-1.5 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </div>
      </div>
    </div>
    </Link>
  );
}

export default BigLegislatorCard;
