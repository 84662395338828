import React, { useEffect, useState } from 'react';
import OrgLayout from "../../../layouts/OrgLayout"
import firebase from "firebase";
import { db } from '../../../config/firebase';
import { useAuth } from "../../../contexts/AuthContext";

const ReshareIssuePage = ({ match, history }) => {

  const { currUser } = useAuth();
  const [comment, setComment] = useState("")
  const [organizationId, setOrganizationID] = useState("")
  const [issueInfo, setIssueInfo] = useState({
    issueTitle: "",
    legislatorName: "",
    legislatorParty: "",
    legislatorState: "",
    issueDateCreated: "",
    regionalCopyCount: "",
    supporterCount: "",
    issueTopicTags: "",
    issueId: ""
  })
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(async () => {

    setLoading(true);

    if (!match.params.issue) {
      setStatus({error: "Invalid issue ID."})
    }
    else {
      let issueId = match.params.issue;

      //TODO: change field names as needed
      db.collection("issues").doc(issueId).get().then((doc) => {
        setIssueInfo({
          issueTitle: doc.data().issueTitle,
          legislatorName: doc.data().legislator.firstName + " " + doc.data().legislator.lastName,
          legislatorParty: doc.data().legislator.party,
          legislatorState: doc.data().legislator.region,
          issueDateCreated: doc.data().dateCreated,
          regionalCopyCount: doc.data().regionalCopies,
          supporterCount: doc.data().supporters,
          issueTopicTags: doc.data().topicTags,
          issueId: issueId
        })
        setOrganizationID(currUser.uid)
      })

    }

    setLoading(false);

  }, [])

  function handleChange(e) {
    setComment(e.target.value);
  }

  async function handleSubmit(e){
    e.preventDefault(); //prevents form from refreshing
    setLoading(true);

    console.log("submitting...")

    let docRef = db.collection("organizations").doc(organizationId).collection("posts").doc()

    console.log(issueInfo)

    docRef.set({
      contentType: "issue",
      datePosted: firebase.firestore.Timestamp.now(),
      postBody: comment,
      issue: {
        issueTitle: issueInfo.issueTitle,
        legislatorName: issueInfo.legislatorName,
        legislatorParty: issueInfo.legislatorParty,
        legislatorState: issueInfo.legislatorState,
        issueDateCreated: issueInfo.issueDateCreated,
        regionalCopyCount: issueInfo.regionalCopyCount,
        supporterCount: issueInfo.supporterCount,
        issueTopicTags: issueInfo.issueTopicTags,
        issueId: issueInfo.issueId
      }

    }).then(() => {
      db.collection("organizations").doc(organizationId).update({
        activeIssueCount: firebase.firestore.FieldValue.increment(1)
      })
    })

    setLoading(false);
    setStatus({ success: "Your post was successful." });
    setTimeout(() => history.goBack(), 500);

  }

  return (
    <OrgLayout>

      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-6 mb-8">Reshare Issue</h1>

        <form
          action="#"
          method="POST"
          class="space-y-8"
          onSubmit={handleSubmit}
        >

          <div className="mt-4">
            <label
              for="title"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Issue Title
            </label>
            <input
              disabled
              id="title"
              name="title"
              // placeholder="Enter event title here..."
              type="text"
              value={issueInfo.issueTitle}
              maxLength="100"
              required
              // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              className="bg-gray-100 text-gray-700 shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="mt-4">
            <label
              for="title"
              className="block whitespace-no-wrap text-2xl font-semibold leading-tight text-gray-900 mb-5"
            >
              Comment
            </label>
            <textarea
              id="title"
              name="title"
              placeholder="Enter text here..."
              type="text"
              rows={5}
              value={comment}
              maxLength="1000"
              required
              onChange={handleChange}
              // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <h1 className="text-gray-500 float-right mt-2">
              {comment.length}/1000 characters
            </h1>
          </div>

          {status.error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{status.error}</span>
            </div>
          )}
          {status.success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{status.success}</span>
            </div>
          )}
          <div className="flex space-x-4">
            <button
              disabled={loading}
              type="submit"
              className="btn-primary"
            >
              Post
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn-white"
            >
              Cancel
            </button>
          </div>

        </form>

      </div>

    </OrgLayout>
  )

}

export default ReshareIssuePage;
