import React from "react";
import MemberCard from "./MemberCard";
import {states_hash} from "../../../assets/states"
const StateMembers = (props) => {
  //   const [members, setMembers] = useState([]);
  //   const [loading, setLoading] = useState(true);
  //   useEffect(async () => {
  //     if (members.length == 0) {
  //       // const membersRef = db.collection("legislators");
  //       const snapshot = await db
  //         .collection("legislators")
  //         .orderBy("region")
  //         .get();
  //       if (!snapshot.empty) {
  //         snapshot.forEach((doc) => {
  //           if ("firstName" in doc.data() && doc.data()["firstName"] !== "")
  //             setMembers((members) => [...members, doc.data()]);
  //         });
  //       } else {
  //         console.log("empty snapshot");
  //       }
  //       setLoading(false);
  //     }
  //   }, []);

  //   if (loading) {
  //     return <></>;
  //   }
  return (
    <>
      <div className="bg-gray-50 mb-5 shadow rounded-lg">
        <div className="px-4 pt-5 sm:px-6">
          <h1 className=" whitespace-no-wrap text-2xl font-bold leading-tight">
            {states_hash[props.state] + " (" + props.state + ")"}
          </h1>
        </div>

        {props.senate && (
          <div className=" px-4 py-5 sm:p-6">
            <p className="text-xl mb-3">Senators</p>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 h-full">
              {props.senate.map((member) => {
                return (
                  <>
                    <MemberCard {...member} />
                  </>
                );
              })}
            </ul>
          </div>
        )}

        <div className=" px-4 py-5 sm:p-6">
          <p className="text-xl mb-3">House of Representatives</p>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 h-full">
            {props.house
              .sort((a, b) => {
                return a.district - b.district;
              })
              .map((member) => {
                return (
                  <>
                    <MemberCard {...member} />
                  </>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default StateMembers;
