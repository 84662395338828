import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import TopicCard from './TopicCard'
import {StateDBContext} from '../../../../contexts/StateContext'

const StateTopicEvents = () => {

    const topicEvents = useContext(StateDBContext);
    let [typeDisplay, setDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    useEffect(() => {

      if(topicEvents[7] != null && Object.keys(topicEvents[7]).length !== 0) {
        setDisplay(Object.keys(topicEvents[7]).map((topic) =>
          (<TopicCard name={topic} data={topicEvents[7][topic]} trendingTopic={false}/>)
        ))
      }
      else{
        setDisplay(<h1 className="px-8 py-8 font-medium text-xl">There are currently no upcoming events in your state.</h1>)
      }

    }, [topicEvents]);



    return (
      <div className="grid grid-cols-4 gap-x-5">
        {typeDisplay}
      </div>
    )

}

export default StateTopicEvents;
