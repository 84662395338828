import React, { useState, useEffect } from "react";
// import { db } from "../../../config/firebase";
// import { NavLink } from "react-router-dom";
import AppLayout from "../../../layouts/OrgLayout";
import LegislatorHeader from "../../../components/org/legislator/LegislatorHeader";
import LegislatorActivity from "../../../components/org/legislator/LegislatorActivity";
import legislators from "../../../assets/jsonData/legislators.json"
const axios = require("axios");

const config = {
  headers: {
    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
  },
};

const LegislatorActivityPage = ({ match }) => {
  const [legislator, setLegislator] = useState({});
  const [billsCount, setBillsCount] = useState();
  const [headerData, setHeaderData] = useState("");
  const [legislatorId, setLegislatorId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(async () => {
      // pulls name and issue infomation for now
      // future optimization for less reads: pass in the name so we don't have to get that document
      // let legislatorId = match.params.legislator;
      // if (legislatorId.length == 0) {
      //   setError("Bad link. No user id given.");
      // } else {
      //   setLegislatorId(legislatorId);
      //   const currLegRef = db.collection("legislators").doc(legislatorId);
      //   const legislatorDoc = await currLegRef.get();
      //   if (!legislatorDoc.exists) {
      //     setError("This legislator was not found.");
      //   } else {
      //     setLegislator(legislatorDoc.data());
      //   }
      // }
      // setLoading(false);
      let legislatorId = match.params.legislator;
      if (legislatorId.length == 0) {
        setError("Bad link. No user id given.");
      } else {
        setLegislatorId(legislatorId);
        console.log("ID:", legislatorId)
        // const currLegRef = db.collection("legislators").doc(legislatorId);
        const legislatorDoc = legislators[legislatorId]
        // console.log("legISLATOR INFO", legislatorDoc)
        if (!legislatorDoc) {
          setError("This legislator was not found.");
        } else {
          setLegislator(legislatorDoc);
          axios.get(`https://api.propublica.org/congress/v1/members/${legislatorId}.json`, config)
          .then((response) => {
            console.log("leg DATA:", response.data.results[0])
            // setHeaderData((response.data.results[0].roles[0].bills_sponsored+response.data.results[0].roles[0].bills_cosponsored) + " Bills")
            setBillsCount(response.data.results[0].roles[0].bills_sponsored)
            setHeaderData(response.data.results[0].roles[0].bills_sponsored + " Bills (117th Congress)")
          })
        }
      }
      setLoading(false);
  }, []);

  if (loading) {
    return (
      <AppLayout>
        <></>
      </AppLayout>
    );
  }
  if (error) {
    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">{error}</div>
      </AppLayout>
    );
  }
  return (
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <LegislatorHeader {...legislator} legislatorId={legislatorId} headerData={headerData} />

        <LegislatorActivity
          id={legislatorId}
          count={billsCount}
          activity={"bills"}
        />

      </div>
    </AppLayout>
  );
};

export default LegislatorActivityPage;
