import React, {useState} from 'react';
import { db } from '../../../../config/firebase'
import LegislatorCard from '../../global/legislators/LegislatorCard'
import { useAuth } from '../../../../contexts/AuthContext'

const StateDashboardSummary = () => {

  const { userData } = useAuth();

  const [issueCount, setIssueCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  db.collection("issues").where("region", "==", userData.state).get().then((result) => {
    setIssueCount(result.docs.length)
  });

  db.collection("civilians").where("state", "==", userData.state).get().then((result) => {
    setUserCount(result.docs.length)
  });


  return (
    <div className="bg-gray-100 grid grid-cols-3 gap-16 items-center px-16 py-10">

      <div className="shadow col-start-1 col-end-2 bg-white rounded-lg px-6 py-8 w-full">
        <div className="grid grid-cols-2 items-center">
          <div className="pl-10 text-6xl font-bold text-gray-700">
            {userData.state}
          </div>
          <div className="col-start-2">
            <h1 className="text-xl"><span className="font-medium">Total issues:</span> {issueCount}</h1>
            <h1 className="text-xl"><span className="font-medium">Total users:</span> {userCount}</h1>
            {/* <h1 className="text-xl">Population: </h1> */}
            {/* <h1 className="text-xl">Your Ranking: </h1> */}
          </div>
        </div>
      </div>

      <div className="col-start-2 col-end-4">
        <h1 className="text-3xl font-bold mb-4">Senators</h1>
        <div className="grid grid-cols-2 gap-x-8">

          {/* TODO: change .senateRep */}
          <LegislatorCard
            className="col-start-1 col-end-2"
            profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
            id={userData.senateRep[0].id}
            name={userData.senateRep[0].firstName + " " + userData.senateRep[0].lastName}
            party={userData.senateRep[0].party}
          />
          <LegislatorCard
            className="col-start-2 col-end-3"
            profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
            id={userData.senateRep[1].id}
            name={userData.senateRep[1].firstName + " " + userData.senateRep[1].lastName}
            party={userData.senateRep[1].party}
          />
          
        </div>
      </div>
    </div>
  )
}

export default StateDashboardSummary;
