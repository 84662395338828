import React, { useState } from 'react';
import {Link} from "react-router-dom";
import LandingLayout from '../../layouts/LandingLayout';
import megaphoneIconWhite from '../../assets/megaphone-white.png';
import { useAuth } from '../../contexts/AuthContext';

const ForgotPassword = () => {
    const [credentials, setCredentials] = useState({
        "email": "",
    });
    const [err, setErr] = useState('');
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const { resetPassword } = useAuth()

    async function handleSubmit(e) {
        e.preventDefault();                      //prevents form from refreshing
        setErr('');
        try {
            setLoading(true);
            await resetPassword(credentials.email);
            setMsg('Email sent. Please check your inbox for further instructions!');
        } catch {
            setErr("Failed to send password reset email");
        }
        setLoading(false);
    }

    function handleChange(e) {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });

    }
    return (
        <LandingLayout>
            <div className="min-h-screen bg-justiceBlue-400 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-24 w-auto rounded-full" src={megaphoneIconWhite} alt="Workflow"/>
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                    Reset your password
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-white max-w">
                    Or
                    <Link to="/signup" className="pl-1 font-medium text-white hover:text-red-600 focus:outline-none focus:underline transition ease-in-out duration-150">
                    create an account
                    </Link>
                </p>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form action="#" method="POST" onSubmit={handleSubmit}>
                    {err && <>
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Holy smokes! </strong>
                            <span className="block sm:inline">{err}</span>
                        </div>
                        <br />
                        </>
                    }
                    {msg && <>
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{msg}</span>
                        </div>
                        <br />
                        </>
                    }

                    <div>
                        <label for="email" className="block text-sm font-medium leading-5 text-gray-700">
                        Email address
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                        <input id="email" name="email" type="email" value={credentials.email} onChange={handleChange} required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-between">

                        <div className="text-sm leading-5">
                        <Link to="/login" className="font-medium text-red-500 hover:text-red-400 focus:outline-none focus:underline transition ease-in-out duration-150">
                            Back to login
                        </Link>
                        </div>
                    </div>

                    <div className="mt-6">
                        <span className="block w-full rounded-md shadow-sm">
                        <button disabled={loading} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:border-red-500 focus:shadow-outline-red-500 active:bg-red-600 transition duration-150 ease-in-out">
                            Send reset email
                        </button>
                        </span>
                    </div>
                    </form>

                </div>
                </div>
            </div>
        </LandingLayout>
    )
}

export default ForgotPassword;
