import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../../layouts/OrgLayout'
import CosponsorCard from '../../../../components/org/global/legislators/CosponsorCard'
import { db } from '../../../../config/firebase'
const axios = require("axios");

const config = {
  headers: {
    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
  },
};

const BillCosponsorsPage = ({match, history}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [billName, setBillName] = useState("");
  const [sponsor, setSponsor] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);
  const [cosponsors, setCosponsors] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect(async () => {
    if (match && match.params.bill) {

      let tempBillId = match.params.bill;

      if (tempBillId.length == 0) {
        setError("Bad link. No bill ID given.");
      } else {

        const billNumber = await (await db.collection("bills").doc(tempBillId).get()).data().number;
        const billTitle = await (await db.collection("bills").doc(tempBillId).get()).data().title;
        const billSlug = await (await db.collection("bills").doc(tempBillId).get()).data().slug;
        setBillName(billNumber + ": " + billTitle)

        // console.log("bill id:", tempBillId)
        // const tempSponsor = await db.collection("bills").doc(tempBillId).get();

        // setSponsor(<CosponsorCard
        //             legislatorName={tempSponsor.data().sponsor}
        //             legislatorState={tempSponsor.data().region}
        //             legislatorParty={tempSponsor.data().party}
        //             />)

        // console.log("slug:", billSlug)

        axios.get(`https://api.propublica.org/congress/v1/117/bills/${billSlug}/cosponsors.json`, config)
        .then((response) => {

          // console.log("sponsor id:")
          setSponsor(<CosponsorCard
            legislatorId={response.data.results[0].sponsor_id}
            legislatorName={response.data.results[0].sponsor_name}
            legislatorState={response.data.results[0].sponsor_state}
            legislatorParty={response.data.results[0].sponsor_party}
            />)

          const tempCosponsors = response.data.results[0].cosponsors
          if (tempCosponsors && tempCosponsors.length > 0){
            setCosponsors(tempCosponsors.map((cosponsor) =>
              <CosponsorCard
                legislatorId={cosponsor.cosponsor_id}
                legislatorName={cosponsor.name}
                legislatorState={cosponsor.cosponsor_state}
                legislatorParty={cosponsor.cosponsor_party}
              />
            ))
          }
          else{
            setCosponsors(<h1 className="px-8 py-8 font-medium text-xl">No co-sponsors.</h1>)
          }

        })
        // const tempCosponsors = await db.collection("bills").doc(tempBillId).collection("cosponsors").get();
        // console.log("DATA:", tempBillComments.docs.map(doc => doc.data()))

      }

    setLoading(false);
    }
  }, [match]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  if (error) {
    return <AppLayout>{error}</AppLayout>;
  }

  return(
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-6 mb-8">{billName}</h1>

        <h1 className="text-2xl font-semibold mb-6">Sponsor</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-md mb-10">
          <div className="divide-y divide-gray-200">
            {sponsor}
          </div>
        </div>

        <h1 className="text-2xl font-semibold mb-6">Co-sponsors</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {cosponsors}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default BillCosponsorsPage;