import React from 'react';
import { NavLink, useLocation} from "react-router-dom";
// import { StoriesProvider } from '../../contexts/NationContext';


const NationNav = (props) => {

  const {pathname} = useLocation();

  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">The Nation</h1>
      </header>
      <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex space-x-8">
        {/* <NavLink to="/nation" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-300" exact activeClassName="text-gray-900">
          What's Happening
        </NavLink> */}
        <NavLink to="/nation/issues/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
                isActive={() => ["/nation/issues/top", "/nation/issues/latest", "/nation/issues/topics", "/nation/issues/resolved"].includes(pathname)}>
          Issues
        </NavLink>
        <NavLink to="/nation/events/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
                isActive={() => ["/nation/events/top", "/nation/events/latest", "/nation/events/topic", "/nation/events/type"].includes(pathname)}>
          Events
        </NavLink>
        <NavLink to="/nation/organizations/top" className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900"
                isActive={() => ["/nation/organizations/top", "/nation/organizations/category", "/nation/organizations/list"].includes(pathname)}  >
          Organizations
        </NavLink>
      </div>
      <hr className="border-solid"/>
    </>
  )
}

export default NationNav;
