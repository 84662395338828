import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { db } from "../../../config/firebase";
import AppLayout from "../../../layouts/OrgLayout";
import LegislatorHeader from "../../../components/org/legislator/LegislatorHeader";
import LegislatorIssues from "../../../components/org/legislator/LegislatorIssues";
// import { useAuth } from "../../../contexts/AuthContext";
import legislators from "../../../assets/jsonData/legislators.json"

const LegislatorIssuesPage = ({ match }) => {
  // const { userData } = useAuth();
  const [legislator, setLegislator] = useState({});
  const [headerData, setHeaderData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [legislatorId, setLegislatorId] = useState("");
  useEffect(async () => {
    if (match && match.params.legislator) {
      // pulls name and issue infomation for now
      // future optimization for less reads: pass in the name so we don't have to get that document
      let legislatorId = match.params.legislator;
      if (legislatorId.length == 0) {
        setError("Bad link. No user id given.");
      } else {
        // const currLegRef = db.collection("legislators").doc(legislatorId);
        const legislatorDoc = legislators[legislatorId]
        if (!legislatorDoc) {
          setError("This legislator was not found.");
        } else {
          setLegislator(legislatorDoc);
          setLegislatorId(legislatorId);
          db.collection("legislators").doc(legislatorId).get().then((result) => {
            setHeaderData(result.data().issueCount + " Issues")
          })
        }
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <AppLayout>
        <></>
      </AppLayout>
    );
  }
  if (error) {
    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">{error}</div>
      </AppLayout>
    );
  }
  return (
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <LegislatorHeader {...legislator} legislatorId={legislatorId} headerData={headerData}/>

        <LegislatorIssues legislatorId={legislatorId} />
      </div>
    </AppLayout>
  );
};

export default LegislatorIssuesPage;
