import React from 'react';
import { NavLink, useLocation} from "react-router-dom";

const BillNav = (props) => {
    const {pathname} = useLocation();
    return(
      <div className="flex items-center justify-between mb-8 flex-wrap sm:flex-no-wrap">
        <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
            <NavLink to="/leg/bills-votes/bills/latest" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400" exact activeClassName="text-gray-900"
                isActive={() => ["/leg/bills-votes", "/leg/bills-votes/bills/latest"].includes(pathname)} >
            Latest
            </NavLink>
            <NavLink to="/leg/bills-votes/bills/most-popular" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400" activeClassName="text-gray-900">
            Most Popular
            </NavLink>
            <NavLink to="/leg/bills-votes/bills/least-popular" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400" activeClassName="text-gray-900">
            Least Popular
            </NavLink>
        </div>
        <div className="ml-4 flex-shrink-0">
          <div>
          <label for="location" className="block text-sm leading-5 font-medium text-justiceBlue-500">Sort By</label>
          <select id="location" onChange={(e) => {props.setSort(e.target.value)}} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md">
              <option selected>None</option>
              <option>Date Introduced</option>
              <option>Number of Cosponsors</option>
              <option>Number of Issues</option>
          </select>
          </div>
        </div>
      </div>
    );
}

export default BillNav;
