import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";
import { db } from "../../config/firebase";
import firebase from "firebase";

const OrgLegSignupPage = () => {

  const [userData, setUserData] = useState({
    accountType: "organization",
    entityName: "",
    email: "",
    websiteLink: "",
    personName: "",
    accountCreated: false,
    submissionDate: firebase.firestore.Timestamp.now()
  });
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    setErr("");
    setLoading(true);
    try {
      const res = await db.collection("/orgLegAccountRequests").add(userData);
      console.log("added account request with id: " + res.id);
      setUserData({
        accountType: "organization",
        entityName: "",
        email: "",
        websiteLink: "",
        personName: "",
        accountCreated: false,
        submissionDate: firebase.firestore.Timestamp.now()
      });
      setSuccess("Successfully submitted! You should receive an email from grassrootsgov.team@gmail.com in 1-2 business days.");
    }
    catch {
      setErr("Failed to submit form. Please try again.");
    }
    setLoading(false);
  }

  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    // console.log("field:", [e.target.name])
    // console.log("value:", e.target.value)
    // console.log("userdata:", userData)
  }

  return (
    <LandingLayout>
      <div class="bg-gradient-to-t from-blue-100 to-blue-50 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div class="relative max-w-xl mx-auto">
          <svg
            class="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            class="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div class="text-center">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Request an organization or legislator account
            </h2>
            <p class="mt-4 text-lg leading-6 text-gray-500">
              Please fill out this form to request to create an account. <br/> We'll email you your login credentials in 1-2 business days.
            </p>
          </div>
          <div class="mt-12">
            {err && (
                <>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Holy smokes!</strong>
                    <span className="block sm:inline">{err}</span>
                  </div>
                  <br />
                </>
              )}
              {success && (
                <>
                  <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">{success}</span>
                  </div>
                  <br />
                </>
              )}
            <form
              onSubmit={handleSubmit}
              // action="#"
              // method="POST"
              class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >

              <div className="sm:col-span-2 items-center flex space-x-8">
                <label
                  for="accountType"
                  class="block text-sm font-medium text-gray-700"
                >
                  Account type
                </label>
                <div className="flex items-center space-x-8">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="typeOrganization"
                      name="accountType"
                      value="organization"
                      onChange={handleChange}
                      className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                      checked={userData.accountType === "organization"}
                    />
                    <label
                      for="typeOrganization"
                      className="ml-2 block text-sm leading-5 text-gray-700"
                    >
                      Organization
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="typeLegislator"
                      name="accountType"
                      value="legislator"
                      onChange={handleChange}
                      className="h-4 w-4 text-justiceBlue-500 transition duration-150 ease-in-out"
                      checked={userData.accountType === "legislator"}
                    />
                    <label
                      for="typeLegislator"
                      className="ml-2 block text-sm leading-5 text-gray-700"
                    >
                      Legislator
                    </label>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="occupation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Name of organization or legislator
                </label>
                <div class="mt-1">
                  <input
                    required
                    type="text"
                    name="entityName"
                    id="entityName"
                    value={userData.entityName}
                    onChange={handleChange}
                    autocomplete="occupation"
                    placeholder=""
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email (this will also be used for your account login credentials)
                </label>
                <div class="mt-1">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    value={userData.email}
                    onChange={handleChange}
                    autocomplete="email"
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="referral"
                  class="block text-sm font-medium text-gray-700"
                >
                  Link to a page from your official website where the above email can be found <br/> (so we can verify your identity)
                </label>
                <div class="mt-1">
                  <input
                    required
                    id="websiteLink"
                    name="websiteLink"
                    type="text"
                    value={userData.websiteLink}
                    onChange={handleChange}
                    placeholder=""
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>


              <div class="sm:col-span-2">
                <label
                  for="affiliation"
                  class="block text-sm font-medium text-gray-700"
                >
                  Name of person filling out this form
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="personName"
                    id="personName"
                    value={userData.personName}
                    onChange={handleChange}
                    placeholder=""
                    class="py-3 px-4 block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2 mt-4">
                <button
                  disabled={loading}
                  type="submit"
                  class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-justiceBlue-600 hover:bg-justiceBlue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue"
                >
                  Request account
                </button>
              </div>
              <p className="sm:col-span-2 text-sm text-gray-500">
                We care about the protection of your data. Read our <Link to="/privacy-policy" className="underline">Privacy Policy</Link>.
              </p>
            </form>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default OrgLegSignupPage;
