import React from "react";
import { Link, NavLink } from "react-router-dom";

const StateIssuesNav = () => {
  return (
    <div className="flex justify-between items-center mb-8">
      <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
        <NavLink
          to="/state/issues/top"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          exact
          activeClassName="text-gray-900"
        >
          Top Issues
        </NavLink>
        <NavLink
          to="/state/issues/latest"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          activeClassName="text-gray-900"
        >
          Latest Issues
        </NavLink>
        <NavLink
          to="/state/issues/topics"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          activeClassName="text-gray-900"
        >
          Issues by Topic
        </NavLink>
        <NavLink
          to="/state/issues/resolved"
          className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline"
          activeClassName="text-gray-900"
        >
          Resolved
        </NavLink>
      </div>
      <div className="ml-4 mt-2 flex space-x-4 items-end">
        {/* <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">
            Filter
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>All Topics</option>
            <option>Topic 1</option>
            <option>Etc</option>
          </select>
        </div> */}
        {/* <div>
          <label for="location" class="block text-sm font-medium text-red-500">
            Sort By
          </label>
          <select
            id="location"
            name="location"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
          >
            <option selected>Date Posted</option>
            <option>Popularity</option>
            <option>Etc</option>
          </select>
        </div> */}
        <span className="rounded-md shadow-sm">
          <Link
            to="/issues/create"
            className="btn-primary"
          >
            + Add New Issue
          </Link>
        </span>
      </div>
    </div>
  );
};

export default StateIssuesNav;
