import React, { useState, useEffect } from "react";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { Link, NavLink, useHistory } from "react-router-dom";
import firebase from "firebase";
// TODO
//   make it so that if you have registered for the event (or for a specific time), it remembers and/or saves the checkbox state
//   add un-RSVPing functionality

function toCustomDateString(dateObj) {
	var options = { weekday: "long" };
	const dayOfWeek = new Intl.DateTimeFormat("en-US", options).format(dateObj);
	const month = dateObj.getMonth() + 1;
	const dayOfMonth = dateObj.getDate();
	const year = dateObj.getFullYear();
	let hours = dateObj.getHours();
	let minutes = dateObj.getMinutes();
	// removes minutes if on the hour
	if (minutes === 0) {
		minutes = "";
	} else {
		minutes = ":" + minutes;
	}
	// formates time to twelve hour AM/PM time
	let formattedTime = "";
	let amOrPm = "am";
	if (hours >= 12) {
		amOrPm = "pm";
	}
	if (hours === 0) {
		hours = 12;
	} else if (hours > 12) {
		hours -= 12;
	}
	formattedTime = hours + minutes + amOrPm;
	return `${dayOfWeek} ${month}/${dayOfMonth}/${year} ${formattedTime}`;
	// December
}

const EventRSVP = (props) => {
	const { currUser, currUserRef, userData } = useAuth();

	// const [unformatAvailableTimes, setUnformatAvailableTimes] = useState([]);
	const [checkedTimes, setCheckedTimes] = useState(new Set());
	const [mailingListSignupChecked, setMailingListSignupChecked] = useState(
		false
	);
	// const [eventData, setEventData] = useState({});
	const [error, setError] = useState(null);

	// updates checkedTimes state to track index of selected times in unformatAvailableTimes
	function handleCheckbox(e, index) {
		const currentBox = e.target;
		if (currentBox.checked) {
			const newSet = checkedTimes.add(index);
			setCheckedTimes(newSet);
		} else {
			checkedTimes.delete(index);
		}
	}

	async function handleEventRegistration() {
		let dateArray = [];
		if (checkedTimes.size > 0) {
			checkedTimes.forEach((index) =>
				dateArray.push(props.eventTimes[index])
			);
		} else {
			dateArray.push(props.eventTimes[0]);
		}

		// REGISTERS USER UPDATES
		// NO-adds new registeredEvents sub-collection if user does not have one
		// assumes registeredEvents sub-collection exists for the user
		// uses same event id?

		if (
			!currUserRef.collection("registeredEvents").doc(props.eventId).get()
				.exists
		) {
			currUserRef.collection("registeredEvents").doc(props.eventId).set({
				eventId: props.eventId,
				eventTitle: props.eventTitle,
				organizerName: props.organizerName,
				organizerId: props.organizerId,
				numAttending: props.numAttending,
				numInterested: props.numInterested,
				mainStartTimeUTC: props.mainStartTimeUTC,
				mainStartTimeString: props.mainStartTimeString,
				timeZone: props.timeZone,
				isVirtual: props.isVirtual,
				city: props.city,
				state: props.state,
				dateRegistered: firebase.firestore.Timestamp.now(),
			});
		}

		currUserRef.collection("registeredEvents").doc(props.eventId).update({
			registeredTimes: dateArray,
		});

		db.collection("organizations")
			.doc(props.organizerId)
			.collection("events")
			.doc(props.eventId)
			.update({
				numAttending: firebase.firestore.FieldValue.increment(1),
			});
		//TODO: more places in db to update?

		console.log("using this event data:");
		// console.log(eventData);

		const orgSignupRef = await db
			.collection("organizations")
			.doc(props.organizerId)
			.collection("eventAttendees")
			.doc(currUser.uid)
			.get();

		let signupData = {
			civilianId: currUser.uid,
			email: userData.email,
			name: userData.firstName + " " + userData.lastName,
			rsvpDate: firebase.firestore.Timestamp.now(),
			district: userData.district,
		};

		let totalRSVP = 0;
		let latestRSVP = null;
		let firstRSVP = null;
		let mailingListYes = null;
		let mailingListYesDate = null;
		if (orgSignupRef.exists) {
			let doc = orgSignupRef.data();
			console.log(orgSignupRef.data().totalRSVP);
			totalRSVP = orgSignupRef.data().totalRSVP + 1;
			firstRSVP = orgSignupRef.data().firstRSVP;
			latestRSVP = firebase.firestore.Timestamp.now();
			mailingListYes = orgSignupRef.data().mailingListSignup;
			mailingListYesDate = orgSignupRef.data().mailingListSignupDate;
		} // if this user has signed up for something in this org already
		else {
			totalRSVP = 1;
			latestRSVP = firebase.firestore.Timestamp.now();
			firstRSVP = firebase.firestore.Timestamp.now();
		} // if this is the first event in this org the user is signing up for

		let generalSignupData = {
			civilianId: currUser.uid,
			email: userData.email,
			name: userData.firstName + " " + userData.lastName,
			zipCode: userData.zipCode ? userData.zipCode : "N/A",
			totalRSVP: totalRSVP,
			firstRSVP: firstRSVP,
			recentRSVP: latestRSVP,
			mailingListSignup: mailingListYes,
			mailingListSignupDate: mailingListYesDate,
		};

		const currEventRef = await db.collection("events").doc(props.eventId);
		const orgRef = await db
			.collection("organizations")
			.doc(props.organizerId);

		currEventRef // set who is going to the event (on event doc)
			.collection("signups")
			.doc(currUser.uid)
			.set(signupData, { merge: true });
		currEventRef // update count of attending
			.update({
				numAttending: firebase.firestore.FieldValue.increment(1),
			});
		orgRef // set who is going to any event (in organizations)
			.collection("eventAttendees")
			.doc(currUser.uid)
			.set(generalSignupData, { merge: true });
		if (mailingListSignupChecked) {
			orgRef // set who is going to any event (in organizations)
				.collection("eventAttendees")
				.doc(currUser.uid)
				.update({
					mailingListSignup: true,
					mailingListSignupDate: firebase.firestore.Timestamp.now(),
				});
		}

		updateRSVP();

		// adds buttons for sharing and viewing other events
		// const shareButton = document.createElement("button");
		// shareButton.setAttribute(
		// 	"class",
		// 	"bg-justiceBlue-600 rounded-lg w-full py-2 text-white text-med font-small hover:bg-justiceBlue-400 my-2 focus:outline-none"
		// );
		// shareButton.innerHTML = "Share this event with a friend";
		// eventInfo.append(shareButton);

		// const otherEventsButton = document.createElement("button");
		// otherEventsButton.setAttribute(
		// 	"class",
		// 	"bg-justiceBlue-600 rounded-lg w-full py-2  text-white text-med font-small hover:bg-justiceBlue-400 my-2 mb-4 focus:outline-none"
		// );
		// otherEventsButton.innerHTML =
		// 	"View other events from this organization";
		// eventInfo.append(otherEventsButton);
	}

	function updateRSVP() {
		// DISPLAY UPDATES
		const eventInfo = document.getElementById("eventInfoContainer");
		//saves first child (header)
		const headerMessage = eventInfo.firstChild;
		// removes children from eventInfo
		while (eventInfo.firstChild) {
			eventInfo.removeChild(eventInfo.firstChild);
		}
		// adds back header
		eventInfo.append(headerMessage);

		// adds success message
		const successMessage = document.createElement("div");
		successMessage.setAttribute(
			"class",
			"bg-green-100 border text-green-600 px-2 py-2 rounded-full relative text-center font-semibold mb-6"
		);
		successMessage.innerHTML = "You have successfully signed up.";
		eventInfo.append(successMessage);
	}

	useEffect(async () => {
    if (currUser){
      if (
        (
          await currUserRef
            .collection("registeredEvents")
            .doc(props.eventId)
            .get()
        ).exists
      ) {
        updateRSVP();
      }
    }
	}, []);

	// renders event RSVP with content based on event times
	return (
		<div>
			<div className="flex items-center bg-gray-100 px-7 pt-6 pb-7 rounded-2xl mb-4">
				<div className="w-full" id="eventInfoContainer">
					<h1 className="text-3xl font-bold mb-4">
						RSVP for this event
					</h1>
					<div
						className="flex-col items-center font-semibold .rounded-sm mb-6"
						id="checkboxContainer"
					>
						{props.eventTimes.length > 1 && (
								<h2 className="font-semibold my-4">
									Available times
								</h2>
							) &&
							props.eventTimes.map((time, index) => {
								return (
									<div className="my-3" key={index}>
										<input
											type="checkbox"
											id="fillerEventTime1"
											name="fillerEvent1"
											value={index}
											className="rounded-sm text-justiceBlue-600"
											onClick={(e) =>
												handleCheckbox(e, index)
											}
										></input>
										<label
											for="fillerEvent1"
											className="mx-3 "
										>
											{time.startString}—{time.endString}{" "}
											{time.timeZone}
										</label>
									</div>
								);
							})}
					</div>

          {
          currUser ?
					<button
						className="bg-justiceBlue-500 rounded-lg w-full py-3 mb-4 text-white text-lg font-medium hover:bg-justiceBlue-400 focus:outline-none"
						onClick={handleEventRegistration}
					>
						Sign up!
					</button>
          :
          <button
						className="bg-justiceBlue-500 rounded-lg w-full py-3 mb-4 text-white text-lg font-medium hover:bg-justiceBlue-400 focus:outline-none"
						onClick={props.toggleAuthPopup}
					>
						Sign up!
					</button>
          }

					<p className="text-center text-xs ">
						By clicking the button above,
					</p>
					<p className="text-center mb-4 text-xs ">
						you agree to our{" "}
						<Link to="/terms-of-service" className="text-justiceBlue-800 font-medium hover:underline">
							Terms of Service
						</Link>{" "}
						and{" "}
						<Link to="/privacy-policy" className="text-justiceBlue-800 font-medium hover:underline">
							Privacy Policy
						</Link>
						.
					</p>
					<div className="flex my-3">
						<input
							type="checkbox"
							id="receieveFutureEmails"
							name="receieveFutureEmails"
							value="yes"
							className="rounded-sm text-justiceBlue-600 focus:outline-none mt-1"
							onChange={() => {
								console.log(
									"mailing list checkbox:",
									!mailingListSignupChecked
								);
								setMailingListSignupChecked(
									!mailingListSignupChecked
								);
							}}
						></input>
						<label
							for="receieveFutureEmails"
							className="font-semibold text-xs text-gray-500 mx-3"
						>
							I want to receive emails about other opportunities
							from {props.organizerName}. By opting in, you agree
							to the Terms of Service.
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventRSVP;
