import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./LegPrivateRoute";

import InboxUnresolvedIssuesPage from '../../pages/leg/inbox/InboxUnresolvedIssuesPage'

const BrowseRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/leg/browse/organizations/nation" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/browse/organizations/state" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/browse/events/nation" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/browse/events/state" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/browse/issues/nation" component={InboxUnresolvedIssuesPage}/>
      <PrivateRoute path="/leg/browse/issues/state" component={InboxUnresolvedIssuesPage}/>
    </Switch>
  )
}

export default BrowseRoutes;
