import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jd from "../../../../assets/jd.png";
import { db } from "../../../../config/firebase";
import firebase from "firebase";
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png";
import { useAuth } from "../../../../contexts/AuthContext";

const HomeUpcomingEvents = () => {
	const { currUser } = useAuth();
	const [events, setEvents] = useState();
	const [firstEventTime, setFirstEventTime] = useState({});
	//const [unformatAvailableTimes, setUnformatAvailableTimes] = useState([]);

	useEffect(async () => {
		var date = new Date();
		let now = firebase.firestore.Timestamp.fromDate(date);

		const currUserRef = db.collection("civilians").doc(currUser.uid);
		let snapshot = await currUserRef
			.collection("registeredEvents")
			.where("mainStartTimeUTC", ">", now)
			.limit(2)
			.get();

		if (!snapshot.empty) {
			// console.log("#:", snapshot.docs.length)

			Promise.all(
				snapshot.docs.map(async (doc) => {
					let orgSrc = defaultProfilePic;

					let storageRef = firebase.storage().ref();
					let userPicRef = storageRef.child(
						`images/organization/profilePics/${
							doc.data().organizerId
						}`
					);
					try {
						let result = await userPicRef.getDownloadURL();
						orgSrc = result;
					} catch (error) {
						console.log(error);
					}

					return { ...doc.data(), eventId: doc.id, orgSrc: orgSrc };
					// console.log(doc.data());
				})
			).then((result) => {
				// console.log("RESULT:", result.length)
				setEvents(result);
			});
		} else {
			console.log("error getting data");
		}
	}, []);

	return (
		<div className="flex items-center space-x-10 bg-justiceBlue-600 px-10 py-6 rounded-lg">
			<h1 className="text-2xl font-bold text-white">Upcoming Events:</h1>

			<div className="flex-auto grid grid-cols-2 space-x-4">
				{events && events.length > 0 ? (
					events.map((event) => {
						return (
							<Link
								to={`/events/${event.eventId}`}
								className="w-full flex items-center shadow hover:shadow-lg rounded-lg"
							>
								<div className="flex-auto flex items-center space-x-4 bg-white px-5 py-3 rounded-lg">
									<img
										src={event.orgSrc}
										className="h-14 w-14"
									/>
									<div>
										<h1 className="text-lg font-semibold truncate">
											{event.eventTitle}
										</h1>
										<h2 className="text-md truncate">
											{event.organizerName} •{" "}
											{event.mainStartTimeString}{" "}
											{event.timeZone} •{" "}
											{event.isVirtual
												? "Virtual Event"
												: `${event.city}, ${event.state}`}
										</h2>
									</div>
								</div>
							</Link>
						);
					})
				) : (
					<h1 className="text-white font-medium">
						You currently have no upcoming events. Sign up for one to get started!
					</h1>
				)}
			</div>

			<Link to="/home/events/upcoming">
				<svg
					className="flex-shrink-0 ml-1.5 mr-1.5 h-12 w-12 text-white hover:text-gray-100"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M14 5l7 7m0 0l-7 7m7-7H3"
					/>
				</svg>
			</Link>
		</div>
	);
};

export default HomeUpcomingEvents;
