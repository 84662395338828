import React, { useState, useContext, useEffect } from 'react';
import DistrictIssuesNav from './DistrictIssuesNav';
import TopicCard from './TopicCard';
import {DistrictDBContext} from '../../../../contexts/DistrictContext';

const DistrictIssuesTopics = () => {

  const activeIssues = useContext(DistrictDBContext);
  let [topicsDisplay, setTopicsDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);


  useEffect(async () => {

    let topicsList = [];
    if(activeIssues[3] != null && activeIssues[3].length != 0) {
      for(const topic in activeIssues[3]){
        topicsList.push(<TopicCard name={topic} data={activeIssues[3][topic]} trendingTopic={false}/>);
      }

      setTopicsDisplay(topicsList);
    } else {
      setTopicsDisplay(<h1 className="px-8 py-8 font-medium text-xl">{"No issues"}</h1>)
    }

  }, [activeIssues[3]]);

  return (
    <>
      <DistrictIssuesNav />
      <div className="grid grid-cols-4 gap-x-5">
        {topicsDisplay}
      </div>
    </>
    );

}


export default DistrictIssuesTopics;
