import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";
import SemiPrivateRoute from "./CivSemiPrivateRoute";

import LegislatorsPage from "../../pages/civ/legislators/LegislatorsPage";
import LegislatorsCommitteePage from "../../pages/civ/legislators/LegislatorsCommitteePage";
import LegislatorVotesPage from "../../pages/civ/legislators/LegislatorVotesPage";
import LegislatorBillsPage from "../../pages/civ/legislators/LegislatorBillsPage";
import LegislatorIssuesPage from "../../pages/civ/legislators/LegislatorIssuesPage";

const LegislatorsRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/legislators/committees/:chamber/:committee"
				component={LegislatorsCommitteePage}
			/>
			<SemiPrivateRoute
				path="/legislators/:legislator/votes"
				component={LegislatorVotesPage}
			/>
			<SemiPrivateRoute
				path="/legislators/:legislator/bills"
				component={LegislatorBillsPage}
			/>
			<SemiPrivateRoute
				path="/legislators/:legislator/issues"
				component={LegislatorIssuesPage}
			/>
			<PrivateRoute path="/legislators" component={LegislatorsPage} />
		</Switch>
	);
};

export default LegislatorsRoutes;
