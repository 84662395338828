import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";

import HomePage from "../../pages/civ/home/HomePage";
import HomeUpcomingEventsPage from "../../pages/civ/home/HomeUpcomingEventsPage";
import HomePastEventsPage from "../../pages/civ/home/HomePastEventsPage";
import HomeBookmarkedEventsPage from "../../pages/civ/home/HomeBookmarkedEventsPage";
import HomeIssuesPage from "../../pages/civ/home/HomeIssuesPage";
// import HomeIssuesActivePage from "../../pages/civ/home/HomeIssuesActivePage";
// import HomeIssuesResolvedPage from "../../pages/civ/home/HomeIssuesResolvedPage";
import HomeNotesPage from "../../pages/civ/home/HomeNotesPage";
import HomeNotesLegislatorPage from "../../pages/civ/home/HomeNotesLegislatorPage";
import HomeOrganizationsFollowingPage from "../../pages/civ/home/HomeOrganizationsFollowingPage";
import HomeIssuesFollowingPage from "../../pages/civ/home/HomeIssuesFollowingPage";
import HomeBillsFollowingPage from "../../pages/civ/home/HomeBillsFollowingPage";

const HomeRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/home/events/upcoming"
				component={HomeUpcomingEventsPage}
			/>
			<PrivateRoute
				path="/home/events/past"
				component={HomePastEventsPage}
			/>
			<PrivateRoute
				path="/home/events/bookmarked"
				component={HomeBookmarkedEventsPage}
			/>
			<PrivateRoute
				path="/home/issues/:status"
				component={HomeIssuesPage}
			/>
			{/* <PrivateRoute path="/home/issues/resolved" component={HomeIssuesResolvedPage} /> */}
			<PrivateRoute path="/home/notes" component={HomeNotesPage} />
			<PrivateRoute
				path="/home/notes/:legislator"
				component={HomeNotesLegislatorPage}
			/>
			<PrivateRoute
				path="/home/following/organizations"
				component={HomeOrganizationsFollowingPage}
			/>
			<PrivateRoute
				path="/home/following/issues"
				component={HomeIssuesFollowingPage}
			/>
			<PrivateRoute
				path="/home/following/bills"
				component={HomeBillsFollowingPage}
			/>
			<PrivateRoute path="/home" component={HomePage} />
		</Switch>
	);
};

export default HomeRoutes;
