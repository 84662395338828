import React from "react";
import { Link } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout";
import BrowseNav from "../../../components/org/browse/BrowseNav";
import OrganizationsNav from "../../../components/org/browse/OrganizationsNav";
import NationOrganizations from "../../../components/org/browse/NationOrganizations";

function BrowseNationalOrganizationsPage(props) {
	return (
		<OrgLayout>
			<BrowseNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<OrganizationsNav />
				<NationOrganizations />
			</div>
		</OrgLayout>
	);
}

export default BrowseNationalOrganizationsPage;
