import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "../org/OrgPrivateRoute";

import IssueNoteDetailedPage from "../../pages/org/issue/IssueNoteDetailedPage";
import IssueNotesPage from "../../pages/org/issue/IssueNotesPage";
import IssueDetailsPage from "../../pages/org/issue/IssueDetailsPage";
import IssueCopiesPage from "../../pages/org/issue/IssueCopiesPage";
import ReshareIssuePage from "../../pages/org/issue/ReshareIssuePage";

const HomeRoutes = () => {
	return (
		<Switch>
      <PrivateRoute
				path="/org/issues/:issue/notes/:note"
				component={IssueNoteDetailedPage}
			/>
			<PrivateRoute
				path="/org/issues/:issue/notes"
				component={IssueNotesPage}
			/>
			<PrivateRoute
				path="/org/issues/:issue/details"
				component={IssueDetailsPage}
			/>
			<PrivateRoute
				path="/org/issues/:issue/copies"
				component={IssueCopiesPage}
			/>
			<PrivateRoute
				path="/org/issues/:issue/reshare"
				component={ReshareIssuePage}
			/>
		</Switch>
	);
};

export default HomeRoutes;
