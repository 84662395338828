import React from "react";
import HomeEventsBookmarkedDetailed from "../../../components/civ/home/events/HomeEventsBookmarkedDetailed";
import HomeEventsNav from "../../../components/civ/home/events/HomeEventsNav";
import HomePageNav from "../../../components/civ/home/HomeNav";
import AppLayout from "../../../layouts/AppLayout";

const HomeBookmarkedEventsPage = () => {
	return (
		<AppLayout>
			<HomePageNav />
			{console.log("events bookmarked page entered")}
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeEventsNav />
				<HomeEventsBookmarkedDetailed />
			</div>
		</AppLayout>
	);
};

export default HomeBookmarkedEventsPage;
