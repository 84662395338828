import {React, useState} from 'react';
import {Link} from "react-router-dom";
import megaphoneIcon from '../assets/megaphone-icon.png';
import name from '../assets/NameBlackBeta.png'
import { Transition } from '@headlessui/react'

const LandingLayout = ( {children} ) => {

    const [menuIsOpen, setMenuIsOpen] = useState(false)

    return (
        <div>
          {/* Navigation */}
          <nav className="z-20 shadow relative flex items-center justify-between px-5 sm:px-20 py-8">
              <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full lg-hero:w-auto">
                  <Link to="/" aria-label="Home" className="flex items-center">
                    <img className="h-8 w-auto sm:h-10" src={megaphoneIcon} alt="Logo" />
                    <img className="ml-2 mt-2 h-12 w-auto sm:display-none" src={name} alt="Name" />
                    {/* <h1 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-4xl lg:text-3xl xl:text-4xl lg-hero:ml-3">GrassrootsGov</h1> */}
                  </Link>
                  <div className="-mr-2 flex items-center lg-hero:hidden">
                    <button type="button" onClick={() => setMenuIsOpen(!menuIsOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                      <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
              </div>
              </div>
              <div className="hidden lg-hero:block text-right">
              <span className="inline-flex">
                  <div className="hidden lg-hero:block lg-hero:ml-10">
                  <Link to='/features' className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Features</Link>
                  <Link to="/about" className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">About</Link>
                  <Link to="/contact" className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Contact</Link>
                  {/* <a href="#" className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Marketplace</a>
                  <a href="#" className="ml-10 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Company</a> */}
                  </div>
              </span>
              <span className="inline-flex rounded-md shadow-md md:ml-10">
                  <span className="inline-flex rounded-md shadow-xs">
                  <Link to="/login" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-red-500 bg-white hover:text-red-600 hover:bg-gray-50 focus:outline-none focus:shadow-outline-gray-100 active:bg-gray-100 transition duration-150 ease-in-out">
                      Log in
                  </Link>
                  </span>
              </span>
              <span className="inline-flex rounded-md shadow-md lg-hero:ml-10">
                <span className="inline-flex rounded-md shadow-sm">
                <Link to="/signup" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:border-red-500 focus:shadow-outline-red-500 active:bg-red-600 transition ease-in-out duration-150">
                    Sign up
                </Link>
                </span>
              </span>
              {/* <span className="inline-flex rounded-md shadow-md lg-hero:ml-10">
                <span className="inline-flex rounded-md shadow-sm">
                <Link to="/join-waitlist" className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-500 hover:bg-red-500Lighter focus:outline-none focus:border-red-500 focus:shadow-outline-red-500 active:bg-red-500Darker transition ease-in-out duration-150">
                    Join the waitlist!
                </Link>
                </span>
              </span> */}
              </div>
          </nav>

          {/* Mobile Menu */}
          <Transition
            show={menuIsOpen}
            enter="duration-50 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-20 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg-hero:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                        <img className="h-8 w-auto" src={megaphoneIcon} alt=""/>
                    </div>
                    <div className="-mr-2">
                        <button type="button" onClick={() => setMenuIsOpen(!menuIsOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Home</Link>
                    <Link to="/features" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Features</Link>
                    <Link to="/about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">About</Link>
                    <Link to="/contact" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Contact</Link>
                  </div>
                  <div>
                    {/* <Link to="/join-waitlist" className="block w-full px-5 py-3 text-center font-medium text-red-500 bg-gray-50 hover:bg-gray-100 hover:text-red-500Darker focus:outline-none focus:bg-gray-100 focus:text-red-500Darker transition duration-150 ease-in-out" role="menuitem">
                        Join the waitlist!
                    </Link> */}
                    <Link to="/login" className="block w-full px-5 py-3 text-center font-medium text-red-500 bg-gray-50 hover:bg-gray-100 hover:text-red-500Darker focus:outline-none focus:bg-gray-100 focus:text-red-500Darker transition duration-150 ease-in-out border-b" role="menuitem">
                        Log in
                    </Link>
                    <Link to="/signup" className="block w-full px-5 py-3 text-center font-medium text-red-500 bg-gray-50 hover:bg-gray-100 hover:text-red-500Darker focus:outline-none focus:bg-gray-100 focus:text-red-500Darker transition duration-150 ease-in-out" role="menuitem">
                        Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          {children}

          {/* Footer */}
          {/* <footer className="bg-white">
              <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg-hero:flex lg-hero:items-center lg-hero:justify-between lg:px-8">
              <div className="flex justify-center space-x-3 sm:space-x-6 lg-hero:order-2">
                  <Link to="/" className="text-xs sm:text-base leading-6 text-gray-500 hover:text-gray-900">
                  Home
                  </Link>
                  <Link to="/features" className="text-xs sm:text-base leading-6 text-gray-500 hover:text-gray-900">
                  Features
                  </Link>
                  <Link to="/about" className="text-xs sm:text-base leading-6 text-gray-500 hover:text-gray-900">
                  About
                  </Link> */}
                  {/* <Link to="/contact" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                  Press
                  </Link> */}
                  {/* <Link to="/contact" className="text-xs sm:text-base leading-6 text-gray-500 hover:text-gray-900">
                  Contact Us
                  </Link>
                  <Link to="/privacy-policy" className="text-xs sm:text-base leading-6 text-gray-500 hover:text-gray-900">
                  Privacy Policy
                  </Link> */}
                  {/* <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Facebook</span>
                  <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                  </svg>
                  </a> */}
                  {/* <a href="https://twitter.com/GrassrootsGovv" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  </a>
                  <a href="https://www.instagram.com/grassrootsgov/" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Instagram</span>
                  <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                  </svg>
                  </a> */}
                  {/* <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">GitHub</span>
                  <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
                  </svg>
                  </a> */}
              {/* </div>
              <div className="mt-8 md:mt-0 md:order-1">
                  <p className="text-center text-base leading-6 text-gray-400">
                  &copy; 2021 GrassrootsGov. All rights reserved.
                  </p>
              </div>
              </div>
          </footer> */}

          <footer class="bg-white" aria-labelledby="footerHeading">
            <h2 id="footerHeading" class="sr-only">Footer</h2>
            <div class="max-w-7xl mx-auto pt-12 pb-8 px-4 sm:px-6 lg:pt-16 pb-12 lg:px-8">
              <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="space-y-8 xl:col-span-1">
                  <img class="h-10" src={megaphoneIcon} alt="Logo"/>
                  <p class="text-gray-500 text-base">
                    Digital activism reimagined.
                  </p>
                  <div class="flex space-x-6">

                    <a href="https://www.instagram.com/grassrootsgov/" class="text-gray-400 hover:text-gray-500">
                      <span class="sr-only">Instagram</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                      </svg>
                    </a>

                    <a href="https://twitter.com/GrassrootsGovv" class="text-gray-400 hover:text-gray-500">
                      <span class="sr-only">Twitter</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>

                  </div>
                </div>
                <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                  <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Product
                      </h3>
                      <ul class="mt-4 space-y-4">
                        <li>
                          <Link to="/features" class="text-base text-gray-500 hover:text-gray-900">
                            Features
                          </Link>
                        </li>

                      </ul>
                    </div>
                    <div class="mt-12 md:mt-0">
                      <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Help
                      </h3>
                      <ul class="mt-4 space-y-4">
                        <li>
                          <Link to="/help/civilian" class="text-base text-gray-500 hover:text-gray-900">
                            Civilians
                          </Link>
                        </li>

                        <li>
                          <Link to="/help/organization" class="text-base text-gray-500 hover:text-gray-900">
                            Organizations
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Contact
                      </h3>
                      <ul class="mt-4 space-y-4">
                        <li>
                          <Link to="/about" class="text-base text-gray-500 hover:text-gray-900">
                            About
                          </Link>
                        </li>

                        <li>
                          <Link to="/contact" class="text-base text-gray-500 hover:text-gray-900">
                            Contact Us
                          </Link>
                        </li>

                      </ul>
                    </div>
                    <div class="mt-12 md:mt-0">
                      <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Legal
                      </h3>
                      <ul class="mt-4 space-y-4">
                        <li>
                          <Link to="/terms-of-service" class="text-base text-gray-500 hover:text-gray-900">
                            Terms of Service
                          </Link>
                        </li>

                        <li>
                          <Link to="/privacy-policy" class="text-base text-gray-500 hover:text-gray-900">
                            Privacy Policy
                          </Link>
                        </li>

                        <li>
                          <Link to="/community-guidelines" class="text-base text-gray-500 hover:text-gray-900">
                            Community Guidelines
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 border-t border-gray-200 pt-8">
                <p class="text-base text-gray-400 xl:text-center">
                  &copy; 2021 GrassrootsGov. All rights reserved.
                </p>
              </div>
            </div>
          </footer>

        </div>
    )
}

export default LandingLayout;
