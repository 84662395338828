import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout"
import { db } from "../../config/firebase"

const AdminWaitlistPage = () => {

  return (
      <AdminLayout>

      </AdminLayout>
  )
}

export default AdminWaitlistPage;
