import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AdminLayout from '../../layouts/AdminLayout';
// import protestAlt from "../../assets/protest-alt.jpg";
// import megaphoneIcon from "../../assets/megaphone-icon.png";
import { db, functions, auth } from '../../config/firebase';
// import { useAuth } from "../../contexts/AuthContext";
import '../../styles/signup.css';
import generator from 'generate-password';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import firebase from 'firebase';

const SignupPage = ({ match }) => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: generator.generate({
      length: 10,
      numbers: true,
    }),
    firstName: '',
    lastName: '',
    state: '',
  });

  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  // const { signup } = useAuth();
  const history = useHistory();
  const [legData, setLegData] = useState({});
  const [created, setCreated] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [emailContentText, setEmailContentText] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(async () => {
    const legDoc = await db
      .collection('orgLegAccountRequests')
      .doc(match.params.id)
      .get();

    if (legDoc) {
      setLegData(legDoc.data());
      setCredentials({ ...credentials, email: legDoc.data().email });
    } else {
      console.log('error: legislator document id does not exist');
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    if (credentials.password.length < 8) {
      return setErr('Please create a password that is 8 characters or longer');
    }
    // try {
    setErr('');
    setLoading(true);
    // signup(credentials.email, credentials.password)
    var createLegUser = functions.httpsCallable('createLegUser');
    createLegUser({ email: credentials.email, password: credentials.password })
      .then(async (result) => {
        console.log('user with uid created ' + result.data.uid);
        // console.log("firstName:", credentials.firstName)
        // console.log("lastName:", credentials.lastName)
        // console.log("state:", credentials.state)
        const legRef = await db
          .collection('legislators')
          .where('firstName', '==', credentials.firstName)
          .where('lastName', '==', credentials.lastName)
          .where('region', '==', credentials.state)
          .get();
        const legDocId = legRef.docs[0].id;
        const legDoc = await db.collection('legislators').doc(legDocId);
        legDoc
          .update({
            dateAccountCreated: firebase.firestore.Timestamp.now(),
            email: credentials.email,
            uid: result.data.uid,
          })
          .then(() => {
            setEmailContent(
              <p>
                Dear {legData.entityName}, <br /> <br />
                Thank you for your signing up for a GrassrootsGov account!{' '}
                <br />
                Here are your login details: <br />
                Email: {credentials.email} <br />
                Temporary password: {credentials.password} <br />
                Please log in through this link: https://grassrootsgov.org/login{' '}
                <br /> <br />
                Cheers, <br />
                The GrassrootsGov Team
              </p>
            );
            setEmailContentText(`
          EMAIL ADDRESS: ${credentials.email}
          SUBJECT TITLE: Your GrassrootsGov account is ready!

          Dear ${legData.entityName},

          Thank you for your signing up for a GrassrootsGov account!
          Here are your login details:
          Email: ${credentials.email}
          Temporary password: ${credentials.password}
          Please log in through this link: https://grassrootsgov.org/login

          Cheers,
          The GrassrootsGov Team
          `);
            setLoading(false);
            setCreated(true);

            db.collection('users')
              .doc(result.data.uid)
              .set({ type: 'legislator', profileFinished: false });
          });
        // setTimeout(() => history.push("/finish-profile"), 500);
      })
      .catch((e) => {
        setErr(e.message);
        setLoading(false);
      });
  }

  function handleContinue(e) {
    db.collection('orgLegAccountRequests')
      .doc(match.params.id)
      .update({ accountCreated: true });
    history.push('/admin/leg-signups');
  }

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    // console.log("field:", [e.target.name])
    // console.log("value:", e.target.value)
    // console.log("credentials:", credentials)
  }

  return (
    <AdminLayout>
      <div className='bg-justiceBlue-50 mb-px py-8 px-12 mt-0.5'>
        <button
          className='flex space-x-2 items-center text-gray-700'
          onClick={() => {
            history.goBack();
          }}
        >
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg> */}
          <h1>Cancel</h1>
        </button>

        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-full lg:w-1/2'>
            <div>
              <h2 className='mt-6 text-3xl leading-9 font-extrabold text-gray-900'>
                Create account
              </h2>
            </div>

            <div className='mt-8'>
              <div className='mt-6'>
                <form
                  action='#'
                  method='POST'
                  className='space-y-6'
                  onSubmit={handleSubmit}
                >
                  <div className='flex space-x-6'>
                    <div>
                      <label
                        for='firstName'
                        className='block text-sm font-medium leading-5 text-gray-700'
                      >
                        First name (official)
                      </label>
                      <div className='mt-1 rounded-md shadow-sm'>
                        <input
                          id='firstName'
                          name='firstName'
                          type='text'
                          value={credentials.firstName}
                          onChange={handleChange}
                          required
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for='lastName'
                        className='block text-sm font-medium leading-5 text-gray-700'
                      >
                        Last name
                      </label>
                      <div className='mt-1 rounded-md shadow-sm'>
                        <input
                          id='lastName'
                          name='lastName'
                          type='text'
                          value={credentials.lastName}
                          onChange={handleChange}
                          required
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for='state'
                        className='block text-sm font-medium leading-5 text-gray-700'
                      >
                        State (postal code abbr.)
                      </label>
                      <div className='mt-1 rounded-md shadow-sm'>
                        <input
                          id='state'
                          name='state'
                          type='text'
                          value={credentials.state}
                          onChange={handleChange}
                          required
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      for='email'
                      className='block text-sm font-medium leading-5 text-gray-700'
                    >
                      Email address
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        value={credentials.email}
                        onChange={handleChange}
                        required
                        className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for='password'
                      className='block text-sm font-medium leading-5 text-gray-700'
                    >
                      Password
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        id='password'
                        name='password'
                        type='text'
                        value={credentials.password}
                        onChange={handleChange}
                        required
                        className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div>
                    <span className='block w-full rounded-md shadow-sm'>
                      <button
                        disabled={loading}
                        type='submit'
                        className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-justiceBlue-500 hover:bg-justiceBlue-400 focus:outline-none focus:border-justiceBlue-500 focus:shadow-outline-justiceBlue-500 active:bg-justiceBlue-600 transition duration-150 ease-in-out'
                      >
                        Create Account
                      </button>
                    </span>
                  </div>
                </form>
                <br />
                {err && (
                  <div
                    className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                    role='alert'
                  >
                    <strong className='font-bold'>Holy smokes! </strong>
                    <span className='block sm:inline'>{err}</span>
                  </div>
                )}
              </div>
            </div>

            {/* <div className={created ? "" : "hidden"}> */}
            {created && (
              <div>
                <div className='flex justify-between items-center mt-6 mb-4'>
                  <h1 className='text-3xl font-extrabold'>Email to send</h1>
                  <div>
                    <CopyToClipboard
                      text={emailContentText}
                      onCopy={() => setCopied(true)}
                    >
                      <button className='btn-white focus:outline-none'>
                        Copy to clipboard
                      </button>
                    </CopyToClipboard>
                    {copied && (
                      <h1 className='text-center text-sm text-gray-500 mt-1'>
                        Copied to clipboard.
                      </h1>
                    )}
                  </div>
                </div>
                <div className='block mb-6'>{emailContent}</div>

                <button onClick={handleContinue} className='btn-primary'>
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default SignupPage;
