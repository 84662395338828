import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function Verify() {
  const { currUser } = useAuth();
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState("");

  function refreshPage() {
    var refresh = window.localStorage.getItem('refresh');
    console.log(refresh);
    if (refresh===null){
        window.location.reload();
        window.localStorage.setItem('refresh', "1");
    }
  }

  useEffect(async () => {
    // console.log("loaded!")
    refreshPage();
  }, [])

  const handleSubmit = () => {
    currUser
      .sendEmailVerification()
      .then(() => {
        setMsg(
          <div className="grid justify-center justify-items-center">
            <h1 className="text-lg">Verification email successfully sent. Please return here and refresh the page after you have verified your email.</h1>
            <button
              className="block mt-8 btn-primary justify-self-center"
              onClick={handleSubmit}
            >
              Resend Email
            </button>
          </div>
        );
      })
      .catch((e) => {
        console.log(e.message)
        setErr(e.message);
      });
  };
  if (currUser.emailVerified) {
    return <Redirect to="/finish-profile" />;
  }
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="mx-auto w-11/12 py-12 md:py-16 md:w-10/12">
        <h1 className="text-4xl font-semibold">Verify your email</h1>
        <p className="text-xl py-8">
          Click the button below to send a verification email. <br/>
          <span className="font-medium text-indigo-600">Please return to this page after you follow the instructions sent to your email!</span>
        </p>
        <div className="flex justify-center">
          {msg || err ? (
            msg ? (
              msg
            ) : (
              err
            )
          ) : (
            <button
              className="btn-primary"
              onClick={handleSubmit}
            >
              Send Email
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verify;
