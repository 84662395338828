import React from 'react';
import { NavLink } from 'react-router-dom';

const HomeEventsNav = () => {
  return (
    <div className='flex justify-between items-center mb-8'>
      <div className='flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg'>
        <NavLink
          to='/home/events/upcoming'
          className=' whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline'
          exact
          activeClassName='text-gray-900'
        >
          Upcoming
        </NavLink>
        <NavLink
          to='/home/events/past'
          className=' whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline'
          activeClassName='text-gray-900'
        >
          Past
        </NavLink>
        <NavLink
          to='/home/events/bookmarked'
          className=' whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline'
          activeClassName='text-gray-900'
        >
          Bookmarked
        </NavLink>
      </div>
      {/* <div className='ml-4 mt-2 flex space-x-4 items-end'>FILTER HERE</div> */}
    </div>
  );
};

export default HomeEventsNav;
