import React from "react";
import OrganizationIssuePost from '../global/organizations/OrganizationIssuePost'

const OrganizationIssues = (props) => {

  return (
    <div>
      {(props.orgPosts && props.orgPosts.length > 0 ) ?
      <div>
        {props.orgPosts && (props.orgPosts).map((post) => {
            return(
              <OrganizationIssuePost
                postId={post.postId}
                orgId={post.orgId}
                orgName={post.orgName}
                datePosted={post.datePosted}
                postBody={post.postBody}
                issueTitle={post.issue.issueTitle}
                legislatorName={post.issue.legislatorName}
                legislatorParty={post.issue.legislatorParty}
                legislatorState={post.issue.legislatorState}
                issueDateCreated={post.issue.issueDateCreated}
                regionalCopyCount={post.issue.regionalCopyCount}
                supporterCount={post.issue.supporterCount}
                issueTopicTags={post.issue.issueTopicTags}
                issueId={post.issue.issueId}
                deletable={props.isUser}
                profilePicUrl={props.profilePicUrl}
              />
            )
        })}
      </div>
      :
      <div className="text-xl font-medium">This organization has not reshared any issues yet.</div>
      }
    </div>
  )

}

export default OrganizationIssues;
