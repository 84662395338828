import React from 'react';
import { Link } from 'react-router-dom';
import LandingLayout from '../../layouts/LandingLayout';
import julia from '../../assets/headshots/JuliaCamacho_headshot.png';
import gary from '../../assets/headshots/GaryWu_headshot.png';
import prat from '../../assets/headshots/PratyushMallick_headshot.png';
import shama from '../../assets/headshots/ShamaTirukkala_headshot.jpeg';
import swathi from '../../assets/headshots/SwathiMannem_headshot.jpg';
import apsara from '../../assets/headshots/ApsaraMitra_headshot.jpg';
import janna from '../../assets/headshots/JannaGoliff_headshot.JPG';
import aneisha from '../../assets/headshots/AneishaGupta_headshot.jpg';
import navya from '../../assets/headshots/NavyaChintaman_headshot.JPG';
import akshay from '../../assets/headshots/AkshayUnnikrishnan_headshot.png';
import faith from '../../assets/headshots/FaithLanham_headshot.jpg';
import sparky from '../../assets/headshots/SparkyMitra_headshot.jpg';
import mihir from '../../assets/headshots/MihirKhare_headshot.jpg';
import jay from '../../assets/headshots/JayBhalala_headshot.jpeg';

const AboutPage = () => {
  return (
    <LandingLayout>
      <div class='bg-white border'>
        <div class='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
          <div class='text-center'>
            <h2 class='text-base font-semibold text-justiceBlue-400 tracking-wide uppercase'>
              Who we are
            </h2>
            <p class='mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl'>
              About Us
            </p>
            {/* <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">Start building for free, then add a site plan to go live. Account plans unlock additional features.</p> */}
          </div>
        </div>
      </div>

      <div class='bg-justiceBlue-600'>
        <div class='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
          <div class='lg:flex lg:justify-between items-center'>
            <h2 class='mb-4 lg:mb-0 text-3xl font-extrabold text-white sm:text-4xl sm:tracking-tight lg:text-5xl'>
              Our Mission
            </h2>
            <p class='max-w-2xl text-md sm:text-2xl text-white'>
              Our goal is to help facilitate the growth and success of
              movements across the nation by enabling transparent,
              actionable, and community-centered online activism and political
              engagement.
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className='max-w-7xl pt-20 mx-auto px-4 sm:px-6 lg:px-8'>
          <h1 className='text-3xl font-extrabold text-gray-800 sm:text-4xl sm:tracking-tight'>
            Our Values
          </h1>
          <p class='mt-5 text-xl text-gray-500'>
            To join our platform as a civilian or organization, you must also
            respect these baseline values.
          </p>
        </div>

        <div class='max-w-7xl mx-auto pt-16 pb-24 bg-white'>
          <div class='max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
            <dl class='space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'>
              <div>
                <div class='flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Everyone is inherently deserving of equality and respect
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    Race, gender identity, immigration status, sexual
                    orientation, or socioeconomic background should not limit
                    anyone's human or civil rights.
                  </dd>
                </div>
              </div>

              <div>
                <div class='flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Healthcare & housing are human rights
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    It is the duty of the government to ensure that every person
                    has access to affordable basic housing and healthcare.
                  </dd>
                </div>
              </div>

              <div>
                <div class='flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Anti-Black & anti-Indigenous racism need to be dismantled
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    America was founded upon white supremacy. We must enact a
                    Third Reconstruction & actively combat systemic racism in
                    all aspects of our society, from our justice system to our
                    healthcare institutions.
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    The climate crisis requires bold action
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    America must prioritize ending fossil fuel dependence and
                    fully transition to renewable energy sources as soon as
                    possible.
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  {/* <!-- Heroicon name: scale --> */}
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Our economy should not favor the ultra-wealthy
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    We must enact significant reforms to our tax systems,
                    benefits programs, and labor policies to actually support
                    everyday Americans and reduce economic inequality.
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Education should be accessible to all
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    We must end the equity gaps in our systems of education by
                    ensuring that quality education is affordable and available
                    to everyone regardless of race or class.
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  {/* <!-- Heroicon name: globe-alt --> */}
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    American exceptionalism is a dangerous myth
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    We need to end violent American influence in other countries
                    and deconstruct nationalistic and neocolonial policies and
                    ways of thinking.
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Money should not control legislation
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    Big money should have no place in politics. We must enact
                    campaign finance reforms to end corporate influence and big
                    dollar lobbying.{' '}
                  </dd>
                </div>
              </div>

              <div>
                <div class='mt-4 flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white'>
                  <svg
                    class='h-6 w-6'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      stroke-linecap='round'
                      strokeLinejoin='round'
                      stroke-width='2'
                      d='M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z'
                    />
                  </svg>
                </div>
                <div class='mt-5'>
                  <dt class='text-lg leading-6 font-medium text-gray-900'>
                    Nonviolent discussion & action are the best ways to
                    facilitate change
                  </dt>
                  <dd class='mt-2 text-base text-gray-500'>
                    There can be no peace without justice, but violence is not
                    the answer to our problems.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class='bg-gray-50'>
        <div class='max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24'>
          <div class='space-y-8 sm:space-y-12'>
            <div class='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'>
              <h2 class='text-3xl font-extrabold tracking-tight sm:text-4xl'>
                Our Team
              </h2>
              {/* <p class="text-xl text-gray-500">We're a group of college and high school students passionate about using technology for social good.</p> */}
              <p class='text-xl text-gray-500'>
                If you'd like to contact us, please visit our{' '}
                <Link to='/contact' className='underline'>
                  contact page
                </Link>
                .
              </p>
            </div>
            <ul class='mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6'>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={julia}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Julia Camacho</h3>
                      <p class='text-justiceBlue-600'>Founder & President</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={gary}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Gary Wu</h3>
                      <p class='text-justiceBlue-600'>VP of Product</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={prat}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Pratyush Mallick</h3>
                      <p class='text-justiceBlue-600'>VP of Growth</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={shama}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Shama Tirukkala</h3>
                      <p class='text-justiceBlue-600'>Product</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={swathi}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Swathi Mannem</h3>
                      <p class='text-justiceBlue-600'>Engineering</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={apsara}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Apsara Mitra</h3>
                      <p class='text-justiceBlue-600'>Growth</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={janna}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Janna Goliff</h3>
                      <p class='text-justiceBlue-600'>Engineering</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={aneisha}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Aneisha Gupta</h3>
                      <p class='text-justiceBlue-600'>Product</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={navya}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Navya Chintaman</h3>
                      <p class='text-justiceBlue-600'>Growth</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={akshay}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Akshay Unnikrishnan</h3>
                      <p class='text-justiceBlue-600'>Product</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={faith}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Faith Lanham</h3>
                      <p class='text-justiceBlue-600'>Growth</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={sparky}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Sparky Mitra</h3>
                      <p class='text-justiceBlue-600'>Product</p>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={mihir}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Mihir Khare</h3>
                      <p class='text-justiceBlue-600'>Engineering</p>
                    </div>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={sparky}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Mustafa Haque</h3>
                      <p class='text-justiceBlue-600'>Growth</p>
                    </div>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <div class='space-y-4'>
                  <img
                    class='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24'
                    src={jay}
                    alt=''
                  />
                  <div class='space-y-2'>
                    <div class='text-xs font-medium lg:text-sm'>
                      <h3>Jay Bhalala</h3>
                      <p class='text-justiceBlue-600'>Engineering</p>
                    </div>
                  </div>
                </div>
              </li> */}

            </ul>
            {/* <h1 className="leading-10 text-lg text-gray-500">If you'd like to contact us, please send us an email at grassrootsgov.team@gmail.com or visit our <Link to="/contact" className="underline">contact page</Link>.</h1> */}
          </div>
        </div>
      </div>

      <div className='bg-justiceBlue-600'>
        <div className='max-w-7xl pt-20 mx-auto px-4 sm:px-6 lg:px-8'>
          <h1 className='text-2xl font-bold text-white sm:text-2xl sm:tracking-tight lg:text-3xl'>
            Interested in joining the team?
          </h1>
        </div>

        <div class='max-w-7xl mx-auto grid grid-cols-2 gap-4 pt-10 pb-20 px-4 sm:px-6 lg:px-8'>
          <div class='max-w-2xl mx-auto'>
            <div class='bg-justiceBlue-500 rounded-lg shadow-xl overflow-hidden'>
              <div class='pt-10 pb-12 px-2 sm:px-6 sm:pt-16 lg:px-8 lg:py-16 lg:pr-0 xl:py-20 xl:px-16'>
                <div class='text-center'>
                  <h2 class='text-xl font-extrabold text-white sm:text-4xl'>
                    {/* <span class="block">Ready to dive in?</span> */}
                    <span class='block'>
                      Join our <br /> executive team!
                    </span>
                  </h2>
                  <p class='mt-4 text-md sm:text-lg leading-6 text-gray-100'>
                    We're looking for web developers, product managers,
                    marketing specialists and more to volunteer with us.
                  </p>
                  <a
                    href='https://forms.gle/QzzYu452oExY4uSKA'
                    class='mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-red-500 hover:bg-gray-50'
                  >
                    Apply now
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class='max-w-2xl mx-auto'>
            <div class='bg-justiceBlue-500 rounded-lg shadow-xl overflow-hidden'>
              <div class='pt-10 pb-12 px-2 sm:px-6 sm:pt-16 lg:px-8 lg:py-16 lg:pr-0 xl:py-20 xl:px-16'>
                <div class='text-center'>
                  <h2 class='text-xl font-extrabold text-white sm:text-4xl'>
                    {/* <span class="block">Ready to dive in?</span> */}
                    <span class='block'>Become a campus ambassador!</span>
                  </h2>
                  <p class='mt-4 text-md sm:text-lg leading-6 text-gray-100'>
                    Want to spread the word at your school? <br /> Become a
                    campus ambassador today.
                  </p>
                  <a
                    href='https://forms.gle/ADivv7xdg4wWu32F6'
                    class='mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-red-500 hover:bg-gray-50'
                  >
                    Apply now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default AboutPage;
