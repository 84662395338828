import {useState} from 'react';
import AppLayout from '../../../../layouts/AppLayout'
import StateNav from '../../../../components/civ/state/StateNav'
import StateEventsNav from '../../../../components/civ/state/events/StateEventsNav'
import StateTopicEvents from '../../../../components/civ/state/events/StateTopicEvents'
// import addBtnImg from '../../../../assets/addBtnImg.png';


const StateTopicEventsPage = () => {

    return (
        <AppLayout>
          <StateNav />
          <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

            <StateEventsNav />
            <StateTopicEvents/>

            <div className="fixed bottom-0 right-0 w-15 h-15 mr-12 mb-8 cursor-pointer" onClick={()=> {console.log('a')}}>
              {/* <img className='w-11 h-11' src={addBtnImg}/> */}
            </div>

          </div>
        </AppLayout>
      )
}

export default StateTopicEventsPage;
