import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { db } from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";
import "../../styles/signup.css";
import { states_districts, states_hash } from "../../assets/states";
import defaultProfilePic from "../../assets/DefaultOrgProfilePic.png"

const FinishProfile = () => {

  const { currUserAdminInfo, currUser } = useAuth();
  // console.log("currUser:", currUser)

  const [credentials, setCredentials] = useState({
    uid: currUser.uid,
    email: currUser.email,
    dateProfileCompleted: firebase.firestore.Timestamp.now(),
    orgScope: "",
    hqState: "",
    hqDistrict: "",
    hqCity: "",
    type: "",
    websiteURL: "",
    contactEmail: "",
    contactPhone: "",
    twitterURL: "",
    instagramURL: "",
    facebookURL: "",
    description: "",
    followerCount: 0,
    activeIssueCount: 0,
    upcomingEventCount: 0
  });
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [selectedPhoto, setSelectedPhoto] = useState()
  const [preview, setPreview] = useState()
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // async function findLegislators() {
  //   if (credentials.district && credentials.hqState) {
  //     let reps = [];
  //     const snapHouse = await db
  //       .collection("legislators")
  //       .where("chamber", "==", "House")
  //       .where("region", "==", credentials.hqState)
  //       .where("district", "==", credentials.district)
  //       .get();
  //     snapHouse.forEach((doc) => {
  //       // setCredentials({ ...credentials, districtRep: doc.data() });
  //       reps.push(doc.data());
  //     });

  //     const snapSenate = await db
  //       .collection("legislators")
  //       .where("chamber", "==", "Senate")
  //       .where("region", "==", credentials.hqState)
  //       .get();

  //     // let senators = [];
  //     snapSenate.forEach((doc) => {
  //       // senators.push(doc.data());
  //       reps.push(doc.data());
  //     });
  //     // setCredentials({ ...credentials, senateRep: senators });
  //     console.log("my reps are: " + reps);
  //     return reps;
  //   }
  // }

  useEffect(() => {
    if (!selectedPhoto) {
        setPreview(defaultProfilePic)
        return
    }

    const objectUrl = URL.createObjectURL(selectedPhoto)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedPhoto])

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    // findLegislators().then((reps) => {
    //   console.log(credentials, reps);
    //   if (reps.length === 3)
        db.collection("organizations")
          .doc(currUser.uid)
          .update({
            ...credentials,
            // districtRep: reps[0],
            // senateRep: [reps[1], reps[2]],
          })
          .then(() => {
            var cred = firebase.auth.EmailAuthProvider.credential(currUser.email, oldPassword);
            currUser.reauthenticateWithCredential(cred).then(() => {
              currUser.updatePassword(newPassword).then(() => {
                db.collection("users").doc(currUser.uid).update({profileFinished: true}).then(() => {
                  // console.log("pushing to /org/home")
                  // window.location.reload();
                  // history.push("/org/home");
                  db.collection("users").doc(currUser.uid).update({profileFinished: true}).then(() => {
                    let storageRef = firebase.storage().ref();
                    let photoRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)
                    photoRef.put(selectedPhoto).then((snapshot) => {
                      console.log('Uploaded photo!');
                      history.push("/welcome/organization");
                    });
                  })
                })
              })
            })
          })
          .catch((e) => setErr(e));
      // else
      //   db.collection("organizations")
      //     .doc(currUser.uid)
      //     .set({ ...credentials, districtRep: reps[0] })
      //     .then((res) => {
      //       currUser.updatePassword(newPassword).then(() => {
      //         db.collection("users").doc(currUser.uid).set({profileFinished: true}).then(() => {
      //           history.push("/org/home");
      //         })
      //       })
      //     })
      //     .catch((e) => setErr(e));
      setErr("");
      setLoading(false);
    // })
  }

  function handleChange(e) {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    // console.log("Credentials:", credentials)
  }

  function handleOldPasswordChange(e) {
    setOldPassword(e.target.value)
  }

  function handleNewPasswordChange(e) {
    setNewPassword(e.target.value)
  }

  // function handlePicChange(event) {
  //   let storageRef = firebase.storage().ref();
  //   let userPicRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)
  //   var selectedFile = event.target.files[0];
  //   var reader = new FileReader();

  //   var imgtag = document.getElementById("profilePicImg");
  //   imgtag.title = selectedFile.name;

  //   reader.onload = function(event) {
  //     imgtag.src = event.target.result;
  //   };

  //   reader.readAsDataURL(selectedFile);

  //   userPicRef.put(selectedFile).then((snapshot) => {
  //     console.log('Uploaded file!');
  //   });
  //   // console.log("PHOTO:", event.target.value)
  // }

  // function removeProfilePic(event) {
  //   let storageRef = firebase.storage().ref();
  //   let userPicRef = storageRef.child(`images/organization/profilePics/${currUser.uid}`)

  //   // if (userPicRef.exists){
  //   userPicRef.delete().then(() => {
  //     console.log("deleted photo")
  //     var imgtag = document.getElementById("profilePicImg");
  //     imgtag.src = defaultProfilePic
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  //   // }

  // }

  function handlePicChange(event) {
    if (event.target.files[0]){
      setSelectedPhoto(event.target.files[0])
    }
  }

  function removeProfilePic(event) {
    setSelectedPhoto(null)
  }

  if (currUserAdminInfo.profileFinished) {
    return <Redirect to="/org/home" />;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="mx-auto w-11/12 py-5 md:py-10 md:w-10/12">
        <h1 className="text-4xl font-semibold">
          Complete your profile!
        </h1>

        <div className="mt-6">
          <form
            action="#"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit}
          >
            <div className="mb-10">
              <h1 className="text-2xl font-semibold mb-8">Password</h1>
              <div className="mb-6">
                <label
                  for="oldPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Old password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    type="text"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    placeholder="Enter your old temporary password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  for="newPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  New password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="newPassword"
                    name="newPassword"
                    type="text"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    placeholder="Enter new password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              {/* <div className="mb-6">
                <label
                  for="firstName"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Confirm new password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={credentials.firstName}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}
            </div>

            <div className="mb-10 pb-5">
              <h1 className="text-2xl font-semibold mb-8">Region</h1>
              <div className="grid grid-cols-2 gap-x-4">
                <div className="col-start-1 col-end-2 mb-6">
                  <label
                    for="orgScope"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                    Select your regional level
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <select
                      id="orgScope"
                      name="orgScope"
                      value={credentials.orgScope}
                      onChange={handleChange}
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                      <option value="" disabled selected hidden></option>
                      <option value="national">National</option>
                      <option value="state">State</option>
                      <option value="district">District</option>
                    </select>
                  </div>
                </div>
                <div className="col-start-2 col-end-3 mb-6">
                  <label
                    for="websiteURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                    Organization Type
                  </label>
                  <select
                    id="type"
                    name="type"
                    value={credentials.type}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option value="" disabled selected hidden></option>
                    <option value="Campaign">Campaign</option>
                    <option value="Party Chapter">Party Chapter</option>
                    <option value="PAC">PAC</option>
                    <option value="Nonprofit">Nonprofit</option>
                    <option value="Student">Student</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    for="hqState"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                    Select your headquarters' state
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <select
                      id="hqState"
                      name="hqState"
                      value={credentials.hqState}
                      onChange={handleChange}
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                      <option value="" disabled selected hidden></option>
                      {Object.keys(states_hash).map((state) => {
                        return <option value={state}>{states_hash[state]}</option>;
                      })}
                    </select>
                  </div>
                </div>
                {credentials.hqState && (<div className="flex-1">
                  <label
                    for="hqDistrict"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                    Select your headquarters' district
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <select
                        id="hqDistrict"
                        name="hqDistrict"
                        value={credentials.hqDistrict}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      >
                        <option value="" disabled selected hidden></option>
                        {
                          states_districts[credentials.hqState] === "At-Large" ?
                          <option value="At-Large">At Large</option>
                          :
                        Array(states_districts[credentials.hqState])
                          .fill()
                          .map((_, idx) => {
                            return <option value={idx + 1}>{idx + 1}</option>;
                          })}
                      </select>
                  </div>
                </div>)}
                <div className="flex-1">
                <label
                  for="hqCity"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Enter your headquarters' city
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="hqCity"
                    name="hqCity"
                    type="text"
                    value={credentials.hqCity}
                    onChange={handleChange}
                    placeholder="Enter city name"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              </div>
            </div>

            <div className="mb-10">
              <h1 className="text-2xl font-semibold mb-8">Public Contact Information</h1>

              <div className="flex items-end pb-4">
                <label
                  for="profilePic"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                  Profile picture
                </label>
                <label className="ml-2 text-xs text-gray-700">(optional)</label>
              </div>

              <div className="mb-9 flex items-center">
                <div className="bg-gray-400 h-20 w-20 rounded-full">
                  <img id="profilePicImg" className="object-scale-down inline-block h-20 w-20 rounded-full" src={preview} alt=""/>
                </div>
                <div className="ml-4 flex">
                  <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
                    Change
                    <input onChange={handlePicChange} id="profilePic" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
                  </button>
                  <button onClick={removeProfilePic} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
                    Remove
                  </button>
                </div>
              </div>

              <div className="mb-6">
                <label
                  for="websiteURL"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Website link
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="websiteURL"
                    name="websiteURL"
                    type="text"
                    value={credentials.websiteURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="flex space-x-4 mb-6">
                <div className="flex-1">
                  <label
                    for="contactEmail"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                  >
                   Contact email address
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="contactEmail"
                      name="contactEmail"
                      type="email"
                      value={credentials.contactEmail}
                      onChange={handleChange}
                      placeholder="Enter email address"
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex items-end space-x-2 mb-2">
                    <label
                      for="contactPhone"
                      className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                    >
                      Contact phone number
                    </label>
                    <div className="text-xs text-gray-700">(optional)</div>
                  </div>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="contactPhone"
                      name="contactPhone"
                      type="text"
                      value={credentials.contactPhone}
                      onChange={handleChange}
                      placeholder="Enter phone number (numbers only)"
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="twitterURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Twitter profile link
                  </label>
                  <div className="text-xs text-gray-700">(optional)</div>
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="twitterURL"
                    name="twitterURL"
                    type="text"
                    value={credentials.twitterURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="instagramURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Instagram profile link
                  </label>
                  <div className="text-xs text-gray-700">(optional)</div>
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="instagramURL"
                    name="instagramURL"
                    type="text"
                    value={credentials.instagramURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="facebookURL"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Facebook profile link
                  </label>
                  <div className="text-xs text-gray-700">(optional)</div>
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="facebookURL"
                    name="facebookURL"
                    type="text"
                    value={credentials.facebookURL}
                    onChange={handleChange}
                    placeholder="Enter URL"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-end space-x-2 mb-2">
                  <label
                    for="description"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Organization description
                  </label>
                  <div className="text-xs text-gray-700">(optional)</div>
                </div>
                <div className="mt-1 rounded-md shadow-sm">
                  <textarea
                    id="description"
                    name="description"
                    type="text"
                    value={credentials.description}
                    maxLength="500"
                    rows={3}
                    onChange={handleChange}
                    placeholder="Enter a short description of your organization"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                  <h1 className="text-gray-500 float-right mt-2">
                    {credentials.description.length}/500 characters
                  </h1>
                </div>
              </div>
            </div>

            <div>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn-primary focus:outline-none"
                >
                  Submit
                </button>
            </div>
          </form>
          {err && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{err}</span>
              {/* <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </span> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinishProfile;
