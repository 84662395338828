import React, { useEffect, useState } from "react";
import { db } from "../../../../config/firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import SmallestIssueCard from "../../../../components/civ/global/issues/SmallestIssueCard";
import SmallestEventCard from "../../../../components/civ/global/events/SmallestEventCard";
import ScrollMenu from "react-horizontal-scrolling-menu";
import firebase from 'firebase'

const DistrictTrending = (props) => {
  const { userData } = useAuth();

	const [issueCards, setIssueCards] = useState([]);
	const [eventCards, setEventCards] = useState([]);

	useEffect(async () => {
		//TODO: CHANGE SCOPE TO DISTRICT, CHANGE CONTENT TO ACTUAL TRENDING (CALCULATE BASED ON TIME PERIOD)

		// db.collection("issues")
		// 	.orderBy("supporters", "desc")
		// 	.limit(6)
		// 	.get()
		// 	.then((results) => {
		// 		setIssueCards(
		// 			results.docs.map((issue) => (
		// 				<SmallestIssueCard
		// 					key={issue.id}
		// 					issueId={issue.id}
		// 					issueTitle={issue.data().issueTitle}
		// 					supporterCount={issue.data().supporters}
		// 				/>
		// 			))
		// 		);
		// 	});

		// db.collection("events")
		// 	.orderBy("numGoing", "desc")
		// 	.limit(6)
		// 	.get()
		// 	.then((results) => {
		// 		setEventCards(
		// 			results.docs.map((event) => (
		// 				<SmallestEventCard
		// 					key={event.id}
		// 					eventId={event.id}
		// 					eventTitle={event.data().eventTitle}
		// 					organizer={event.data().organizer}
		// 				/>
		// 			))
		// 		);
		// 	});

    console.log("STATE:", userData.state)
    console.log("DISTRICT:", userData.district)

    var date = new Date();
    date.setDate(date.getDate()-30);
    let oneWeekAgo = firebase.firestore.Timestamp.fromDate(date)

    var date2 = new Date();
    let today = firebase.firestore.Timestamp.fromDate(date2)

    // let tempIssues = await db.collection("issues").orderBy("supporters", "desc").limit(5).get()
    let tempIssues = await db.collection("issues").where("active", "==", true).where("region", "==", userData.state).where("district", "==", userData.district).where("dateCreated", ">", oneWeekAgo).get()
    console.log("# of issues:", tempIssues.docs.length)
    let finalIssues = tempIssues.docs.sort((a,b) => (a.data().supporters < b.data().supporters) ? 1 :  -1)
      // .filter((doc) => {
      //   return(doc.data().active == true)
      // })
    console.log("final issues:", finalIssues)
    Promise.all(finalIssues.slice(0, 5).map((issue) => (
      				<SmallestIssueCard
      					key={issue.id}
      					issueId={issue.id}
      					issueTitle={issue.data().issueTitle}
      					supporterCount={issue.data().supporters}
      				/>
      			))).then((result) => {
              setIssueCards(result)
            })

    // let tempEvents = await db.collection("events").orderBy("numAttending", "desc").limit(5).get()
    let tempEvents = await db.collection("events").where("mainStartTimeUTC", ">", today).where("state", "==", userData.state).where("district", "==", userData.district).get()
    let finalEvents = tempEvents.docs.sort((a,b) => (a.data().numAttending < b.data().numAttending) ? 1 :  -1)
      // .filter((doc) => {
      //   return(doc.data().active == true)
      // })
      Promise.all(finalEvents.slice(0, 5).map((event) => (
        				<SmallestEventCard
        					key={event.id}
        					eventId={event.id}
        					eventTitle={event.data().eventTitle}
        					organizer={event.data().organizerName}
        				/>)))
                .then((result) => {
                  setEventCards(result)
                })

	}, []);

	return (
		<div>
			<h1 className="text-2xl font-semibold">Trending</h1>
			{ (issueCards.length > 0 || eventCards.length > 0) ? <ScrollMenu
				data={[...issueCards, ...eventCards].sort(
					() => Math.random() - 0.5
				)}
				arrowLeft={
					<svg
						className="cursor-pointer flex-shrink-0 ml-1.5 mr-1.5 h-10 w-10 text-gray-500"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M10 19l-7-7m0 0l7-7m-7 7h18"
						/>
					</svg>
				}
				arrowRight={
					<svg
						className="cursor-pointer flex-shrink-0 ml-1.5 mr-1.5 h-10 w-10 text-gray-500"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M14 5l7 7m0 0l-7 7m7-7H3"
						/>
					</svg>
				}
				// menuClass={}
				wrapperClass={"py-7"}
			/>
      :
      <div className="py-6 text-xl">There are currently no trending issues or events in your district.</div>
      }
		</div>
	);
};

export default DistrictTrending;
