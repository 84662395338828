import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function AdminPrivateRoute({ component: Component, ...rest }) {
  const { currUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currUser ? (
            (currUser.uid === "QnrPp6LuHYNhi5ZxP0nfTCCbubE2") ? (
             <Component {...props} />
            ) :
            (
              <Redirect to="/error" />
            )
          ) : (
          <Redirect to="/error" />
        );
      }}
    ></Route>
  );
}

export default AdminPrivateRoute;
