import React, { useState, useEffect } from "react";
import IssueCard from "../../global/issues/IssueCard";
import {db} from "../../../../config/firebase"

const LegislatorIssues = (props) => {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    if (issues.length == 0) {
      const snapshot = await db
        .collection("issues")
        .where("legislatorId", "==", props.legislatorId)
        .where("active", "==", true)
        .get();
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          setIssues((issues) => [...issues, doc]);
        });
      } else {
        console.log("empty snapshot");
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <></>;
  } else if (issues.length == 0) {
    return (
      <>
        <div className="bg-white overflow-hidden text-center md:my-28">
          <div className="text-xl">
            There are currently no active issues for this legislator.
          </div>
        </div>
      </>
    );
  } else
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
          {issues.map((doc) => {
              return (
                <>
                  <IssueCard
                    issueTitle={doc.data().issueTitle}
                    supporters={doc.data().supporters}
                    legislator={
                      doc.data().legislator.firstName +
                      " " +
                      doc.data().legislator.lastName
                    }
                    region={`${doc.data().region}-${doc.data().district}`}
                    created={doc.data().dateCreated}
                    copies={doc.data().regionalCopies}
                    issueId={doc.id}
                  />
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LegislatorIssues;
