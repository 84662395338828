import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../layouts/AppLayout'
import VoteCard from '../../../components/civ/global/votes/VoteCard'
import BillVoteNav from '../../../components/civ/billsvotes/BillsVotesNav'
import VoteNav from '../../../components/civ/billsvotes/VoteNav'
import { BillVoteDBContext } from '../../../contexts/BillVoteContext';
import {db} from "../../../config/firebase"

const VotesHousePage = () => {

  const houseData = useContext(BillVoteDBContext);
  const [votesDisplay, setDisplay] = useState(<p className="h-screen px-8 py-8 font-medium text-2xl">{"Loading..."}</p>);
  const [sort, setSort] = useState("Roll Call");

  const [page, setPage] = useState(1);
  const pagination = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [lastVisible, setLast] = useState(null);
  const [items, setItems] = useState({});
  const [lastPage, setLastPage] = useState(0);

  useEffect(async () => {

  if(page.toString() in items) {

    let tempData = items[page.toString()];

    if(tempData.length != 0) {
    setDisplay(
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
        {tempData.map((vote, i) =>
        (<VoteCard
          key = {i}
          voteId = {vote.id}
          type={"house"}
          number={vote.data().number}
          outcome={vote.data().outcome}
          numIssues={vote.data().relatedIssueCount}
          billId ={vote.data().billInfo.number}
          title={vote.data().billInfo.title}
          altTitle={vote.data().altTitle}
          date={vote.data().date}
          time={vote.data().time}
          purpose={vote.data().purpose}
          legislatorResponse={vote.data().legislatorResponse}
          />))}
        </div>
      </div>);
    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No votes"}</p>)
    }

  } else if(page == 1) {

    if(houseData[6] != null && houseData[6].length != 0) {

      // copy context data
      let tempData = [...houseData[6]];

      // sort applied
      if(sort == "Number of Issues")
        tempData.sort((a,b) => (a.numIssues < b.numIssues) ? 1 : -1);
      else if(sort == "Date")
        tempData.sort((a,b) => (a.date < b.date) ? 1 : -1);
      else if(sort == "Roll Call")
        tempData = houseData[6];

      // create display out of it
      setDisplay(
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
          {tempData.map((vote, i) =>
          (<VoteCard
            key = {i}
            voteId = {vote.id}
            type={"house"}
            number={vote.data().number}
            outcome={vote.data().outcome}
            numIssues={vote.data().relatedIssueCount}
            billId ={vote.data().billInfo.number}
            title={vote.data().billInfo.title}
            altTitle={vote.data().altTitle}
            date={vote.data().date}
            time={vote.data().time}
            purpose={vote.data().purpose}
            legislatorResponse={vote.data().legislatorResponse}
            />))}
          </div>
        </div>);

      // add to items
      var tempObj = items;
      tempObj["1"] = [...tempData];
      setItems(tempObj);

      // set last for next query
      setLast(tempData[tempData.length - 1]);
      setLastPage(1);

    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No votes"}</p>)
    }

  } else {

    let wow = lastVisible;
    var i;
    for(i = lastPage + 1; i <= page; i++) {

      // get new data from DB
      let votes = [];

      if(db) {
        const votesRef = db.collection('votes').where("chamber", "==", "House").orderBy("number").startAfter(wow).limit(20);
        const snapshot = await votesRef.get();
        if(!snapshot.empty) {
            snapshot.forEach((doc) => { votes.push(doc); });
        } else {
            console.log('empty snapshot');
        }
      }

      if(votes != null && votes.length != 0) {

        // sort applied
        if(sort == "Number of Cosponsors")
          votes.sort((a,b) => (a.cosponsors < b.cosponsors) ? 1 :  -1);
        else if(sort == "Number of Issues")
          votes.sort((a,b) => (a.issues < b.issues) ? 1 : -1);
        else if(sort == "Date Introduced")
          votes.sort((a,b) => (a.introduced_date < b.introduced_date) ? 1 : -1);

        // save display at index 1
        var tempObj = items;
        tempObj[i.toString()] = [...votes];
        setItems(tempObj);

        wow = votes[votes.length - 1];

      } else {
        var tempObj = items;
        tempObj[i.toString()] = [];
        setItems(tempObj);
      }

    }

    setLastPage(i - 1);
    setLast(wow);
    let tempData = items[page.toString()];

    if(tempData != null && tempData.length != 0) {
    setDisplay(
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
        {tempData.map((vote, i) =>
        (<VoteCard
          key = {i}
          voteId = {vote.id}
          type={"house"}
          number={vote.data().number}
          outcome={vote.data().outcome}
          numIssues={vote.data().relatedIssueCount}
          billId ={vote.data().billInfo.number}
          title={vote.data().billInfo.title}
          altTitle={vote.data().altTitle}
          date={vote.data().date}
          time={vote.data().time}
          purpose={vote.data().purpose}
          legislatorResponse={vote.data().legislatorResponse}
          />))}
        </div>
      </div>);
    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No votes"}</p>)
    }
  }

}, [sort, houseData[6], page]);



  return (
    <AppLayout>
      <BillVoteNav/>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <VoteNav setSort={setSort}/>


        <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
          {votesDisplay}
        </div>
        </div>

        <div className="flex justify-between mt-8">
        <div className="text-xl">{page in items && items[page].length} results</div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          {pagination.map((num) => {
            return (
              <button
                className={`${
                  num === page ? "bg-gray-200" : "bg-white"
                } focus:outline-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50`}
                onClick={() => setPage(num)}
              >
                {num.toString()}
              </button>
            );
          })}
        </nav>
      </div>


      </div>
    </AppLayout>
  );
}

export default VotesHousePage;
