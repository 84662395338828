import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import DistrictOrganizationsNav from "./DistrictOrganizationsNav";
import OrganizationCard from "../../global/organizations/OrganizationCard"
import { DistrictDBContext } from "../../../../contexts/DistrictContext";
import firebase from "firebase";
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png"

const DistrictTopOrganizations = () => {

  const organizations = useContext(DistrictDBContext);
  let [topOrganizations, setTopOrganizations] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect( () => {
    if(organizations[9] != null && organizations[9].length != 0) {

        Promise.all(organizations[9].map(async (org, i) => {

        let orgSrc = defaultProfilePic

        let storageRef = firebase.storage().ref();
        let userPicRef = storageRef.child(`images/organization/profilePics/${org.id}`)
        try {
          let result = await userPicRef.getDownloadURL()
          orgSrc = result
        } catch(error) {
          console.log(error)
        }

        return (<OrganizationCard
          // key = {i}
          // name={org.data().name}
          // followers={org.data().followerCount}
          // issues={org.data().activeIssueCount}
          // events={org.data().upcomingEventCount}
          key = {i}
          organizationId={org.id}
          name={org.data().name}
          activeIssues={org.data().activeIssueCount}
          followers={org.data().followerCount}
          upcomingEvents={org.data().upcomingEventCount}
          profilePicUrl={orgSrc}
        />)
        })).then((result) => {
          setTopOrganizations(result)
        })

    } else {
      setTopOrganizations(<h1 className="px-8 py-8 font-medium text-xl">{"There are currently no organizations in your district."}</h1>)
    }
  }, [organizations[9]]);

  return (
    <>
      {/* <DistrictOrganizationsNav /> */}
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {topOrganizations}
          </div>
        </div>
      </>
    );

};

export default DistrictTopOrganizations;
