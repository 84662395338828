import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import jd from '../../../../assets/jd.png';

const EventCard = (props) => {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.title) {
      setLoading(false);
    } else {
      console.log('props are not here!');
    }
  });

  // console.log('props title:');
  // console.log(props.title);

  const formatDate = (timestamp, timestring, isVirtual, location) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    if (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
      let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
      yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
      uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
      let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

      // let isAm = true;
      // let hours = timestamp.toDate().getHours();
      // if (hours >= 12) {
      //   isAm = false;
      //   if (hours !== 12) {
      //     hours = hours - 12;
      //   }
      // } else if (hours === 0) {
      //   hours = 12;
      // }

      // let minutes;
      // if (timestamp.toDate().getMinutes() !== 0) {
      //   minutes = ':' + timestamp.toDate().getMinutes();
      // } else {
      //   minutes = ':00';
      // }

      const numOfDayOfWeek = timestamp.toDate().getDay();

      let locationString = location;
      if (isVirtual) {
        locationString = 'Virtual Event';
      }

      // let amOrPm = 'pm';
      // if (isAm) {
      //   amOrPm = 'am';
      // }

      const fullString =
        daysOfWeek[numOfDayOfWeek] +
        ' ' +
        f_dateString +
        ' @ ' +
        timestring +
        ' • ' +
        locationString; // format from notion

      return fullString;
    } else {
      // if there isn't a date associated with the issue
      return 'Date N/A';
    }
  };

  return (
    <Link
      to={`/events/${props.eventId}`}
      className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out divide-y divide-gray-200"
    >
      {/* <div className='rounded-lg border border-gray-100 px-6 py-6 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out'> */}

      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <h1 className='text-2xl leading-5 font-semibold text-gray-900 truncate max-w-4/5 py-2'>
            {props.title}
          </h1>
          {/* <h2 className='text-xl leading-5 font-medium text-gray-900'>
            {props.going} attending • {props.interested} interested
          </h2> */}
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="flex space-x-4">
            <div className='flex flex-row items-center justify-center'>
              {/* <img
                className='rounded-med h-10 w-10 border-2 border-gray-100'
                src={jd}
              /> */}
              <svg className="flex-shrink-0 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <h3 className='text-med font-medium mx-3 text-gray-700'>{props.organizer}</h3>
            </div>
            <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
              {/* <!-- Heroicon name: location-marker --> */}
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              {formatDate(props.date, props.time, props.isVirtual, props.location)}
            </div>
          </div>
          <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
            <span>View event</span>
            <svg
              className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </div>
      </div>


    </Link>
  );
};
export default EventCard;
