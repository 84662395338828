import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import TypeCard from './TypeCard'
import {StateDBContext} from '../../../../contexts/StateContext'

const StateTypeEvents = () => {

    const typeEvents = useContext(StateDBContext);
    let [typeDisplay, setDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    useEffect(() => {

      if(typeEvents[8] != null && Object.keys(typeEvents[8]).length !== 0) {
        setDisplay(Object.keys(typeEvents[8]).map((topic) =>
          (<TypeCard name={topic} data={typeEvents[8][topic]} trendingTopic={false}/>)
        ))
      }
      else{
        setDisplay(<h1 className="px-8 py-8 font-medium text-xl">There are currently no upcoming events in your state.</h1>)
      }

    }, [typeEvents]);



    return (
      <div className="grid grid-cols-4 gap-x-5">
        {typeDisplay}
      </div>
    )

}

export default StateTypeEvents;
