import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IssueCard from "../global/issues/IssueCard";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";

const IssueCopies = (props) => {
  const [sortBy, setSortBy] = useState("upvotes");
  const [origIssue, setOrigIssue] = useState();
  const [supporters, setSupporters] = useState(1);
  const [numDistricts, setNumDistricts] = useState(1);
  const [loading, setLoading] = useState(true);
  const [issueCopies, setIssueCopies] = useState([]);

  const { currUser, currUserRef } = useAuth();

  useEffect(async () => {
    if (props.issueId) {
      if (props.issue.originalIssue) {
        // an original issue exists
        const origDoc = await db
          .collection("issues")
          .doc(props.issue.originalIssue)
          .get();
        setOrigIssue(origDoc.data());
        if (origDoc.data().supporters != 0){
          setSupporters(origDoc.data().supporters);
        }
        setNumDistricts(Object.keys(origDoc.data().districts).length);
        const snapshot = await db
          .collection("issues")
          .where("originalIssue", "==", props.issue.originalIssue)
          .get();
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            setIssueCopies((issues) => [...issues, doc]);
            setSupporters((old) => old + doc.data().supporters);
          });
        }
      } else {
        // this is the original issue
        if (props.issue.supporters != 0){
          setSupporters(props.issue.supporters);
        }
        const snapshot = await db
          .collection("issues")
          .where("originalIssue", "==", props.issueId)
          .get();
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            setIssueCopies((issues) => [...issues, doc]);
            setSupporters((old) => old + doc.data().supporters);
          });
        }
      }

      setLoading(false);
    }
  }, []);
  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  return (
    <>
      <div className="flex space-x-2 justify-center bg-gray-400 py-7 rounded-lg text-white text-3xl mb-8">
        <h1 className="font-bold">
          {supporters !== undefined ? supporters : "N/A"}{" "}
          {supporters === 1 ? "person" : "people"}
        </h1>
        <h1>in</h1>
        <h1 className="font-bold">
          {numDistricts ? numDistricts : "N/A"}{" "}
          {numDistricts === 1 ? "district" : "districts"}
        </h1>
        <h1>{supporters === 1 ? "supports this issue." : "support this issue."}</h1>
      </div>
      {origIssue && (
        <>
          <h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">
            Original Issue
          </h1>
          <ul className="shadow">
            <IssueCard
              issueTitle={origIssue.issueTitle}
              supporters={origIssue.supporters}
              legislator={
                origIssue.legislator.firstName +
                " " +
                origIssue.legislator.lastName
              }
              region={`${origIssue.region}-${origIssue.district}`}
              created={origIssue.dateCreated}
              copies={origIssue.regionalCopies}
              issueId={props.issue.originalIssue}
            />
          </ul>
        </>
      )}
      <div className="mb-8"></div>

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold leading-tight text-gray-900">
          Regional Issue Copies
        </h1>
        <div className="ml-4 mt-2 flex space-x-4 items-end">
          {/* <div>
            <label for="location" class="block text-sm font-medium text-justiceBlue-500">
              Sort By
            </label>
            <select
              id="location"
              name="location"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            >
              <option value="datePosted">Date Posted</option>
              <option value="upvotes">Popularity</option>
              <option value="">Etc</option>
            </select>
          </div> */}
          <span className="rounded-md shadow-sm">
            {currUser ?
              <Link
              to={`/issues/copy/${props.issueId}`}
              type="button"
              className="btn-primary"
            >
              + Create New Copy
            </Link>
            :
            <button
              className="btn-primary focus:outline-none"
              onClick={props.toggleAuthPopup}>
              + Create New Copy
            </button>}
          </span>
        </div>
      </div>
      {issueCopies.length !== 0 ? (
        <div className="divide-y divide-gray-200 shadow">
          {issueCopies.map((issueDoc) => {
            const issue = issueDoc.data();
            return (
              <IssueCard
                issueTitle={issue.issueTitle}
                supporters={issue.supporters}
                legislator={
                  issue.legislator.firstName + " " + issue.legislator.lastName
                }
                region={`${issue.region}-${issue.district}`}
                created={issue.dateCreated}
                copies={false}
                issueId={issueDoc.id}
              />
            );
          })}
        </div>
      ) : (
        <div className="text-lg w-full text-center mt-6">
          There are currently no regional copies for this issue.
        </div>
      )}
    </>
  );
};

export default IssueCopies;
