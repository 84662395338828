import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpMainPage = () => {

  return(
    <LandingLayout>

      <div class="bg-justiceBlue-500">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
          <div class="max-w-xl">
            <h2 class="text-3xl font-extrabold text-white sm:text-4xl sm:tracking-tight lg:text-5xl">Civilian Help Center</h2>
            <p class="mt-5 text-xl text-gray-100">Need help using the site? Don't know where to start or what to do first? Never fear, for the help docs are here!</p>
          </div>
        </div>
      </div>

      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <div class="bg-white">
        <div class="max-w-7xl mx-auto pt-16 px-4 divide-y-2 divide-gray-200 sm:pt-24 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-gray-900">
            Getting Started
          </h2>
          <div class="mt-6 pt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <Link to="/help/civilian/find-your-way-around" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Find your way around GrassrootsGov</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn about the different pages of the site and how to navigate through them.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/view-whats-happening" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>View what's happening</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn the fundamentals of how to use GrassrootsGov and view issues, events, organizations, legislators, legislation, and more on the site.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/get-engaged" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Get engaged</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to join the conversation and get engaged with GrassrootsGov through issues, notes, and events.
                </dd>
              </div>
              {/* <div>
                <Link to="/help/civilian/new-user-faq" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>New user FAQ</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  I don&#039;t know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-gray-900">
            Using GrassrootsGov
          </h2>
          <div class="mt-6 pt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <Link to="/help/civilian/issues-notes" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Issues & notes</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how issues and notes work as well as how to post and support them.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/legislators-legislation" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Legislators & legislation</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to view and interact with legislation and legislators.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/organizations" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Organizations</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to get involved with organizations.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/events" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Events</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to find, sign up for, and bookmark events.
                </dd>
              </div>
              <div>
                <Link to="/help/civilian/user-profiles" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>User profiles</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to view user profiles, including your own.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="max-w-7xl mx-auto pb-16 px-4 divide-y-2 divide-gray-200 sm:pb-24 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-gray-900">
            Managing Your Account
          </h2>
          <div class="mt-6 pt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div>
                <Link to="/help/civilian/login-password" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Login & password</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to log in to your account and reset your password.
                </dd>
              </div>
              {/* <div>
                <Link to="/help/civilian/account-settings" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Account settings</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  I don&#039;t know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                </dd>
              </div> */}
              <div>
                <Link to="/help/civilian/deleting-your-account" class="flex space-x-2 items-center text-lg leading-6 font-medium text-justiceBlue-600 hover:text-justiceBlue-500">
                  <h1>Deleting your account</h1>
                  <svg className="flex-shrink-0 h-5 w-5 text-justiceBlue-600 hover:text-justiceBlue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </Link>
                <dd class="mt-2 text-base text-gray-500">
                  Learn how to delete your account.
                </dd>
              </div>
              {/* <div>
                <dt class="text-lg leading-6 font-medium text-gray-900">
                  What&#039;s the best thing about Switzerland?
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  I don&#039;t know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200"></div>

    </LandingLayout>
  )
}

export default CivilianHelpMainPage;
