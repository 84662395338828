import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Events
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Events 101</h2>
            <p>Events are held and created by organizations on our platform, and as a civilian user, you have the ability to browse various events across district/state/nation and interact with them in many different ways! When created, events can be labeled with specific topic tags (like Healthcare, or Climate Change), specific types (like Zoom, Watch party, Phonebank), and other categories that make them easier to filter by. Read through the following help docs to learn more!</p>

            <h2>How to view events in your district and state</h2>
            <p>Once logged into your GrassrootsGov account, you should be able to navigate to the  "District" and "State" pages. From there, you will be able to see a scrollable list of currently trending events in your area. </p>

            <h2>How to view events from around the nation</h2>
            <p>Once logged into your GrassrootsGov account, navigate to the "Nation" tab in the top right. Once clicked on, scroll to "Events" tab and select "Latest Events". Once on this page, you will be able to access information regarding how many people are going/interested in certain events. If you would like to see the top events, click on the "Top Events" tab located under "Events". </p>

            <h2>How to sign up / RSVP for events</h2>
            <p>Once you've found an event you want to attend, click "Sign up!" next to "RSVP for this event". Note that by signing up, you must agree to our [Terms of Service] and [Privacy Policy]. Afterwards, the event should be added to the "Upcoming" tab under " My Events" on your homepage, where you can easily access this information later.</p>

            <h2>How to bookmark events</h2>
            <p>To bookmark an event so that you can easily go back to it and consider signing up later, navigate to the top right of an event's page, and you'll find "Bookmark for Later". After bookmarking, you can then go to the "My Events" tab under the "Home" page. You should see a tab called "Bookmarked". If you click on this tab, you should be able to find any events that you have previously marked interest in and can come back to it later to sign up or get updated.  </p>

            <h2>How to view the upcoming events you've signed up for</h2>
            <p>We know that it may be hard to keep track of the events you signed up for, so we have a feature that does the work for you!</p>
            <p>Navigate to "My Events" from the homepage, and then you'll automatically be taken to the upcoming events you've RSVP'd for! You'll also find useful info, like the event topic, number of people attending/interested, and can click "View Event" to be taken to its page!</p>

            <h2>How to view the events you've previously attended</h2>
            <p>If you've attended an event and really enjoyed it, or just want to keep track of how active you've been on our platform, check out the previously attended events feature! </p>
            <p>From the homepage, click "My Events", then navigate to "Past". Here you'll find all the events that you've previously RSVP'd for! </p>

            <h2>How to view the events you've bookmarked</h2>
            <p>After you've bookmarked an event to revisit later, the most efficient way to access it is by utilizing our bookmarked events feature!</p>
            <p>From the homepage, navigate to "My Events" and then "Bookmarked". Here, you'll find all the events you've previously bookmarked, as well as additional info about each event. Clicking on "View Event" will bring you to its page, where you'll have the opportunity to RSVP for it!</p>

            <h2>How to view all the events an organization has posted</h2>
            <p>If you're interested in what an organization has been up to, the best way to find out is to view its events!</p>
            <p>First, navigate to an organization's page - you can do this by finding them in your "Following" section of your homepage, or by browsing through district and state. Then click on "Events". This will display all of the events that the organization is holding!</p>

            <h2>How to view the events related to a particular issue</h2>
            <p>Find an issue that you care about and want to get involved? Try out our related events feature!</p>
            <p>Starting from an issue page, navigate to "Take Action". Here you'll find the related events, and pressing "View Event" will take you to its page where you can either bookmark it for later, or RSVP right there!</p>


          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
