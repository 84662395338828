import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext"
import firebase from "firebase";

const formatDate = (timestamp) => {

  if (timestamp) {
    let date = new Date(timestamp.seconds * 1000);
    let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
    let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
    yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
    uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
    let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

    return f_dateString;
  } else {
    // if there isn't a date associated with the issue
    return 'Date N/A';
  }
};

function DashboardUpcomingEvents(props) {

  const {currUserRef} = useAuth();

  let [upcomingEvents, setUpcomingEvents] = useState([])

  useEffect(async () => {

    currUserRef.collection("events").orderBy("mainStartTimeUTC").startAt(firebase.firestore.Timestamp.now()).limit(3).get().then((events) => {

      if(events.docs && events.docs.length != 0) {

        let result = events.docs
          .filter((doc) => {
            let dateSeconds = doc.data().mainStartTimeUTC.seconds
            let nowSeconds = firebase.firestore.Timestamp.now().seconds
            return(dateSeconds > nowSeconds)
          })

        if (result.length !== 0) {
          setUpcomingEvents(result.map((doc, i) => {
            return(doc)
          }));
        }
      }
    })

  }, [])

  return (
    <div className="bg-gray-100 rounded-lg py-8 px-12 mb-10">

      <div className="flex justify-between items-end mb-8">
        <h1 className="text-2xl font-bold">My Upcoming Events</h1>
        <Link to="/org/events/upcoming" className="flex items-center text-red-500 font-semibold">
          View all
          <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </Link>
      </div>

      <div className="flex space-x-6">

        {upcomingEvents.length !== 0 ?
          upcomingEvents.map((event) =>
            <Link
              to={`/org/events/${event.id}/details`}
              className="flex-auto w-1/3 bg-white rounded-lg py-6 px-8 shadow hover:shadow-md">
              <h2 className="text-lg font-medium mb-3">
                {event.data().eventTitle}
              </h2>
              <div className="flex justify-between items-center">
                <p className="text-justiceBlue-600">{formatDate(event.data().mainStartTimeUTC)}</p>
                <p>{event.data().numAttending} attending  •  {event.data().numInterested} interested</p>
              </div>
            </Link>
          ):
          <h1 className="text-lg">You currently have no upcoming events. Post one to get started!</h1>}

      </div>

    </div>
  );
}

export default DashboardUpcomingEvents;
