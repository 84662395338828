import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import TypeCard from './TypeCard'
import {NationDBContext} from '../../../../contexts/NationContext'

const NationTypeEvents = () => {

    const typeEvents = useContext(NationDBContext);
    let [typeDisplay, setDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    useEffect(() => {

      if(typeEvents[8] != null && Object.keys(typeEvents[8]).length !== 0) {
        setDisplay(Object.keys(typeEvents[8]).map((topic) =>
          (<TypeCard name={topic} data={typeEvents[8][topic]} trendingTopic={false}/>)
        ))
      }
      else{
        setDisplay(<h1 className="px-8 py-8 font-medium text-xl">There are currently no upcoming events in your district.</h1>)
      }


    //   let typeList = [];
    //   if(typeEvents[5][0] != null) {
    //     for(const type in typeEvents[5][0]){
    //         topicsList.push(<TypeCard data={typeEvents[5][0][type]} />);
    //       }
    //   }

    //   showTopics(topicsList);

    }, [typeEvents]);

    return (
      <div className="grid grid-cols-4 gap-x-5">
        {typeDisplay}
      </div>
    )

}

export default NationTypeEvents;
