import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/OrgLayout";
import CivilianHeader from "../../../components/org/civilian/CivilianHeader";
import CivilianNotes from "../../../components/org/civilian/CivilianNotes";
import { db } from "../../../config/firebase";

function CivilianNotesPage({ match }) {
  const [civilianInfo, setCivilianInfo] = useState({
    uid: "",
    firstName: "Default",
    lastName: "Default",
  });
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(async () => {
    if (match && match.params.user && notes.length == 0) {
      // pulls name and issue infomation for now
      // future optimization for less reads: pass in the name so we don't have to get that document
      let userId = match.params.user;

      if (userId.length == 0) {
        setError("Bad link. No user id given.");
      } else {
        const currUserRef = db.collection("civilians").doc(userId);
        const userDoc = await currUserRef.get();
        if (!userDoc.exists) {
          setError("This user was not found.");
        } else {
          setCivilianInfo(
            userDoc.data()
          );
          const notesRef = currUserRef.collection("notes");
          const snapshot = await notesRef.get();
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              setNotes((note)=>[...note, doc.data()])
              // console.log(doc.id, "=>", doc.data());
              // notes.push(doc.data());
              // setIssues([...issues, { ...doc.data() }]);
            });
          } else {
            console.log("empty snapshot");
          }
        }
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <AppLayout>
        <></>
      </AppLayout>
    );
  }
  if (error) {
    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">{error}</div>
      </AppLayout>
    );
  }
  return (
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <CivilianHeader {...civilianInfo} counter={notes.length + " notes"} />

        <CivilianNotes notes={notes} />
      </div>
    </AppLayout>
  );
}

export default CivilianNotesPage;
