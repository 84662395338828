import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "../org/OrgPrivateRoute";

import OrganizationLatestPage from "../../pages/org/organization/OrganizationLatestPage";
import OrganizationIssuesPage from "../../pages/org/organization/OrganizationIssuesPage";
import OrganizationEventsPage from "../../pages/org/organization/OrganizationEventsPage";
import OrganizationAboutPage from "../../pages/org/organization/OrganizationAboutPage";
import OrganizationSettingsPage from "../../pages/org/organization/OrganizationSettingsPage";
import OrganizationEditPage from "../../pages/org/organization/OrganizationEditPage";

const OrganizationRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/org/organizations/:organization/latest"
				component={OrganizationLatestPage}
			/>
			<PrivateRoute
				path="/org/organizations/:organization/issues"
				component={OrganizationIssuesPage}
			/>
			<PrivateRoute
				path="/org/organizations/:organization/events"
				component={OrganizationEventsPage}
			/>
			<PrivateRoute
				path="/org/organizations/:organization/about"
				component={OrganizationAboutPage}
			/>
			<PrivateRoute
				path="/org/organizations/edit-profile"
				component={OrganizationEditPage}
			/>
			<PrivateRoute
				path="/org/organizations/settings"
				component={OrganizationSettingsPage}
			/>
		</Switch>
	);
};

export default OrganizationRoutes;
