import React, { useState, useEffect, useContext } from 'react';
// import IssueCard from '../../../components/civ/global/issues/IssueCard'
import {NationDBContext} from '../../../../contexts/NationContext'
import TopicCard from './TopicCard';

const NationTopicIssues = () => {

  const topIssues = useContext(NationDBContext);
  let [topicsDisplay, showTopics] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect(() => {

    let topicsList = [];
    if(topIssues[3] != null && topIssues[3].length != 0) {
      for(const topic in topIssues[3]){
        console.log(topIssues[3][topic]);
        topicsList.push(<TopicCard name={topic} data={topIssues[3][topic]} trendingTopic={false}/>);
      }
    } else {
      showTopics(<h1 className="px-8 py-8 font-medium text-xl">{"There are currently no active issues."}</h1>)
    }

    showTopics(topicsList);

  }, [topIssues]);



  return (
    <div className="grid grid-cols-4 gap-x-5">
      {topicsDisplay}
    </div>
  )
}

export default NationTopicIssues;
