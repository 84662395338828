import React, { useState } from 'react';
import { db } from '../../../../config/firebase';
// import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import firebase from 'firebase';

const ReportCard = (props) => {
  const [reportInput, setReportInput] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  function removeOverlay() {
    const overlay = document.getElementById('tempOverlay');
    if (overlay) {
      overlay.remove();
    }
  }

  function cancelSubmit() {
    removeOverlay();
  }

  function handleReportSubmit() {
    db.collection('reportedContent')
      .add({
        contentType: props.reportType,
        contentId: props.contentId,
        userInput: reportInput,
        dateReported: firebase.firestore.Timestamp.now(),
        reporterId: props.currUserId
      })
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
    // removeOverlay();
    setHasSubmitted(true);
  }

  if (hasSubmitted) {
    return (
      <div className='p-6 space-y-4 w-5/12 bg-white rounded-xl shadow flex-col items-center'>
        <div
          className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative'
          role='alert'
        >
          <strong className='font-bold'>Report submitted! </strong>
          <span className='block sm:inline'>Thank you.</span>
        </div>
        <div className='space-x-4'>
          <button onClick={removeOverlay} className='btn-white'>
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='p-6 space-y-4 w-5/12 bg-white rounded-xl shadow flex-col items-center'>
      <h2 className='text-3xl font-bold text-gray-900 mb-5'>
        Report this {props.reportType}:{' '}
      </h2>
      <form>
        <textarea
          onChange={(e) => setReportInput(e.target.value)}
          id='report'
          name='report'
          type='text'
          placeholder={
            'Explain why you want to report this ' +
            props.reportType +
            ' (optional)'
          }
          className='appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
        />
      </form>

      <div className='space-x-4'>
        <button onClick={handleReportSubmit} className='btn-primary'>
          Report
        </button>
        <button onClick={cancelSubmit} className='btn-white'>
          Cancel
        </button>
      </div>
    </div>
  );
};
export default ReportCard;
