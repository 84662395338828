import React, { useState } from 'react';
import { db } from '../../../../config/firebase'
import LegislatorCard from '../../global/legislators/LegislatorCard'
import { useAuth } from '../../../../contexts/AuthContext'

const DistrictDashboardSummary = (props) => {

  const { userData } = useAuth();

  const [issueCount, setIssueCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  db.collection("issues").where("region", "==", userData.state).where("district", "==", userData.district).get().then((result) => {
    setIssueCount(result.docs.length)
  });

  db.collection("civilians").where("state", "==", userData.state).where("district", "==", userData.district).get().then((result) => {
    setUserCount(result.docs.length)
  });


  return (
    <div className="bg-gray-100 grid grid-cols-3 gap-16 items-center px-16 py-10">

      <div className="shadow col-start-1 col-end-2 bg-white rounded-lg px-6 py-8 w-full">
        <div className="grid grid-cols-2 items-center">
          <div className="pl-5 text-6xl font-bold text-gray-700">
            {userData.state}-{userData.district}
          </div>
          <div className="col-start-2">
            <h1 className="text-xl"><span className="font-medium">Total issues:</span> {issueCount}</h1>
            <h1 className="text-xl"><span className="font-medium">Total users:</span> {userCount}</h1>
            {/* <h1 className="text-xl">Population: </h1> */}
            {/* <h1 className="text-xl">Your Ranking: </h1> */}
          </div>
        </div>
      </div>

      <div className="col-start-2 col-end-3">
        <h1 className="text-3xl font-bold mb-4 grid grid-cols-2 gap-x-8">Representative</h1>
        {/* TODO: change .districtRep */}
        <LegislatorCard
          className="col-start-1 col-end-2"
          profilePic="https://image.cnbcfm.com/api/v1/image/105438875-1536336503523screen-shot-2018-09-07-at-12.07.32-pm.jpg?v=1536336590"
          id={userData.districtRep.id}
          name={userData.districtRep.firstName + " " + userData.districtRep.lastName}
          party={userData.districtRep.party}
        />
      </div>
    </div>
  )
}

export default DistrictDashboardSummary;
