import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import LegLayout from "../../../layouts/LegLayout"
import LegBillVoteNav from "../../../components/leg/billsvotes/LegBillVoteNav.js";
import VotesNav from '../../../components/leg/billsvotes/VotesNav.js';

function VotesMostPopular(props) {
    // const dbData = useContext(someContext);
    const [votesDisplay, setDisplay] = useState(<p className="h-screen px-8 py-8 font-medium text-2xl">{"Loading..."}</p>);
    const [sort, setSort] = useState("None");

    useEffect(() => {
        // Implement sorting by criteria
        setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No votes to see right now"}</p>);
    }, [sort]);

    return (
      <LegLayout>
        <LegBillVoteNav />
            <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
                <VotesNav setSort={setSort}/>
                {votesDisplay}
            </div>
      </LegLayout>
    );
}

export default VotesMostPopular;
