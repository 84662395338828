import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./CivPrivateRoute";

import NationHomePage from "../../pages/civ/nation/NationHomePage";
import NationalStoriesList from "../../pages/civ/nation/NationalStoriesList";

import NationTopIssuesPage from "../../pages/civ/nation/issues/NationTopIssuesPage";
import NationLatestIssuesPage from "../../pages/civ/nation/issues/NationLatestIssuesPage";
import NationTopicsIssuesPage from "../../pages/civ/nation/issues/NationTopicIssuesPage";
import NationTopicsIssuesListPage from "../../pages/civ/nation/issues/NationTopicIssueListPage";
import NationResolvedIssuesPage from "../../pages/civ/nation/issues/NationResolvedIssuesPage";

import NationTopEventsPage from '../../pages/civ/nation/events/NationTopEventsPage';
import NationLatestEventsPage from '../../pages/civ/nation/events/NationLatestEventsPage';
import NationTypeEventsPage from '../../pages/civ/nation/events/NationTypeEventsPage';
import NationTypeEventListPage from '../../pages/civ/nation/events/NationTypeEventListPage';
import NationTopicEventsPage from '../../pages/civ/nation/events/NationTopicEventsPage';
import NationTopicEventListPage from '../../pages/civ/nation/events/NationTopicEventListPage';

import NationCategoryOrganizationPage from '../../pages/civ/nation/organizations/NationCategoryOrganizationsPage';
import NationOrganizationListPage from '../../pages/civ/nation/organizations/NationOrganizationListPage';
import NationPopularOrganizations from '../../pages/civ/nation/organizations/NationPopularOrganizationsPage';


import { StoriesProvider, NationProvider } from "../../contexts/NationContext";

const NationRoutes = () => {
  return (
    <NationProvider>
    <StoriesProvider>
      <Switch>
        <PrivateRoute path="/nation/issues/top" component={NationTopIssuesPage} />
        <PrivateRoute path="/nation/issues/latest" component={NationLatestIssuesPage} />
        <PrivateRoute path="/nation/issues/topics" component={NationTopicsIssuesPage} />
        <PrivateRoute path="/nation/issues/list" component={NationTopicsIssuesListPage} />
        <PrivateRoute path="/nation/issues/resolved" component={NationResolvedIssuesPage} />

        <PrivateRoute path="/nation/events/top" component={NationTopEventsPage} />
        <PrivateRoute path="/nation/events/latest" component={NationLatestEventsPage} />
        <PrivateRoute path="/nation/events/type" component={NationTypeEventsPage} />
        <PrivateRoute path="/nation/events/typelist" component={NationTypeEventListPage} />
        <PrivateRoute path="/nation/events/topic" component={NationTopicEventsPage} />
        <PrivateRoute path="/nation/events/topiclist" component={NationTopicEventListPage} />

        <PrivateRoute path="/nation/organizations/top" component={NationPopularOrganizations} />
        <PrivateRoute path="/nation/organizations/category" component={NationCategoryOrganizationPage} />
        <PrivateRoute path="/nation/organizations/list" component={NationOrganizationListPage} />

        {/* <PrivateRoute path="/nation/news/top" component={NationalStoriesList}/>
        <PrivateRoute path="/nation" component={NationHomePage} /> */}
      </Switch>
    </StoriesProvider>
    </NationProvider>
  )
}

export default NationRoutes;
