import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import AuthPopup from '../../../components/civ/global/AuthPopup'
import OrganizationHeader from "../../../components/civ/organization/OrganizationHeader";
import OrganizationEvents from "../../../components/civ/organization/OrganizationEvents";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png";

function OrganizationEventsPage({ match }) {
	const { currUser } = useAuth();

	// const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [organizationInfo, setOrganizationInfo] = useState({});
	const [following, setFollowing] = useState();
	const [orgEvents, setOrgEvents] = useState([]);
	const [src, setSrc] = useState(defaultProfilePic);
  const [authPopup, setAuthPopup] = useState(false);

	useEffect(async () => {
		if (match && match.params.organization) {
			let organizationId = match.params.organization;

			if (organizationId.length == 0) {
				setError("Bad link. No organization id given.");
			} else {
				let storageRef = firebase.storage().ref();
				let userPicRef = storageRef.child(
					`images/organization/profilePics/${organizationId}`
				);
				try {
					let result = await userPicRef.getDownloadURL();
					setSrc(result);
				} catch (error) {
					console.log(error);
				}

				db.collection("organizations")
					.doc(organizationId)
					.get()
					.then((organizationData) => {
						setOrganizationInfo(organizationData.data());

              db.collection("events")
                .where(
                  "organizerId",
                  "==",
                  organizationId
                )
                .get()
                .then((events) => {
                  console.log(
                    "# of events:",
                    events.docs.length
                  );

                  return (
                    events.docs &&
                    events.docs.map(
                      (event) => ({
                        eventId: event.id,
                        ...event.data(),
                      })
                    )
                  );
                })
                .then((orgEventsList) => {
                  //TODO: switch to datePosted
                  orgEventsList.sort((a, b) =>
                    a.mainStartTimeUTC <
                    b.mainStartTimeUTC
                      ? 1
                      : -1
                  );

                  console.log(
                    "orgEventsList:",
                    orgEventsList
                  );
                  setOrgEvents(orgEventsList);

                  if (currUser){
                    db
                  .collection("civilians")
                  .doc(currUser.uid)
                  .collection("orgsFollowing")
                  .doc(organizationId)
                  .get()
                  .then((result) => {
                    if (result.exists) {
                      setFollowing(true);
                      console.log("following: doc found!")
                    } else {
                      setFollowing(false);
                      console.log("not following: doc not found!")
                    }
                  })
                  }
                  // console.log("number of posts:", orgPostsList)
                });
					})
					.then(() => {
						setLoading(false);
					});
			}
		}
	}, []);

  function toggleAuthPopup(){
    setAuthPopup(!authPopup)
    // console.log("toggled:", authPopup)
  }

	if (loading) {
		return (
			<div className="w-screen h-screen flex items-center justify-center">
				<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
			</div>
		);
	}

	if (error) {
		return <AppLayout>{error}</AppLayout>;
	}

	return (
		<AppLayout>

      {authPopup &&
        <AuthPopup
          toggleAuthPopup={toggleAuthPopup}
          type="organization"
          >
        </AuthPopup>
      }

      {currUser ?
        <div>
          {(following===true || following===false) && <OrganizationHeader
            orgId={match.params.organization}
            orgName={organizationInfo.name}
            activeIssueCount={organizationInfo.activeIssueCount}
            upcomingEventCount={organizationInfo.upcomingEventCount}
            followerCount={organizationInfo.followerCount}
            hqState={organizationInfo.hqState}
            hqCity={organizationInfo.hqCity}
            following={following}
            profilePicUrl={src}
            toggleAuthPopup={toggleAuthPopup}
          />}
        </div>
        :
        <OrganizationHeader
          orgId={match.params.organization}
          orgName={organizationInfo.name}
          activeIssueCount={organizationInfo.activeIssueCount}
          upcomingEventCount={organizationInfo.upcomingEventCount}
          followerCount={organizationInfo.followerCount}
          hqState={organizationInfo.hqState}
          hqCity={organizationInfo.hqCity}
          following={following}
          profilePicUrl={src}
          toggleAuthPopup={toggleAuthPopup}
        />
      }
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        {orgEvents && <OrganizationEvents orgEvents={orgEvents} />}
			</div>
		</AppLayout>
	);
}

export default OrganizationEventsPage;
