import {useState} from 'react';
import AppLayout from '../../../../layouts/AppLayout'
import DistrictNav from '../../../../components/civ/district/DistrictNav'
import DistrictEventsNav from '../../../../components/civ/district/events/DistrictEventsNav'
import DistrictTopEvents from '../../../../components/civ/district/events/DistrictTopEvents'
// import addBtnImg from '../../../../assets/addBtnImg.png';


const DistrictTopEventsPage = () => {

    return (
        <AppLayout>
          <DistrictNav/>
          <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

            <DistrictEventsNav />
            <DistrictTopEvents/>
{/*
            <div className="fixed bottom-0 right-0 w-15 h-15 mr-12 mb-8 cursor-pointer" onClick={()=> {console.log('a')}}>
              <img className='w-11 h-11' src={addBtnImg}/>
            </div> */}

          </div>
        </AppLayout>
      )
}

export default DistrictTopEventsPage;
