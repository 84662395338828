import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import CivilianHeader from "../../../components/civ/civilian/CivilianHeader";
import CivilianIssues from "../../../components/civ/civilian/CivilianIssues";
import { db } from "../../../config/firebase";

function CivilianIssuesPage({ match }) {
	const [civilianInfo, setCivilianInfo] = useState();
	const [issues, setIssues] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	useEffect(async () => {
		if (match && match.params.user && issues.length == 0) {
			// pulls name and issue infomation for now
			// future optimization for less reads: pass in the name so we don't have to get that document
			let userId = match.params.user;

			if (userId.length == 0) {
				setError("Bad link. No user id given.");
			} else {
				console.log(userId);
				const currUserRef = db.collection("civilians").doc(userId);
				const userDoc = await currUserRef.get();
				if (!userDoc.exists) {
					setError("This user was not found.");
				} else {
					setCivilianInfo(userDoc.data());
					// const issuesRef = currUserRef.collection("issues");
					// const snapshot = await issuesRef.where("active", "==", true).get();
					const snapshot = await db
						.collection("issues")
						.where("createdBy", "==", userId)
						.where("active", "==", true)
						.get();
					if (!snapshot.empty) {
						console.log(snapshot.docs);
						console.log("# of issues:", snapshot.docs.length);
						Promise.all(
							snapshot.docs.map((issue) => {
								return issue;
							})
						).then((result) => {
							setIssues(result);
						});
						console.log("loading issues here");
					} else {
						console.log("empty snapshot");
					}
				}
			}
			setLoading(false);
		}
	}, []);

	if (loading) {
		return (
			<AppLayout>
				<></>
			</AppLayout>
		);
	}
	if (error) {
		return (
			<AppLayout>
				<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
					{error}
				</div>
			</AppLayout>
		);
	}
	return (
		<AppLayout>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<CivilianHeader
					{...civilianInfo}
					counter={issues.length + " issues"}
				/>

				<CivilianIssues issues={issues} />
			</div>
		</AppLayout>
	);
}

export default CivilianIssuesPage;
