import React from "react";
import IssueCard from "../../civ/global/issues/IssueCard";

const CivilianIssues = (props) => {
	if (props.issues.length == 0) {
		return (
			<>
				<div className="bg-white overflow-hidden text-center md:my-28">
					<div className="text-xl">No active issues</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="bg-white shadow overflow-hidden sm:rounded-md">
					<div className="divide-y divide-gray-200">
						{props.issues.map((doc) => {
							return (
								<>
									<IssueCard
										// title={doc.data().title}
										// supporters={doc.data().numSupporters}
										// legislator="Ayanna Pressley"
										// region="MA-7"
										issueTitle={doc.data().issueTitle}
										supporters={doc.data().supporters}
										legislator={
											doc.data().legislator.firstName +
											" " +
											doc.data().legislator.lastName
										}
										region={`${doc.data().region}-${
											doc.data().district
										}`}
										created={doc.data().dateCreated}
										copies={doc.data().regionalCopies}
										issueId={doc.id}
									/>
								</>
							);
						})}
					</div>
				</div>
			</>
		);
	}
};

export default CivilianIssues;
