import React from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeEventsNav from "../../../components/civ/home/events/HomeEventsNav.js";
import HomeEventsUpcomingDetailed from "../../../components/civ/home/events/HomeEventsUpcomingDetailed.js";

const HomeUpcomingEventsPage = () => {
	return (
		<AppLayout>
			<HomePageNav />
			{console.log("events upcoming page entered")}
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeEventsNav />
				<HomeEventsUpcomingDetailed />
			</div>
		</AppLayout>
	);
};

export default HomeUpcomingEventsPage;
