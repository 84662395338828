import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { db } from "../../../config/firebase";
import AppLayout from "../../../layouts/AppLayout";
import AuthPopup from '../../../components/civ/global/AuthPopup'
import EventInfo from "../../../components/civ/events/EventInfo";
import EventDescription from "../../../components/civ/events/EventDescription";
import EventTags from "../../../components/civ/events/EventTags";
import EventAttendance from "../../../components/civ/events/EventAttendance";
import EventRSVP from "../../../components/civ/events/EventRSVP";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import { Redirect } from "react-router-dom";

// TODO
//   implement timezone conversion for displaying info, bookmarking, and registering
//   make the event picture always the same height as the event time and location stack
//   implement events with multiple days available (in the RSVP section and also in event cards)

const EventPage = ({ match }) => {
	let history = useHistory();
	const { currUser, currUserAdminInfo } = useAuth();

	const [eventData, setEventData] = useState({});
	const [photoSrc, setPhotoSrc] = useState();
	const [eventTimes, setEventTimes] = useState([]);
	const [eventTimesDisplay, setEventTimesDisplay] = useState();
	const [eventBookmarked, setEventBookmarked] = useState(false);
  const [authPopup, setAuthPopup] = useState(false);

	let eventId = match.params.eventId;

	useEffect(async () => {
		const currEventRef = await db.collection("events").doc(eventId);
    const eventDoc = await currEventRef.get();
    let currUserRef
    if (currUser){
      currUserRef = await db.collection("civilians").doc(currUser.uid);
    }

		if (!eventDoc.exists) {
			// setError('This event  was not found.');
			console.log("no event doc exists");
		} else {
			setEventData(eventDoc.data());
			let timeArr = [];
			await currEventRef
				.collection("eventTimes")
				.get()
				.then((snapshot) => {
					snapshot.forEach((doc) => {
						console.log(doc.data().startTimeUTC);
						timeArr.push({
							start: doc.data().startTimeUTC,
							startString: doc.data().startTimeString,
							end: doc.data().endTimeUTC,
							endString: doc.data().endTimeString,
							timeZone: doc.data().timeZone,
						});
					});

					setEventTimesDisplay(
						snapshot.docs.map((doc) => (
							<div className="text-lg mt-2">
								{doc.data().startTimeString}—
								{doc.data().endTimeString} {doc.data().timeZone}
							</div>
						))
					);
				})
				.then(() => {
					console.log(timeArr);
					timeArr.sort((a, b) => a.start - b.start);
					setEventTimes(timeArr);

					let storageRef = firebase.storage().ref();
					let photoRef = storageRef.child(
						`images/organization/events/${eventDoc.id}`
					);
					photoRef
						.getDownloadURL()
						.then((url) => {
							setPhotoSrc(url);
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((error) => {
					console.log("Error getting documents: ", error);
				});

      if (currUser) {
        if (
          (
            await currUserRef
              .collection("bookmarkedEvents")
              .doc(eventId)
              .get()
          ).exists
        ) {
          setEventBookmarked(true);
        }
      }
		}
	}, []);

  function toggleAuthPopup(){
    setAuthPopup(!authPopup)
    // console.log("toggled:", authPopup)
  }

	const handleBookmark = () => {
		const currUserRef = db.collection("civilians").doc(currUser.uid);

		if (eventBookmarked) {
			setEventBookmarked(false);
			// Remove event from bookmarked list

			currUserRef.collection("bookmarkedEvents").doc(eventId).delete();

			db.collection("events")
				.doc(eventId)
				.update({
					numInterested: firebase.firestore.FieldValue.increment(-1),
				});
			db.collection("organizations")
				.doc(eventData.organizerId)
				.collection("events")
				.doc(eventId)
				.update({
					numInterested: firebase.firestore.FieldValue.increment(-1),
				});

			// decrement interested count
		} else {
			setEventBookmarked(true);
			// Add event to bookmarked list

			currUserRef.collection("bookmarkedEvents").doc(eventId).set({
				eventTitle: eventData.eventTitle,
				organizerName: eventData.organizerName,
				mainStartTimeUTC: eventData.mainStartTimeUTC,
				mainStartTimeString: eventData.mainStartTimeString,
				timeZone: eventData.timeZone,
				isVirtual: eventData.isVirtual,
				city: eventData.city,
				state: eventData.state,
				eventId: eventId,
				// bookmarkedTimes: eventTimes, // FIX THIS AS ABOVE!!!
			});

			db.collection("events")
				.doc(eventId)
				.update({
					numInterested: firebase.firestore.FieldValue.increment(1),
				});
			db.collection("organizations")
				.doc(eventData.organizerId)
				.collection("events")
				.doc(eventId)
				.update({
					numInterested: firebase.firestore.FieldValue.increment(1),
				});
			// TODO: anywhere else in db to update?
		}
	};

	const formatDate = (timestamp) => {
		const daysOfWeek = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		if (timestamp) {
			let date = new Date(timestamp.seconds * 1000);
			let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
			let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
			yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
			uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
			let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY

			const numOfDayOfWeek = timestamp.toDate().getDay();

			const fullString = daysOfWeek[numOfDayOfWeek] + " " + f_dateString;

			return fullString;
		} else {
			// if there isn't a date associated with the issue
			return "Date N/A";
		}
	};

	let hostLocationInfo;

	if (eventData.isVirtual) {
		hostLocationInfo = (
			<div className="pt-2">
				<h2 className="text-lg mb-2">
					<span className="font-medium">Virtual Event</span>
					<span> • Join from anywhere!</span>
				</h2>
				<h2 className="text-lg">
					Hosted from {eventData.city}, {eventData.state}{" "}
					{eventData.zipCode}
				</h2>
			</div>
		);
	} else {
		hostLocationInfo = (
			<div className="pt-2">
				<h2 className="text-lg mb-2">
					<span className="font-medium">In-Person Event</span>
				</h2>
				<h2 className="text-lg">
					Hosted at {eventData.streetAddress} {eventData.city},{" "}
					{eventData.state} {eventData.zipCode}
				</h2>
			</div>
		);
	}

	let tagsList =
		eventData.topicTags &&
		eventData.topicTags.map((tag) => {
			let styling = "";
			switch (tag) {
				case "Climate":
					styling =
						"bg-blue-500 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Healthcare":
					styling =
						"bg-pink-500 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Economy":
					styling =
						"bg-yellow-400 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Criminal Justice":
					styling =
						"bg-purple-500 px-5 py-2 rounded-full text-white font-medium";
					break;
        case "Foreign Policy":
          styling =
            "bg-blue-600 px-5 py-2 rounded-full text-white font-medium";
          break;
				case "Education":
					styling =
						"bg-green-500 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Reproductive Rights":
					styling =
						"bg-indigo-700 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "LGBTQ+":
					styling =
						"bg-yellow-600 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Immigration":
					styling =
						"bg-pink-700 px-5 py-2 rounded-full text-white font-medium";
					break;
				case "Voting/Elections":
					styling =
						"bg-red-500 px-5 py-2 rounded-full text-white font-medium";
					break;
        case "Infrastructure":
          styling =
            "bg-purple-600 px-5 py-2 rounded-full text-white font-medium";
          break;
				default:
					styling =
						"bg-gray-500 px-5 py-2 rounded-full text-white font-medium";
					break;
			}

			return <div className={styling}>{tag}</div>;
		});

  if (currUser && currUserAdminInfo.type === "organization") {
    return <Redirect to={`/org/events/${match.params.eventId}/details`} />
  }

	return (
		<AppLayout>

      {authPopup &&
        <AuthPopup
          toggleAuthPopup={toggleAuthPopup}
          type="event"
          >
        </AuthPopup>
      }

			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        {currUser &&
				<button
					className="mb-2 flex space-x-2 items-center focus:outline-none"
					onClick={() => {
						history.goBack();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="flex-shrink-0 h-5 w-5 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
					<h1>Back</h1>
				</button>
        }

				{/* <div className="flex justify-between items-end mb-8 mt-4">
					<div>
						<h1 className="text-5xl font-bold text-gray-900 mb-5">
							{eventData.eventTitle
								? eventData.eventTitle
								: "Loading Title"}
						</h1>
						<div className="text-2xl font-semibold flex space-x-3 items-center">
							<Link className="hover:underline">
								by{" "}
								{eventData.organizerName
									? eventData.organizerName
									: "Loading Organizer"}
							</Link>
						</div>
					</div>

					<div className="flex space-x-3">
						<button
							className={`rounded-md px-6 py-2 text-md font-bold focus:outline-none ${
								eventBookmarked
									? "bg-justiceBlue-500 text-white hover:bg-justiceBlue-400"
									: "bg-justiceBlue-200 text-justiceBlue-700 hover:bg-justiceBlue-100"
							}`}
							onClick={handleBookmark}
						>
							{eventBookmarked
								? "Bookmarked!"
								: "Bookmark for Later"}
						</button>
						<button className="bg-gray-200 rounded-md px-6 py-2 text-md text-gray-500 font-bold focus:outline-none">
							Share
						</button>
					</div>
				</div> */}

				<div className="mt-4 mb-8">
					<h1 className="text-3xl font-bold">
						{eventData.eventTitle
							? eventData.eventTitle
							: "Loading Title"}
					</h1>
					<div className="flex justify-between items-center mt-2">
						<h2>
							Hosted by{" "}
							<Link
								to={`/organizations/${eventData.organizerId}/latest`}
								className="hover:underline"
							>
								{eventData.organizerName
									? eventData.organizerName
									: "Loading Organizer"}
							</Link>
						</h2>
						<div className="flex space-x-2 items-center">
							{currUser ?
                <button
								className={
									eventBookmarked
										? "btn-secondary mr-2 focus:outline-none"
										: "btn-primary mr-2 focus:outline-none"
								}
								onClick={handleBookmark}
							>
								{eventBookmarked
									? "Bookmarked"
									: "Bookmark for Later"}
							</button>
              :
              <button
								className="btn-primary mr-2 focus:outline-none"
								onClick={toggleAuthPopup}
							>
								Bookmark for Later
							</button>
              }
							{/* <button className="btn-white">Share</button> */}
							{/* <div class="relative inline-block text-left pl-2">
                <div>
                  <button onClick={toggleOptions} type="button" class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="options-menu" aria-expanded="true" aria-haspopup="true">
                    <span class="sr-only">Open options</span> */}
							{/* <!-- Heroicon name: solid/dots-vertical --> */}
							{/* <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                  </button>
                </div>
                <Transition
                show={optionsOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                  <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <div class="py-1" role="none">
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Report content</a>
                    </div>
                  </div>
                </Transition>
              </div> */}
						</div>
					</div>
				</div>

				<div className="grid grid-cols-3 gap-x-8 mb-12">
					{/* <div className="flex flex-col mb-8 w-2/3 mr-12 h-full">
						<EventInfo
							hostLocation={`${eventData.city}, ${eventData.state}`}
							isVirtual={eventData.isVirtual}
							eventTimes={eventTimes}
						/>

						<EventDescription />
						<div className="flex flex-row">
							<EventTags />
							<EventAttendance
								numGoing={eventData.numAttending}
								numInterested={eventData.numInterested}
							/>
						</div>
					</div> */}

					<div className="col-start-1 col-end-3 gap-x-8">
						<div className="block grid grid-cols-3 gap-x-8 mb-10">
							<div className="col-start-1 col-end-2">
								<div className="mb-6">
									<div className="items-start shadow-sm bg-gray-100 px-8 py-7 rounded-lg h-full">
										<div>
											<h1 className="text-2xl font-bold mb-4">
												Time
											</h1>
											<h2 className="font-medium text-lg">
												{formatDate(
													eventData.mainStartTimeUTC
												)}
											</h2>
											{eventTimesDisplay}
										</div>
									</div>
								</div>
								<div className="">
									<div className="items-start shadow-sm bg-gray-100 px-8 py-7 rounded-lg h-full">
										<div>
											<h1 className="text-2xl font-bold mb-4">
												Location
											</h1>
											{hostLocationInfo}
										</div>
									</div>
								</div>
							</div>
							<div className="col-start-2 col-end-4 w-full bg-gray-100 h-96 w-full rounded-lg">
								<img
									src={photoSrc}
									className="col-start-2 col-end-3 object-scale-down inline-block h-96 w-full rounded-lg"
								/>
							</div>
						</div>

						<div className="block">
							<h1 className="text-2xl font-bold mb-4">
								Description & Details
							</h1>
							<div>{eventData.description}</div>
						</div>
					</div>

					<div className="col-start-3 col-end-4">
						<EventRSVP
							eventId={eventId}
							eventTitle={eventData.eventTitle}
							organizerName={eventData.organizerName}
							organizerId={eventData.organizerId}
							numAttending={eventData.numAttending}
							numInterested={eventData.numInterested}
							mainStartTimeUTC={eventData.mainStartTimeUTC}
							mainStartTimeString={eventData.mainStartTimeString}
							timeZone={eventData.timeZone}
							isVirtual={eventData.isVirtual}
							city={eventData.city}
							state={eventData.state}
							eventTimes={eventTimes}
              toggleAuthPopup={toggleAuthPopup}
						/>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-x-8">
					<div className="col-start-1 col-end-2">
						<h1 className="text-2xl font-bold mb-4">Topic Tags</h1>
						<div className="flex items-center space-x-4">
							{tagsList}
						</div>
					</div>
					<div className="col-start-2 col-end-3">
						<h1 className="text-2xl font-bold mb-4">Attendance</h1>
						<div>
							{eventData.numAttending} attending (RSVPed) •{" "}
							{eventData.numInterested} interested (bookmarked)
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default EventPage;

// Componentize this:
// Info Componenent
// Time
// Location
// Image
// Description Component
// Event Description and Details
// Tags Component
// Tags
// Attendance Component
// Num attending and Num interested
// RSVP Component
// Sign up info
// CONDITIONAL Shift selector
// Terms and Conditions
// Once signed up
// Share
// View other Events
