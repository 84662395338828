import React, { useState, useEffect } from "react";
// import EventCard from "../../global/events/EventCard";
import EventCard from './HomeEventCard'
import { useAuth } from "../../../../contexts/AuthContext";
import { db } from "../../../../config/firebase";

const HomeEventsPastDetailed = (props) => {
	const { currUser } = useAuth();
	const [eventsReg, setEventsReg] = useState({ events: [] });
	let eventArray = [];
	const currentTime = new Date();
	// only runs once for data retrieval
	useEffect(async () => {
		const currUserRef = db.collection("civilians").doc(currUser.uid);
		await currUserRef
			.collection("registeredEvents")
			.get()
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					eventArray.push(doc);
				});
			})
			.catch((error) => {
				console.log("Error getting documents: ", error);
			});
		eventArray.sort((a, b) => a.data().start - b.data().start);
    let filteredArray = eventArray.filter((doc) => {
      let upcoming = false
      doc.data().registeredTimes.forEach((time) => {
        if (time.start.toDate() < currentTime) {
          upcoming = true
        }
      })
      return(upcoming)
    })
    console.log("array:", filteredArray.length)
		setEventsReg({ events: filteredArray });
	}, []);

	return (
		<div className="divide-y divide-gray-200 bg-white overflow-hidden sm:rounded-md shadow">
			{ eventsReg.events.length > 0 ?
        eventsReg.events.map((event, index) => {
				return (
					<div key={index}>
						{event.data().registeredTimes.map((eventTime) => {
							{/* if (eventTime.start.toDate() < currentTime) { */}
								return (
									<EventCard
										eventId={event.id}
                    title={event.data().eventTitle}
                    going={event.data().numAttending}
                    interested={event.data().numInterested}
                    date={event.data().mainStartTimeUTC}
                    time={
                      event.data().mainStartTimeString +
                      " " +
                      event.data().timeZone
                    }
                    organizer={event.data().organizerName}
                    isVirtual={event.data().isVirtual}
                    location={`${event.data().city}, ${
                      event.data().state
                    }`}
									/>
								);
							{/* } */}
						})}
					</div>
				);
			})
      :
      <h1 className="text-lg px-6 py-6">You currently have no past events.</h1>
      }
		</div>
	);
};
export default HomeEventsPastDetailed;
