import React from "react";
import { Link } from "react-router-dom";

const IssueCard = (props) => {
  const formatDate = (timestamp) => {
    if (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
      let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
      yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
      uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
      let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
      return f_dateString;
    } else {
      // if there isn't a date associated with the issue
      return "01/01/1979";
    }
  };

  return (
    <Link
      to={`/org/issues/${props.issueId}/notes`}
      className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out divide-y divide-gray-200"
    >
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="text-2xl leading-5 font-semibold text-gray-900 truncate max-w-4/5 py-2">
            {props.issueTitle}
          </div>
          <span className="px-5 inline-flex text-lg leading-9 font-medium rounded-full bg-gray-100 text-gray-800">
            {props.supporters != 1
              ? props.supporters + " supporters"
              : props.supporters + " supporter"}
          </span>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <div className="mr-6 flex items-center text-md leading-5 text-gray-500">
              {/* <!-- Heroicon name: users --> */}
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              {props.legislator} ({props.region})
            </div>
            <div className="mr-6 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
              {/* <!-- Heroicon name: location-marker --> */}
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              Created {props.created ? formatDate(props.created) : "XX/XX/XX"}
            </div>
            {props.copies === false ? (
              ""
            ) : (
              <div className="mt-2 flex items-center text-md leading-5 text-gray-500 sm:mt-0">
                {/* <!-- Heroicon name: location-marker --> */}
                <svg
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                {props.copies} regional copies
              </div>
            )}
          </div>
          <div className="mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0">
            <span>View issue</span>
            <svg
              className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default IssueCard;
