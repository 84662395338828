import React, { useEffect, useState } from "react";
import { db } from "../../../../config/firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import SmallestIssueCard from "../../../../components/civ/global/issues/SmallestIssueCard";
import SmallestEventCard from "../../../../components/civ/global/events/SmallestEventCard";
import ScrollMenu from "react-horizontal-scrolling-menu";

const DistrictRecommended = (props) => {
	const { userData } = useAuth();

	const userTopics = userData.interests;

	const [issueCards, setIssueCards] = useState([]);
	const [eventCards, setEventCards] = useState([]);

	useEffect(async () => {
		//TODO later: change "region" to "state"

		db.collection("issues")
			.where("region", "==", userData.state)
			.where("district", "==", userData.district)
			.where("topicTags", "array-contains-any", userTopics)
			.limit(6)
			.get()
			.then((results) => {
				setIssueCards(
					results.docs.map((issue) => (
						<SmallestIssueCard
							key={issue.id}
							issueId={issue.id}
							issueTitle={issue.data().issueTitle}
							supporterCount={issue.data().supporters}
						/>
					))
				);
			});

		db.collection("events")
			.where("state", "==", userData.state)
			.where("district", "==", userData.district)
			.where("topicTags", "array-contains-any", userTopics)
			.limit(6)
			.get()
			.then((results) => {
				setEventCards(
					results.docs.map((event) => (
						<SmallestEventCard
							key={event.id}
							eventId={event.id}
							eventTitle={event.data().eventTitle}
							organizer={event.data().organizer}
						/>
					))
				);
			});
	}, []);

	return (
		<div>
			<h1 className="text-2xl font-semibold">Recommended For You</h1>
      { (issueCards.length > 0 || eventCards.length > 0) ? <ScrollMenu
				data={[...issueCards, ...eventCards].sort(
					() => Math.random() - 0.5
				)}
				arrowLeft={
					<svg
						className="cursor-pointer flex-shrink-0 ml-1.5 mr-1.5 h-10 w-10 text-gray-500"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M10 19l-7-7m0 0l7-7m-7 7h18"
						/>
					</svg>
				}
				arrowRight={
					<svg
						className="cursor-pointer flex-shrink-0 ml-1.5 mr-1.5 h-10 w-10 text-gray-500"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M14 5l7 7m0 0l-7 7m7-7H3"
						/>
					</svg>
				}
				wrapperClass={"py-7"}
			/>
      :
      <div className="py-6 text-xl">There are currently no issues and events in your district relating to topics you are interested in.</div>
      }
		</div>
	);
};

export default DistrictRecommended;
