import React from "react";
import { Link } from "react-router-dom";

const CosponsorCard = (props) => {

  return(
    <Link
      to={`/org/legislators/${props.legislatorId}/votes`}
      className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-100 ease-in-out divide-y divide-gray-200">
      <div className="px-8 py-6 flex justify-between ">
        <div className="text-xl font-medium">
          {props.legislatorName} ({props.legislatorParty}-{props.legislatorState})
        </div>
        <div className="flex items-center space-x-2 text-lg font-medium text-red-500">
          <h1>View legislator profile</h1>
          <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </Link>
  )
}

export default CosponsorCard;
