import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import LegislatorsCommitteePage from "../../pages/org/legislator/LegislatorsCommitteePage";
import LegislatorVotesPage from "../../pages/org/legislator/LegislatorVotesPage";
import LegislatorBillsPage from "../../pages/org/legislator/LegislatorBillsPage";
import LegislatorIssuesPage from "../../pages/org/legislator/LegislatorIssuesPage";

const LegislatorsRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/org/legislators/committees/:chamber/:committee"
        component={LegislatorsCommitteePage}
      />
      <PrivateRoute
        path="/org/legislators/:legislator/votes"
        component={LegislatorVotesPage}
      />
      <PrivateRoute
        path="/org/legislators/:legislator/bills"
        component={LegislatorBillsPage}
      />
      <PrivateRoute
        path="/org/legislators/:legislator/issues"
        component={LegislatorIssuesPage}
      />
    </Switch>
  );
};

export default LegislatorsRoutes;
