import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";

const IssueHeader = (props) => {
  let history = useHistory();

  const { currUserRef, currUser } = useAuth();
  const [alreadyFollowing, setAlreadyFollowing] = useState(false);
  const [followed, setFollowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);
  const [issueInfo, setIssueInfo] = useState({});

  const [ issue, setIssue] = useState({
    following:"issue",
    issueId: props.issueId,
    legislator: props.legislator,
    dateCreated: props.dateCreated,
    issueTitle: props.title,
    region: props.region,
    supporters: props.supporters,
    regionalCopies: props.regionalCopies,
  });

  useEffect(async () => { // TODO store the previous values so that the new value can be added to that list
    // const issueDocRef = db.collection("civilians").doc(currUserRef.uid).collection("follows");

    // // const issuesFollowingRef = db.collection("civilians").doc(currUser.uid).collection("follows");
    // const snapshot = await issueDocRef.where("issueId", '==', props.issueId).get();
    // if (!snapshot.empty) {
    //   setAlreadyFollowing(true);
    //   return;
    // }
    // const issueDocRef = db.collection("civilians").doc(currUserRef.uid).collection("follows");

    // // console.log(props.issueId);
    // const snapshot = await issueDocRef.where("issueId", '==', props.issueId).get();
    // console.log(snapshot);
    // if (!snapshot.empty) {
    //   setFollowed(true);
    //   console.log("there are other values like this");
    //   // console.log("There are no other values like this");
    //   // db.collection("civilians").doc(currUser.uid).collection("follows").add({...issue});
    //   return;
    // }
    // snapshot.forEach(doc => {
    //   issues.push(doc.data());
    // });

    setLoading(false);
  }, []);

  async function followIssue() {
    // console.log(issues);
    // console.log(issues.length == 0);
    // if (!loading && !followed && issues.length == 0){
    //   console.log("Following now");
    //   // const issueDocRef = db.collection("civilians").doc(currUserRef.uid).collection("follows");

    //   // const snapshot = await issueDocRef.where("issueId", '==', props.issueId).get();
    //   // if (snapshot.empty) {
    //     // console.log("There are no other values like this");
      db.collection("civilians").doc(currUser.uid).collection("follows").doc(props.issueId).set({...issue});
    //   // }
    //   setFollowed(true);
    // }
  }

  function AbleToDelete(props) {
    if (props.isCreator) {
        return <button
        className="bg-gray-200 rounded-full px-6 py-2 text-md"
            onClick={() => deleteNote(props)}>
                Delete Note </button>
    }
    return null;
  }

  async function deleteNote(props) {
    // await db.collection("issues")
    //         .doc(props.issueId)
    //         .update({
    //             supporters: firebase.firestore.FieldValue.increment(-1),
    //         });
    // await db.collection("issues").doc(props.issueId).collection("notes").doc(props.noteId).delete();
    // await db.collection("notes").doc(props.noteId).delete();
    // await db.collection("civilians").doc(props.currUser.uid).collection("notes").doc(props.noteId).delete();
    // history.push(`/issues/${props.issueId}/notes`);
  }

  return (
    <>
      {/* <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        <h1>Back</h1>
      </button> */}

      <div className="flex justify-between items-end mt-2 mb-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 mb-5">
            {props.title ? props.title : "Issue Title"}
          </h1>
          <div className="text-2xl font-regular flex space-x-3 items-center">
            <h1>
              to <Link to={`/org/legislators/${props.legislatorId}/bills`} className="hover:underline">{props.legislator ? props.legislator : "Legislator"}</Link>
            </h1>
            <span> • </span>
            <h1>
              {props.region ? props.region : "Region"}
            </h1>
          </div>
        </div>

        <div className="flex space-x-4">
          <Link to={`/org/issues/${props.issueId}/reshare`} className="btn-primary">
            Reshare Issue
          </Link>
          <button className="btn-white" onClick={props.toggleSharePopup}>
            <div className="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                </svg>
              <div>Share</div>
            </div>
          </button>
        </div>
      </div>

      <hr className="border-solid mb-5" />

      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-8 bg-blue-50 px-8 py-5 rounded-lg">
          <NavLink
            to={`/org/issues/${props.issueId}/notes`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Notes
          </NavLink>
          <NavLink
            to={`/org/issues/${props.issueId}/details`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Details
          </NavLink>
          <NavLink
            to={`/org/issues/${props.issueId}/copies`}
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            Regional Copies
          </NavLink>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default IssueHeader;
