import React, {useState, useContext, useEffect} from 'react';
import AppLayout from '../../../../layouts/AppLayout'
import {Link, NavLink, useHistory} from "react-router-dom";
// import AddBtn from '../../../../components/civ/global/buttons/AddButton';
import IssueCard from '../../../../components/civ/global/issues/IssueCard'
import {DistrictDBContext} from '../../../../contexts/DistrictContext'
import OrganizationCard from '../../../../components/civ/global/organizations/OrganizationCard'
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png"
import firebase from "firebase";

const DistrictOrganizationListPage = (props) => {
    let history = useHistory();

    const category = props.location.state;

    const organizations = useContext(DistrictDBContext);
    let [organizationsDisplay, showOrganizations] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    // update component results when data fetched & passed
    useEffect( () => {
      if(organizations[10] != null) {
        Promise.all(organizations[10][category].map(async (org, i) => {

          let orgSrc = defaultProfilePic

          let storageRef = firebase.storage().ref();
          let userPicRef = storageRef.child(`images/organization/profilePics/${org.id}`)
          try {
            let result = await userPicRef.getDownloadURL()
            orgSrc = result
          } catch(error) {
            console.log(error)
          }

          return (<OrganizationCard
            key = {i}
            organizationId={org.id}
            name={org.data().name}
            activeIssues={org.data().activeIssueCount}
            followers={org.data().followerCount}
            upcomingEvents={org.data().upcomingEventCount}
            profilePicUrl={orgSrc}
          />)
          })).then((result) => {
            showOrganizations(result)
          })
        // showOrganizations(<ul>{organizations[10][category].map((org, i) => (
        //     <OrganizationCard key = {i}
        //     name={org.data().name}
        //     followers={org.data().followerCount}
        //     issues={org.data().activeIssueCount}
        //     events={org.data().upcomingEventCount}
        //   />))}</ul>);
      }
    }, [organizations]);


    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
          <button
            className="flex space-x-2 items-center focus:outline-none"
            onClick={() => {
              history.goBack();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 h-5 w-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <h1>Back</h1>
          </button>

          <h1 className="mt-8 whitespace-no-wrap text-3xl font-bold leading-tight text-black">
            District Organizations: {category}
          </h1>

          <br />

          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="divide-y divide-gray-200">
              {organizationsDisplay}
            </div>
          </div>
        </div>
      </AppLayout>
    );

}


export default DistrictOrganizationListPage;
