import React, {useState, useEffect} from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
import OrganizationCard from "./CivilianOrganizationCard"
import firebase from "firebase";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png"

const CivilianOrganizations = (props) => {

  const [orgsList, setOrgsList] = useState()

  useEffect(async () => {

    if (props.organizations && props.organizations.length !== 0) {

      Promise.all(props.organizations.map(async (doc) => {

        // console.log("DOC:", doc)

        let orgSrc = defaultProfilePic

        let storageRef = firebase.storage().ref();
        let userPicRef = storageRef.child(`images/organization/profilePics/${doc.id}`)
        try {
          let result = await userPicRef.getDownloadURL()
          orgSrc = result
        } catch(error) {
          console.log(error)
        }

        return(<OrganizationCard
          organizationId={doc.id}
          name={doc.data().orgName}
          profilePicUrl={orgSrc}
        />)

      }))
      .then((result) => {
        setOrgsList(result)
      })
    }
    else {
      setOrgsList(<div className="text-lg my-6 mx-6">This user is not following any organizations.</div>)
    }

  }, [])

  // if (props.organizations.length == 0) {
  //   return (
  //     <>
  //       <div className="bg-white overflow-hidden text-center md:my-28">
  //         <div className="text-xl">This user is not following any organizations.</div>
  //       </div>
  //     </>
  //   );
  // } else {
    return (
      <>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {orgsList}
          </div>
        </div>
      </>
    );
  // }
};

export default CivilianOrganizations;
