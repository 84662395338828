import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "../org/OrgPrivateRoute";

import BrowseNationalIssuesPage from "../../pages/org/browse/BrowseNationalIssuesPage";
import BrowseNationalEventsPage from "../../pages/org/browse/BrowseNationalEventsPage";
import BrowseNationalOrganizationsPage from "../../pages/org/browse/BrowseNationalOrganizationsPage";
import { NationProvider } from "../../contexts/NationContext";

const HomeRoutes = () => {
	return (
		<NationProvider>
			<Switch>
				<PrivateRoute
					path="/org/browse/nation/organizations"
					component={BrowseNationalOrganizationsPage}
				/>
				<PrivateRoute
					path="/org/browse/nation/events"
					component={BrowseNationalEventsPage}
				/>
				<PrivateRoute
					path="/org/browse/nation/issues"
					component={BrowseNationalIssuesPage}
				/>
			</Switch>
		</NationProvider>
	);
};

export default HomeRoutes;
