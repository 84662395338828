import React from "react";
import { Link } from "react-router-dom";

const LegislatorBillVoteComment = (props) => {

  const formatDate = (timestamp) => {

    if (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
      let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
      yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
      uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
      let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
      return f_dateString;
    } else {
      // if there isn't a date associated with the issue
      return "01/01/1979";
    }

  };

  return(
    <div className={(props.color=="white") ? "bg-white rounded-lg px-10 py-7 mb-4 shadow" : "bg-gray-100 rounded-lg px-10 py-7 mb-4 shadow"}>
      <div className="flex justify-between">
        <Link className="text-xl font-semibold hover:underline" to={`/org/legislators/${props.legislatorId}/votes`}>
          {props.legislatorName} ({props.legislatorState}-{props.legislatorDistrict}, {props.legislatorParty})
        </Link>
        <h1>Posted {formatDate(props.datePosted)}</h1>
      </div>
      <p className="mt-4">{props.body}</p>
    </div>
  )
}

export default LegislatorBillVoteComment;
