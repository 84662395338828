import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Organizations
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>Organization profiles 101</h2>
            <p>Organizations are an important part of the GrassrootsGov platform. Following and staying up to date with organizations that champion the issues that you're passionate about is one of the first steps in getting involved with politics in your community. This set of organization tutorials will teach you all about the ways you can interact with organizations in GrassrootsGov. </p>
            <p>A great place to start is our tutorial named "find and follow organizations." Once you have found a few organizations that you are interested in and following, proceed with this tutorial to learn all about organization profiles.</p>
            <p>To view a specific organization's profile, simply press "view" on the button next to the organization's name. You can do this from any page you see the organization. You will now be brought to the organization's profile page.</p>
            <p>When viewing any organization's profile there are four pages you can select: "Latest," "Issues," "Events," and "About." </p>
            <p>Select the "Latest" tab for an constantly updating feed of the most recent things that the organization is posting or promoting. </p>
            <p>Select the "Issues" tab for the most recent issues that the organization is posting about. Scroll down to see older issues. Select "view issue" on any issue that you want to know more about or interact with.</p>
            <p>Select the "Events" tab to see specific events that the organization is promoting or hosting. Scroll down to see older events. Select "View Event" on any event that you want to learn more about.</p>
            <p>Select the "About" tab to learn more about the organization.</p>
            <p>If you'd like to share the organization to friends, family, or other social media, just hit the share button from anywhere on the organization profile.</p>

            <h2>How to view your feed of posts from organizations you're following</h2>
            <p>To view a feed of all posts your followed organizations are posting, navigate to "Home" and scroll down to find"My Feed" - here you'll see everything your organizations are posting!</p>

            {/* <h2>How to view all the organizations that have reshared a particular issue</h2>
            <p>Select "View Issue" on the issue of your choice. From its own issue page, select "Take Action." From here, scroll down until you see the "Posts Mentioned In" header. Here you will be shown a list of posts from Organizations that have shared this issue.</p> */}

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
