import React, { useContext, useEffect, useState } from 'react';
import {NavLink} from "react-router-dom";
import { NationDBContext } from '../../../../contexts/NationContext';

const NationIssuesNav = (props) => {

  const issues = useContext(NationDBContext);
  const [topics, setTopics] = useState(<option>None</option>);

  useEffect(() => {

    let temp = [];
    if(issues[3] != null) {
      for(var topic in issues[3]){
        temp.push(<option>{topic}</option>)
      }
    }

    setTopics(temp);

  }, [issues]);


  return (
    <div className="flex justify-between items-center mb-8">
      <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
        <NavLink to="/nation/issues/top" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" exact activeClassName="text-gray-900">
          Top Issues
        </NavLink>
        <NavLink to="/nation/issues/latest" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Latest Issues
        </NavLink>
        <NavLink to="/nation/issues/topics" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Issues by Topic
        </NavLink>
        <NavLink to="/nation/issues/resolved" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
          Resolved
        </NavLink>
      </div>
      <div className="ml-4 mt-2 flex space-x-4 items-end">
        <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">Chamber</label>
          <select id="location" onChange={(e) => {props.setChamberSort(e.target.value)}} name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md">
            <option selected>House & Senate</option>
            <option>House Only</option>
            <option>Senate Only</option>
          </select>
        </div>
        {/* <div>
          <label for="location" class="block text-sm font-medium text-justiceBlue-500">Filter</label>
          <select id="location" onChange={(e) => {props.setTopicSort(e.target.value)}} name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md">
            {topics}
          </select>
        </div> */}
        {/* <span className="rounded-md shadow-sm">
          <button type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:shadow-outline-red-500 focus:border-indigo-red-600 active:bg-red-600">
            + Add New Issue
          </button>
        </span> */}
      </div>
    </div>
  )
}

export default NationIssuesNav;
