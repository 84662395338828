import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppLayout from "../../../layouts/AppLayout";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
// import { billsIndex, eventsIndex, votesIndex } from "../../../config/algolia";
import { index } from "../../../config/algolia";
// import { InstantSearch } from "react-instantsearch-dom";
// import { DropdownAutocomplete } from "../../../components/civ/global/search/DropdownAutocomplete";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import legislators from "../../../assets/jsonData/legislators.json"

const topicTags = [
  { value: "Climate", label: "Climate" },
  { value: "Criminal Justice", label: "Criminal Justice" },
  { value: "Economy", label: "Economy" },
  { value: "Education", label: "Education" },
  { value: "Foreign Policy", label: "Foreign Policy" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Immigration", label: "Immigration" },
  { value: "Infrastructure", label: "Infrastructure" },
  { value: "LGBTQ+", label: "LGBTQ+" },
  { value: "Reproductive Rights", label: "Reproductive Rights" },
  { value: "Voting/Elections", label: "Voting/Elections" },
];

const CreateIssue = ({ match }) => {
  const history = useHistory();
  const [inputListBill, setInputListBill] = useState([]);
  const [inputListVote, setInputListVote] = useState([]);
  const [inputListEvent, setInputListEvent] = useState([]);
  // const [inputListBill, setInputListBill] = useState([{ bill: "" }]);
  // const [inputListVote, setInputListVote] = useState([{ vote: "" }]);
  // const [inputListEvent, setInputListEvent] = useState([{ event: "" }]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [issue, setIssue] = useState();

  const { currUser, userData } = useAuth();
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // const [postedIssueId, setPostedIsueID] = useState("");
  const [contactType, setContactType] = useState("email");
  const [emailSubjectText, setEmailSubjectText] = useState("");
  const [subjectCopied, setSubjectCopied] = useState(false);
  const [emailContentText, setEmailContentText] = useState("");
  const [contentCopied, setContentCopied] = useState(false);
  const [callScriptText, setCallScriptText] = useState("");

  // needs to read from legislators in firebase
  const [legOptions, setLegOptions] = useState();
  const [chosenLegislators, setChosenLegislators] = useState();

  /**
   * For determining whether creating regional copy or original issue
   */
  useEffect(async () => {
    if (match && match.params.issue) {
      const issueDocRef = db.collection("issues").doc(match.params.issue);
      const issueDoc = await issueDocRef.get();
      const issueData = issueDoc.data();
      if (issueData.topicTags) {
        let temp = [];
        issueData.topicTags.map((topic) => {
          temp.push({ value: topic, label: topic });
        });
        setSelectedTags(temp);
      }
      if (issueData.relatedBills) {
        issueData.relatedBills.map((bill) => {
          setInputListBill((old) => [
            ...old,
            { label: bill.description, value: bill },
          ]);
        });
      }
      if (issueData.relatedEvents) {
        issueData.relatedEvents.map((event) => {
          setInputListEvent((old) => [
            ...old,
            { label: event.eventTitle, value: event },
          ]);
        });
      }
      if (issueData.relatedVotes) {
        issueData.relatedVotes.map((vote) => {
          setInputListVote((old) => [
            ...old,
            { label: vote.altTitle, value: vote },
          ]);
        });
      }
      setIssue({
        dateCreated: firebase.firestore.Timestamp.now(),
        legislator: {},
        issueTitle: issueData.issueTitle,
        issueDescription: issueData.issueDescription,
        topicTags: issueData.topicTags,
        links: issueData.links,
        originalIssue: issueData.originalIssue
          ? issueData.originalIssue
          : issueDoc.id,
        // relatedBills: issueData.relatedBills,
        // relatedVotes: issueData.relatedVotes,
        // relatedEvents: issueData.relatedEvents,
        active: true,
        copy: true,
        supporters: 0,
        districts: 1,
        regionalCopies: 0,
      });
    } else {
      setIssue({
        dateCreated: firebase.firestore.Timestamp.now(),
        legislator: {},
        issueTitle: "",
        issueDescription: "",
        topicTags: [],
        links: [],
        // relatedBills: { inputListBill },
        // relatedVotes: { inputListVote },
        // relatedEvents: { inputListEvent },
        active: true,
        supporters: 0,
        regionalCopies: 0,
      });
    }
  }, [match]);

  /**
   * Used for getting legislator options for the user
   */
  useEffect(async () => {
    // legOptions.push({

    let tempOptions = []

    tempOptions.push({
      value: [userData.districtRep],
      label: `${userData.districtRep.firstName} ${
        userData.districtRep.lastName
      } (${userData.districtRep.party}), District`,
    });

    const senators = []

    if (userData.senateRep) {
      userData.senateRep.map((senator) => {
        // legOptions.push({
        tempOptions.push({
          value: [senator],
          label: `${senator.firstName} ${senator.lastName} (${senator.party}), State`,
        });
        senators.push({
          value: senator,
          label: `${senator.firstName} ${senator.lastName} (${senator.party}), State`,
        })
      });

      // legOptions.push({
      tempOptions.push(
        { value: [senators[0].value, senators[1].value],
          label: `${senators[0].label} & ${senators[1].label}`
        })
      setLegOptions(tempOptions)
    }

  }, []);

  async function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    setLoading(true);
    // TODO: validate the data

    for (let i=0; i<chosenLegislators.length; i++) {
      let legislator = chosenLegislators[i]
      // console.log("legislator submitting for:", legislator)
      if (issue.issueDescription.length < 10) {
        setStatus({ error: "The description is too short" });
        setLoading(false);
        return;
      }
      const currDistrict = `${legislator.region}-${
        legislator.district
          ? legislator.district
          : legislator.region
      }`;
      // console.log("curr district is " + currDistrict);

      if (issue.copy) {
        const origDoc = await db
          .collection("issues")
          .doc(issue.originalIssue)
          .get();
        const revisedDistricts = origDoc.data().districts;
        revisedDistricts[currDistrict] = true;
        await db
          .collection("issues")
          .doc(issue.originalIssue)
          .set(
            {
              regionalCopies: origDoc.data().regionalCopies + 1,
              districts: revisedDistricts,
            },
            { merge: true }
          );
      }

      const relatedBills = [];
      inputListBill.map((bill) => {
        relatedBills.push(bill.value);
      });
      const relatedEvents = [];
      inputListEvent.map((event) => {
        relatedEvents.push(event.value);
      });
      const relatedVotes = [];
      inputListVote.map((vote) => {
        relatedVotes.push(vote.value);
      });

      const origIssueDistrict = {};
      origIssueDistrict[currDistrict] = true;
      const res = await db.collection("issues").add({
        ...issue,
        region: legislator.region,
        state: legislator.region,
        district: legislator.district
          ? legislator.district
          : "Senate",
        districts: issue.copy ? {} : origIssueDistrict,
        createdBy: currUser.uid,
        createdByName: userData.firstName + " " + userData.lastName[0] + ".",
        legislator: legislator,
        legislatorId: legislator.id,
        relatedBills: relatedBills,
        relatedEvents: relatedEvents,
        relatedVotes: relatedVotes,
      });

      await db.collection("legislators").doc(legislator.id).update({issueCount: firebase.firestore.FieldValue.increment(1)})
      await db.collection("civilians").doc(currUser.uid).update({issueCount: firebase.firestore.FieldValue.increment(1)})

    }
    /**
     * This is for duplicating data
     */
    // await currUserRef
    //   .collection("issues")
    //   .doc(res.id)
    //   .set({
    //     ...issue,
    //     region: issue.legislator.region,
    //     district: issue.legislator.district
    //       ? issue.legislator.district
    //       : "Senate",

    //     districts: issue.copy ? {} : origIssueDistrict,
    //   });
    setEmailSubjectText(issue.issueTitle)
    setEmailContentText(`
      My name is ${userData.firstName} and I am one of your constituents.
      I am writing to you because I would like you to ${issue.issueTitle}.
      ${issue.issueDescription}
      I created an online petition regarding this issue; please visit grassrootsgov.org to view it along with other constituents' notes of support.
      Thank you!
      `
    )
    setCallScriptText(`
      Hello, my name is ${userData.firstName} and I am one of your constituents.
      I am calling you today to ask that you ${issue.issueTitle}.
      ${issue.issueDescription}
      I created an online petition regarding this issue; please visit grassrootsgov.org to view it along with other constituents' notes of support.
      Thank you!
    `)

    setStatus({ success: "You have successfully submitted the issue!" });
    // history.goBack();
    setLoading(false);
    setSubmitted(true);
  }

  function handleChange(e) {
    setIssue({ ...issue, [e.target.name]: e.target.value });
  }

  function handleChangeLeg(selectedOption) {
    if (selectedOption) {
      setChosenLegislators(selectedOption.value);
    }
  }

  function handleChangeTopics(selectedOption) {
    if (selectedOption) {
      selectedOption = selectedOption.slice(-3);
      let selectedArr = [];
      selectedOption.map((option) => {
        selectedArr.push(option.label);
      });
      console.log(selectedOption);
      setIssue({ ...issue, topicTags: selectedArr });
      setSelectedTags(selectedOption);
    }
  }

  // functions to help with related bills, votes, events

  function loadBills(inputVal, callback) {
    if (!inputVal) {
      return callback([]);
    }

    /**
     * Waits 1 second and searches algolia
     */
    setTimeout(() => {
      index.search(inputVal, {
        filters: 'objectType:bill'
      }).then(({ hits }) => {
        const options = [];
        hits.map((hit) => {
          if (hit["_highlightResult"]) delete hit["_highlightResult"];
          if (hit["objectType"]) delete hit["objectType"];
          options.push({ label: hit.number + ": " + hit.title, value: hit });
        });
        // return options;
        callback(options);
      });
    }, 1000);
  }
  const handleBillChange = (newValue) => {
    if (!newValue) {
      setInputListBill([]);
    } else setInputListBill(newValue.slice(-3));
  };

  function loadEvents(inputVal, callback) {
    if (!inputVal) {
      return callback([]);
    }

    /**
     * Waits 1 second and searches algolia
     */
    setTimeout(() => {
      index.search(inputVal, {
        filters: 'objectType:event'
      }).then(({ hits }) => {
        const options = [];
        hits.map((hit) => {
          if (hit["_highlightResult"]) delete hit["_highlightResult"];
          if (hit["objectType"]) delete hit["objectType"];
          options.push({ label: hit.eventTitle, value: hit });
        });
        // return options;
        callback(options);
      });
    }, 1000);
  }
  const handleEventChange = (newValue) => {
    if (!newValue) {
      setInputListEvent([]);
    } else setInputListEvent(newValue.slice(-3));
  };

  function loadVotes(inputVal, callback) {
    if (!inputVal) {
      return callback([]);
    }

    /**
     * Waits 1 second and searches algolia
     */
    setTimeout(() => {
      index.search(inputVal, {
        filters: 'objectType:vote'
      }).then(({ hits }) => {
        const options = [];
        hits.map((hit) => {
          if (hit["_highlightResult"]) delete hit["_highlightResult"];
          if (hit["objectType"]) delete hit["objectType"];
          options.push({ label: hit.chamber + " " + hit.number + ": " + hit.altTitle, value: hit });
        });
        // return options;
        callback(options);
      });
    }, 1000);
  }
  const handleVoteChange = (newValue) => {
    if (!newValue) {
      setInputListVote([]);
    } else setInputListVote(newValue.slice(-3));
  };


  if (!issue) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <AppLayout>

      {submitted &&
        <div className="min-h-screen fixed h-full w-full top-0 left-0 bg-gray-700 bg-opacity-30 z-50">
          {/* <div className="absolute top-1/2 left-1/2 w-3/4 h-3/4 -mt-36 -ml-96"> */}
          <div className="absolute w-full h-full flex flex-col justify-center items-center text-center">
          {/* <div className="flex flex-col justify-center"> */}
            <div className="bg-gray-100 w-3/4 h-5/6 px-40 py-20 rounded-lg shadow">

              <h1 className="block flex justify-center text-4xl font-semibold mb-8">
                Share this issue with your legislators!
              </h1>

              <div className="block h-2/3 text-left text-lg mb-28">

                <div className="flex justify-between items-center mb-8">
                  <h1 className="text-xl font-medium">
                    Your issue has been posted. Tell your legislator(s) about it:
                  </h1>
                  <span className="relative z-0 inline-flex bg-white shadow-sm rounded-md border border-gray-200">
                    <span className="flex space-x-1 rounded-md border-2 border-white">
                      <button
                        type="button"
                        className={`relative inline-flex items-center px-4 py-2 rounded-md ${(contactType === "email") ? "bg-justiceBlue-500 text-white hover:bg-justiceBlue-500" : "bg-white text-gray-700 hover:bg-gray-50"} text-sm font-medium focus:z-10 focus:outline-none`}
                        onClick={() => (setContactType("email"))}
                      >
                        Email
                      </button>
                      <button
                        type="button"
                        className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-md ${(contactType === "call") ? "bg-justiceBlue-500 text-white hover:bg-justiceBlue-500" : "bg-white text-gray-700 hover:bg-gray-50"} text-sm font-medium focus:z-10 focus:outline-none`}
                        onClick={() => (setContactType("call"))}
                      >
                        Call
                      </button>
                    </span>
                  </span>
                </div>

                {(contactType === "email") &&
                  <div className="h-full bg-white rounded-lg px-6 py-6 mb-8 overflow-y-scroll">
                    <div className="text-xl text-justiceBlue-600 mb-4 font-medium">Contact form(s):</div>
                    {chosenLegislators && chosenLegislators.map((legislator) => {
                      let url = legislators[legislator.id]["contactForm"] ? legislators[legislator.id]["contactForm"] : legislators[legislator.id]["officialUrl"]
                      return(
                        <a href={url} className="block hover:underline">{url}</a>
                      )
                    })}

                    <div className="text-xl text-justiceBlue-600 mt-8 font-medium mb-4">Submission content: </div>

                    <div className="mb-2">
                      <span className="font-medium">Subject:</span> {emailSubjectText}
                    </div>
                    <div className="mb-6">
                      <CopyToClipboard
                        text={emailSubjectText}
                        onCopy={() => setSubjectCopied(true)}>
                        <button className="btn-white text-sm focus:outline-none">Copy to clipboard</button>
                      </CopyToClipboard>
                      {subjectCopied && <h1 className="text-xs text-gray-500 mt-2">Copied to clipboard.</h1>}
                    </div>

                    <div className="mb-2">
                      <span className="font-medium">Body:</span>
                    </div>
                    <div className="mb-2">
                      {emailContentText}
                    </div>
                    <div className="mb-2">
                      <CopyToClipboard
                        text={emailContentText}
                        onCopy={() => setContentCopied(true)}>
                        <button className="btn-white text-sm focus:outline-none">Copy to clipboard</button>
                      </CopyToClipboard>
                      {contentCopied && <h1 className="text-xs text-gray-500 mt-2">Copied to clipboard.</h1>}
                    </div>
                  </div>
                }

                {(contactType === "call") &&
                  <div className="h-full bg-white rounded-lg px-6 py-6 mb-8 overflow-y-scroll">
                    <div className="text-xl text-justiceBlue-600 mb-4 font-medium">Phone number(s):</div>
                    {chosenLegislators && chosenLegislators.map((legislator) => {
                      let phone = legislators[legislator.id]["officialPhone"]
                      return(
                        <h1>{phone}</h1>
                      )
                    })}

                    <div className="text-xl text-justiceBlue-600 mt-8 font-medium mb-4">Call script: </div>
                    <div>
                      {callScriptText}
                    </div>
                  </div>
                }

              </div>

              <div className="block flex justify-center space-x-4">
                <button
                  disabled={loading}
                  onClick={() => {history.goBack();}}
                  className="btn-primary"
                >
                  Continue
                </button>
                {/* <button className="btn-white" onClick={() => {setSubmitted(false)}}>Cancel</button> */}
              </div>

            </div>

          </div>
        </div>
      }

      <div className="mb-px py-8 mx-4 sm:px-8 lg:px-16">
        <h1 className="whitespace-no-wrap text-4xl font-bold leading-tight text-gray-900 mb-5">
          {match.params.issue
            ? "Create Regional Copy of Issue"
            : "Create New Issue"}
        </h1>

        <form
          action="#"
          method="POST"
          class="space-y-8"
          onSubmit={handleSubmit}
        >
          <div>
            <div className="flex items-center mb-5">
              <p className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900">
                Legislator
              </p>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  Select the legislator(s) that you want to direct your issue towards.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <Select
              className="border-0"
              isSearchable={false}
              isClearable={true}
              onChange={handleChangeLeg}
              options={legOptions}
              // defaultValue={issue.legislator}
            />
          </div>

          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="issueTitle"
                className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Issue Title
              </label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  ex: "Eliminate the Filibuster", "Support Fossil Fuel Restrictions", "Vote Yes on House Bill 123"
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <input
              id="issueTitle"
              name="issueTitle"
              placeholder="Enter issue title here..."
              type="text"
              disabled={match.params.issue ? true : false}
              value={issue.issueTitle}
              maxLength="100"
              onChange={handleChange}
              required
              // className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              className={`shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md ${
                match.params.issue ? "bg-gray-100" : ""
              }`}
            />
            <h1 className="text-gray-500 float-right mt-2">
              {issue.issueTitle.length}/100 characters
            </h1>
          </div>

          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="issueDescription"
                className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Issue Description
              </label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  Enter a short description of why this issue is important or why your legislator should take action.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <textarea
              id="issueDescription"
              name="issueDescription"
              placeholder="Enter issue description here..."
              type="issueDescription"
              value={issue.issueDescription}
              maxLength="2000"
              onChange={handleChange}
              required
              rows={6}
              className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <h1 className="text-gray-500 float-right mt-2">
              {issue.issueDescription.length}/2000 characters
            </h1>
          </div>

          <div className="mt-4">
            <label
              for="topicTags"
              className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900 mb-5"
            >
              Topic Tags
            </label>
            <label className="ml-2">(optional)</label>
            <div className="w-full flex space-x-4 items-center mt-3">
              {/* <ReactTagInput
                tags={issue.topicTags}
                removeOnBackspace={true}
                editable={true}
                maxTags={3}
                onChange={(newTags) =>
                  setIssue({ ...issue, topicTags: newTags })
                }
              /> */}
              <Select
                isMulti
                value={selectedTags}
                className="flex-auto"
                isSearchable={false}
                isClearable={true}
                onChange={handleChangeTopics}
                options={topicTags}
              />
              <div className="text-gray-500 text-sm">
                <h1>({issue.topicTags.length}/3max)</h1>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="links"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Links
              </label>
              <label className="ml-2">
                (optional — press enter to add each link!)
              </label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  ex: news articles, academic sources, organization websites, etc.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="w-full flex space-x-4 items-center mt-3">
              <ReactTagInput
                tags={issue.links}
                removeOnBackspace={true}
                editable={true}
                maxTags={3}
                onChange={(newTags) => setIssue({ ...issue, links: newTags })}
              />
              <div className="text-gray-500 text-sm">
                <h1>({issue.links.length}/3max)</h1>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="bills"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Bills
              </label>
              <label className="ml-2">(optional)</label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  Link any congressional legislation related to this issue.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="w-full flex space-x-4 items-center mt-3">
              {/* <InstantSearch
                indexName="grassrootsgov_bills"
                searchClient={searchClient}
              >
                <DropdownAutocomplete />
              </InstantSearch> */}
              <AsyncSelect
                isMulti
                cacheOptions
                // value={selectedTags}
                className="flex-auto"
                loadOptions={loadBills}
                onChange={handleBillChange}
                value={inputListBill}
                placeholder="Search for bill title..."
              />
              <div className="text-gray-500 text-sm">
                <h1>({inputListBill.length}/3max)</h1>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="bills"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Votes
              </label>
              <label className="ml-2">(optional)</label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  Link any congressional votes related to this issue.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="w-full flex space-x-4 items-center mt-3">
              {/* <InstantSearch
                indexName="grassrootsgov_bills"
                searchClient={searchClient}
              >
                <DropdownAutocomplete />
              </InstantSearch> */}
              <AsyncSelect
                isMulti
                cacheOptions
                // value={selectedTags}
                className="flex-auto"
                loadOptions={loadVotes}
                onChange={handleVoteChange}
                value={inputListVote}
                placeholder="Search for vote title..."
              />
              <div className="text-gray-500 text-sm">
                <h1>({inputListVote.length}/3max)</h1>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center mb-5">
              <label
                for="bills"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Events
              </label>
              <label className="ml-2">(optional)</label>
              <div className="has-tooltip flex items-center">
                <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                  Link any organization events (rallies, phonebanks, etc.) related to this issue.
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
            <div className="w-full flex space-x-4 items-center mt-3">
              {/* <InstantSearch
                indexName="grassrootsgov_bills"
                searchClient={searchClient}
              >
                <DropdownAutocomplete />
              </InstantSearch> */}
              <AsyncSelect
                isMulti
                cacheOptions
                // value={selectedTags}
                className="flex-auto"
                loadOptions={loadEvents}
                onChange={handleEventChange}
                value={inputListEvent}
                placeholder="Search for event title..."
              />
              <div className="text-gray-500 text-sm">
                <h1>({inputListEvent.length}/3max)</h1>
              </div>
            </div>
          </div>

          {/* <div className="mt-4">
            <div className="block flex items-end mb-5">
              <label
                for="originalIssue"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Original Issue
              </label>
              <h1 className="ml-2">(optional)</h1>
            </div>
            <input
              id="originalIssue"
              name="originalIssue"
              placeholder="Search for title of original issue here..."
              type="text"
              value={issue.originalIssue}
              onChange={handleChange}
              className="block w-full shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div> */}

          {/* <div className="mt-4">
            <div className="block flex items-end mb-2">
              <label
                for="relatedBills"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Bills
              </label>
              <h1 className="ml-2">(optional)</h1>
            </div>
            <div className="block flex items-end mb-5">
              <div className="App">
                {inputListBill.map((x, i) => {
                  return (
                    <div
                      style={{ width: 1100 }}
                      className="block flex space-x-4 items-center mt-3"
                    >
                      <input
                        list="bills"
                        name="bill"
                        id="bill"
                        placeholder="Search for title of related bill here..."
                        value={x.bill}
                        onChange={(e) => handleInputChangeBill(e, i)}
                        className="flex-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />

                      <button type="button" className="btn-box">
                        {inputListBill.length !== 1 && (
                          <button
                            type="button"
                            class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                            onClick={() => handleRemoveClickBill(i)}
                          >
                            Remove
                          </button>
                        )}
                        {inputListBill.length - 1 === i &&
                          inputListBill.length < 3 && (
                            <button
                              type="button"
                              class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                              onClick={handleAddClickBill}
                            >
                              + Add additional bill
                            </button>
                          )}
                      </button>

                      <h1 className="text-gray-500 text-sm">
                        {inputListBill.length - 1 === i && (
                          <h1>({getActualLength(issue.relatedBills)}/3 max)</h1>
                        )}
                      </h1>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="block flex items-end mb-2">
              <label
                for="relatedVotes"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Votes
              </label>
              <h1 className="ml-2">(optional)</h1>
            </div>
            <div className="block flex items-end mb-5">
              <div className="App">
                {inputListVote.map((x, i) => {
                  return (
                    <div
                      style={{ width: 1100 }}
                      className="block flex space-x-4 items-center mt-3"
                    >
                      <input
                        type="text"
                        name="vote"
                        placeholder="Search for title of related vote here..."
                        value={x.vote}
                        onChange={(e) => handleInputChangeVote(e, i)}
                        className="flex-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />

                      <button type="button" className="btn-box">
                        {inputListVote.length !== 1 && (
                          <button
                            type="button"
                            class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                            onClick={() => handleRemoveClickVote(i)}
                          >
                            Remove
                          </button>
                        )}
                        {inputListVote.length - 1 === i &&
                          inputListVote.length < 3 && (
                            <button
                              type="button"
                              class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                              onClick={handleAddClickVote}
                            >
                              + Add additional vote
                            </button>
                          )}
                      </button>

                      <h1 className="text-gray-500 text-sm">
                        {inputListVote.length - 1 === i && (
                          <h1>({getActualLength(issue.relatedVotes)}/3 max)</h1>
                        )}
                      </h1>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="block flex items-end mb-2">
              <label
                for="relatedEvents"
                className="whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
              >
                Related Events
              </label>
              <h1 className="ml-2">(optional)</h1>
            </div>
            <div className="block flex items-end mb-5">
              <div className="App">
                {inputListEvent.map((x, i) => {
                  return (
                    <div
                      style={{ width: 1100 }}
                      className="block flex space-x-4 items-center mt-3"
                    >
                      <input
                        type="text"
                        name="event"
                        placeholder="Search for title of related event here..."
                        value={x.event}
                        onChange={(e) => handleInputChangeEvent(e, i)}
                        className="flex-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />

                      <button type="button" className="btn-box">
                        {inputListEvent.length !== 1 && (
                          <button
                            type="button"
                            class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                            onClick={() => handleRemoveClickEvent(i)}
                          >
                            Remove
                          </button>
                        )}
                        {inputListEvent.length - 1 === i &&
                          inputListEvent.length < 3 && (
                            <button
                              type="button"
                              class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
                              onClick={handleAddClickEvent}
                            >
                              + Add additional event
                            </button>
                          )}
                      </button>

                      <h1 className="text-gray-500 text-sm">
                        {inputListEvent.length - 1 === i && (
                          <h1>
                            ({getActualLength(issue.relatedEvents)}/3 max)
                          </h1>
                        )}
                      </h1>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}
          {status.error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{status.error}</span>
            </div>
          )}
          {status.success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{status.success}</span>
            </div>
          )}
          <div className="flex space-x-4">
            <button
              disabled={loading}
              type="submit"
              className="btn-primary"
            >
              {" "}
              Post Issue{" "}
            </button>
            {/* <button
              onClick={() => {
                window.location.reload(false);
              }}
              className="btn-secondary"
            >
              {" "}
              Reset Form{" "}
            </button> */}
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn-white"
            >
              {" "}
              Cancel{" "}
            </button>
          </div>
        </form>
      </div>
    </AppLayout>
  );
};

// const TagsInput = (props) => {
//   const [tags, setTags] = React.useState([]);

//   const addTags = (event) => {
//     if (event.key === "Enter" && event.target.value !== "" && tags.length < 3) {
//       setTags([...tags, event.target.value]);
//       setTags([...tags, event.target.value]);
//       event.target.value = "";
//     }
//   };

//   const removeTags = (index) => {
//     setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
//   };

//   return (
//     <div className="tags-input">
//       {/* change tag formatting to badge */}
//       <u1>
//         {tags.map((tag, index) => (
//           <li key={index}>
//             <span>{tag}</span>
//             <i className="material-icons" onClick={() => removeTags(index)}></i>
//           </li>
//         ))}
//       </u1>
//       <input
//         type="text"
//         onKeyUp={(event) => addTags(event)}
//         placeholder="+ Add Tags"
//         className={props.boxDesign}
//       />
//       <label className="ml-3">({tags.length}/3 max)</label>
//     </div>
//   );
// };

// const MultInput = (props) => {
//   const [inputList, setInputList]= React.useState([{inputValue: ""}]);

//   // handle input change
//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...inputList];
//     list[index][name] = value;
//     setInputList(list);
//   };

//   // handle click event of the Remove button
//   const handleRemoveClick = index => {
//     const list = [...inputList];
//     list.splice(index, 1);
//     setInputList(list);
//   };

//   // handle click event of the Add button
//   const handleAddClick = () => {
//     setInputList([...inputList, { inputValue: ""}]);
//   };

//   return (
//     // className="App flex-1"
//     <div className="w-full">
//       {inputList.map((x, i) => {
//         return (
//           //className="block flex space-x-4 items-center"
//           // className="flex-1"
//           <div className="flex-1">
//             <input
//               id={props.id}
//               name={props.id}
//               placeholder={props.placeholder}
//               type="text"
//               value={x.words}
//               onChange={e => handleInputChange(e, i)}
//               className="flex-1"
//               // className="flex-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
//               // className="flex-1 w-12/12 shadow-sm sm:text-sm border-gray-300 rounded-md"
//             />
//             {/* <div className="btn-box">
//               {inputList.length !== 1 && <button className="mr10">Remove</button>}
//               {inputList.length - 1 === i &&
//                 <button
//                   type="button"
//                   class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500"
//                 >
//                   + Add Related Bill
//                 </button>
//               }
//             </div> */}
//             <div className="btn-box">
//               {inputList.length !== 1 &&
//                 <button
//                   // className="mr10"
//                   onClick={() => handleRemoveClick(i)}
//                   // class="flex-1 ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500">
//                   // class="flex-1 ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500">
//                   >Remove
//                 </button>
//               }
//               {inputList.length - 1 === i && inputList.length <= 2 &&
//                 <button
//                   onClick={handleAddClick}
//                   // class="flex-1 ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500">
//                   // class="flex-1 ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-justiceBlue-500">
//                   >+ Add Related Vote
//                 </button>
//               }
//               {/* {inputList.length - 1 === i && inputList.length <= 2 &&
//                 <h3>({inputList.length}/3 max)</h3>
//               } */}
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

export default CreateIssue;
