import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import EventCard from '../../global/events/EventCard'
import {StateDBContext} from '../../../../contexts/StateContext'

const StateTopEvents = () => {

    const topEvents = useContext(StateDBContext);
    let [eventsDisplay, setDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    // update component results when data fetched & passed
    useEffect( () => {
      if(topEvents[5] != null && topEvents[5].length !== 0) {
        setDisplay(topEvents[5].map((event, i) => (
          <EventCard
            key={i}
            eventId={event.id}
            title={event.data().eventTitle}
            going={event.data().numAttending}
            interested={event.data().numInterested}
            date={event.data().mainStartTimeUTC}
            time={
              event.data().mainStartTimeString +
              " " +
              event.data().timeZone
            }
            organizer={event.data().organizerName}
            isVirtual={event.data().isVirtual}
            location={`${event.data().city}, ${
              event.data().state
            }`}
          />)));
      }
      else{
        setDisplay(<h1 className="px-8 py-8 font-medium text-xl">There are currently no upcoming events in your state.</h1>)
      }
    }, [topEvents[5]]);

    return (
      <>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {eventsDisplay}
          </div>
        </div>
      </>
    );

}

export default StateTopEvents;
