import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import AppLayout from "../../../../layouts/AppLayout";
import StateResolvedIssues from "../../../../components/civ/state/issues/StateResolvedIssues";
import StateNav from "../../../../components/civ/state/StateNav";

const StateResolvedIssuesPage = () => {
	return (
		<AppLayout>
			<StateNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<StateResolvedIssues />
			</div>
		</AppLayout>
	);
};

export default StateResolvedIssuesPage;
