import React from "react";

import AppLayout from "../../../layouts/AppLayout";
import DistrictNav from "../../../components/civ/district/DistrictNav";
import DistrictDashboardSummary from "../../../components/civ/district/dashboard/DistrictDashboardSummary";
import DistrictTrending from "../../../components/civ/district/dashboard/DistrictTrending";
import DistrictRecommended from "../../../components/civ/district/dashboard/DistrictRecommended";
// import { useAuth } from "../../../contexts/AuthContext"

const DistrictPage = () => {
	// const { currUser, currUserRef, userData } = useAuth();

	return (
		<AppLayout>
			<DistrictNav />

			<DistrictDashboardSummary
			// name={userData.districtRep.firstName + " " + userData.districtRep.lastName}
			// party={userData.districtRep.party}
			/>
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				{/* Legislator component */}
				{/* Daily challenge component */}
				<DistrictTrending />
				<br />
				<DistrictRecommended />
				{/* <br /> */}
			</div>
		</AppLayout>
	);
};

export default DistrictPage;
