import React from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";

const HomeNotesLegislatorPage = () => {
	return (
		<AppLayout>
			<HomePageNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16"></div>
		</AppLayout>
	);
};

export default HomeNotesLegislatorPage;
