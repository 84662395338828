import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";
import { useHistory, Link } from "react-router-dom";
import { db } from "../../../config/firebase";
import { states_districts, states_hash } from "../../../assets/states";
// import { isCompositeComponent } from "react-dom/test-utils";
// import { formatDate } from "../../../assets/usefulFunctions";
import defaultProfilePic from "../../../assets/DefaultProfilePic.png"
// import legislators from '../../../assets/jsonData/legislators.json'

function CivilianEditPage(props) {
  const { currUserRef, currUser } = useAuth();
  const [credentials, setCredentials] = useState();
  const [lastEdit, setLastEdit] = useState();
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(true);
  const [imageOriginalSrc, setImageOriginalSrc] = useState(defaultProfilePic);
  const history = useHistory();

  useEffect(async () => {
    if (currUserRef) {
      const userDoc = await currUserRef.get();
      const userData = userDoc.data();
      setCredentials(userData);

      if (userData.lastEdited !== undefined) {
        let start = new Date(userData.lastEdited * 1000);
        let end = new Date(firebase.firestore.Timestamp.now() * 1000);
        const timeElapsed = end.getTime() - start.getTime();

        const daysElapsed = Math.trunc(timeElapsed / (1000 * 3600 * 24));

        console.log("days " + daysElapsed);
        setLastEdit(daysElapsed);

        if (daysElapsed < 364) {
          console.log("can edit false");
        }
      }

      let storageRef = firebase.storage().ref();
      let userPicRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)
      // if (userPicRef.exists){
        userPicRef.getDownloadURL().then((url) => {
          setImageOriginalSrc(url)
          setLoading(false);
        }).catch((error) => {
          console.log(error)
          setLoading(false);
        })
      // }
      // else{
      //   setLoading(false);
      // }

    }
  }, []);

  //TODO: change to use legislators.json instead of db
  async function findLegislators() {
    if (credentials.district && credentials.state) {
      let reps = [];
      const snapHouse = await db
        .collection("legislators")
        .where("chamber", "==", "House")
        .where("region", "==", credentials.state)
        .where("district", "==", credentials.district)
        .get();
      // const snapHouse = [...legislators].filter(object => {
      //   return (object["chamber"] === "House" && object["state"] === credentials.state && object["district"] === credentials.district)
      // })
      snapHouse.forEach((doc) => {
        // setCredentials({ ...credentials, districtRep: doc.data() });
        reps.push(doc.data());
      });

      const snapSenate = await db
        .collection("legislators")
        .where("chamber", "==", "Senate")
        .where("region", "==", credentials.state)
        .get();
      // let senators = [];
      // const snapSenate = [...legislators].filter(object => {
      //   return (object["chamber"] === "Senate" && object["state"] === credentials.state)
      // })
      snapSenate.forEach((doc) => {
        // senators.push(doc.data());
        reps.push(doc.data());
      });
      // setCredentials({ ...credentials, senateRep: senators });
      return reps;
    }
  }

  //TODO: proper error messages
  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    findLegislators().then((reps) => {
      console.log(credentials, reps);
      if (reps.length === 3)
        db.collection("civilians")
          .doc(currUser.uid)
          .set(
            {
              ...credentials,
              districtRep: reps[0],
              senateRep: [reps[1], reps[2]],
              lastEdited: firebase.firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then((res) => {
            setTimeout(() => history.push(`/users/${currUser.uid}/issues`), 500);
          })
          .catch((e) => setErr(e.message));
      /**
       * meaning the user resides in a territory that doesn't have senators
       */ else
        db.collection("civilians")
          .doc(currUser.uid)
          .set(
            {
              ...credentials,
              districtRep: reps[0],
              lastEdited: firebase.firestore.Timestamp.now(),
            },
            { merge: true }
          )
          .then((res) => {
            setTimeout(() => history.push(`/users/${currUser.uid}/issues`), 500);
          })
          .catch((e) => setErr(e.message));
      // setErr("");
      setLoading(false);
    });
  }

  function handleChange(e) {
    // console.log(e.target.value)
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }

  function handlePicChange(event) {
    let storageRef = firebase.storage().ref();
    let userPicRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    var imgtag = document.getElementById("profilePicImg");
    imgtag.title = selectedFile.name;

    reader.onload = function(event) {
      imgtag.src = event.target.result;
    };

    reader.readAsDataURL(selectedFile);

    userPicRef.put(selectedFile).then((snapshot) => {
      console.log('Uploaded file!');
    });
    // console.log("PHOTO:", event.target.value)
  }

  function removeProfilePic(event) {
    let storageRef = firebase.storage().ref();
    let userPicRef = storageRef.child(`images/civilian/profilePics/${currUser.uid}`)

    // if (userPicRef.exists){
    userPicRef.delete().then(() => {
      console.log("deleted photo")
      var imgtag = document.getElementById("profilePicImg");
      imgtag.src = defaultProfilePic
    }).catch((error) => {
      console.log(error)
    })
    // }

  }

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  // if (lastEdit < 364) {
    return (
      <AppLayout>
        <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
          <h1 className="text-2xl font-semibold md:text-4xl mb-8">
            Edit your profile
          </h1>

          <label
          for="profilePic"
          className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-4"
          >
            Profile picture
          </label>

          <div className="mb-9 flex items-center">
            {/* <div className="inline-bloc w-20 h-20 overflow-hidden rounded-full">
              <img id="profilePicImg" class="inline mx-0 my-auto h-full w-auto" src={defaultProfilePic} alt=""/>
            </div> */}
            <div className="bg-gray-400 h-20 w-20 rounded-full">
              <img id="profilePicImg" className="object-scale-down inline-block h-20 w-20 rounded-full" src={imageOriginalSrc} alt=""/>
            </div>
            <div className="ml-4 flex">
              <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
                Change
                <input onChange={handlePicChange} id="profilePic" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
              </button>
              <button onClick={removeProfilePic} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
                Remove
              </button>
            </div>
          </div>

          <h1 className="text-lg font-normal italic md:text-xl">
            Note: You can only edit your profile information (name, location, etc.) once per year
          </h1>

          {lastEdit < 364 ?

          <div>

            <h1 className="text-md font-normal pt-5 md:text-lg">
              You last edited your profile information {lastEdit} days ago. You do not have
              permission to edit it again at this time.
            </h1>
            <div className="flex pt-5">
              <Link
                disabled={loading}
                to={`/users/${currUser.uid}/issues`}
                className="btn-primary"
              >
                Return
              </Link>
            </div>
          </div>

          :
          <div>
            <div className="mt-6">
            <form
              action="#"
              method="POST"
              className="space-y-6"
              onSubmit={handleSubmit}
            >

                <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    for="firstName"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    First name
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={credentials.firstName}
                      onChange={handleChange}
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label
                    for="lastName"
                    className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                  >
                    Last name
                  </label>
                  <div className="mt-1 rounded-md shadow-sm">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={credentials.lastName}
                      onChange={handleChange}
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  for="state"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                >
                  Select your state
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <select
                    id="state"
                    name="state"
                    value={credentials.state}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option value=""></option>
                    {Object.keys(states_hash).map((state) => {
                      return <option value={state}>{states_hash[state]}</option>;
                    })}
                  </select>
                </div>
              </div>
              {credentials.state && (
                <div className="flex space-x-4">
                  <div className="w-full md:w-3/5">
                    <label
                      for="district"
                      className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                    >
                      Enter your district
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <select
                        id="district"
                        name="district"
                        value={credentials.district}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      >
                        <option value=""></option>
                        {/* {Array(states_districts[credentials.state])
                          .fill()
                          .map((_, idx) => {
                            return <option value={idx + 1}>{idx + 1}</option>;
                          })} */}
                        {
                          states_districts[credentials.state] === "At-Large" ?
                          <option value="At-Large">At Large</option>
                          :
                        Array(states_districts[credentials.state])
                          .fill()
                          .map((_, idx) => {
                            return <option value={idx + 1}>{idx + 1}</option>;
                          })}
                      </select>
                    </div>
                    {/* <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="district"
                        name="district"
                        type="text"
                        value={credentials.district}
                        onChange={handleChange}
                        required
                        className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div> */}
                  </div>

                  <div className="flex items-end w-full md:w-2/5">
                    {/* <div className="items-center"> */}
                      <p className="text-justiceBlue-500 text-xl font-medium">
                        Don't know your district? Click <a
                          className="underline"
                          href="https://www.house.gov/representatives/find-your-representative"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                      </p>
                    {/* </div> */}
                  </div>
                </div>
              )}
              {/* <div>
                <label
                  for="engagementLevel"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl"
                >
                  Select your current level of political engagement
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="engagementLevel"
                    name="engagementLevel"
                    type="text"
                    value={credentials.engagementLevel}
                    onChange={handleChange}
                    placeholder="not implemented, skip this"
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}

              {/* <div className="flex items-center justify-between">
                              <div className="flex items-center">
                              <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-red-500 transition duration-150 ease-in-out"/>
                              <label for="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                                  Remember me
                              </label>
                              </div>

                              <div className="text-sm leading-5">
                              <a href="#" className="font-medium text-red-500 hover:text-red-400 focus:outline-none focus:underline transition ease-in-out duration-150">
                                  Forgot your password?
                              </a>
                              </div>
                          </div> */}

              <div>
                <span className="block flex space-x-4">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn-primary"
                  >
                    Confirm Information
                  </button>
                  <button
                    disabled={loading}
                    // to={`/users/${currUser.uid}/issues`}
                    onClick={() => {history.goBack();}}
                    className="btn-white"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
            {err && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Holy smokes! </strong>
                <span className="block sm:inline">{err}</span>
                {/* <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                  <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                  </span> */}
              </div>
            )}
          </div>
          </div>

          }

        </div>
      </AppLayout>
    );
  // // }
  // // return (
  //   <AppLayout>
  //     <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
  //       <h1 className="text-2xl font-semibold md:text-4xl mb-8">
  //         Edit your profile!
  //       </h1>

  //       <label
  //         for="firstName"
  //         className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-4"
  //       >
  //         Profile picture
  //       </label>

  //       <div className="mb-8 flex items-center">
  //         {/* <div className="inline-bloc w-20 h-20 overflow-hidden rounded-full">
  //           <img id="profilePicImg" class="inline mx-0 my-auto h-full w-auto" src={defaultProfilePic} alt=""/>
  //         </div> */}
  //         <div className="bg-gray-400 h-20 w-20 rounded-full">
  //           <img id="profilePicImg" className="object-scale-down inline-block h-20 w-20 rounded-full" src={imageOriginalSrc} alt=""/>
  //         </div>
  //         <div className="ml-4 flex">
  //           <button className="cursor-pointer relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center hover:bg-blue-gray-50 focus-within:outline-none">
  //             Change
  //             <input onChange={handlePicChange} id="profilePic" name="profilePic" type="file" class="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-gray-300 rounded-md"/>
  //           </button>
  //           <button onClick={removeProfilePic} type="button" className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none">
  //             Remove
  //           </button>
  //         </div>
  //       </div>

  //       <h1 className="text-lg font-normal italic md:text-xl">
  //         Note: You can only edit the information below once per year
  //       </h1>


  //     </div>
  //   </AppLayout>
  // // );
}

export default CivilianEditPage;
