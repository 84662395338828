import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AdminRoutes from "./admin/AdminRoutes";

import LandingRoutes from "./LandingRoutes";
import HomeRoutes from "./civ/HomeRoutes";
import DistrictRoutes from "./civ/DistrictRoutes";
import StateRoutes from "./civ/StateRoutes";
import NationRoutes from "./civ/NationRoutes";
import BillVoteRoutes from "./civ/BillVoteRoutes";
import LegislatorsRoutes from "./civ/LegislatorsRoutes";
import CivilianRoutes from "./civ/CivilianRoutes";
import IssueRoutes from "./civ/IssueRoutes";
import OrganizationRoutes from "./civ/OrganizationRoutes";
import EventRoutes from "./civ/EventRoutes";

import OrgHomeRoutes from "./org/HomeRoutes";
import OrgEventsRoutes from "./org/EventsRoutes";
import OrgSupportersRoutes from "./org/SupportersRoutes";
import OrgBrowseStateRoutes from "./org/BrowseStateRoutes";
import OrgBrowseNationRoutes from "./org/BrowseNationRoutes";
import OrgOrganizationRoutes from "./org/OrganizationProfileRoutes";
import OrgIssueRoutes from "./org/IssueRoutes";
import OrgBillVoteRoutes from "./org/BillVoteRoutes";
import OrgCivilianRoutes from "./org/CivilianRoutes";
import OrgLegislatorRoutes from "./org/LegislatorRoutes";

import LegInboxRoutes from "./leg/InboxRoutes";
import LegBillsVotesRoutes from "./leg/BillsVotesRoutes";
import LegInsightsRoutes from "./leg/InsightsRoutes";
import LegBrowseRoutes from "./leg/BrowseRoutes";

const routes = () => {
	return (
		<Switch>
			<Route path="/admin" component={AdminRoutes} />

			{/* temporarily putting leg routes here, can move */}
			<Route path="/leg/inbox" component={LegInboxRoutes} />
			<Route path="/leg/bills-votes" component={LegBillsVotesRoutes} />
			<Route path="/leg/insights" component={LegInsightsRoutes} />
			<Route path="/leg/browse" component={LegBrowseRoutes} />

			{/* temporarily putting org routes here, can move */}
			<Route path="/org/home" component={OrgHomeRoutes} />
			<Route path="/org/events" component={OrgEventsRoutes} />
			<Route path="/org/supporters" component={OrgSupportersRoutes} />
			<Route path="/org/browse/state" component={OrgBrowseStateRoutes} />
			<Route
				path="/org/browse/nation"
				component={OrgBrowseNationRoutes}
			/>
			<Route
				path="/org/organizations"
				component={OrgOrganizationRoutes}
			/>
			<Route path="/org/issues" component={OrgIssueRoutes} />
			<Route path="/org/bills-votes" component={OrgBillVoteRoutes} />
			<Route path="/org/users" component={OrgCivilianRoutes} />
			<Route path="/org/legislators" component={OrgLegislatorRoutes} />

			<Route path="/home" component={HomeRoutes} />
			<Route path="/district" component={DistrictRoutes} />
			<Route path="/state" component={StateRoutes} />
			<Route path="/nation" component={NationRoutes} />
			<Route path="/bills-votes" component={BillVoteRoutes} />
			<Route path="/legislators" component={LegislatorsRoutes} />
			<Route path="/users" component={CivilianRoutes} />
			<Route path="/organizations" component={OrganizationRoutes} />
			<Route path="/issues" component={IssueRoutes} />
			<Route path="/events" component={EventRoutes} />
			<Route path="/" component={LandingRoutes} />
		</Switch>
	);
};

export default routes;
