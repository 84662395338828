import React, { useState } from "react";
import { Link } from "react-router-dom";
import LandingLayout from "../../layouts/LandingLayout";
import protest from "../../assets/protest.jpg";
import home1 from "../../assets/Home1v2.png";
import home2 from "../../assets/Home2v2.png";
import { useAuth } from "../../contexts/AuthContext"
import { Redirect } from "react-router-dom";

const LandingHomePage = () => {

  const { currUser, currUserAdminInfo } = useAuth();
  const [userEmail, setUserEmail] = useState("");

  function handleChange(e) {
    setUserEmail(e.target.value);
    console.log(userEmail);
  }

  function handleSubmit(e) {
    console.log("user email:", userEmail);
  }

  if (currUser && currUserAdminInfo.type === "civilian"){
    return <Redirect to="/home" />
  }

  if (currUser && currUserAdminInfo.type === "organization"){
    return <Redirect to="/org/home" />
  }

  return (
    <LandingLayout>
      {/* Hero */}

    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative pb-8 pt-1 bg-white sm:pb-16 md:pb-20 lg-hero:max-w-2xl lg-hero:w-full lg-hero:pb-28 xl:pb-32">

          <svg class="hidden lg-hero:block z-10 absolute right-0 top-0 h-full w-64 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="0,0 100,0 50,100 0,100" />
          </svg>


          <main class="mt-8 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 md:mt-16 lg-hero:mt-20 lg-hero:px-8 xl:mt-28">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg-hero:text-left">
                <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 mb-5 sm:text-base lg-hero:text-sm xl:text-base">
                  Beta version out now!
                </div>
                <h2 className="mt-1 text-5xl tracking-tight font-extrabold text-gray-900 sm:text-6xl">
                  Digital activism
                  <br />
                  <span className="text-justiceBlue-600">reimagined.</span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg-hero:text-lg xl:text-xl">
                  Tired of yelling into the void? Get involved in your <br className="hidden sm:block"/> community and hold your
                  elected officials accountable.
                </p>
                <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg-hero:text-left lg-hero:mx-0 md:mb-10 sm:mb-10">
                  <div className="mt-3 sm:flex sm:justify-center lg-hero:justify-start">
                    <div className="rounded-md shadow">
                      <Link
                        to="/signup"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:border-red-600 focus:shadow-outline-red-500 active:bg-red-500Darker transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        // to="/signup"
                        // className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:border-red-600 focus:shadow-outline-red-500 active:bg-red-600 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        Sign up now!
                      </Link>
                    </div>
                    {/* Get started */}
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                      <Link
                        to="/features"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-500 bg-white hover:text-red-600 hover:bg-gray-50 focus:outline-none focus:border-red-500 focus:shadow-outline-red-500 active:br-gray-100 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              {/* </div> */}
              {/* <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <svg
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                  width="640"
                  height="784"
                  fill="none"
                  viewBox="0 0 640 784"
                >
                  <defs>
                    <pattern
                      id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                      x="118"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    y="72"
                    width="640"
                    height="640"
                    className="text-gray-50"
                    fill="currentColor"
                  />
                  <rect
                    x="118"
                    width="404"
                    height="784"
                    fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                  />
                </svg>
                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md"> */}
                  {/* <button type="button" className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline"> */}
                  {/* <img
                    className="w-full rounded-lg"
                    src={protest}
                    alt="Two people walking down a street"
                  /> */}
                  {/* <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                            <svg className="h-20 w-20 text-justiceBlue-400" fill="currentColor" viewBox="0 0 84 84">
                            <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                            </svg>
                        </div> */}
                  {/* </button> */}
                {/* </div>
              </div> */}
            </div>
          </main>
        </div>
      </div>

      <div class="lg-hero:absolute lg-hero:inset-y-0 lg-hero:right-0 lg-hero:w-1/2">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg-hero:w-full lg-hero:h-full" src={protest} alt="protest"/>
      </div>
    </div>








      {/* Stats */}
      <div className="bg-justiceBlue-500 pt-12 sm:pt-16">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
              Facilitating grassroots change
            </h2>
            <p className="mt-3 text-xl leading-7 text-gray-100 sm:mt-4">
              A platform for the people to influence Congress, not the other way
              around.
            </p>
          </div>
        </div>

        <div className="mt-10 pb-10 bg-gray-50 sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-justiceBlue-500"></div>
            <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="mx-auto">
                <dl className="rounded-lg bg-white shadow-lg md:grid md:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 py-10 text-center md:border-0 md:border-r">
                    <dt
                      className="order-2 mt-2 text-lg leading-10 font-medium text-gray-500"
                      id="item-1"
                    >
                      for elected officials
                    </dt>
                    <dd
                      className="order-1 sm: text-3xl md: text-4xl lg:text-5xl leading-none font-extrabold text-red-500"
                      aria-describedby="item-1"
                    >
                      Accountability
                    </dd>
                  </div>

                  <div className="flex flex-col border-t border-b border-gray-100 py-10 text-center md:border-0 md:border-l md:border-r">
                    <dt className="order-2 mt-2 text-lg leading-10 font-medium text-gray-500">
                      centered organizing
                    </dt>
                    <dd className="order-1 sm: text-3xl md: text-4xl lg:text-5xl leading-none font-extrabold text-red-500">
                      Community
                    </dd>
                  </div>

                  <div className="flex flex-col border-t border-gray-100 py-10 text-center md:border-0 md:border-l">
                    <dt className="order-2 mt-2 text-lg leading-10 font-medium text-gray-500">
                      in communication
                    </dt>
                    <dd className="order-1 sm: text-3xl md: text-4xl lg:text-5xl leading-none font-extrabold text-red-500">
                      Transparency
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h3 className="text-4xl leading-8 font-extrabold tracking-tight text-justiceBlue-600 sm:text-4xl sm:leading-10">
              A better way to make your voice heard
            </h3>
            <p className="mt-4 max-w-3xl text-xl leading-7 text-gray-500">
              With GrassrootsGov, you'll never feel like you're just yelling
              into the void again.
            </p>
          </div>

          {/* First Feature */}
          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                {/* Build grassroots movements for issues you care about */}
                Make a difference in your community
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Real change starts from right where you are. Speak up about the
                things you want your legislators to hear about & contribute to
                movements shaping the nation.
              </p>

              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                        {/* Heroicon name: document-text */}
                        {/* <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                            </svg> */}
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Get involved in real ways
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Got something you're passionate about? Tell your
                        district and state about it, find organizations & events
                        advancing the cause, and support others in your community
                        who feel the same way.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                        {/* Heroicon name: user-group */}
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        See what others in your community care about
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Feel like your legislators are ignoring the voice of the
                        public? Now you can find out just how many of your
                        fellow constituents support a policy or initiative.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                        {/* Heroicon name: globe */}
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg leading-6 font-medium text-gray-900">
                        Build local and national movements
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Change starts at the local level, but it shouldn't be
                        limited to just that. Amplify your message by joining
                        forces with others across the nation.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width="784"
                height="404"
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="784"
                  height="404"
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width="490"
                src={home1}
                alt=""
              />
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>

          {/* Second Feature */}
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Track your legislators & hold them accountable
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Finding out what your members of Congress are up to and giving
                  them direct feedback shouldn't be hard. It's your government,
                  after all.
                </p>

                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                          {/* Heroicon name: bell */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Stay informed
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Are your elected officials fulfilling their campaign
                          promises? Keep track of your legislators' latest bills
                          and votes and receive updates on major congressional
                          activity.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                          {/* Heroicon name: speakerphone */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Give direct feedback
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Strength in numbers is a powerful tool. Let your
                          lawmakers know what you think about their legislative
                          decisions by voicing your opinions on their bills and
                          votes.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-justiceBlue-400 text-white">
                          {/* Heroicon name: clipboard-check */}
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          Promote accountability
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Tired of never getting a response when you call or
                          email your elected officials? Through GrassrootsGov,
                          everyone will know if your legislators are hiding from
                          their constituents.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                  />
                </svg>
                <img
                  className="relative mx-auto"
                  width="490"
                  src={home2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA */}
      <div className="bg-justiceBlue-600">
        <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
            <span className="block">Ready to make your voice heard?</span>
          </h2>
          <p className="mt-6 mb-6 text-lg leading-6 text-gray-50">
            Try out the beta version now!
          </p>
          <Link to='/signup' className="w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-500 bg-white shadow-sm hover:text-white hover:bg-red-400 focus:outline-none focus:border-red-500 focus:shadow-red-500 active:bg-red-500 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
            Sign up
          </Link>
        </div>
      </div>
    </LandingLayout>
  );
};

export default LandingHomePage;
