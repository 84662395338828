import React, { useState } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
// import jd from '../../../../assets/jd.png'

const OrganizationCard = (props) => {
  return (
    <Link
      to={`/organizations/${props.organizationId}/latest`}
      className='block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out'
    >
      <div className='flex items-center px-4 py-4 sm:px-6'>
        {/* <img className='ml-2 mr-9 rounded-md h-20 w-20' src={jd} /> */}
        <img className="ml-2 mr-9 rounded-md h-20 w-20" src={props.profilePicUrl} />

        <div className="w-auto flex-1">
          <div className="flex items-center justify-between">
            <div className="text-2xl leading-9 font-semibold text-gray-900 truncate">
              {props.name}
            </div>
          </div>
        </div>

        <div className='mt-2 flex items-center text-md leading-9 font-semibold text-red-500 sm:mt-0'>
          <span>View</span>
          <svg
            className='flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 text-red-500'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M14 5l7 7m0 0l-7 7m7-7H3'
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};
export default OrganizationCard;
