import React, { useState, useEffect } from "react";
import IssueCard from "../../global/issues/IssueCard";
import { db } from "../../../../config/firebase";
import { useAuth } from "../../../../contexts/AuthContext";

const HomeIssues = (props) => {
  const { currUser } = useAuth();
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    if (currUser) {

      const snapshot = await db
        .collection("issues")
        .where("createdBy", "==", currUser.uid)
        .where("active", "==", props.status === "active")
        .get();
      let tempIssues = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          tempIssues.push(doc)
        });
        setIssues(tempIssues);
      } else {
        console.log("empty snapshot");
      }
      setLoading(false);
    }
  }, [props]);

  if (loading) {
    return <></>;
  } else if (issues.length == 0) {
    return (
      <>
        <div className="bg-white overflow-hidden text-center md:my-28">
          <div className="text-lg">You currently have no issues. Create one to get started!</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {issues.map((doc) => {
              return (
                <>
                  <IssueCard
                    issueTitle={doc.data().issueTitle}
                    supporters={doc.data().supporters}
                    legislator={
                      doc.data().legislator.firstName +
                      " " +
                      doc.data().legislator.lastName
                    }
                    region={`${doc.data().region}-${doc.data().district}`}
                    created={doc.data().dateCreated}
                    copies={doc.data().regionalCopies}
                    issueId={doc.id}
                  />
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }
};

export default HomeIssues;
