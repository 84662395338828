import React from "react";
import { Link } from "react-router-dom";
import OrgLayout from "../../../layouts/OrgLayout";
import BrowseNav from "../../../components/org/browse/BrowseNav";
import OrganizationsNav from "../../../components/org/browse/OrganizationsNav";
import StateOrganizations from "../../../components/org/browse/StateOrganizations";

function BrowseStateOrganizationsPage(props) {
	return (
		<OrgLayout>
			<BrowseNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<OrganizationsNav />
				<StateOrganizations />
			</div>
		</OrgLayout>
	);
}

export default BrowseStateOrganizationsPage;
