import React from "react";
import LandingLayout from "../../../layouts/LandingLayout";

const CivilianHelpFindYourWayAroundPage = ({history}) => {

  return(
    <LandingLayout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-justiceBlue-600 font-semibold tracking-wide uppercase">
                For Civilians
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                User profiles
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
              aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
              egestas fringilla sapien.
            </p> */}
          </div>
          <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h2>User profiles 101</h2>
            <p>At GrassrootsGov, we encourage you to customize your account so that we can personalize your experience on our platform.</p>
            <p>Upon signup, you will be asked to enter your name, state/district, a profile photo, and your level of political engagement. This is done so that we can bring you content from your region, and so that you can find movements to support/create in your own community.</p>
            <p>After you create your profile, you will be able to view your profile info at any time by accessing it from the right-hand dropdown menu.</p>

            <h2>How to view your profile</h2>
            <p>Viewing your profile is easy, after signing in, click on the icon at the top right of the page. Then select "My Profile". </p>
            <p>Here you should be able to see the information you filled out upon signup, as well as the issues you've created, notes you've written, and organizations you follow. </p>
            <p>Click on any of these sections and you can sort by popularity/date as well as filter by category.</p>

            <h2>How to edit your profile</h2>
            <p>To edit, click on the profile photo icon on the top right corner of the page, then click "My Profile". Then press the red button "Edit Profile", and there you should be able to update any personal information, including your name, district, profile picture, and password.</p>
            <p>Rules for when you can edit your profile:</p>
            <ul>
              <li>You may edit your profile once, anytime after you first sign up.</li>
              <li>After that, you may only edit your profile once a year.</li>
            </ul>
            <p>Keep these rules in mind when using the platform. If you encounter issues with editing your profile, please contact us.</p>

            <h2>How to view other users' profiles</h2>
            <p>In order to view other users' profiles, simply click on their name from an issue they've created, under "Learn More".</p>
            <p>Here, you can see that user's issues, notes, and organizations.</p>

          </div>
        </div>
      </div>
      <div className="w-full border-t border-gray-200"/>
    </LandingLayout>
  )
}

export default CivilianHelpFindYourWayAroundPage;
