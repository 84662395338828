import React, { useState, useEffect } from "react";
// import EventCard from "../../global/events/EventCard";
import EventCard from './HomeEventCard'
import { useAuth } from "../../../../contexts/AuthContext";
import { db } from "../../../../config/firebase";

// When more people bookmark/register for an event, the saved events in the registeredEvents and bookmarkedEvents collections for each user should be able to update
//   When rendering the event card, check to see if the number of interested/going for the event has changed

const HomeEventsBookmarkedDetailed = (props) => {
	const { currUser } = useAuth();
	const [eventsReg, setEventsReg] = useState({ events: [] });
	let eventArray = [];
	const currentTime = new Date();
	// only runs once for data retrieval
	useEffect(async () => {
		const currUserRef = db.collection("civilians").doc(currUser.uid);
		await currUserRef.collection("bookmarkedEvents").get()
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					eventArray.push(doc);
				});
			})
			.catch((error) => {
				console.log("Error getting documents: ", error);
			});
    console.log("event array:", eventArray)
		eventArray.sort((a, b) => a.data().start - b.data().start);
		setEventsReg({ events: eventArray });
	}, []);

	return (
		<div className="divide-y divide-gray-200 bg-white overflow-hidden sm:rounded-md shadow">
			{eventsReg.events.length > 0 ?
        eventsReg.events.map((currEvent, index) => {
				return (
					<div key={index}>
						<EventCard
							title={currEvent.data().eventTitle}
							organizer={currEvent.data().organizerName}
							date={currEvent.data().mainStartTimeUTC}
              time={
                currEvent.data().mainStartTimeString +
                " " +
                currEvent.data().timeZone
              }
              isVirtual={currEvent.data().isVirtual}
              location={`${currEvent.data().city}, ${
                currEvent.data().state
              }`}
							eventId={currEvent.id}
						/>
					</div>
				);
			})
      :
      <h1 className="text-lg px-6 py-6">You currently have no bookmarked events.</h1>
    }
		</div>
	);
};
export default HomeEventsBookmarkedDetailed;
