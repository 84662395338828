import React from "react";
import { NavLink } from "react-router-dom";

const HomePageNav = () => {
//   const { pathname } = useLocation();
  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">Home</h1>
      </header>
      <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16">
        <div className="flex space-x-8">
          <NavLink
            to="/org/home"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            exact
            activeClassName="text-gray-900"
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/org/home/feed"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
          >
            My Feed
          </NavLink>
        </div>
      </div>
      <hr className="border-solid" />
    </>
  );
};

export default HomePageNav;
