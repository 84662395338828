import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { db } from "../../config/firebase";
import { useAuth } from "../../contexts/AuthContext";
import "../../styles/signup.css";
// import { states_districts, states_hash } from "../../assets/states";

const FinishProfile = () => {

  const { currUserAdminInfo, currUser } = useAuth();
  console.log("currUser:", currUser)

  // const [credentials, setCredentials] = useState({
  //   uid: currUser.uid,
  //   email: currUser.email,
  //   dateCreated: firebase.firestore.Timestamp.now(),
  // });
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault(); //prevents form from refreshing
    // findLegislators().then((reps) => {
    //   console.log(credentials, reps);
    //   if (reps.length === 3)
        // db.collection("legislators")
        //   .where("uid", "==", currUser.uid)
        //   .set({
        //     ...credentials,
        //     // districtRep: reps[0],
        //     // senateRep: [reps[1], reps[2]],
        //   })
        //   .then(() => {
            var cred = firebase.auth.EmailAuthProvider.credential(currUser.email, oldPassword);
            currUser.reauthenticateWithCredential(cred).then(() => {
              currUser.updatePassword(newPassword).then(() => {
                db.collection("users").doc(currUser.uid).update({profileFinished: true}).then(() => {
                  console.log("pushing to leg inbox")
                  window.location.reload();
                  history.push("/inbox/issues/unresolved");
                })
              })
            })
          // })
          .catch((e) => setErr(e));
      // else
      //   db.collection("organizations")
      //     .doc(currUser.uid)
      //     .set({ ...credentials, districtRep: reps[0] })
      //     .then((res) => {
      //       currUser.updatePassword(newPassword).then(() => {
      //         db.collection("users").doc(currUser.uid).set({profileFinished: true}).then(() => {
      //           history.push("/org/home");
      //         })
      //       })
      //     })
      //     .catch((e) => setErr(e));
      setErr("");
      setLoading(false);
    // })
  }

  function handleOldPasswordChange(e) {
    setOldPassword(e.target.value)
  }

  function handleNewPasswordChange(e) {
    setNewPassword(e.target.value)
  }

  if (currUserAdminInfo.profileFinished) {
    return <Redirect to="/inbox/issues/unresolved" />;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="mx-auto w-11/12 py-5 md:py-10 md:w-10/12">
        <h1 className="text-4xl font-semibold">
          Complete your profile!
        </h1>

        <div className="mt-6">
          <form
            action="#"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit}
          >
            <div className="mb-10">
              {/* <h1 className="text-2xl font-semibold mb-8">Password</h1> */}
              <div className="mb-6">
                <label
                  for="oldPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Old password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    type="text"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    placeholder="Enter your old temporary password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  for="newPassword"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  New password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="newPassword"
                    name="newPassword"
                    type="text"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    placeholder="Enter new password"
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              {/* <div className="mb-6">
                <label
                  for="firstName"
                  className="block text-sm font-medium leading-5 text-gray-700 text-xl mb-2"
                >
                  Confirm new password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={credentials.firstName}
                    onChange={handleChange}
                    required
                    className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div> */}
            </div>

            <div>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn-primary focus:outline-none"
                >
                  Submit
                </button>
            </div>
          </form>
          {err && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes! </strong>
              <span className="block sm:inline">{err}</span>
              {/* <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </span> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinishProfile;
