import React, { useEffect, useState } from "react";
import EventCard from "../../org/global/events/EventCard";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase";

const PastEvents = () => {
	const { currUserRef } = useAuth();

	let [pastEvents, setPastEvents] = useState(
		<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>
	);

	useEffect(async () => {
		currUserRef
			.collection("events")
			.get()
			.then((events) => {
				if (events.docs && events.docs.length != 0) {
					let result = events.docs.filter((doc) => {
						let dateSeconds = doc.data().mainStartTimeUTC.seconds;
						let nowSeconds = firebase.firestore.Timestamp.now()
							.seconds;
						return dateSeconds < nowSeconds;
					});

					if (result.length !== 0) {
						setPastEvents(
							result.map((doc, i) => {
								return (
									<EventCard
										key={i}
										eventId={doc.id}
										title={doc.data().eventTitle}
										going={doc.data().numAttending}
										interested={doc.data().numInterested}
										date={doc.data().mainStartTimeUTC}
										time={
											doc.data().mainStartTimeString +
											" " +
											doc.data().timeZone
										}
										organizer={doc.data().organizerName}
										isVirtual={doc.data().isVirtual}
										location={`${doc.data().city}, ${
											doc.data().state
										}`}
									/>
								);
							})
						);
					} else {
						setPastEvents(
							<h1 className="px-8 py-8 font-medium text-xl">
								You currently have no past events.
							</h1>
						);
					}
				} else {
					setPastEvents(
						<h1 className="px-8 py-8 font-medium text-xl">
							You currently have no past events.
						</h1>
					);
				}
			});
	}, []);

	return (
		<>
			<div className="bg-white shadow overflow-hidden sm:rounded-md">
				<div className="divide-y divide-gray-200">{pastEvents}</div>
			</div>
		</>
	);
};

export default PastEvents;
