import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./OrgPrivateRoute";

import UpcomingEventsPage from "../../pages/org/events/UpcomingEventsPage";
import PastEventsPage from "../../pages/org/events/PastEventsPage";
import CreateEventPage from "../../pages/org/events/CreateEventPage";
import EventDetailsPage from "../../pages/org/events/EventDetailsPage";
import EventSignupsPage from "../../pages/org/events/EventSignupsPage";
import ReshareEventPage from "../../pages/org/events/ReshareEventPage";

const HomeRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				path="/org/events/upcoming"
				component={UpcomingEventsPage}
			/>
			<PrivateRoute path="/org/events/past" component={PastEventsPage} />
			<PrivateRoute
				path="/org/events/create"
				component={CreateEventPage}
			/>
			<PrivateRoute
				path="/org/events/:event/details"
				component={EventDetailsPage}
			/>
			<PrivateRoute
        path="/org/events/:event/signups"
        component={EventSignupsPage}/>
			<PrivateRoute
				path="/org/events/:event/reshare"
				component={ReshareEventPage}
			/>
		</Switch>
	);
};

export default HomeRoutes;
