export const states_hash = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  // FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  // MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const states_districts = {
  LA: 6,
  NC: 13,
  AL: 7,
  CA: 53,
  GA: 14,
  TX: 36,
  MI: 14,
  NV: 4,
  ND: "At-Large",
  IA: 4,
  NE: 3,
  IN: 9,
  OH: 16,
  KY: 6,
  VA: 11,
  AZ: 9,
  FL: 27,
  UT: 4,
  OR: 5,
  DE: "At-Large",
  IL: 18,
  PA: 18,
  NY: 27,
  MD: 8,
  CO: 7,
  TN: 9,
  HI: 2,
  WY: "At-Large",
  RI: 2,
  MA: 9,
  MO: 8,
  SC: 7,
  OK: 5,
  CT: 5,
  MN: 8,
  AR: 4,
  KS: 4,
  WA: 10,
  WI: 8,
  ID: 2,
  MT: "At-Large",
  ME: 2,
  PR: "At-Large",
  NJ: 12,
  MS: 4,
  NM: 3,
  SD: "At-Large",
  NH: 2,
  WV: 3,
  DC: "At-Large",
  VI: "At-Large",
  AS: "At-Large",
  MP: "At-Large",
  GU: "At-Large",
  VT: "At-Large",
  AK: "At-Large",
};

export const allStates = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  // "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  // "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
