import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import firebase from "firebase";

const NoteCard2 = (props) => {
  // const initial = props.upvotedNotes.includes(props.id);
  // let upvotes = props.upvotes;
  // if (initial) upvotes--;

  // const [upvoted,setUpvoted] = useState(upvotes);
  const formatDate = (timestamp) => {
    let date = new Date(timestamp.seconds * 1000);
    // console.log("date:", date.getMonth())

    let dateMonth = date.getMonth() + 1
    let dateStr =
    dateMonth < 10 ? "0" + dateMonth : dateMonth;
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);
    return dateStr;
  };

  // useEffect(() => {
  //   props.upvotedNotesRef.doc(props.id).update({upvotes: upvotes + upvoted})
  //   if (upvoted) {
  //     props.upvotedNotesRef.doc(props.id).set({});
  //   } else {
  //     props.upvotedNotesRef.doc(props.id).delete();
  //   }
  // }, [upvoted]);

  return (
    <Link
      to={`/org/issues/${props.issueId}/notes/${props.noteId}`}
      className="w-full flex-none mr-10 mb-10"
    >
      <div className="flex-none h-80 bg-yellow-300 shadow-sm hover:shadow-md px-8 py-10">
        <h1 className="font-bold text-xl truncate mb-2">
          {props.noteTitle}
        </h1>
        <div className="mb-1"></div>
        <Link to={`/org/users/${props.creatorId}/issues`} className="font-medium text-md hover:underline">
          {props.createdBy}
        </Link>

        <h1 className="font-regular text-sm text-gray-800 mt-4 mb-6 h-32 overflow-ellipsis overflow-hidden">
          {props.noteBody}
        </h1>

        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xs">
            {props.dateCreated ? formatDate(props.dateCreated) : "Na/Na/Na"}
          </h1>

          {/* <button className = {`flex items-center bg-gray-100 hover:bg-white font-semibold text-xs px-3 py-1 rounded-md text-gray-900 ${upvoted ? "text-red-500":""}`}
                  onClick={()=>setUpvoted(!upvoted)}
                  >
            <svg
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
            <span>{upvotes + upvoted}</span>
          </button> */}
        </div>
      </div>
    </Link>
  );
};

export default NoteCard2;
