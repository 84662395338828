import React from 'react';
import {NavLink} from "react-router-dom";

const SenateNav = (props) => {

    return(
      <div className="flex items-center justify-between mb-8 flex-wrap sm:flex-no-wrap">
        <div className="flex space-x-8 bg-gray-100 px-8 py-5 rounded-lg">
            <NavLink to="/bills-votes/senate/upcoming" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" exact activeClassName="text-gray-900">
            Upcoming
            </NavLink>
            <NavLink to="/bills-votes/senate/introduced" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
            Introduced
            </NavLink>
            <NavLink to="/bills-votes/senate/past" className=" whitespace-no-wrap text-2xl font-bold leading-tight text-gray-400 hover:underline" activeClassName="text-gray-900">
            Past
            </NavLink>
        </div>
        <div className="ml-4 flex-shrink-0">
          {/* <div>
          <label for="location" className="block text-sm leading-5 font-medium text-justiceBlue-500">Sort By</label>
          <select id="location" onChange={(e) => {props.setSort(e.target.value)}} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md">
              <option selected>None</option>
              <option>Date Introduced</option>
              <option>Number of Cosponsors</option>
              <option>Number of Issues</option>
          </select>
          </div> */}
        </div>
      </div>
    );


}


export default SenateNav;
