import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function PrivateRoute({ component: Component, ...rest }) {
  const { currUserAdminInfo,  currUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currUser ? (
          (currUserAdminInfo.type === "civilian" ?
          (currUser.emailVerified ? (
            (currUserAdminInfo.profileFinished ?
            <Component {...props} />
            :
            <Redirect to="/finish-profile" />)
          ) : (
            <Redirect to="/verify" />
          )) : <Redirect to="/error" />
          )
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
}

export default PrivateRoute;
