import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const SupportersNav = () => {

  const {pathname} = useLocation();

  return (
    <>
      <header className="sm:px-8 lg:px-16 py-6 shadow-md">
        <h1 className="text-3xl font-bold">Browse</h1>
      </header>
      <div className="mb-px py-7 mx-auto sm:px-8 lg:px-16 flex justify-between items-center">

        <div className="flex space-x-8">
          <NavLink
            to="/org/browse/state/issues"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            exact
            activeClassName="text-gray-900"
            isActive={() => ["/org/browse/state/issues", "/org/browse/nation/issues"].includes(pathname)}
          >
            Issues
          </NavLink>
          <NavLink
            to="/org/browse/state/events"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
            isActive={() => ["/org/browse/state/events", "/org/browse/nation/events"].includes(pathname)}
          >
            Events
          </NavLink>
          <NavLink
            to="/org/browse/state/organizations"
            className=" whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline"
            activeClassName="text-gray-900"
            isActive={() => ["/org/browse/state/organizations", "/org/browse/nation/organizations"].includes(pathname)}
          >
            Organizations
          </NavLink>
        </div>

      </div>
      <hr className="border-solid" />
    </>
  );
};

export default SupportersNav;
