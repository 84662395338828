import React, { useState, useEffect } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { db } from "../../../config/firebase";
import { useAuth } from "../../../contexts/AuthContext";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import "@pathofdev/react-tag-input/build/index.css";
import firebase from "firebase";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import legislators from "../../../assets/jsonData/legislators.json"

function CreateNote({ match }) {
    let history = useHistory();

    const [allNotesRef, setAllNotesRef] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [issueId, setIssueId] = useState("");
    const [issueInfo, setIssueInfo] = useState({});
    const { currUser, currUserRef, userData } = useAuth();
    const [status, setStatus] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [contactType, setContactType] = useState("email");
    const [emailSubjectText, setEmailSubjectText] = useState("");
    const [subjectCopied, setSubjectCopied] = useState(false);
    const [emailContentText, setEmailContentText] = useState("");
    const [contentCopied, setContentCopied] = useState(false);
    const [callScriptText, setCallScriptText] = useState("");
    const [issueCreatorName, setIssueCreatorName] = useState({});
    const [noteCreatorName, setNoteCreatorName] = useState({});

    useEffect(async () => {
        if (match && match.params.issue) {
            let issueId = match.params.issue;

            if (!issueId) {
                setError("Bad link. No issue id given.");
            } else {
                setIssueId(issueId);
                setAllNotesRef(
                    db.collection("issues").doc(issueId).collection("notes")
                );
                // console.log(issueId);
                const issueRef = await db.collection("issues").doc(issueId).get();
                // console.log("issueRef.data():", issueRef.data())
                setIssueInfo(issueRef.data());

                // console.log("start");
                const issueCreatorRef = await db.collection("civilians").doc(issueRef.data().createdBy).get();
                const issueCreatorData = issueCreatorRef.data();
                // console.log(issueCreatorData);
                const issueCreatorFullName = issueCreatorData.firstName + " " + issueCreatorData.lastName;
                // console.log("end");
                setIssueCreatorName(issueCreatorFullName);

                const noteCreatorRef = await db.collection("civilians").doc(currUser.uid).get();
                const noteCreatorData = noteCreatorRef.data();
                const noteCreatorFullName = noteCreatorData.firstName + " " + noteCreatorData.lastName;
                setNoteCreatorName(noteCreatorFullName);

            }

            setLoading(false);
        }
    }, []);

    const[note, setNote] = useState({
        createdBy: currUser.uid,
        noteTitle: "",
        noteBody: "",
        upvotes: 0
    });


    function handleChange(e) {
        setNote({ ...note, [e.target.name]: e.target.value });
    }

    async function handleSubmit(e) {
        e.preventDefault(); //prevents form from refreshing
        setLoading(true);

        const res = await db.collection("notes").add({
            ...note,
            issue:{
            issueTitle: issueInfo.issueTitle,
            id: issueId
            },
            dateCreated: firebase.firestore.Timestamp.now(),
        });
        await currUserRef
          .collection("notes")
          .doc(res.id)
          .set({
            ...note,
            issue:{
                issueTitle: issueInfo.issueTitle,
                id: issueId
            },
            dateCreated: firebase.firestore.Timestamp.now(),
            noteId: res.id
          });

        await allNotesRef
          .doc(res.id)
          .set({
            ...note,
            issue:{
                issueTitle: issueInfo.issueTitle,
                id: issueId
            },
            createdByName: noteCreatorName,
            dateCreated: firebase.firestore.Timestamp.now(),
            noteId: res.id
          });

        await db.collection("issues")
            .doc(issueId)
            .update({
                supporters: firebase.firestore.FieldValue.increment(1),
                noteId: res.id
            });
        await db.collection("civilians").doc(currUser.uid).update({noteCount: firebase.firestore.FieldValue.increment(1)})

        setEmailSubjectText(issueInfo.issueTitle)
        setEmailContentText(`
          My name is ${userData.firstName} and I am one of your constituents.
          I am writing to you because I would like you to ${issueInfo.issueTitle}.
          ${note.noteTitle}. ${note.noteBody}
          I signed an online petition regarding this issue; please visit grassrootsgov.org to view the petition along with other constituents' notes of support.
          Thank you!
          `
        )
        setCallScriptText(`
          Hello, my name is ${userData.firstName} and I am one of your constituents.
          I am calling you today to ask that you ${issueInfo.issueTitle}.
          ${note.noteTitle}. ${note.noteBody}
          I signed an online petition regarding this issue; please visit grassrootsgov.org to view the petition along with other constituents' notes of support.
          Thank you!
        `)

        setStatus({ success: "You have successfully submitted the note!" });
        setLoading(false);
        // history.push(`/issues/${issueId}/notes`);
        setSubmitted(true);
    }



    return (
        <AppLayout>

          {submitted &&
            <div className="min-h-screen fixed h-full w-full top-0 left-0 bg-gray-700 bg-opacity-30 z-50">
              {/* <div className="absolute top-1/2 left-1/2 w-3/4 h-3/4 -mt-36 -ml-96"> */}
              <div className="absolute w-full h-full flex flex-col justify-center items-center text-center">
              {/* <div className="flex flex-col justify-center"> */}
                <div className="bg-gray-100 w-3/4 h-4/5 px-40 py-20 rounded-lg shadow">

                  <h1 className="flex justify-center text-4xl font-semibold mb-8">
                    Share this note with your legislators!
                  </h1>

                  <div className="text-left text-lg">

                    <div className="flex justify-between items-center mb-8">
                      <h1 className="text-xl font-medium">
                        Your note has been posted. Tell your legislator(s) about it:
                      </h1>
                      <span className="relative z-0 inline-flex bg-white shadow-sm rounded-md border border-gray-200">
                        <span className="flex space-x-1 rounded-md border-2 border-white">
                          <button
                            type="button"
                            className={`relative inline-flex items-center px-4 py-2 rounded-md ${(contactType === "email") ? "bg-justiceBlue-500 text-white hover:bg-justiceBlue-500" : "bg-white text-gray-700 hover:bg-gray-50"} text-sm font-medium focus:z-10 focus:outline-none`}
                            onClick={() => (setContactType("email"))}
                          >
                            Email
                          </button>
                          <button
                            type="button"
                            className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-md ${(contactType === "call") ? "bg-justiceBlue-500 text-white hover:bg-justiceBlue-500" : "bg-white text-gray-700 hover:bg-gray-50"} text-sm font-medium focus:z-10 focus:outline-none`}
                            onClick={() => (setContactType("call"))}
                          >
                            Call
                          </button>
                        </span>
                      </span>
                    </div>

                    {(contactType === "email") &&
                      <div className="h-96 bg-white rounded-lg px-6 py-6 mb-8 overflow-y-scroll">
                        <div className="text-xl text-justiceBlue-600 mb-4 font-medium">Contact form:</div>
                          <a href={legislators[issueInfo.legislator.id]["contactForm"] ? legislators[issueInfo.legislator.id]["contactForm"] : legislators[issueInfo.legislator.id]["officialUrl"]} className="block hover:underline">{legislators[issueInfo.legislator.id]["contactForm"] ? legislators[issueInfo.legislator.id]["contactForm"] : legislators[issueInfo.legislator.id]["officialUrl"]}</a>
                        <div className="text-xl text-justiceBlue-600 mt-8 font-medium mb-4">Submission content: </div>

                        <div className="mb-2">
                          <span className="font-medium">Subject:</span> {emailSubjectText}
                        </div>
                        <div className="mb-6">
                          <CopyToClipboard
                            text={emailSubjectText}
                            onCopy={() => setSubjectCopied(true)}>
                            <button className="btn-white text-sm focus:outline-none">Copy to clipboard</button>
                          </CopyToClipboard>
                          {subjectCopied && <h1 className="text-xs text-gray-500 mt-2">Copied to clipboard.</h1>}
                        </div>

                        <div className="mb-2">
                          <span className="font-medium">Body:</span>
                        </div>
                        <div className="mb-2">
                          {emailContentText}
                        </div>
                        <div className="mb-2">
                          <CopyToClipboard
                            text={emailContentText}
                            onCopy={() => setContentCopied(true)}>
                            <button className="btn-white text-sm focus:outline-none">Copy to clipboard</button>
                          </CopyToClipboard>
                          {contentCopied && <h1 className="text-xs text-gray-500 mt-2">Copied to clipboard.</h1>}
                        </div>
                      </div>
                    }

                    {(contactType === "call") &&
                      <div className="h-96 bg-white rounded-lg px-6 py-6 mb-8 overflow-y-scroll">
                        <div className="text-xl text-justiceBlue-600 mb-4 font-medium">Phone number(s):</div>
                            <h1>{legislators[issueInfo.legislator.id]["officialPhone"]}</h1>
                        <div className="text-xl text-justiceBlue-600 mt-8 font-medium mb-4">Call script: </div>
                        <div>
                          {callScriptText}
                        </div>
                      </div>
                    }

                  </div>

                  <div className="flex justify-center space-x-4">
                    <button
                      disabled={loading}
                      onClick={() => {history.goBack();}}
                      className="btn-primary"
                    >
                      Continue
                    </button>
                    {/* <button className="btn-white" onClick={() => {setSubmitted(false)}}>Cancel</button> */}
                  </div>

                </div>

              </div>
            </div>
          }

            <div className="mb-px py-8 mx-4 sm:px-8 lg:px-16">

            <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
              <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
              <h1>Back</h1>
            </button>

                <h1 className="mt-6 whitespace-no-wrap text-4xl font-bold leading-tight text-gray-900 mb-5">
                Create New Note
                </h1>

                <form
                action="#"
                method="POST"
                class="space-y-8"
                onSubmit={handleSubmit}
                >
                <div>
                    <p className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900 mb-3">
                    Issue
                    </p>
                    <Select
                    className="border-0"
                    value={{value: issueId, label: issueInfo.issueTitle}}
                    isDisabled={true}
                    />
                </div>

                <div className="mt-4">
                    <div className="flex items-center mb-5">
                      <label
                      for="noteTitle"
                      className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
                      >
                      Note Title
                      </label>
                      <div className="has-tooltip flex items-center">
                        <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                          Enter your main reason for supporting this issue.
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </div>
                    <input
                    id="noteTitle"
                    name="noteTitle"
                    placeholder="Enter note title here"
                    type="text"
                    value={note.noteTitle}
                    maxLength="100"
                    onChange={handleChange}
                    required
                    className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <h1 className="text-gray-500 float-right mt-2">
                    {note.noteTitle.length}/100 characters
                    </h1>
                </div>

                <div className="mt-4">
                    <div className="flex items-center mb-5">
                      <label
                      for="noteBody"
                      className="block whitespace-no-wrap text-2xl font-bold leading-tight text-gray-900"
                      >
                      Note Body
                      </label>
                      <div className="has-tooltip flex items-center">
                        <div className="tooltip ml-14 px-3 py-1 bg-justiceBlue-600 text-white rounded">
                          Write a sentence or two explaining your reason(s) for supporting this issue!
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-5 h-6 w-6 text-justiceBlue-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </div>
                    <textArea
                    id="noteBody"
                    name="noteBody"
                    placeholder="Enter note body here"
                    type="text"
                    value={note.noteBody}
                    maxLength="1000"
                    onChange={handleChange}
                    required
                    rows={6}
                    className="shadow-sm focus:ring-justiceBlue-500 focus:border-justiceBlue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <h1 className="text-gray-500 float-right mt-2">
                    {note.noteBody.length}/1000 characters
                    </h1>
                </div>

                {status.error && (
                    <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                    >
                    <strong className="font-bold">Holy smokes! </strong>
                    <span className="block sm:inline">{status.error}</span>
                    </div>
                )}
                {status.success && (
                    <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                    role="alert"
                    >
                    <span className="block sm:inline">{status.success}</span>
                    </div>
                )}
                <div >
                    <button
                    disabled={loading}
                    type="submit"
                    className="btn-primary"
                    >
                    Post Note
                    </button>
                </div>
                </form>
            </div>
        </AppLayout>
    );
};

export default CreateNote;
