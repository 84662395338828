import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout"
import { db } from "../../config/firebase"
import {Link} from "react-router-dom";

const AdminOrgSignupsPage = () => {

  const [loading, setLoading] = useState(true);
  const [pendingCount, setPendingCount] = useState(0);
  const [createdCount, setCreatedCount] = useState(0);
  const [pendingAccounts, setPendingAccounts] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>)
  const [createdAccounts, setCreatedAccounts] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>)

  const formatDate = (timestamp) => {
    if (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let uf_dateStr = date.toLocaleDateString(); // Unformatted date string: Converts to MM/DD/YYYY
      let yearStr = uf_dateStr.substring(uf_dateStr.length - 4); // 4 digit year
      yearStr = yearStr.substring(2, 4); // Get the last two digits of the year
      uf_dateStr = uf_dateStr.substring(0, uf_dateStr.length - 4); // Becomes MM/DD/
      let f_dateString = uf_dateStr + yearStr; // Formatted date string: MM/DD/YY
      return f_dateString;
    } else {
      // if there isn't a date associated with the issue
      return "01/01/1979";
    }
  };

  useEffect(async () => {

    const tempPendingAccounts = await db.collection("orgLegAccountRequests").where("accountType", "==", "organization").where("accountCreated", "==", false).get();

    if (tempPendingAccounts.docs && tempPendingAccounts.docs.length > 0){

      setPendingCount(tempPendingAccounts.docs.length)

      setPendingAccounts(tempPendingAccounts.docs.map((doc) => (
        <tr>
          <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {doc.data().entityName}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().email}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().websiteLink}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().personName}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {formatDate(doc.data().submissionDate)}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <Link to={`/admin/org/create-account/${doc.id}`} class="text-justiceBlue-600 hover:text-justiceBlue-900">Create Account</Link>
          </td>
        </tr>
      )))
    }
    else {
      setPendingAccounts(<h1 className="px-8 py-8 font-medium text-lg">There are no organization accounts pending approval at this time.</h1>)
    }

    const tempCreatedAccounts = await db.collection("orgLegAccountRequests").where("accountType", "==", "organization").where("accountCreated", "==", true).get();

    if (tempCreatedAccounts.docs && tempCreatedAccounts.docs.length > 0){

      setCreatedCount(tempCreatedAccounts.docs.length)

      setCreatedAccounts(tempCreatedAccounts.docs.map((doc) => (
        <tr>
          <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {doc.data().entityName}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().email}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().websiteLink}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {doc.data().personName}
          </td>
          <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
            {formatDate(doc.data().submissionDate)}
          </td>
        </tr>
      )))
    }
    else {
      setCreatedAccounts(<h1 className="px-8 py-8 font-medium text-lg">There are no organization accounts that have been created at this time.</h1>)
    }

    setLoading(false);

  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
      <AdminLayout>
        <div className="mb-px py-8 px-12">

          <div className="flex justify-between items-end mb-8">
            <h1 className="text-2xl font-semibold">Pending Accounts</h1>
            <h2 className="text-lg font-medium">{pendingCount} accounts</h2>
          </div>

          <div class="flex flex-col max-w-full">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Website Link
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name of Person
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date of Submission
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Create Account</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {pendingAccounts}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-end mt-20 mb-8">
            <h1 className="text-2xl font-semibold">Created Accounts</h1>
            <h2 className="text-lg font-medium">{createdCount} accounts</h2>
          </div>

          <div class="flex flex-col max-w-full">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Website Link
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name of Person
                        </th>
                        <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date of Submission
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {createdAccounts}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </AdminLayout>
  )
}

export default AdminOrgSignupsPage;
