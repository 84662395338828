import React, { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import HomePageNav from "../../../components/civ/home/HomeNav";
import HomeIssuesNav from "../../../components/civ/home/issues/HomeIssuesNav";
import HomeIssues from "../../../components/civ/home/issues/HomeIssues";

const HomeIssuesPage = ({ match }) => {
	// const [status, setStatus] = useState("active");
	const [sortBy, setSortBy] = useState("dateCreated");

	// useEffect(() => {
	//   console.log(match.params.status)
	//   setStatus(match.params.status);
	// }, [match])

	return (
		<AppLayout>
			<HomePageNav />
			<div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
				<HomeIssuesNav sortBy={sortBy} setSortBy={setSortBy} />

				<HomeIssues status={match.params.status} sortBy={sortBy} />
			</div>
		</AppLayout>
	);
};

export default HomeIssuesPage;
