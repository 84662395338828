import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { db } from "../../../config/firebase"
import firebase from "firebase";

function DashboardTrending(props) {

  const [trendingIssues, setTrendingIssues] = useState([]);
  const [trendingEvents, setTrendingEvents] = useState([]);

  useEffect(async () => {

    var date = new Date();
    date.setDate(date.getDate()-30);
    let oneWeekAgo = firebase.firestore.Timestamp.fromDate(date)

    // let tempIssues = await db.collection("issues").orderBy("supporters", "desc").limit(5).get()
    let tempIssues = await db.collection("issues").where("active", "==", true).where("dateCreated", ">", oneWeekAgo).get()
    let finalIssues = tempIssues.docs.sort((a,b) => (a.data().supporters < b.data().supporters) ? 1 :  -1)
      // .filter((doc) => {
      //   return(doc.data().active == true)
      // })
    console.log("final issues:", finalIssues)
    setTrendingIssues(finalIssues.slice(0, 5))

    // let tempEvents = await db.collection("events").orderBy("numAttending", "desc").limit(5).get()
    let tempEvents = await db.collection("events").where("dateCreated", ">", oneWeekAgo).get()
    let finalEvents = tempEvents.docs.sort((a,b) => (a.data().numAttending < b.data().numAttending) ? 1 :  -1)
      // .filter((doc) => {
      //   return(doc.data().active == true)
      // })
    setTrendingEvents(finalEvents.slice(0, 5))

  }, [])

  return (
    <div className="grid grid-cols-2 space-x-8">

      <div>
        <h1 className="text-2xl font-bold mb-6">Trending Issues</h1>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="divide-y divide-gray-200">

            {trendingIssues &&
            trendingIssues.map((issue) =>
              <Link
                to={`/org/issues/${issue.id}/notes`}
                className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out divide-y divide-gray-200">
                <div className="py-4 px-6">
                  <div className="flex justify-between items-center mb-3">
                    <h1 className="text-lg font-semibold">{issue.data().issueTitle}</h1>
                    <h1 className="text-lg font-medium">{issue.data().supporters} {issue.data().supporters === 1 ? "supporter" : "supporters"}</h1>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2 items-center">
                      <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      <h2>{`${issue.data().legislator.firstName} ${issue.data().legislator.lastName} (${issue.data().legislator.party}-${issue.data().legislator.region})`}</h2>
                    </div>
                    <Link className="flex items-center text-red-500 font-medium">
                      View issue
                      <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </Link>
            )}

            </div>
        </div>

      </div>

      <div>
        <h1 className="text-2xl font-bold mb-6">Trending Events</h1>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="divide-y divide-gray-200">

            {trendingEvents &&
            trendingEvents.map((event) =>
              <Link
                to={`/org/events/${event.id}/details`}
                className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out divide-y divide-gray-200">
                <div className="py-4 px-6">
                  <div className="flex justify-between items-center mb-3">
                    <h1 className="text-lg font-semibold">{event.data().eventTitle}</h1>
                    <h1 className="text-lg font-medium">{event.data().numAttending} attending</h1>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                      <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                      <h2>{event.data().organizerName}</h2>
                    </div>
                    <Link className="flex items-center text-red-500 font-medium">
                      View event
                      <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </Link>
            )}

            </div>
        </div>

      </div>

    </div>
  )
}

export default DashboardTrending;
