import React, { useEffect, useState } from 'react';
import { db } from '../../../config/firebase';
import OrgLayout from "../../../layouts/OrgLayout"
import { useAuth } from "../../../contexts/AuthContext";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import SupportersNav from "../../../components/org/supporters/SupportersNav";

function SupportersOverviewPage(props) {

  const formatDate = (timestamp) => {
    let date = new Date(timestamp.seconds * 1000);
    let month = date.getMonth() + 1;
    let dateStr = month < 10 ? "0" + month : month;
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);

    return dateStr;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'name', headerName: 'NAME', width: 290 },
    { field: 'email', headerName: 'EMAIL', width: 300 },
    { field: 'zipCode', headerName: 'ZIP CODE', width: 120 },
    { field: 'totalRSVP', headerName: 'TOTAL RSVP\'S', width: 160 },
    { field: 'firstRSVP', headerName: 'FIRST RSVP', width: 140},
    { field: 'recentRSVP', headerName: 'MOST RECENT RSVP', width: 200}
  ];

  const { currUser, userData } = useAuth();
  const [dataRows, setDataRows] = useState([]);

  useEffect(async () => {
    const currOrgRef = await db.collection("organizations").doc(currUser.uid).collection("eventAttendees").get();
    // let rowsTemp = [];
    // let docId = 0;

    if (!currOrgRef.empty) {
      Promise.all(currOrgRef.docs.map((doc, i) => {
        console.log(doc.data());
        return({id: doc.id, name: doc.data().name, email: doc.data().email, zipCode: doc.data().zipCode, totalRSVP: doc.data().totalRSVP, firstRSVP: formatDate(doc.data().firstRSVP), recentRSVP: formatDate(doc.data().recentRSVP) });
        // docId = docId + 1;
      })).then((result) => {
        setDataRows(result);
      })
    } else {
      console.log("empty snapshot");
    }

    // setDataRows(rowsTemp);
  }, []);



  return (
    <OrgLayout>
      <SupportersNav />

      <hr className="border-solid" />

      <div className="mt-10 ml-16 mr-16">
        <div style={{ height: 340, width: '100%' }}>
          {dataRows && console.log("ROWS:", dataRows)}
          {dataRows && <DataGrid rows={dataRows} columns={columns} pageSize={5} components={{Toolbar: CustomToolbar}}/>}
        </div>
      </div>
    </OrgLayout>
  );
}

export default SupportersOverviewPage;

