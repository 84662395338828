import React, { useEffect, useState } from 'react';
import { db } from '../../../config/firebase';
import OrgLayout from "../../../layouts/OrgLayout"
import SharePopup from '../../../components/org/global/SharePopup.js'
import MyEventNav from '../../../components/org/events/MyEventNav'
import BrowseEventNav from '../../../components/org/events/BrowseEventNav'
import { useAuth } from "../../../contexts/AuthContext";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';


const EventSignupsPage = ({ match, history }) => {

  const formatDate = (timestamp) => {
    let date = new Date(timestamp.seconds * 1000);
    let month = date.getMonth() + 1;
    let dateStr = month < 10 ? "0" + month : month;
    dateStr += "/";
    dateStr += date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    dateStr += "/" + date.getFullYear().toString().substr(-2);

    return dateStr;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'name', headerName: 'NAME', width: 400 },
    { field: 'email', headerName: 'EMAIL', width: 400 },
    { field: 'zipCode', headerName: 'ZIP CODE', width: 200 },
    { field: 'rsvpDate', headerName: 'RSVP DATE', width: 200}
  ];

  const { currUser, userData } = useAuth();
  const [eventData, setEventData] = useState({});
  const [eventTimes, setEventTimes] = useState([]);
  const [isMyEvent, setIsMyEvent] = useState(false);
  const [signups, setSignups] = useState([]);
  const [rows, setRows] = useState([]);
  const [sharePopup, setSharePopup] = useState(false);
  let eventId = match.params.event;

  useEffect(async () => {
    const currEventRef = db.collection('events').doc(eventId);
    const eventDoc = await currEventRef.get();
    if (!eventDoc.exists) {
      // setError('This event  was not found.');
      console.log('no event doc exists');
    } else {
      setEventData(eventDoc.data());
      if (currUser.uid === eventDoc.data().organizerId){
        setIsMyEvent(true)
      }
    }

    const currEventSignupsSnapshot = await db.collection('events').doc(eventId).collection("signups").get();
    let rowsTemp = [];
    let id = 0;
    if (!currEventSignupsSnapshot.empty) {
      Promise.all(currEventSignupsSnapshot.docs.map((doc) => {
        console.log(doc.data());
        return({id: doc.id, name: doc.data().name, email: doc.data().email, zipCode: doc.data().zipCode, rsvpDate: (formatDate(doc.data().rsvpDate)).toString() })
        // id = id + 1;
      }))
      .then((result) => {
        setRows(result)
      })
      // setRows(rowsTemp);
    } else {
      console.log("empty snapshot");
    }
  }, []);

  function toggleSharePopup(){
    setSharePopup(!sharePopup)
    // console.log("toggled:", sharePopup)
  }

  return (
    <OrgLayout>

      {sharePopup &&
        <SharePopup
          toggleSharePopup={toggleSharePopup}
          url={`https://grassrootsgov.org/events/${match.params.event}`}
          type="event"
          >
        </SharePopup>
      }

      <div className='mb-px pt-8 pb-4 mx-auto sm:px-8 lg:px-16'>

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        {isMyEvent ?
          <MyEventNav
            title={eventData.eventTitle}
            eventId={match.params.event}
            toggleSharePopup={toggleSharePopup}
          /> :
          <BrowseEventNav
            title={eventData.eventTitle}
            eventId={match.params.event}
            organizer={eventData.organizerName}
            organizerId={eventData.organizerId}
            toggleSharePopup={toggleSharePopup}
          /> }
      </div>

      <hr className="border-solid" />

      {isMyEvent ?
      <div className="mt-10 ml-16 mr-16">
        <div style={{ height: 340, width: '100%' }}>
          {rows && <DataGrid rows={rows} columns={columns} pageSize={5} components={{Toolbar: CustomToolbar}}/>}
        </div>
      </div>
      : ""
      }

    </OrgLayout>
  );
};

export default EventSignupsPage;
