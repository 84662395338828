import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../../layouts/OrgLayout'
import { db } from '../../../../config/firebase'
import { Link } from "react-router-dom";
import LegislatorBillVoteComment from '../../../../components/org/global/legislators/LegislatorBillVoteCommentCard'
import { useAuth } from "../../../../contexts/AuthContext"
import houseCommittees from "../../../../assets/jsonData/houseCommittees.json"
import senateCommittees from "../../../../assets/jsonData/senateCommittees.json";
const axios = require("axios");

const config = {
  headers: {
    "X-API-Key": "CmXVdVQ0b6hZjXLtHqc6P5sRUBSv4L1pMFBMskWa",
  },
};

const BillPage = ({match, history}) => {

  const { currUser, currUserRef, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [billId, setBillId] = useState("");
  const [billData, setBillData] = useState({});

  const [committeeList, setCommitteeList] = useState([]);
  const [votesList, setVotesList] = useState([]);
  const [votesExpanded, setVotesExpanded] = useState(false);

  const [legislatorId, setLegislatorId] = useState("");
  const [legislatorComments, setLegislatorComments] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  // const [following, setFollowing] = useState(false);

  useEffect(async () => {
    if (match && match.params.bill) {

      const userState = userData.state;

      let tempBillId = match.params.bill;

      if (tempBillId.length == 0) {
        setError("Bad link. No bill ID given.");
      } else {
        setBillId(tempBillId);
        const tempBillData = await db.collection("bills").doc(tempBillId).get();
        setBillData(tempBillData.data());

        const billSlug = tempBillData.data().slug;
        const tempLegislatorId = tempBillData.data().sponsorId;
        setLegislatorId(tempLegislatorId);

        // console.log("tempBillData.chamber:", tempBillData.chamber)
        // console.log("tempBillData.committeeIds:", tempBillData.data().committeeIds)
        if (tempBillData.data().committeeIds.length > 0) {
          console.log("COMMITTEES:", tempBillData.data().committeeIds)
          setCommitteeList(tempBillData.data().committeeIds.map((committeeId) => {

            let committeeDataRef = houseCommittees
            let committeeChamber = "house"
            if (committeeId[0] == "S") {
              committeeDataRef = senateCommittees
              committeeChamber = "senate"
            }

            return (
              <Link to={`/org/legislators/committees/${committeeChamber}/${committeeId}`} className="col-start-3 col-end-4 text-lg hover:underline active:text-red-600">
                {/* <div className="flex items-center space-x-2"> */}
                  <h1>{committeeDataRef[committeeId].name}</h1>
                  {/* <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div> */}
              </Link>
            // </div>
            )
          }))
        }
        else{
          setCommitteeList(`This bill has not yet been assigned to any ${tempBillData.chamber} committees.`)
        }

        axios.get(`https://api.propublica.org/congress/v1/117/bills/${billSlug}.json`, config)
        .then((response) => {

          const tempVotesList = response.data.results[0].votes;
          // console.log("VOTES:", tempVotesList)

          setVotesList(tempVotesList.map((vote) =>
            <div className="grid grid-cols-9">
              <div className="col-start-1 col-end-2">
                {vote.date}
              </div>
              <div className="col-start-2 col-end-4">
                ({vote.result})
              </div>
              <div className="col-start-4 col-end-8">
                {vote.chamber} Vote #{vote.roll_call}: {vote.question}
              </div>
              <Link to={`/org/bills-votes/votes/${vote.chamber}-${vote.roll_call}-${tempBillId.split("-")[tempBillId.split("-").length-1]}`} className="col-start-8 col-end-10 text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600">
                <div className="flex items-center space-x-2">
                  <h1>View vote</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </Link>
            </div>
          ))
        })

        //TODO later: change .districtrep and .senaterep to representative and senators, legislatorIds to .legislatorIds
        // const legislatorIds = [userData.districtRep.id, userData.senateRep[0].id, userData.senateRep[1].id]
        const tempBillComments = await db.collection("bills").doc(tempBillId).collection("comments").limit(3).get();

        if (tempBillComments.docs && tempBillComments.docs.length > 0){
          // console.log('comments exist')
          // console.log("number of comments:", tempBillComments.docs.length)
          // console.log(tempBillComments.docs)
          setLegislatorComments(tempBillComments.docs.map((doc) =>
            <LegislatorBillVoteComment
              legislatorId={doc.data().legislatorId}
              legislatorName={doc.data().legislatorName}
              legislatorState={doc.data().legislatorState}
              legislatorDistrict={doc.data().legislatorDistrict ? doc.data().legislatorDistrict : "Senate"}
              legislatorParty={doc.data().legislatorParty}
              datePosted={doc.data().datePosted}
              body={doc.data().body}
              color="white"
            />
          ))
        }
        else{
          setLegislatorComments(
            <div className="bg-white px-2 py-1 rounded-lg flex space-x-2 items-center">
              <h1 className="px-8 py-8 font-medium text-xl">There are no comments regarding this bill from legislators at this time.</h1>
              <Link className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600" to={`/org/bills-votes/bills/${tempBillId}/related-issues`}>
                <div className="flex items-center space-x-2">
                  <h1>View related issues</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </Link>
            </div>
          )
        }

      }

    setLoading(false);
    }
  }, [match]);

  // const toggleFollowing = () => {
  //   setFollowing(!following);
  // }

  const toggleVotes = () => {
    setVotesExpanded(!votesExpanded);
  }

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  if (error) {
    return <AppLayout>{error}</AppLayout>;
  }

  return(
    <AppLayout>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">

        <button className="flex space-x-2 items-center focus:outline-none" onClick={() => {history.goBack();}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <h1>Back</h1>
        </button>

        <h1 className="text-3xl font-bold mt-5 mb-5">
          {billData.number}: {billData.title}
        </h1>

        <div className="flex justify-between">
          <div className="flex space-x-2">
            <Link className="btn-primary" to={`/org/bills-votes/bills/${billId}/related-issues`}>
              <div className="flex items-center space-x-2">
                <h1>View related issues</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
            {/* <Link className="btn-primary" to="/issues/create">
              + Create related issue
            </Link> */}
            <a className="btn-secondary" href={billData.congressGovUrl}>
              <div className="flex items-center space-x-2">
                <h1>View Congress.gov page</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </a>
          </div>
          <div className="flex space-x-2">
            {/* <button
              className={following ? "btn-primary focus:outline-none" : "btn-secondary focus:outline-none"}
              onClick={toggleFollowing}>
              {following ? "Unfollow bill" : "Follow bill"}
            </button> */}
          </div>
        </div>
      </div>

      <hr className="border-solid"/>

      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16 space-y-7">

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Date Introduced
          </div>
          <div class="col-start-2 pt-1">
            <h1 className="text-lg">
              {billData.dateIntroduced}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start mb-2">
          <div class="block text-2xl text-right font-medium">
            Sponsor
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <div className="flex space-x-6 items-center">
              <Link to={`/org/legislators/${legislatorId}/votes`}>
                <div className="text-lg bg-gray-500 hover:bg-gray-400 active:bg-gray-600 rounded-lg px-4 py-1 text-white">
                  {billData.sponsorName} ({billData.sponsorParty}-{billData.sponsorState})
                </div>
              </Link>
              <Link
                className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600"
                to={`/org/bills-votes/bills/${billId}/cosponsors`}>
                + {billData.cosponsorCount} co-sponsors {billData.cosponsorCount > 0 ? `(${billData.cosponsorsByParty.D} D, ${billData.cosponsorsByParty.R} R)` : ""}
              </Link>
            </div>
          </div>
        </div>

        <hr className="border-solid mb-2"/>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Current Status
          </div>
          {/* <div class="col-start-2 col-end-4 pt-1"> */}
            {(() => {
              switch (billData.billStatus) {
                case "Introduced":
                  return(<h1 className={billData.upcoming ? "col-start-2 col-end-4 pt-1 bg-yellow-500 rounded-full max-w-xs px-4 py-1 text-lg text-white text-center" : "col-start-2 col-end-3 pt-1 bg-yellow-500 rounded-full max-w-xs px-4 py-1 text-lg text-white text-center"}>{billData.upcoming ? billData.billStatus + " (Upcoming)" : billData.billStatus}</h1>)
                case "Past":
                  return(<h1 className="col-start-2 col-end-3 pt-1 bg-indigo-500 rounded-full max-w-xs px-4 py-1 text-lg text-white text-center">{billData.billStatus}</h1>)
                default:
                  return(<h1 className="col-start-2 col-end-3 pt-1 bg-gray-500 rounded-full px-4 py-1 text-lg text-white">{billData.billStatus}</h1>)
              }
            })()}
          {/* </div> */}
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Latest Action
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <h1 className="text-lg">
              {"(" + billData.latestActionDate + ") " + billData.latestActionDescription}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Summary
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <h1 className="text-lg">
              {billData.description ? billData.description : "N/A"}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start mb-2">
          <div class="block text-2xl text-right font-medium">
            Topics
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <h1 className="text-lg">
              {billData.topic ? billData.topic : "N/A"}
            </h1>
          </div>
        </div>

        <hr className="border-solid mb-2"/>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Committees
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <h1 className="text-lg">
              {committeeList}
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Amendments
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <Link
              className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600"
              to={`/org/bills-votes/bills/${billId}/amendments`}>
              <div className="flex items-center space-x-2">
                <h1>View amendments to this bill</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start mb-2">
          <div class="block text-2xl text-right font-medium">
            Votes
          </div>
          <div class="col-start-2 col-end-10 pt-1">
            <div className="text-lg">
            {votesList.length > 0 ? (votesList.length > 5 ? (votesExpanded ? votesList : votesList.slice(0,5)) : votesList)
              : "This bill has not yet had any votes."}
            </div>
            <button
              className="mt-1 text-lg text-justiceBlue-500 hover:text-justiceBlue-400 focus:outline-none"
              onClick={toggleVotes}>{votesExpanded ? "Show less" : "Show more"}</button>
          </div>
        </div>

        <hr className="border-solid mb-2"/>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Similar Bills
          </div>
          <div class="col-start-2 col-end-8 pt-1">
            <Link
              className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600"
              to={`/org/bills-votes/bills/${billId}/similar`}>
                <div className="flex items-center space-x-2">
                  <h1>View similar bills</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
            </Link>
          </div>
        </div>

        <div class="grid grid-cols-8 space-x-16 items-start">
          <div class="block text-2xl text-right font-medium">
            Related Issues
          </div>
          <div class="col-start-2 col-end-8 pt-1 flex space-x-6 items-center">
            <h1 className="text-lg">
              There are {billData.relatedIssueCount} issues related to this bill.
            </h1>
            <Link className="btn-primary" to={`/org/bills-votes/bills/${billId}/related-issues`}>
              <div className="flex items-center space-x-2">
                <h1>View related issues</h1>
                <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </Link>
          </div>
        </div>

        <div className="bg-gray-100 rounded-lg px-10 py-8 mt-10">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-2xl font-medium">Comments from legislator(s) on this bill:</h1>
              <Link className="text-lg font-medium text-red-500 hover:text-red-400 active:text-red-600" to={`/org/bills-votes/bills/${billId}/comments`}>
                <div className="flex items-center space-x-2">
                  <h1>View all legislator comments</h1>
                  <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </Link>
            </div>
            {legislatorComments}
        </div>

      </div>
    </AppLayout>
  )
}

export default BillPage;
