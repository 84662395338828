import React, { useEffect, useState, useContext } from "react";
// import { Link, NavLink, useHistory } from "react-router-dom";
// import StateIssuesNav from "./StateIssuesNav";
import OrganizationCard from "../global/organizations/OrganizationCard"
// import { db } from "../../../config/firebase";
// import { useAuth } from "../../../contexts/AuthContext";
import {StateDBContext} from "../../../contexts/StateContext"
import firebase from "firebase";
import defaultProfilePic from "../../../assets/DefaultOrgProfilePic.png"

const StateOrganizations = () => {

  const organizations = useContext(StateDBContext);
  let [topOrganizations, setTopOrganizations] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

  useEffect(async () => {

    // console.log("activeIssues:", activeIssues)

    if(organizations[9] && organizations[9].length != 0) {
      // console.log("exists")

      Promise.all(organizations[9].map(async (doc, i) => {
        let orgSrc = defaultProfilePic

        let storageRef = firebase.storage().ref();
        let userPicRef = storageRef.child(`images/organization/profilePics/${doc.id}`)
        try {
          let result = await userPicRef.getDownloadURL()
          orgSrc = result
        } catch(error) {
          console.log(error)
        }

        return (
          <OrganizationCard
          key = {i}
          organizationId={doc.id}
          name={doc.data().name}
          activeIssues={doc.data().activeIssueCount}
          followers={doc.data().followerCount}
          upcomingEvents={doc.data().upcomingEventCount}
          profilePicUrl={orgSrc}
        />
        )
      })).then((result) => {
        setTopOrganizations(result)
      })

    }
      else {
        setTopOrganizations(<h1 className="px-8 py-8 font-medium text-xl">There are currently no organizations in your state.</h1>)
    }

  }, [organizations[9]]);

  return (
    <>
      {/* <StateIssuesNav /> */}
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            {topOrganizations}
          </div>
        </div>
      </>
    );

}


export default StateOrganizations;
