import React, { useState, useEffect } from "react";
import megaphone from '../../../assets/megaphone-icon.png'
import { Link, NavLink, useHistory } from 'react-router-dom';

const AuthPopup = (props) => {

  let mainMessage = "Join the conversation on GrassrootsGov"
  let subMessage = "Add your voice and help make a difference in your community."

  //message switch case based on type of page?
  switch(props.type) {
    case "issue":
      break;
    case "event":
      mainMessage="Sign up for this event on GrassrootsGov"
      subMessage = "Get involved and help make a difference in your community."
      break;
    case "organization":
      mainMessage="Follow this organization on GrassrootsGov"
      subMessage = "Stay updated on their latest events and activity."
      break;
    case "legislator":
      mainMessage="Learn more about this legislator on GrassrootsGov"
      subMessage = "Stay updated on their latest congressional activity and more."
      break;
    case "bill":
      mainMessage="Learn more about this bill on GrassrootsGov"
      subMessage = "Explore related issues and voice your opinions."
      break;
    case "vote":
      mainMessage="Learn more about this vote on GrassrootsGov"
      subMessage = "Explore related issues and voice your opinions."
      break;
    default:
      break;
  }

  return (
    <div className="min-h-screen fixed h-full w-full top-0 left-0 bg-gray-700 bg-opacity-30 z-50">
      <div className="absolute w-full h-full flex flex-col justify-center items-center">
        <div className="bg-white w-1/2 h-1/2 px-7 py-7 rounded-lg shadow">

          <div className="justify-start">
            <button className="focus:outline-none" onClick={props.toggleAuthPopup}>
              <svg className="flex-shrink-0 h-8 w-8 text-justiceBlue-600 hover:text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="py-5 text-center">
            <div className="flex justify-center pb-8">
              <img src={megaphone} className="h-20" />
            </div>
            <div className="text-3xl font-bold pb-2">{mainMessage}</div>
            <div className="text-xl pb-8">{subMessage}</div>
            <div className="flex justify-center pb-2">
              <Link to="/signup" className="inline-flex justify-center items-center w-1/2 py-2 leading-7 font-medium text-white rounded-md bg-justiceBlue-500 shadow-sm hover:bg-justiceBlue-400 active:bg-justiceBlue-600 focus:ring-4 focus:ring-justiceBlue-50 focus:ring-opacity-50 focus:outline-none">Sign up</Link>
            </div>
            <div className="flex justify-center">
              <Link to="/login" className="inline-flex justify-center items-center w-1/2 py-2 leading-7 font-medium text-gray-500 rounded-md border border-gray-300 bg-gray-50 shadow-sm hover:bg-white active:bg-gray-100 focus:ring-4 focus:ring-gray-50 focus:ring-opacity-50 focus:outline-none">Log in</Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AuthPopup;
