import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { db } from '../../../config/firebase'
import { useAuth } from '../../../contexts/AuthContext';
// import ReportCard from '../global/issues/ReportCard';
import DropdownMenu from '../global/issues/DropdownMenu'

import firebase from "firebase";

const CivilianHeader = (props) => {
  let history = useHistory();

  const { currUserRef, currUser } = useAuth();
  const [alreadyFollowing, setAlreadyFollowing] = useState(false);
  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);

  // const [issueInfo, setIssueInfo] = useState({});

  const [ issue, setIssue] = useState({
    following: "issue",
    issueId: props.issueId,
    legislator: props.legislator,
    legislatorId: props.legislatorId,
    dateCreated: props.dateCreated,
    issueTitle: props.title,
    region: props.region,
    // supporters: props.supporters,
    // regionalCopies: props.regionalCopies,
    createdBy: props.createdBy
  });

  // function clickMenu() {
  //   console.log('clicked on menu!');
  //   setMenuOn(!menuOn);
  // }

  // function handleReport() {
  //   console.log('reporting is ', !reporting);
  //   addOverlay();
  //   setReporting(!reporting);
  // }

  // function setMenuOnFalse() {
  //   console.log('mouseleft!');
  //   setMenuOn(false);
  // }

  // function addOverlay() {
  //   const overlay = document.createElement('div');
  //   overlay.setAttribute('id', 'tempOverlay');
  //   overlay.setAttribute(
  //     'class',
  //     'z-50 inset-0 fixed bg-black bg-opacity-50 w-full h-full flex items-center justify-center'
  //   );
  //   const element = (
  //     <ReportCard issueId={props.issueId} handleReport={handleReport} />
  //   );
  //   ReactDOM.render(element, overlay);
  //   document.getElementById('root').appendChild(overlay);
  // }

  useEffect(async () => {
    // TODO store the previous values so that the new value can be added to that list

    if (currUser){
      const issuesFollowingRef = db.collection("civilians").doc(currUser.uid).collection("follows");
      const snapshot = await issuesFollowingRef.where("issueId", '==', props.issueId).get();
      if (!snapshot.empty) {
        setFollowing(true);
      }
    }

    setLoading(false);
  }, []);

  async function followIssue() {
    if (following) {
      db.collection('civilians')
        .doc(currUser.uid)
        .collection('follows')
        .doc(props.issueId)
        .delete()
      .then(() => {
        setFollowing(!following)
      })
    }
    else {
      db.collection('civilians')
        .doc(currUser.uid)
        .collection('follows')
        .doc(props.issueId)
        .set({ ...issue })
      .then(() => {
        setFollowing(!following)
      })
    }
  }

  async function deleteCollection(db, collectionPath, batchSize) {
    const collectionRef = db.collection(collectionPath);
    const query = collectionRef.orderBy('__name__').limit(batchSize);

    return new Promise((resolve, reject) => {
      deleteQueryBatch(db, query, resolve).catch(reject);
    });
  }

  async function deleteQueryBatch(db, query, resolve) {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }

    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      deleteQueryBatch(db, query, resolve);
    });
  }


  function deleteAtPath(deletePath) {
    var deleteFn = firebase.functions().httpsCallable('recursiveDelete');
    console.log(deletePath);
    deleteFn({ path: deletePath })
        .then(function(result) {
            console.log('Delete success: ' + JSON.stringify(result));
        })
        .catch(function(err) {
            console.log('Delete failed, see console,');
            console.log(err);
        });
    history.push(`/home`);
  }

  async function deleteIssue(prop) {
    if (window.confirm("Are you sure you want to delete this issue?")){
      console.log("deleting!");
      // deleteAtPath(prop.path);
      deleteCollection(db, prop.path, 5);
      await db.collection("issues").doc(props.issueId).delete();

      await db.collection("legislators").doc(props.legislatorId).update({issueCount: firebase.firestore.FieldValue.increment(-1)})
      await db.collection("civilians").doc(currUser.uid).update({issueCount: firebase.firestore.FieldValue.increment(-1)})

      const snapshot = await db.collection("civilians").doc(currUser.uid).collection("notes").where("issue.id", '==', props.issueId).get();
      let toDeleteId;
      snapshot.forEach(doc => {
        toDeleteId = doc.data().noteId;
      });
      console.log(toDeleteId);

      await db.collection("civilians").doc(currUser.uid).collection("notes").doc(toDeleteId).delete();

      history.push(`/home`);
    }
    else {
      console.log("not deleting");
    }
  }

  return (
    <>
      <div className="flex justify-between items-end mb-8">
        <div>
          <h1 className='text-3xl font-bold text-gray-900 mb-5'>
            {props.title ? props.title : 'Issue Title'}
          </h1>
          <div className='text-2xl font-regular flex space-x-3 items-center'>
            {/* <img
              className='rounded-full h-8 w-8 mr-2'
              src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60'
            /> */}
            <h1>
              to <Link
                  to={`/legislators/${props.legislatorId}/bills`}
                  className="hover:underline">
                  {props.legislator ? props.legislator : "Legislator"}
                </Link>
            </h1>
            <span> • </span>
            <h1>
              {props.region ? props.region : "Region"}
            </h1>
          </div>
        </div>

        <div className="flex space-x-4 mr-4">
          {currUser && currUser.uid===props.createdBy &&
          <button
            className="relative inline-flex items-center px-4 py-2 leading-7 font-medium text-white rounded-md bg-red-500 shadow-sm hover:bg-red-400 active:bg-red-600 focus:ring-4 focus:ring-red-50 focus:ring-opacity-50 focus:outline-none"
            onClick={() => deleteIssue(props)}>
            Delete Issue
          </button>
          }
          {currUser ?
            <button
              className={following ? "btn-secondary focus:outline-none" : "btn-white focus:outline-none"}
              onClick={followIssue}>
              {following ? "Unfollow Issue" : "Follow Issue"}
            </button>
            :
            <button
              className="btn-secondary focus:outline-none"
              onClick={props.toggleAuthPopup}>
              Follow Issue
            </button>
          }
          {/* <button className='btn-white'>Share</button> */}
          {currUser ? <DropdownMenu contentId={props.issueId} currUserId={currUser.uid} reportType={'issue'} /> : <></>}
        </div>
      </div>

      <hr className='border-solid mb-5' />

      <div className='flex justify-between items-center mb-6'>
        <div className='flex space-x-8 bg-blue-50 px-8 py-5 rounded-lg'>
          <NavLink
            to={`/issues/${props.issueId}/notes`}
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Notes
          </NavLink>
          <NavLink
            to={`/issues/${props.issueId}/learnmore`}
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Learn More
          </NavLink>
          <NavLink
            to={`/issues/${props.issueId}/takeaction`}
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Take Action
          </NavLink>
          <NavLink
            to={`/issues/${props.issueId}/copies`}
            className=' whitespace-no-wrap text-2.5xl font-bold leading-tight text-gray-400 hover:underline'
            activeClassName='text-gray-900'
          >
            Regional Copies
          </NavLink>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default CivilianHeader;
