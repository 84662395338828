import React, { useState, useEffect, useContext } from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import EventCard from '../../global/events/EventCard'
import {DistrictDBContext} from '../../../../contexts/DistrictContext'

const DistrictLatestEvents = () => {

    const latestEvents = useContext(DistrictDBContext);
    let [eventsDisplay, setDisplay] = useState(<h1 className="px-8 py-8 font-medium text-xl">{"Loading..."}</h1>);

    // update component results when data fetched & passed
    useEffect( () => {
        if(latestEvents[6] != null && latestEvents[6].length !== 0) {
          setDisplay(latestEvents[6].map((event, i) => (
            <EventCard
              key={i}
              eventId={event.id}
              title={event.data().eventTitle}
              going={event.data().numAttending}
              interested={event.data().numInterested}
              date={event.data().mainStartTimeUTC}
              time={
                event.data().mainStartTimeString +
                " " +
                event.data().timeZone
              }
              organizer={event.data().organizerName}
              isVirtual={event.data().isVirtual}
              location={`${event.data().city}, ${
                event.data().state
            }`}
            />)));
        }
        else{
          setDisplay(<h1 className="px-8 py-8 font-medium text-xl">There are currently no upcoming events in your district.</h1>)
        }
    }, [latestEvents[6]]);

    return (
      <>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
              {eventsDisplay}
          </div>
        </div>
      </>
    );

}

export default DistrictLatestEvents;
