import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { db } from "../../../../config/firebase";
import OrganizationCard from '../../global/organizations/OrganizationCard'
import firebase from "firebase";
import defaultProfilePic from "../../../../assets/DefaultOrgProfilePic.png"

const HomeOrganizationsFollowing = () => {

  const { currUser, currUserRef } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (currUserRef && organizations.length == 0) {

      db.collection("civilians").doc(currUser.uid).collection("orgsFollowing").get().then((snapshot) => {

        if (snapshot.empty) {
          console.log('No matching documents.');
          setLoading(false);
          return;
        }

        // const tempOrgs = [];

        Promise.all(snapshot.docs.map(async (doc) => {

          let orgSrc = defaultProfilePic

          let storageRef = firebase.storage().ref();
          let userPicRef = storageRef.child(`images/organization/profilePics/${doc.id}`)
          try {
            let result = await userPicRef.getDownloadURL()
            orgSrc = result
          } catch(error) {
            console.log(error)
          }

          return({...doc.data(), orgSrc: orgSrc});
          // console.log(doc.data());

        })).then((result) => {
          setOrganizations(result)
        })

      })
    }
    setLoading(false);
  }, []);

  if(loading) {
    return <div>loading</div>;
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {/* <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="ml-4 mt-2">
              <h3 className="text-3xl font-bold leading-6 text-black">
                Issues Following
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm"> */}
                {/* <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                >
                  + Add New Issue
                </button> */}
                {/* query stuff here
              </span>
            </div>
          </div>
        </div> */}
        <div className="divide-y divide-gray-200">
          {(organizations.length != 0)
          ? organizations.map((tempOrg) => {
            return (
              <OrganizationCard
                organizationId={tempOrg.orgId}
                name={tempOrg.orgName}
                activeIssues={tempOrg.activeIssueCount}
                upcomingEvents={tempOrg.upcomingEventCount}
                followers={tempOrg.followerCount}
                profilePicUrl={tempOrg.orgSrc}
                // districts={tempOrg.name}
              />
            );
            }
          ):
          <h1 className="text-lg py-6 px-6">You are currently not following any organizations.</h1>}
        </div>
      </div>
    </>
  );
};

export default HomeOrganizationsFollowing;
