import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CivWelcomePage = () => {

  // function refreshPage() {
  //   var refresh = window.localStorage.getItem('refresh');
  //   console.log("REFRESH VALUE:", refresh);
  //   if (!refresh) {
  //       console.log("REFRESHING...")
  //       window.location.reload();
  //       window.localStorage.setItem('refresh', "1");
  //   }
  //   else {
  //     console.log("NOT REFRESHING...")
  //   }
  // }

  useEffect(async () => {
    // console.log("loaded!")
    // window.location.reload();
    if( window.localStorage )
  {
    if( !localStorage.getItem('firstLoad') )
    {
      localStorage['firstLoad'] = true;
      window.location.reload();
    }
    else
      localStorage.removeItem('firstLoad');
    }
    // console.log("refreshed!")
  }, [])

  return(
    <div className="min-h-screen">
      <div className="mx-auto w-11/12 py-12 md:py-16 md:w-10/12">

        <h1 className="text-4xl font-bold text-justiceBlue-600">Welcome to GrassrootsGov!</h1>

        <div className="pt-8 pb-4 text-lg">
          <h2>Welcome to the beta version of GrassrootsGov!</h2>
        </div>

        <div className="py-4 text-lg">
          <h2>For the best user experience, we recommend using a laptop or tablet in landscape orientation.</h2>
        </div>

        <div className="py-4 text-lg">
          <h2>
            All posts and user actions must follow our <Link className="text-justiceBlue-600" to="/terms-of-service">Terms of Service</Link> and <Link className="text-justiceBlue-600" to="/community-guidelines">Community Guidelines</Link>.
            We take user privacy seriously and will never show you ads or sell your data. As this is a beta release, though, we are still working on ensuring full data security.
            You can learn how your data will be used through our <Link className="text-justiceBlue-600" to="">Privacy Policy</Link>.
          </h2>
        </div>

        <div className="pt-4 pb-10 text-lg">
          <h2>
            For tutorials on how to get started or use specific features, please visit our <Link className="text-justiceBlue-600" to="/help/civilian">User Help Center</Link>!
            If you encounter any bugs or have any further questions or feedback, please email us at contact@grassrootsgov.org.
            And if you enjoy using the site, please spread the word to others :)
          </h2>
        </div>

        <Link to="/home" className="btn-primary">
          Continue to Site
        </Link>

      </div>
    </div>
  )
}

export default CivWelcomePage;
