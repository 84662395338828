import React, { useState, useEffect, useContext } from 'react';
import AppLayout from '../../../layouts/AppLayout'
import BillCard from '../../../components/civ/global/bills/BillCard'
import BillVoteNav from "../../../components/civ/billsvotes/BillsVotesNav"
import HouseNav from '../../../components/civ/billsvotes/HouseNav';
import {BillVoteAPIContext, BillVoteDBContext} from '../../../contexts/BillVoteContext';
import {db} from "../../../config/firebase"

// TODO: page for each bill w/ react-router

const BillsHouseIntroduced = () => {

  const houseData = useContext(BillVoteDBContext);
  const [billsDisplay, setDisplay] = useState(<p className="h-screen px-8 py-8 font-medium text-2xl">{"Loading..."}</p>);
  const [sort, setSort] = useState("None");

  const [page, setPage] = useState(1);
  const pagination = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [lastVisible, setLast] = useState(null);
  const [items, setItems] = useState({});
  const [lastPage, setLastPage] = useState(0);

  useEffect(async () => {

  if(page.toString() in items) {

    let tempData = items[page.toString()];

    if(tempData.length != 0) {
      setDisplay(
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
          {tempData.map((bill, i) =>
          (<BillCard
            key = {i}
            billId = {bill.id}
            number={bill.data().number}
            title={bill.data().title}
            relatedIssueCount={bill.data().relatedIssueCount}
            sponsorName={bill.data().sponsorName}
            sponsorState={bill.data().sponsorState}
            sponsorParty={bill.data().sponsorParty}
            dateIntroduced={bill.data().dateIntroduced}
            cosponsorCount={bill.data().cosponsorCount}
            />))}
          </div>
        </div>);
    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No bills"}</p>)
    }

  } else if(page == 1) {

    if(houseData[1] != null && houseData[1].length != 0) {

      // copy context data
      let tempData = [...houseData[1]];

      // sort applied
      if(sort == "Number of Cosponsors")
        tempData.sort((a,b) => (a.cosponsors < b.cosponsors) ? 1 :  -1);
      else if(sort == "Number of Issues")
        tempData.sort((a,b) => (a.issues < b.issues) ? 1 : -1);
      else if(sort == "Date Introduced")
        tempData.sort((a,b) => (a.introduced_date < b.introduced_date) ? 1 : -1);

      // create display out of it
      setDisplay(
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
          {tempData.map((bill, i) =>
          (<BillCard
            key = {i}
            billId = {bill.id}
            number={bill.data().number}
            title={bill.data().title}
            relatedIssueCount={bill.data().relatedIssueCount}
            sponsorName={bill.data().sponsorName}
            sponsorState={bill.data().sponsorState}
            sponsorParty={bill.data().sponsorParty}
            dateIntroduced={bill.data().dateIntroduced}
            cosponsorCount={bill.data().cosponsorCount}
            />))}
          </div>
        </div>);

      // add to items
      var tempObj = items;
      tempObj["1"] = [...tempData];
      setItems(tempObj);

      // set last for next query
      setLast(tempData[tempData.length - 1]);
      setLastPage(1);

    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No bills"}</p>)
    }

  } else {

    let wow = lastVisible;
    var i;
    for(i = lastPage + 1; i <= page; i++) {

      // get new data from DB
      let bills = [];

      if(db) {
        const billsRef = db.collection('bills').where("billStatus", "==", "Introduced").where("chamber", "==", "House").orderBy("dateIntroducedUTC", "desc").startAfter(wow).limit(20);
        const snapshot = await billsRef.get();
        if(!snapshot.empty) {
            snapshot.forEach((doc) => { bills.push(doc); });
        } else {
            console.log('empty snapshot');
        }
      }

      if(bills != null && bills.length != 0) {

        // sort applied
        if(sort == "Number of Cosponsors")
          bills.sort((a,b) => (a.cosponsors < b.cosponsors) ? 1 :  -1);
        else if(sort == "Number of Issues")
          bills.sort((a,b) => (a.issues < b.issues) ? 1 : -1);
        else if(sort == "Date Introduced")
          bills.sort((a,b) => (a.introduced_date < b.introduced_date) ? 1 : -1);

        // save display at index 1
        var tempObj = items;
        tempObj[i.toString()] = [...bills];
        setItems(tempObj);

        wow = bills[bills.length - 1];

      } else {
        var tempObj = items;
        tempObj[i.toString()] = [];
        setItems(tempObj);
      }

    }

    setLastPage(i - 1);
    setLast(wow);
    let tempData = items[page.toString()];

    if(tempData != null && tempData.length != 0) {
      setDisplay(
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
          {tempData.map((bill, i) =>
          (<BillCard
            key = {i}
            billId = {bill.id}
            number={bill.data().number}
            title={bill.data().title}
            relatedIssueCount={bill.data().relatedIssueCount}
            sponsorName={bill.data().sponsorName}
            sponsorState={bill.data().sponsorState}
            sponsorParty={bill.data().sponsorParty}
            dateIntroduced={bill.data().dateIntroduced}
            cosponsorCount={bill.data().cosponsorCount}
            />))}
          </div>
        </div>);
    } else {
      setDisplay(<p className="h-screen px-8 py-8 font-medium text-2xl">{"No bills"}</p>)
    }

  }

}, [sort, houseData[1], page]);


  return (
    <AppLayout>
      <BillVoteNav/>
      <div className="mb-px py-8 mx-auto sm:px-8 lg:px-16">
        <HouseNav setSort={setSort}/>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="divide-y divide-gray-200">
          {billsDisplay}
        </div>
        </div>

        <div className="flex justify-between mt-8">
        <div className="text-xl">{page in items && items[page].length} results</div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          {pagination.map((num) => {
            return (
              <button
                className={`${
                  num === page ? "bg-gray-200" : "bg-white"
                } focus:outline-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50`}
                onClick={() => setPage(num)}
              >
                {num.toString()}
              </button>
            );
          })}
        </nav>
      </div>


      </div>
    </AppLayout>
  );
}

export default BillsHouseIntroduced;
