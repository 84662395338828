import React from 'react';
import {Link} from "react-router-dom";

const CategoryCard = (props) => {

  return (
    <Link to={{pathname: "/nation/organizations/list", state: props.name}}>
      <div class="w-full mb-6 text-justiceBlue-500 bg-justiceBlue-100 rounded-lg shadow hover:bg-justiceBlue-50 hover:shadow-md px-8 py-6 flex justify-between items-center">

        <div class="">
          <div class="font-bold text-xl ">{props.name}</div>
          <div class="text-sm ">{props.data.length + (props.data.length != 1 ? " organizations" : " organization")}</div>
        </div>

        <div className="flex items-center space-x-2">
          <h1 className="font-medium ">View organizations</h1>
          <svg className="flex-shrink-0 ml-1.5 mr-1.5 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>

      </div>
    </Link>
  );

}
export default CategoryCard;
